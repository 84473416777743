import React, { useCallback, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { LANGUAGES } from "../../../utils/enums";
import { LanguageIcon } from "../../../assets/icons/header_icons";
import { useTranslation } from "react-i18next";

const LanguageSelectorContainer = styled.div`
  position: relative;
`;

const LanguageSelectButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
`;

const LanguageIconStyled = styled(LanguageIcon)``;

const LanguageMenuContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 1024px) {
    right: 60px;
    top: -32px;
    left: unset;
    transform: unset;
    flex-direction: row;
    border-radius: 20px;
    padding: 10px 10px;
  }
`;

const LanguageMenuItem = styled.div`
  cursor: pointer;
  font-size: 20px;
  line-height: 120%;
  color: ${({ $active }) => ($active ? "#1E1E1E" : "#CACACA")};
  padding: 7px 10px;
  transition: 0.2s;

  ${({ $active }) =>
    !$active &&
    `
     &:hover {
      color: #8e8e8e;
     }
   `};

  @media (max-width: 1024px) {
    border-left: 1px solid rgb(202 202 202 / 52%);
    padding-left: 12px;

    &:first-child {
      border: none;
    }
  }
`;

const HeaderLanguageSelector = ({ userLanguage = "ru" }) => {
  const { i18n } = useTranslation();

  const langMenuRef = useRef();
  const langButtonRef = useRef();

  const [langMenuIsOpened, setLangMenuIsOpened] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleOpenLangMenu = useCallback((event) => {
    event.stopPropagation();
    setLangMenuIsOpened((prevState) => !prevState);
  }, []);

  useOnclickOutside(() => setLangMenuIsOpened(false), {
    refs: [langMenuRef, langButtonRef],
  });

  const handleLanguageChange = (lang) => {
    localStorage.setItem("i18nextLng", lang);
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageSelectorContainer ref={langButtonRef}>
      <LanguageSelectButton
        onClick={handleOpenLangMenu}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <LanguageIconStyled
          fill={
            langMenuIsOpened ? "#383838" : isHovered ? "#6e6e6e" : "#8e8e8e"
          }
        />
      </LanguageSelectButton>

      {langMenuIsOpened && (
        <LanguageMenuContainer ref={langMenuRef}>
          {LANGUAGES.map((lang) => (
            <LanguageMenuItem
              key={lang.code}
              $active={lang.code === userLanguage}
              onClick={() => handleLanguageChange(lang.code)}
            >
              {lang.label}
            </LanguageMenuItem>
          ))}
        </LanguageMenuContainer>
      )}
    </LanguageSelectorContainer>
  );
};

export default HeaderLanguageSelector;
