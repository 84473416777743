import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import axios from "axios";
import { BASE_API_URL } from "../../../common/endpoints";
import Feedbacks from "./admin_course_feedbacks";

const TAB_DISCIPLES = "disciples";
const TAB_PASSING = "passing";
const TAB_FEEDBACKS = "feedbacks";

const ModalWindowWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 968px;
  height: 85%;
  max-height: 900px;
  border-radius: 16px;
  padding-bottom: 28px;
  background: #fff;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Tab = styled.button`
  width: 324px;
  height: 87px;
  padding: 10px;
  border-left: 0.5px solid var(--different-purle-50, #5172ea);
  background: ${({ $active }) =>
    $active
      ? "linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%)"
      : "#f1f1f1"};
  color: ${({ $active }) => ($active ? "#fff" : "#000")};
  border: none;
  cursor: pointer;
  border-radius: ${({ $active }) => ($active ? "8px 8px 0 0" : "0")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;

const TabFeedbacksRating = styled.span`
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

const TabFeedbacksTitle = styled.span`
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const TabDisciplesTitle = styled.span`
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const TabPassingTitle = styled.span`
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 968px;
  height: 60px;
  padding: 18px 27px;
`;

const CourseButton = styled.button`
  display: flex;
  padding: 15px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 84px;
  height: 42px;
  border-radius: 6px;
  background: #725fed;
  border: 1px solid #cacaca;
  padding: 15px 23px;
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  background: #f9f9f9;
  border: 1px solid #ddd;
`;

const AdminCourseModal = ({ course, onClose }) => {
  const [activeTab, setActiveTab] = useState(TAB_FEEDBACKS);
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoadingFeedbacks, setIsLoadingFeedbacks] = useState(false);

  const fetchFeedbacks = useCallback(async () => {
    try {
      const response = await axios.get(
        BASE_API_URL + `/course-feedback?courseId=${course._id}`
      );
      setFeedbacks(response.data);
    } catch (error) {
      console.error("Error on GET /course-feedback:", error);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose(e);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    const loadFeedbacks = async () => {
      setIsLoadingFeedbacks(true);
      await fetchFeedbacks();
      setIsLoadingFeedbacks(false);
    };

    if (activeTab === TAB_FEEDBACKS) {
      loadFeedbacks();
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeTab, onClose]);

  return (
    <ModalWindowWrapper onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Tabs>
          <Tab
            $active={activeTab === TAB_PASSING}
            onClick={() => setActiveTab(TAB_PASSING)}
          >
            <TabPassingTitle>Прохождение</TabPassingTitle>
          </Tab>
          <Tab
            $active={activeTab === TAB_DISCIPLES}
            onClick={() => setActiveTab(TAB_DISCIPLES)}
          >
            <TabDisciplesTitle>Ученики</TabDisciplesTitle>
          </Tab>
          <Tab
            $active={activeTab === TAB_FEEDBACKS}
            onClick={() => setActiveTab(TAB_FEEDBACKS)}
          >
            <TabFeedbacksTitle>Оценка&nbsp;/&nbsp;Отзывы</TabFeedbacksTitle>
            <span>
              <TabFeedbacksRating>
                {course.course_user_rating_value.toFixed(1)}&nbsp;/&nbsp;
                {course.course_user_rating_amount}
              </TabFeedbacksRating>
            </span>
          </Tab>
        </Tabs>
        <ButtonContainer>
          {activeTab === TAB_FEEDBACKS && <CourseButton>Курс</CourseButton>}
        </ButtonContainer>
        <ContentContainer>
          <div>
            {activeTab === TAB_PASSING && <p>В разработке</p>}
            {activeTab === TAB_DISCIPLES && <p>В разработке</p>}
            {activeTab === TAB_FEEDBACKS && <Feedbacks feedbacks={feedbacks} />}
          </div>
        </ContentContainer>
      </ModalContent>
    </ModalWindowWrapper>
  );
};

export default memo(AdminCourseModal);
