import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import AuthModalWindow from "./auth_modal_window";
import { useUserDataContext } from "../../../../user_data_context";
import AskQuestionModalWindow from "../../../../common/components/modal_windows/ask_question_modal_window";
import { useTranslation } from "react-i18next";

const VIDEO_EXAMPLE_URL =
  "https://api.on-bible.com/uploads/f2951e82f630fa5735af.mp4";

const HomePageAskQuestionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 82px;
  height: 204px;
  padding: 12px 29px;
  ${({ marginTop }) => marginTop && "margin-top: 107px;"}
  border-radius: 25px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);

  @media (max-width: 1124px) {
    gap: 50px;
    ${({ smallVariant }) => !smallVariant && "margin: 0 20px;"}
    ${({ marginTop }) => marginTop && "margin-top: 80px;"}
  }

  @media (max-width: 920px) {
    gap: 20px;
    padding: 12px;
  }

  @media (max-width: 744px) {
    flex-direction: column;
    gap: 0;
    height: auto;
    padding: 12px;
    ${({ smallVariant }) => !smallVariant && "margin: 0 12px;"}
    ${({ marginTop }) => marginTop && "margin-top: 20px;"}
  }

  ${({ smallVariant }) =>
    smallVariant &&
    `
    width: 46%;
    min-width: 46%;
    flex-direction: column;
    gap: 14px;
    height: auto;
    padding: 20px;
    margin: 0;
    height: max-content;

   @media (max-width: 744px) {
    width: 100%;
  }
  `}

  ${({ isAuth }) =>
    isAuth &&
    `
      @media (max-width: 1124px) {
         gap: 14px;
      }
  `}
`;

const AskQuestionVideoContainer = styled.div`
  width: 469px;
  border-radius: 15px;
  overflow: hidden;
  height: max-content;

  @media (max-width: 744px) {
    width: 100%;
  }

  ${({ smallVariant }) =>
    smallVariant &&
    `
     width: 100%;
  `}
`;

const AskQuestionVideo = styled.video`
  height: auto;
  width: 100%;
  border-radius: 15px;

  &::-webkit-media-controls {
    display: none !important;
  }

  &::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
`;

const AskQuestionDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 27px;
  width: 386px;
  height: 100%;
  padding: 15px 0px 22px;

  @media (max-width: 744px) {
    width: 100%;
    padding: 20px 0 0;
  }

  ${({ smallVariant }) =>
    smallVariant &&
    `
    width: 100%;
    padding: 0;
    gap: 14px;
  `}
`;

const AskQuestionDescriptionText = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 26px;
`;

const AskQuestionDescriptionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  max-height: 64px;
  width: max-content;
  padding: 0 50px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
  }

  p {
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 744px) {
    width: 100%;
    min-height: 53px;
    max-height: 53px;
  }

  ${({ smallVariant }) =>
    smallVariant &&
    `
    width: 100%;
    min-height: 64px;
    max-height: 64px;
  `}
`;

const HomePageAskQuestion = ({ marginTop = false, smallVariant = false }) => {
  const { t } = useTranslation();

  const { userData } = useUserDataContext();

  const [openUserQuestionModal, setOpenUserQuestionModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const handleAskQuestionModalWindow = useCallback(
    (event) => {
      event.stopPropagation();
      if (userData._id) {
        setOpenUserQuestionModal(true);
      } else {
        setOpenAuthModal(true);
      }
    },
    [userData]
  );

  return (
    <>
      <HomePageAskQuestionContainer
        marginTop={marginTop}
        smallVariant={smallVariant}
        isAuth={userData._id}
      >
        <AskQuestionVideoContainer smallVariant={smallVariant}>
          {VIDEO_EXAMPLE_URL && (
            <AskQuestionVideo
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              disablePictureInPicture
            >
              <source src={VIDEO_EXAMPLE_URL} type={"video/mp4"} />
              Your browser does not support mp4 videos
            </AskQuestionVideo>
          )}
        </AskQuestionVideoContainer>
        <AskQuestionDescription smallVariant={smallVariant}>
          <AskQuestionDescriptionText>
            {t("home-page.ask-question-text")}
          </AskQuestionDescriptionText>
          <AskQuestionDescriptionButton
            smallVariant={smallVariant}
            onClick={handleAskQuestionModalWindow}
          >
            <p>{t("home-page.ask-question-btn")}</p>
          </AskQuestionDescriptionButton>
        </AskQuestionDescription>
      </HomePageAskQuestionContainer>

      {openUserQuestionModal &&
        createPortal(
          <AskQuestionModalWindow
            inComponent={"home"}
            setOpenUserQuestionModal={setOpenUserQuestionModal}
          />,
          document.body
        )}

      {openAuthModal &&
        createPortal(
          <AuthModalWindow onClose={setOpenAuthModal} />,
          document.body
        )}
    </>
  );
};

export default memo(HomePageAskQuestion);
