import React from "react";

const TextAlignmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5625 4.2C1.5625 3.53726 2.09976 3 2.7625 3H21.7625C22.4252 3 22.9625 3.53726 22.9625 4.2C22.9625 4.86274 22.4252 5.4 21.7625 5.4H2.7625C2.09976 5.4 1.5625 4.86274 1.5625 4.2Z"
        fill="#725FED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5625 11.2001C5.5625 10.5374 6.09976 10.0001 6.7625 10.0001H17.7625C18.4252 10.0001 18.9625 10.5374 18.9625 11.2001C18.9625 11.8628 18.4252 12.4001 17.7625 12.4001H6.7625C6.09976 12.4001 5.5625 11.8628 5.5625 11.2001Z"
        fill="#725FED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5625 18.8C3.5625 18.1373 4.09976 17.6 4.7625 17.6H19.7625C20.4252 17.6 20.9625 18.1373 20.9625 18.8C20.9625 19.4627 20.4252 20 19.7625 20H4.7625C4.09976 20 3.5625 19.4627 3.5625 18.8Z"
        fill="#725FED"
      />
    </svg>
  );
};

export default TextAlignmentIcon;
