import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import Checkbox from "../../../../common/components/checkbox";
import CheckIcon from "../../../../common/assets/icons/admin_page_icons/check_icon";
import { COURSE_CERTIFICATE_IMAGES } from "../../../../common/utils/enums";
import BibleCopyRightArrowIcon from "../../../../common/assets/icons/bible_page_icons/bible_copy_right_arrow_icon";
import BibleCopyLeftArrowIcon from "../../../../common/assets/icons/bible_page_icons/bible_copy_left_arrow_icon";
import PlayIcon from "../../../../common/assets/icons/play_icon";
import AdminCourseCertificateModal from "../courses_modal_window/admin_course_certificate_modal";
import DeleteTestModalWindow from "../courses_modal_window/delete_test_modal_window";

const CourseCertificateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #cacaca;
`;

const ViewCourseCertificateButton = styled.div`
  position: absolute;
  top: 13px;
  left: 135px;
  height: 16px;
  width: max-content;
  cursor: pointer;

  &:hover {
    transform: scale(1.14);
    transition: all 0.2s;
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  height: 100%;
  width: max-content;
`;

const InputWrapper = styled.div`
  border: 1px solid rgb(149 120 255 / 50%);
  border-radius: 8px;
  overflow: hidden;
  min-height: max-content;
`;

const CertificateDescriptionInput = styled.textarea`
  resize: none;
  max-height: 136px;
  width: 100%;
  padding: 9px 15px 7px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const CertificateImagesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const CertificateImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ imageUrl }) => `url(${imageUrl})`} no-repeat center/cover;
  cursor: pointer;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.18);
    transition: box-shadow 0.2s;
  }
`;

const CheckedIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: #fff;
  padding: 1px 0 0 0;
  border-radius: 50%;
`;

const CertificateTestContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  h6 {
    position: absolute;
    left: 67px;
    top: 7px;
    color: #828282;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const CertificatePassRateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    width: 42px;
    text-align: center;
    color: #725fed;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
`;

const CertificatePassRateArrow = styled.div`
  padding: 1px 3px 0;
  cursor: pointer;
`;

const CourseCertificate = ({
  courseSettingsData,
  courseSettingsMemoData,
  setCourseSettingsData,
  courseStudentName,
}) => {
  const certificateDescriptionInputRef = useRef(null);
  const [openViewCertificateModal, setOpenViewCertificateModal] =
    useState(false);
  const [openDeleteTestModal, setOpenDeleteTestModal] = useState(false);

  const certificateImageTypes = Object.values(COURSE_CERTIFICATE_IMAGES);

  const handleCourseCertificateEnable = useCallback(
    (event) => {
      event.stopPropagation();

      setCourseSettingsData((prevState) => ({
        ...prevState,
        course_certificate: {
          ...prevState.course_certificate,
          certificate_is_enabled:
            !prevState.course_certificate.certificate_is_enabled,
        },
      }));
    },
    [setCourseSettingsData]
  );

  const handleCertificateDescription = useCallback(
    (event) => {
      setCourseSettingsData((prevState) => ({
        ...prevState,
        course_certificate: {
          ...prevState.course_certificate,
          certificate_description: event.target.value,
        },
      }));
    },
    [setCourseSettingsData]
  );

  const handleCertificateImage = useCallback(
    (event, imageUrl) => {
      event.stopPropagation();

      setCourseSettingsData((prevState) => ({
        ...prevState,
        course_certificate: {
          ...prevState.course_certificate,
          certificate_image: imageUrl,
        },
      }));
    },
    [setCourseSettingsData]
  );

  const handleCourseCertificateTest = useCallback(
    (withTest) => {
      setCourseSettingsData((prevState) => ({
        ...prevState,
        course_certificate: {
          ...prevState.course_certificate,
          certificate_after_test: withTest,
        },
      }));
    },
    [setCourseSettingsData]
  );

  const onClickTestCheckbox = useCallback(() => {
    if (
      courseSettingsMemoData.course_certificate.certificate_after_test &&
      courseSettingsData.course_certificate.certificate_after_test
    ) {
      setOpenDeleteTestModal(true);
    } else if (
      !courseSettingsMemoData.course_certificate.certificate_after_test &&
      courseSettingsData.course_certificate.certificate_after_test
    ) {
      handleCourseCertificateTest(false);
    } else {
      handleCourseCertificateTest(true);
    }
  }, [courseSettingsMemoData, courseSettingsData, handleCourseCertificateTest]);

  const handleCourseTestRate = useCallback(
    (event, arrow) => {
      event.stopPropagation();

      setCourseSettingsData((prevState) => ({
        ...prevState,
        course_certificate: {
          ...prevState.course_certificate,
          certificate_pass_rate:
            arrow === "left"
              ? prevState.course_certificate.certificate_pass_rate - 1
              : prevState.course_certificate.certificate_pass_rate + 1,
        },
      }));
    },
    [
      courseSettingsData.course_certificate?.certificate_pass_rate,
      setCourseSettingsData,
    ]
  );

  const onClickViewCertificateModal = useCallback((event) => {
    event.stopPropagation();
    setOpenViewCertificateModal(true);
  }, []);

  useEffect(() => {
    if (certificateDescriptionInputRef.current) {
      certificateDescriptionInputRef.current.style.height = "auto";
      certificateDescriptionInputRef.current.style.height =
        certificateDescriptionInputRef.current.scrollHeight + "px";
    }
  }, [
    certificateDescriptionInputRef.current,
    courseSettingsData.course_certificate?.certificate_description,
  ]);

  return (
    <CourseCertificateContainer>
      <Checkbox
        checked={courseSettingsData.course_certificate?.certificate_is_enabled}
        onClickCheckbox={handleCourseCertificateEnable}
        fontSize={"14px"}
      >
        Сертификат
      </Checkbox>
      {courseSettingsData.course_certificate?.certificate_is_enabled && (
        <ViewCourseCertificateButton onClick={onClickViewCertificateModal}>
          <PlayIconStyles color={"#F8D254"} />
        </ViewCourseCertificateButton>
      )}

      {courseSettingsData.course_certificate?.certificate_is_enabled && (
        <>
          <CertificateImagesContainer>
            {certificateImageTypes.map((image, index) => {
              return (
                <CertificateImage
                  key={index + 1 + "_certificate_image"}
                  imageUrl={image.smallImage}
                  onClick={(e) => handleCertificateImage(e, image.type)}
                >
                  {courseSettingsData.course_certificate?.certificate_image ===
                    image.type && (
                    <CheckedIconContainer>
                      <CheckIcon />
                    </CheckedIconContainer>
                  )}
                </CertificateImage>
              );
            })}
          </CertificateImagesContainer>

          <CertificateTestContainer>
            <Checkbox
              checked={
                courseSettingsData.course_certificate?.certificate_after_test
              }
              onClickCheckbox={onClickTestCheckbox}
              fontSize={"14px"}
            >
              Тест
            </Checkbox>
            <h6>(проходной процент)</h6>

            {courseSettingsData.course_certificate?.certificate_after_test && (
              <CertificatePassRateContainer>
                {courseSettingsData.course_certificate?.certificate_pass_rate >
                  0 && (
                  <CertificatePassRateArrow
                    onClick={(e) => handleCourseTestRate(e, "left")}
                  >
                    <BibleCopyLeftArrowIcon height={"14"} />
                  </CertificatePassRateArrow>
                )}
                <p>
                  {courseSettingsData.course_certificate?.certificate_pass_rate}
                  %
                </p>
                {courseSettingsData.course_certificate?.certificate_pass_rate <
                  100 && (
                  <CertificatePassRateArrow
                    onClick={(e) => handleCourseTestRate(e, "right")}
                  >
                    <BibleCopyRightArrowIcon height={"14"} />
                  </CertificatePassRateArrow>
                )}
              </CertificatePassRateContainer>
            )}
          </CertificateTestContainer>

          <InputWrapper>
            <CertificateDescriptionInput
              ref={certificateDescriptionInputRef}
              placeholder="Описание"
              type="text"
              rows={1}
              value={
                courseSettingsData.course_certificate.certificate_description
              }
              onChange={handleCertificateDescription}
            />
          </InputWrapper>
        </>
      )}

      {openViewCertificateModal &&
        createPortal(
          <AdminCourseCertificateModal
            courseSettingsData={courseSettingsData}
            courseStudentName={courseStudentName}
            setOpenViewCertificateModal={setOpenViewCertificateModal}
          />,
          document.body
        )}

      {openDeleteTestModal &&
        createPortal(
          <DeleteTestModalWindow
            setOpenDeleteTestModal={setOpenDeleteTestModal}
            handleCourseCertificateTest={handleCourseCertificateTest}
          />,
          document.body
        )}
    </CourseCertificateContainer>
  );
};

export default memo(CourseCertificate);
