import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../common/utils/functions/time_functions";
import { BASE_API_URL } from "../../../common/endpoints";
import axios from "../../../axios_config";
import CertificateDownloadIcon from "../../../common/assets/icons/course_certificate_icons/certificate_download_icon";

const CourseCertificateDownloadButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 16px auto 0;
  height: 48px;
  width: max-content;
  padding: 0 40px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: 0.24px;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 48px;
  text-align: center;
  color: red;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const DownloadCerificateButton = ({ userData, userCourse, courseData }) => {
  const { t } = useTranslation();

  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState("");

  const certificateDate = getFormattedDate(
    userCourse?.course_completed_at || new Date().toISOString()
  );

  const onClickDownloadCertificate = useCallback(
    async (event) => {
      event.stopPropagation();
      setIsDownloading(true);
      setError(null);

      try {
        const certificateData = {
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
          course_name: courseData.course_name,
          certificate_description:
            courseData.course_certificate?.certificate_description,
          certificate_type: courseData.course_certificate?.certificate_image,
          course_completed_at: certificateDate,
        };

        const response = await axios.post(
          `${BASE_API_URL}/courses/certificate`,
          certificateData,
          { responseType: "blob" }
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "certificate.pdf");
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setError("Ошибка при получении сертификата");
      } finally {
        setIsDownloading(false);
      }
    },
    [userData, courseData, certificateDate]
  );

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 4000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <CourseCertificateDownloadButton
      onClick={onClickDownloadCertificate}
      disabled={isDownloading}
    >
      <h6>
        {isDownloading
          ? t("course-page.certificate-btn.loading")
          : t("course-page.certificate-btn.download")}
      </h6>
      <CertificateDownloadIcon />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CourseCertificateDownloadButton>
  );
};

export default memo(DownloadCerificateButton);
