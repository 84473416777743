import React, { useCallback } from "react";
import styled from "styled-components";
import AsLinkVariantIcon from "../../../../assets/icons/bible_modal_window_icons/as_link_variant_icon";
import AsTextVariantIcon from "../../../../assets/icons/bible_modal_window_icons/as_text_variant_icon";
import CheckIcon from "../../../../assets/icons/bible_modal_window_icons/check_icon";
import {
  extractingTextOfBibleVerses,
  insertAsSeparateVerses,
  insertWholeChapter,
  isCombinedVerses,
} from "../../../../utils/functions/bible_page_functions";

const InsertionVariantButtonsContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 259px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const VariantButton = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4.46154px 4.46154px 7.80769px 0px rgba(0, 0, 0, 0.12);
  cursor: not-allowed;

  ${({ enabled }) =>
    enabled &&
    `  cursor: pointer;

  &:hover {
    transform: scale(1.08);
    transition: 0.3s;
  }`}
`;

const InsertionVariantButtons = ({
  currentTranslation,
  currentBibleParams,
  selectedVerses,
  setSelectedVerses,
  setChatInputValue,
  setBibleVersesData,
  handleCloseBibleModalWindow,
  handleAddBibleVerses,
  inComponent,
}) => {
  const enabledButtons =
    !!selectedVerses?.length || inComponent === "text_editor";

  const processedVerseData = selectedVerses.map((verse) => ({
    ...verse,
    verse_text: extractingTextOfBibleVerses(verse.verse_text),
  }));

  const insertAsTextInChatInput = useCallback(
    (e) => {
      if (processedVerseData?.length) {
        const addedVerses = isCombinedVerses(
          processedVerseData,
          currentTranslation?.bible_locale
        );
        const versesAndAddress =
          addedVerses.verseText + " " + `(${addedVerses.verseAddress})`;

        setChatInputValue((prevState) => prevState + versesAndAddress);
        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
      }
    },
    [processedVerseData, setChatInputValue]
  );

  const insertAsLinkInChatInput = useCallback(
    (e) => {
      if (processedVerseData?.length) {
        handleAddBibleVerses(
          isCombinedVerses(processedVerseData, currentTranslation?.bible_locale)
        );
        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
      }
    },
    [processedVerseData]
  );

  const insertVerseDefault = useCallback(
    (e, currentTranslation) => {
      if (
        processedVerseData?.length &&
        inComponent !== "create_lesson-bible_settings"
      ) {
        handleAddBibleVerses(
          isCombinedVerses(processedVerseData, currentTranslation?.bible_locale)
        );

        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
        setBibleVersesData({});
      }

      if (!processedVerseData?.length && inComponent === "text_editor") {
        handleAddBibleVerses(
          insertWholeChapter(
            currentBibleParams,
            currentTranslation?.bible_locale
          )
        );
      }

      if (
        processedVerseData?.length &&
        inComponent === "create_lesson-bible_settings"
      ) {
        handleAddBibleVerses(
          insertAsSeparateVerses(
            currentBibleParams,
            processedVerseData,
            currentTranslation?.bible_locale
          )
        );

        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
        setBibleVersesData({});
      }
    },
    [processedVerseData, inComponent, currentBibleParams]
  );

  return (
    <InsertionVariantButtonsContainer>
      {inComponent === "chat_page" ? (
        <>
          <VariantButton
            enabled={enabledButtons}
            onClick={insertAsTextInChatInput}
          >
            <AsTextVariantIcon />
          </VariantButton>
          <VariantButton
            enabled={enabledButtons}
            onClick={insertAsLinkInChatInput}
          >
            <AsLinkVariantIcon />
          </VariantButton>
        </>
      ) : (
        <VariantButton
          enabled={enabledButtons}
          onClick={(e) => insertVerseDefault(e, currentTranslation)}
        >
          <CheckIcon />
        </VariantButton>
      )}
    </InsertionVariantButtonsContainer>
  );
};

export default InsertionVariantButtons;
