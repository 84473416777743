import React, { useCallback, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import onBibleChatAvatar from "../../../common/assets/images/on_bible_chat_avatar.png";
import { BASE_API_URL } from "../../../common/endpoints";
import { useNavigate } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { useTranslation } from "react-i18next";

const CourseFeedbackModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  @media (max-width: 530px) {
    padding: 10px;
  }
`;

const CourseFeedbackModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 522px;
  padding: 40px;
  border-radius: 25px;
  background: #fff;

  @media (max-width: 530px) {
    width: 100%;
    border-radius: 15px;
    padding: 25px;
  }
`;

const OnBibleMessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  width: 88%;
`;

const OnBibleMessage = styled.div`
  padding: 10px 14px 8px 18px;
  border-radius: 15px;
  background: #725fed;

  h6 {
    margin-bottom: 3px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.1px;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
`;

const OnBibleMessageImage = styled.div`
  min-width: 27px;
  width: 27px;
  height: 27px;
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;
`;

const CourseFeedbackInputContainer = styled.div`
  border-radius: 15px;
  border: 2px solid #725fed;
  height: 130px;
  overflow: hidden;
`;

const CourseFeedbackInput = styled.textarea`
  resize: none;
  padding: 6px 10px 6px 14px;
  width: 100%;
  height: 100%;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const SendFeedbackModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  transition: all 0.2s;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.2s;
  }
`;

const CourseFeedbackModal = ({
  courseData,
  userData,
  setCourseFeedbackModal,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [courseFeedbackValue, setCourseFeedbackValue] = useState("");

  const handleCourseFeedbackValue = useCallback((event) => {
    setCourseFeedbackValue(event.target.value);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setCourseFeedbackModal(false);
  }, []);

  const onClickSendFeedbackButton = useCallback(
    async (e) => {
      e.stopPropagation();

      const userFeedbackData = {
        course: { _id: courseData._id },
        user: {
          _id: userData._id,
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
        },
        course_feedback: courseFeedbackValue,
      };

      try {
        await axios.post(`${BASE_API_URL}/course-feedback`, userFeedbackData);
        navigate(APPLICATION_ROUTES.STUDY_COURSES);
      } catch (error) {
        console.error("Ошибка отправки отзыва о курсе:", error);
      }
    },
    [userData, courseData, courseFeedbackValue]
  );

  return (
    <CourseFeedbackModalWrapper onClick={handleClose}>
      <CourseFeedbackModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <OnBibleMessageContainer>
          <OnBibleMessageImage background={onBibleChatAvatar} />
          <OnBibleMessage>
            <h6>OnBible</h6>
            <p>
              {t("lesson-page.course-feedback-modal.title", {
                course_name: courseData.course_name,
              })}
            </p>
            <p>{t("lesson-page.course-feedback-modal.request")}</p>
          </OnBibleMessage>
        </OnBibleMessageContainer>

        <CourseFeedbackInputContainer>
          <CourseFeedbackInput
            placeholder={t(
              "lesson-page.course-feedback-modal.input-placeholder"
            )}
            value={courseFeedbackValue}
            onChange={handleCourseFeedbackValue}
          />
        </CourseFeedbackInputContainer>

        <SendFeedbackModalButton onClick={onClickSendFeedbackButton}>
          {t("lesson-page.course-feedback-modal.send-btn")}
        </SendFeedbackModalButton>
      </CourseFeedbackModalContainer>
    </CourseFeedbackModalWrapper>
  );
};

export default CourseFeedbackModal;
