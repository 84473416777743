import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import DeleteQuestionModalWindow from "../../../../common/components/modal_windows/delete_modal_window";
import AdminPageDeleteIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_delete_icon";
import { AdminPageSettingsDeleteButton } from "../../admin_page_components/admin_page_styled_components";
import UnsavedChangesModalWindow from "../../../../common/components/modal_windows/unsaved_changes_modal_window";
import AdminPageSettingsButtons from "../../admin_page_components/admin_page_settings_buttons";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";
import { useUserDataContext } from "../../../../user_data_context";

const QuestionSettingsButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const QuestionSettingsButtonsWrapper = styled.div`
  position: relative;
  z-index: 19;
`;

const QuestionSettingsButtons = ({
  questionSettingsData,
  questionSettingsMemoData,
  openEditQuestionModal,
  setOpenEditQuestionModal,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
  openUnsentAnswerModal,
  setOpenUnsentAnswerModal,
  onClickSendAnswerButton,
  setTextEditorKey,
  closeAllSettings,
  fetchQuestions,
}) => {
  const { userData } = useUserDataContext();

  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [hasNewQuestionAnswer, setHasNewQuestionAnswer] = useState(false);

  useEffect(() => {
    if (
      !compareObjects(
        questionSettingsData.question_answer?.answer_text,
        questionSettingsMemoData.question_answer?.answer_text
      )
    ) {
      setHasNewQuestionAnswer(true);
    } else {
      setHasNewQuestionAnswer(false);
    }
  }, [questionSettingsData, questionSettingsMemoData]);

  useEffect(() => {
    setShowCheckIcon(
      compareObjects(questionSettingsData, questionSettingsMemoData)
    );
  }, [questionSettingsData, questionSettingsMemoData]);

  const onClickSaveButton = useCallback(async () => {
    if (showCheckIcon) return;

    if (hasNewQuestionAnswer) {
      setOpenUnsentAnswerModal(true);
      return;
    }

    let savedData = { ...questionSettingsData };

    if (questionSettingsData.question_answer?.answer_created_at) {
      savedData.question_status = "answered";
    } else {
      savedData.question_status = "noanswer";
    }

    try {
      const response = await axios.patch(
        `${BASE_API_URL}/questions/${questionSettingsData._id}`,
        savedData
      );

      setShowCheckIcon(true);
      setQuestionSettingsData(response.data);
      setQuestionSettingsMemoData(response.data);

      fetchQuestions();
    } catch (error) {
      console.error("Error making PATCH request:", error);
    }
  }, [
    questionSettingsData,
    userData,
    hasNewQuestionAnswer,
    fetchQuestions,
    showCheckIcon,
  ]);

  const onClickNotSaveButton = useCallback(
    async (event) => {
      event.stopPropagation();

      const prevAnswerData =
        questionSettingsMemoData.question_answer.answer_text;

      const updatedSettingsData = {
        ...questionSettingsData,
        question_answer: {
          ...questionSettingsData.question_answer,
          answer_text: prevAnswerData,
        },
      };

      try {
        const response = await axios.patch(
          `${BASE_API_URL}/questions/${questionSettingsData._id}`,
          updatedSettingsData
        );

        setShowCheckIcon(true);
        setOpenUnsentAnswerModal(false);
        setOpenEditQuestionModal(false);
        setQuestionSettingsData(response.data);
        setQuestionSettingsMemoData(response.data);
        setTextEditorKey((prevState) => prevState + 1);

        fetchQuestions();
      } catch (error) {
        console.error("Error making PATCH request:", error);
      }
    },
    [questionSettingsData, questionSettingsMemoData, setTextEditorKey]
  );

  const onClickDeleteQuestion = useCallback(() => {
    setOpenDeleteQuestionModal(true);
  }, []);

  const handleDeleteQuestion = useCallback(async () => {
    try {
      await axios.delete(
        `${BASE_API_URL}/questions/${questionSettingsData._id}`
      );
      closeAllSettings();
      fetchQuestions();
    } catch (error) {
      console.error("Ошибка при удалении вопроса:", error);
    }
  }, [questionSettingsData._id, closeAllSettings, fetchQuestions]);

  const onClickModalSaveButton = async () => {
    if (hasNewQuestionAnswer) {
      setOpenUnsentAnswerModal(true);
      return;
    } else {
      await onClickSaveButton();
      closeAllSettings();
    }
  };

  return (
    <QuestionSettingsButtonsWrapper>
      <QuestionSettingsButtonsContainer>
        <AdminPageSettingsButtons
          showCheckIcon={showCheckIcon}
          onClickSaveButton={onClickSaveButton}
          onClickCancelButton={closeAllSettings}
        />

        <AdminPageSettingsDeleteButton onClick={onClickDeleteQuestion}>
          <AdminPageDeleteIcon />
        </AdminPageSettingsDeleteButton>
      </QuestionSettingsButtonsContainer>

      {openEditQuestionModal && (
        <UnsavedChangesModalWindow
          key={"unsave"}
          setOpen={setOpenEditQuestionModal}
          onSaveSettingsButton={onClickModalSaveButton}
          onClickNotSaveButton={closeAllSettings}
        />
      )}

      {openUnsentAnswerModal && (
        <UnsavedChangesModalWindow
          key={"unsent_answer"}
          type={"unsent_answer"}
          setOpen={setOpenUnsentAnswerModal}
          onSaveSettingsButton={(e) => onClickSendAnswerButton(e, "all")}
          onClickNotSaveButton={onClickNotSaveButton}
        />
      )}

      {openDeleteQuestionModal && (
        <DeleteQuestionModalWindow
          setOpen={setOpenDeleteQuestionModal}
          handleDelete={handleDeleteQuestion}
          deletionObject={"вопрос"}
        />
      )}
    </QuestionSettingsButtonsWrapper>
  );
};

export default memo(QuestionSettingsButtons);
