import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import axios from "../../../../axios_config";
import QuestionInput from "./question_input";
import QuestionBibleVerses from "./question_bible_verses";
import AskQuestionRecipient from "./question_recipient";
import AskQuestionButtons from "./ask_question_buttons";
import { BASE_API_URL } from "../../../endpoints";
import { useUserDataContext } from "../../../../user_data_context";

const ASK_QUESTION_DEFAULT_DATA = {
  question_text: "",
  question_bible_verses: [1],
  question_from_author: {},
  question_from_author_mentor: {},
  question_to_author: {},
  question_status: "noanswer",
  question_tags: "",
  question_answer: {},
  question_from_place: "",
  question_is_favorite: false,
};

export const AskQuestionLabel = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 8px;
  font-size: 10px;
  color: ${({ limitReached }) => (limitReached ? "red" : "#828282")};

  @media (max-width: 440px) {
    font-size: 12px;
  }
`;

export const AskQuestionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: max-content;
  padding: 20px 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: 0.24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.15);
  }
`;

const AskQuestionModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 9999;
`;

const AskQuestionModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 480px;
  min-height: 310px;
  max-height: 94vh;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
  cursor: default;
  z-index: 9999;

  @media (max-width: 440px) {
    width: 95%;
    min-height: max-content;
    padding: 15px;
  }

  h3 {
    color: #1e1e1e;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    width: 95%;
  }
`;

const FinalModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 21px;
  width: 420px;
  min-height: 170px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
  cursor: default;
  z-index: 10001;

  @media (max-width: 440px) {
    width: 94%;
    margin: 0 auto;
    padding: 15px;
  }
`;

const FinalModalWindowTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  text-align: center;
  color: #1e1e1e;

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;

    span {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
    }
  }
`;

const AskQuestionModalWindow = ({
  inComponent,
  setOpenUserQuestionModal,
  courseInfoForQuestion = {},
  courseAuthors = [],
}) => {
  const { t } = useTranslation();

  const { userData } = useUserDataContext();

  const [userQuestionData, setUserQuestionData] = useState(
    ASK_QUESTION_DEFAULT_DATA
  );
  const [currentModal, setCurrentModal] = useState("question"); // question, final
  const [activeSendButton, setActiveSendButton] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [error, setError] = useState(false);

  const maxQuestionLength = 1000;

  useEffect(() => {
    if (userQuestionData.question_text?.trim().length >= maxQuestionLength) {
      setLimitReached(true);
    } else {
      setLimitReached(false);
    }
  }, [userQuestionData.question_text]);

  const onCloseAskQuestionModalWindow = useCallback((event) => {
    event.stopPropagation();
    setOpenUserQuestionModal(false);
  }, []);

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        onCloseAskQuestionModalWindow(e);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const onCreateNewQuestion = useCallback(
    async (userQuestionData) => {
      if (limitReached) {
        return;
      }

      try {
        const sanitizedQuestionText = DOMPurify.sanitize(
          userQuestionData.question_text
        );

        const newQuestionData = {
          ...userQuestionData,
          question_text: sanitizedQuestionText,
          question_bible_verses:
            userQuestionData.question_bible_verses?.[0] === 1
              ? []
              : userQuestionData.question_bible_verses,
          question_from_author: {
            _id: userData._id,
            user_name: userData.user_name,
            user_second_name: userData.user_second_name,
            user_email: userData.user_email,
          },
          question_from_author_mentor: userData.user_mentor,
          question_from_place: inComponent,
          ...(inComponent === "course" && {
            question_from_place_data: courseInfoForQuestion,
            question_to_author: courseAuthors.length ? courseAuthors?.[0] : "",
          }),
        };

        await axios.post(BASE_API_URL + "/questions", newQuestionData);

        setCurrentModal("final");
      } catch (error) {
        console.error("Error create question request:", error);
        setError(true);
        setTimeout(() => setError(false), 3000);
      }
    },
    [userData, inComponent, limitReached, courseInfoForQuestion, courseAuthors]
  );

  useEffect(() => {
    if (userQuestionData.question_text) {
      setActiveSendButton(true);
    } else setActiveSendButton(false);
  }, [userQuestionData.question_text]);

  return (
    <AskQuestionModalWindowWrapper onMouseDown={onCloseAskQuestionModalWindow}>
      {currentModal === "question" && (
        <AskQuestionModalWindowContainer
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {inComponent === "course" && <h3>{t("ask-question-modal.title")}</h3>}

          <QuestionInput
            userQuestionData={userQuestionData}
            setUserQuestionData={setUserQuestionData}
            limitReached={limitReached}
            setLimitReached={setLimitReached}
          />
          <QuestionBibleVerses
            userQuestionData={userQuestionData}
            setUserQuestionData={setUserQuestionData}
          />
          {inComponent !== "course" && (
            <AskQuestionRecipient
              userQuestionData={userQuestionData}
              setUserQuestionData={setUserQuestionData}
              userData={userData}
            />
          )}
          <AskQuestionButtons
            activeSendButton={activeSendButton}
            onCreateNewQuestion={onCreateNewQuestion}
            userQuestionData={userQuestionData}
            error={error}
            onCloseAskQuestionModalWindow={onCloseAskQuestionModalWindow}
          />
        </AskQuestionModalWindowContainer>
      )}

      {currentModal === "final" && (
        <FinalModalWindowContainer>
          <FinalModalWindowTitle>
            <h4>{t("ask-question-modal.congratulations")}</h4>
            <h5>{t("ask-question-modal.question-successed-sent")}</h5>
            <p>
              {t("ask-question-modal.answer-in-profile.p")}{" "}
              <span>{t("ask-question-modal.answer-in-profile.span")}</span>
            </p>
          </FinalModalWindowTitle>
          <AskQuestionButton onClick={onCloseAskQuestionModalWindow}>
            {t("ask-question-modal.thank-btn")}
          </AskQuestionButton>
        </FinalModalWindowContainer>
      )}
    </AskQuestionModalWindowWrapper>
  );
};

export default memo(AskQuestionModalWindow);
