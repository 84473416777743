import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import VerticalLine from "../../../../../assets/icons/vertical_line";
import { BASE_API_URL } from "../../../../../endpoints";
import BibleModalWindowVerse from "./bible_modal_window_verse";
import SpinnerLoader from "../../../../loaders/spinner_loader";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../../../pages/bible/bible_page_data";
import BibleChapterArrows from "./bible_chapter_arrows";
import BibleModalStrongsCode from "./bible_modal_strongs_code";
import BibleStrongModalWindow from "../../../bible_strong_modal_window";
import { createPortal } from "react-dom";
import { useBibleModalWindowContext } from "../../bible_modal_window_context";
import { useTranslation } from "react-i18next";
import BibleModalWindowSidebar from "../../../bible_modal_window_sidebar";
import { extractingTextOfBibleVerses } from "../../../../../utils/functions/bible_page_functions";
import { useNavigate } from "react-router-dom";

const BibleChapterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  padding: 12px 1px 0 12px;
  border-radius: 15px;
  border: 2px solid var(--solid-colors-white, #fff);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const BibleChapterAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  gap: 13px;
`;

const BibleChapterAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  width: 215px;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 140%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BibleVerses = styled.div`
  padding-bottom: 100px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  @media (max-width: 1024px) {
    ::-webkit-scrollbar {
      width: 2px;
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

export const StrongButton = styled.div`
  display: flex;
  align-items: center;
  color: #1e1e1e;
  font-size: 12px;
  background: #fff;
  padding: 0 13px;
  height: 24px;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;

  ${({ showStrongsCode }) =>
    showStrongsCode &&
    `
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  `}

  ${({ showStrongsCode }) =>
    !showStrongsCode &&
    `
    &: hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  }
  `}

   @media (max-width: 744px) {
    height: 32px;
    font-size: 13px;
  }
`;

export const modalTransformTextWithStrongCode = (text) => {
  const cleanedText = text
    .replace(/<J>/g, "")
    .replace(/<\/J>/g, "")
    .replace(/<R>/g, "")
    .replace(/<\/R>/g, "")
    .replace(/<br\/?>/g, "")
    .replace(/<n>.*?<\/n>/g, "");

  const parts = cleanedText.split(/(<S>\d+<\/S>)/g);

  return parts.map((part, index) => {
    if (/<S>(\d+)<\/S>/.test(part)) {
      const code = part.replace(/<\/?S>/g, "");
      return <BibleModalStrongsCode key={index}>{code}</BibleModalStrongsCode>;
    }

    return <span key={index}>{part}</span>;
  });
};

const BibleModalWindowVerses = ({
  selectedVerses,
  setSelectedVerses,
  currentBibleParams,
  currentTranslation,
  searchWords,
  currentTranslationData,
  setCurrentBibleParams,
  bibleFontSettings,
  inComponent,
  handleCloseBibleModalWindow,
  fromSearchToBiblePage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  } = useBibleModalWindowContext();

  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [selectedVersesData, setSelectedVersesData] = useState([]);

  const abbreviation = chapterData?.[0]?.bible_code.toUpperCase();
  const bibleChapterAddress =
    (BIBLE_BOOK_NAMES_DATA?.[currentTranslation?.bible_locale]?.[
      currentBibleParams?.bookId
    ]?.secondName ||
      BIBLE_BOOK_NAMES_DATA?.[currentTranslation?.bible_locale]?.[
        currentBibleParams?.bookId
      ]?.name) +
    " " +
    currentBibleParams?.chapterId;

  const shouldRenderContent = !isLoading && chapterData.length > 0;

  const strongButtonName = t("bible-page.strong-btn");

  const bibleId = currentBibleParams?.bibleId;
  const bookId = currentBibleParams?.bookId;
  const chapterId = currentBibleParams?.chapterId;
  const selectBible = currentBibleParams?.bibleId;
  const selectVerses = selectedVerses?.map((verse) => verse.verse_num).join("");
  const versePathInBiblePage = `/bible/?bibleId=${bibleId}&bookId=${bookId}&chapterId=${chapterId}&selectVerses=${selectVerses}&selectBible=${selectBible}`;

  useEffect(() => {
    if (chapterData.length) {
      setCurrentTestament(chapterData?.[0]?.book_place);
    }
  }, [chapterData]);

  useEffect(() => {
    if (chapterData.length && selectedVerses.length) {
      const selectedVersesData = chapterData
        .filter((verse) =>
          selectedVerses.some(
            (selected) => selected.verse_num === verse.verse_num
          )
        )
        .map((verse) => {
          const isString = (value) => typeof value === "string";

          return {
            ...verse,
            verse_text: isString(verse.verse_text)
              ? verse.verse_text
              : extractingTextOfBibleVerses(verse.verse_text),
          };
        });

      setSelectedVersesData(selectedVersesData);
    }
  }, [chapterData, selectedVerses]);

  const chapterFetch = useCallback(async ({ bibleId, bookId, chapterId }) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const parsedChapter = response.data.verses.map((verse) => ({
        ...verse,
        verse_text: modalTransformTextWithStrongCode(verse.verse_text),
      }));

      setChapterData(parsedChapter);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentBibleParams.bibleId) {
      chapterFetch(currentBibleParams);
    }
  }, [currentBibleParams]);

  const handleShowStrongsCodes = useCallback((event) => {
    event.stopPropagation();
    setShowStrongsCode((prevState) => !prevState);
  }, []);

  const onClickBibleAddress = useCallback(
    (event) => {
      event.stopPropagation();
      handleCloseBibleModalWindow(event);
      navigate(versePathInBiblePage);
      fromSearchToBiblePage();
    },
    [versePathInBiblePage]
  );

  return (
    <BibleChapterContainer>
      <BibleChapterAddressWrapper>
        <BibleChapterAddressContainer onClick={onClickBibleAddress}>
          {shouldRenderContent && (
            <>
              <BibleTranslationVersion>{abbreviation}</BibleTranslationVersion>
              <VerticalLineStyled />
              <BibleChapterAddress>{bibleChapterAddress}</BibleChapterAddress>
            </>
          )}
        </BibleChapterAddressContainer>

        <BibleChapterArrows
          chapterData={chapterData}
          currentTranslationData={currentTranslationData}
          currentBibleParams={currentBibleParams}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
        />

        {currentTranslation?.bible_code === "rst" && (
          <StrongButton
            showStrongsCode={showStrongsCode}
            onClick={handleShowStrongsCodes}
          >
            {strongButtonName}
          </StrongButton>
        )}
      </BibleChapterAddressWrapper>

      {!isLoading ? (
        <BibleVerses>
          {chapterData.map((verse) => (
            <BibleModalWindowVerse
              key={verse._id}
              verse={verse}
              bibleFontSettings={bibleFontSettings}
              searchWords={searchWords}
              selectedVerses={selectedVerses}
              setSelectedVerses={setSelectedVerses}
              inComponent={inComponent}
            />
          ))}
        </BibleVerses>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {!!strongsCodeModalData &&
        currentTranslation?.bible_strong &&
        createPortal(
          <BibleStrongModalWindow
            strongsCodeModalData={strongsCodeModalData}
            currentTranslation={currentTranslation}
            currentTestament={currentTestament}
            setOpen={setStrongsCodeModalData}
            setShowStrongsCode={setShowStrongsCode}
          />,
          document.body
        )}

      {inComponent === "search_modal" && !!selectedVerses.length && (
        <BibleModalWindowSidebar
          selectedVerses={selectedVersesData}
          bibleLocale={currentTranslation?.bible_locale}
          openCopyModal={openCopyModal}
          setOpenCopyModal={setOpenCopyModal}
          inComponent={inComponent}
        />
      )}
    </BibleChapterContainer>
  );
};

export default memo(BibleModalWindowVerses);
