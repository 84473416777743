import React, { memo } from "react";
import GroupPreview from "./group_preview";
import GroupsListHeader from "./groups_list_header";
import styled from "styled-components";

const GroupsListWrapper = styled.div`
  position: relative;
  flex: 1;
  padding-top: 42px;
  height: max-content;
  margin: 0 auto;
  padding-bottom: 120px;
`;

const GroupsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 630px;
  padding-top: 28px;
  margin-left: 64px;
`;

const GroupsList = () => {
  return (
    <GroupsListWrapper>
      <GroupsListHeader />
      <GroupsListContainer>
        <GroupPreview />
      </GroupsListContainer>
    </GroupsListWrapper>
  );
};

export default memo(GroupsList);
