import React, { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import FullscreenIcon from "../../../../../common/assets/icons/fullscreen_icon";

const QuestionModalButton = styled.div`
  ${({ unselected }) =>
    !unselected &&
    `
   cursor: pointer;
   transition: 0.2s;

   &:hover {
     transform: scale(1.12);
  }
`}
`;

const QuestionModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  cursor: pointer;
`;

const QuestionModalWindowContainer = styled.div`
  border-radius: 16px;
  width: 100%;
  max-width: 950px;
  max-height: 90%;
  margin: 40px;
  box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  cursor: default;
  overflow: hidden;
`;

const UserQuestionContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 26px 30px;
  width: 100%;
  overflow: auto;
  max-height: 90vh;
`;

const UserQuestionText = styled.div`
  color: #1e1e1e;
  font-size: 21px;
  line-height: 140%;
  padding-right: 55px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
`;

const UserQuestionBibleVerses = styled.div`
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const BibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 0 10px;
  color: #383838;
  font-size: 18px;
  font-style: normal;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #eaeaea;
  }
`;

const QuestionModalWindow = ({
  question,
  unselected,
  setTextBibleModalData,
}) => {
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const questionBibleVerses = question.question_bible_verses;

  const handleOpenQuestionModal = useCallback(
    (event) => {
      if (!unselected) {
        event.stopPropagation();
        setOpenQuestionModal((prevState) => !prevState);
      }
    },
    [question, unselected]
  );

  const handleClickVerseAddress = useCallback((event, verses) => {
    event.stopPropagation();

    const selectedVerses = verses.versesData
      .map((verse) => verse.verse_num)
      .join(",");

    const bibleParams = {
      bibleId: verses.versesParams.bibleId,
      bookId: verses.versesParams.bookId,
      chapterId: verses.versesParams.chapterId,
      selectedVerses,
    };

    setTextBibleModalData(bibleParams);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <QuestionModalButton
        unselected={unselected}
        onClick={handleOpenQuestionModal}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <FullscreenIcon />
      </QuestionModalButton>

      {openQuestionModal &&
        createPortal(
          <QuestionModalWindowWrapper
            onMouseDown={handleOpenQuestionModal}
            onClick={(e) => e.stopPropagation()}
          >
            <QuestionModalWindowContainer
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <UserQuestionContent>
                <UserQuestionText>{question?.question_text}</UserQuestionText>

                {!!questionBibleVerses.length && (
                  <UserQuestionBibleVerses
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    {questionBibleVerses.map((verses, index) => {
                      return (
                        <BibleVerseAddress
                          key={index}
                          onClick={(e) => handleClickVerseAddress(e, verses)}
                        >
                          {verses.verseAddress}
                        </BibleVerseAddress>
                      );
                    })}
                  </UserQuestionBibleVerses>
                )}
              </UserQuestionContent>
            </QuestionModalWindowContainer>
          </QuestionModalWindowWrapper>,
          document.body
        )}
    </div>
  );
};

export default QuestionModalWindow;
