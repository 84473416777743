import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ProgressBarModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  @media (max-width: 530px) {
    padding: 10px;
  }
`;

const ProgressBarModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 475px;
  padding: 16px 24px;
  border-radius: 15px;
  background: #fff;
  user-select: none;
  text-align: center;

  @media (max-width: 530px) {
    width: 100%;
    border-radius: 15px;
    padding: 25px;
  }

  & > h5 {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 144%;
  }
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 20px;
  border: 1px solid #5172ea;
  border-radius: 54px;
  overflow: hidden;
`;

const ProgressBarFiller = styled.div`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: #5172ea;
  transition: width 1.8s ease-in-out;
`;

const CertificateProgressBarModal = ({ setCertificateProgressBarModal }) => {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressTimer = setTimeout(() => {
      setProgress(100);
    }, 100);

    const closeModalTimer = setTimeout(() => {
      setCertificateProgressBarModal(false);
    }, 2000);

    return () => {
      clearTimeout(progressTimer);
      clearTimeout(closeModalTimer);
    };
  }, [setCertificateProgressBarModal]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setCertificateProgressBarModal(false);
  }, []);

  return (
    <ProgressBarModalWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ProgressBarModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h5>{t("lesson-page.progress-bar-modal")}</h5>
        <ProgressBarWrapper>
          <ProgressBarFiller width={progress} />
        </ProgressBarWrapper>
      </ProgressBarModalContainer>
    </ProgressBarModalWrapper>
  );
};

export default CertificateProgressBarModal;
