import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import AdminPageRemoveFilterIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_remove_filter_icon";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../bible/bible_page_data";
import { monthsOfYear } from "./questions_filter_by_date/calendar_dropdown_months";
import { useTranslation } from "react-i18next";

const QuestionsSelectedFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  width: 100%;
`;

const QuestionsSelectedFiltersItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 8px;
  height: 28px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(81, 114, 234, 0.3) -18.38%,
    rgba(122, 90, 238, 0.3) 108.82%
  );
  color: #fff;
  font-size: 12px;
  line-height: 16px;
`;

const RemoveFilterIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.12);
    transition: 0.2s;
  }
`;

const QuestionsSelectedFilters = ({
  questionsFilterSettings,
  removeSelectedFilterOption,
  accessRights,
}) => {
  const { t } = useTranslation();

  const [filterByStatus, setFilterByStatus] = useState("");
  const [sortByAnswerDate, setSortByAnswerDate] = useState("");
  const [filterByPlace, setFilterByPlace] = useState("");
  const [filterByRecipient, setFilterByRecipient] = useState([]);
  const [filterByDate, setFilterByDate] = useState([]);
  const [filterByBible, setFilterByBible] = useState([]);
  const [filterBySearchWords, setFilterBySearchWords] = useState([]);
  const [filterByAuthor, setFilterByAuthor] = useState("");

  const status = questionsFilterSettings?.question_status;
  const sort = questionsFilterSettings?.question_by_answer_date;
  const recipients = questionsFilterSettings?.question_recipient;
  const dates = questionsFilterSettings?.question_date_range;
  const biblePlaces = questionsFilterSettings?.question_bible;
  const searchWords = questionsFilterSettings?.search_words;
  const place = questionsFilterSettings?.question_place;
  const author = questionsFilterSettings?.question_by_author;

  useEffect(() => {
    if (status && status === "noanswer") {
      setFilterByStatus(
        t("common-components.questions-filter-by-status.noanswer")
      );
    } else if (status && status === "answered") {
      setFilterByStatus(
        t("common-components.questions-filter-by-status.answered")
      );
    } else {
      setFilterByStatus("");
    }
  }, [status]);

  useEffect(() => {
    if (sort && sort === "newest") {
      setSortByAnswerDate(
        t("common-components.questions-filter-by-status.newest-answer")
      );
    } else if (sort && sort === "oldest") {
      setSortByAnswerDate(
        t("common-components.questions-filter-by-status.oldest-answer")
      );
    } else {
      setSortByAnswerDate("");
    }
  }, [sort]);

  useEffect(() => {
    if (recipients?.length) {
      const recipientsNames = recipients.map(
        (recipient) => `${recipient.user_name} ${recipient.user_second_name}`
      );

      setFilterByRecipient(recipientsNames);
    } else {
      setFilterByRecipient([]);
    }
  }, [recipients]);

  useEffect(() => {
    if (dates?.length) {
      const datesStrings = dates.map((date) => {
        if (date.dateRange.startRangeDate) {
          const formattedStartDate = format(
            date.dateRange.startRangeDate,
            "dd.MM.yyyy"
          );
          const formattedEndDate = format(
            date.dateRange.endRangeDate,
            "dd.MM.yyyy"
          );
          return formattedStartDate === formattedEndDate
            ? formattedStartDate
            : `${formattedStartDate}-${formattedEndDate}`;
        } else if (date.year && !date.monthOfYear) {
          return date.year + " год";
        } else {
          return monthsOfYear[date.monthOfYear] + " " + date.year;
        }
      });

      setFilterByDate(datesStrings);
    } else {
      setFilterByDate([]);
    }
  }, [dates]);

  useEffect(() => {
    if (biblePlaces?.length) {
      const placesArray = biblePlaces.map((place) => {
        const bibleBook =
          BIBLE_BOOK_NAMES_DATA["ru"]?.[place?.bibleBookCode]?.name;
        const bibleChapters =
          place.bibleChapters?.length > 1
            ? `${place.bibleChapters[0]}-${
                place.bibleChapters[place.bibleChapters?.length - 1]
              }`
            : place.bibleChapters[0];

        return bibleChapters
          ? bibleBook + " " + bibleChapters + " гл."
          : bibleBook;
      });

      setFilterByBible(placesArray);
    } else {
      setFilterByBible([]);
    }
  }, [biblePlaces]);

  useEffect(() => {
    if (searchWords) {
      setFilterBySearchWords(searchWords);
    } else {
      setFilterBySearchWords([]);
    }
  }, [searchWords]);

  useEffect(() => {
    if (place) {
      const placeName =
        place === "course"
          ? "Курс"
          : place === "home"
          ? "Главная страница"
          : "";

      setFilterByPlace(placeName);
    } else {
      setFilterByPlace("");
    }
  }, [place]);

  useEffect(() => {
    if (author?._id) {
      setFilterByAuthor(author.author_name);
    } else {
      setFilterByAuthor("");
    }
  }, [author]);

  return (
    <QuestionsSelectedFiltersContainer>
      {!!filterByStatus && (
        <QuestionsSelectedFiltersItem onMouseDown={(e) => e.stopPropagation()}>
          {filterByStatus}
          <RemoveFilterIconContainer
            onClick={() => removeSelectedFilterOption("question_status")}
          >
            <AdminPageRemoveFilterIcon />
          </RemoveFilterIconContainer>
        </QuestionsSelectedFiltersItem>
      )}

      {!!filterByAuthor && (
        <QuestionsSelectedFiltersItem onMouseDown={(e) => e.stopPropagation()}>
          {filterByAuthor}
          <RemoveFilterIconContainer
            onClick={() => removeSelectedFilterOption("question_by_author")}
          >
            <AdminPageRemoveFilterIcon />
          </RemoveFilterIconContainer>
        </QuestionsSelectedFiltersItem>
      )}

      {!!sortByAnswerDate && (
        <QuestionsSelectedFiltersItem onMouseDown={(e) => e.stopPropagation()}>
          {sortByAnswerDate} ↓
          <RemoveFilterIconContainer
            onClick={() =>
              removeSelectedFilterOption("question_by_answer_date")
            }
          >
            <AdminPageRemoveFilterIcon />
          </RemoveFilterIconContainer>
        </QuestionsSelectedFiltersItem>
      )}

      {!!filterByRecipient &&
        filterByRecipient.map((recipient, index) => {
          return (
            <QuestionsSelectedFiltersItem
              key={recipient}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {recipient}
              {(accessRights.isSuperadmin ||
                accessRights.isAdminOfPlatformQuestions) && (
                <RemoveFilterIconContainer
                  onClick={() =>
                    removeSelectedFilterOption("question_recipient", index)
                  }
                >
                  <AdminPageRemoveFilterIcon />
                </RemoveFilterIconContainer>
              )}
            </QuestionsSelectedFiltersItem>
          );
        })}

      {!!filterByDate.length &&
        filterByDate.map((date, index) => {
          return (
            <QuestionsSelectedFiltersItem
              key={date + index}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {date}
              <RemoveFilterIconContainer
                onClick={() =>
                  removeSelectedFilterOption("question_date_range", index)
                }
              >
                <AdminPageRemoveFilterIcon />
              </RemoveFilterIconContainer>
            </QuestionsSelectedFiltersItem>
          );
        })}

      {!!filterByBible.length &&
        filterByBible.map((place, index) => {
          return (
            <QuestionsSelectedFiltersItem
              key={place}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {place}
              <RemoveFilterIconContainer
                onClick={() =>
                  removeSelectedFilterOption("question_bible", index)
                }
              >
                <AdminPageRemoveFilterIcon />
              </RemoveFilterIconContainer>
            </QuestionsSelectedFiltersItem>
          );
        })}

      {!!filterByPlace && (
        <QuestionsSelectedFiltersItem onMouseDown={(e) => e.stopPropagation()}>
          {filterByPlace}
          <RemoveFilterIconContainer
            onClick={() => removeSelectedFilterOption("question_place")}
          >
            <AdminPageRemoveFilterIcon />
          </RemoveFilterIconContainer>
        </QuestionsSelectedFiltersItem>
      )}

      {!!filterBySearchWords.length &&
        filterBySearchWords.map((word, index) => {
          return (
            <QuestionsSelectedFiltersItem
              key={word}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {word}
              <RemoveFilterIconContainer
                onClick={() =>
                  removeSelectedFilterOption("search_words", index)
                }
              >
                <AdminPageRemoveFilterIcon />
              </RemoveFilterIconContainer>
            </QuestionsSelectedFiltersItem>
          );
        })}
    </QuestionsSelectedFiltersContainer>
  );
};

export default memo(QuestionsSelectedFilters);
