import React from "react";

const UserProfileRemoveVerseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3681 9.88227C11.7093 10.2235 11.7093 10.7768 11.3681 11.1181C11.0268 11.4593 10.4735 11.4593 10.1323 11.1181L6.00018 6.98598L1.86862 11.1175C1.52736 11.4588 0.974075 11.4588 0.632817 11.1175C0.291559 10.7763 0.29156 10.223 0.632818 9.88172L4.76437 5.75017L0.632829 1.61862C0.291571 1.27736 0.291571 0.724076 0.63283 0.382818C0.974088 0.0415602 1.52738 0.0415611 1.86863 0.382819L6.00018 4.51437L10.1323 0.382275C10.4735 0.0410167 11.0268 0.0410166 11.3681 0.382275C11.7093 0.723534 11.7093 1.27682 11.3681 1.61808L7.23598 5.75017L11.3681 9.88227Z"
        fill="#F12B2B"
      />
    </svg>
  );
};

export default UserProfileRemoveVerseIcon;
