import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useOnclickOutside from "react-cool-onclickoutside";
import axios from "../../../../axios_config";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../../common/utils/functions/time_functions";
import UserProfileQuestionAnswer from "./user_profile_question_answer";
import AdminPageDeleteIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_delete_icon";
import { BASE_API_URL } from "../../../../common/endpoints";
import QuestionInput from "../../../../common/components/modal_windows/ask_question_modal_window/question_input";
import ThreeDotsIcon from "../../../../common/assets/icons/three_dots_icon";
import EditQuestionIcon from "../../../../common/assets/icons/user_profile_page_icons/edit_question_icon";
import UserProfileQuestionBible from "./user_profile_question_bible";

const UserProfileQuestionWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  border-left: 2px solid;
  border-color: ${({ answered }) =>
    answered ? "#5172EA" : "rgba(241, 43, 43, 0.6)"};
  background: #fff;
  padding: 12px 11px;
  transition: 0.2s;
  ${({ editeMode }) =>
    editeMode && "box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);"}

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }
`;

const QuestionHeader = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 20px;
`;

const QuestionCreationDate = styled.div`
  width: max-content;
  color: #828282;
  font-size: 9px;
  line-height: 144%;
  letter-spacing: 0.4px;
`;

const QuestionText = styled.div`
  padding-right: 53px;
  padding-left: 7px;
  color: #1e1e1e;
  font-size: 14px;
  line-height: 142%;

  @media (max-width: 500px) {
    padding-right: 30px;
  }

  @media (max-width: 420px) {
    padding-right: 0px;
  }
`;

const ThreeDotsButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 11px;
  border-radius: 8px;
  cursor: pointer;
`;

const QuestionSettingsButtonsContainer = styled.div`
  position: absolute;
  top: -38px;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 34px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const QuestionSettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 100%;
  border-radius: 13px;
  cursor: pointer;
  transition: 0.2s;
`;

const DeleteQuestionIcon = styled(AdminPageDeleteIcon)`
  height: 20px;
  width: 20px;
`;

const EditQuestionIconStyled = styled(EditQuestionIcon)`
  height: 20px;
  width: 20px;
`;

const EditQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 10px;
`;

const EditQuestionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const EditBlockButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
  height: 30px;
  width: max-content;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: 0.4px;
  cursor: default;
  user-select: none;
  transition: 0.2s;
  background: red;
`;

const SaveEditButton = styled(EditBlockButton)`
  background: #eaeaea;
  cursor: pointer;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  opacity: ${({ isHoverOnCancel }) => (isHoverOnCancel ? "0.5" : "1")};

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;
const CancelEditButton = styled(EditBlockButton)`
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const UserQuestionBibleVerses = styled.div`
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const BibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 0 10px;
  color: #383838;
  font-size: 14px;
  font-style: normal;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #eaeaea;
  }
`;

const QuestionCourseInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.4px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const UserProfileQuestion = ({
  question,
  setTextBibleModalData,
  setOpenDeleteQuestionModal,
  setDeleteQuestionModalData,
}) => {
  const { t } = useTranslation();

  const threeDotsRef = useRef(null);
  const settingsContainerRef = useRef(null);

  const [editeMode, setEditeMode] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [questionMemoData, setQuestionMemoData] = useState({});
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [showSettingsButtons, setShowSettingsButtons] = useState(false);

  const maxQuestionLength = 1000;
  const formattedDate = getFormattedDate(question.question_created_at);
  const questionIsAnswered = question.question_status === "answered";
  const questionBibleVerses = questionData.question_bible_verses;

  const questionFromPlace = question.question_from_place;
  const { course_name, lesson_num, lesson_name } =
    question.question_from_place_data || {};

  useOnclickOutside(() => !editeMode && setShowSettingsButtons(false), {
    refs: [settingsContainerRef, threeDotsRef],
  });

  useEffect(() => {
    if (question._id) {
      setQuestionData(question);
      setQuestionMemoData(question);
    }
  }, [question]);

  useEffect(() => {
    if (questionData.question_text?.trim().length >= maxQuestionLength) {
      setLimitReached(true);
    } else {
      setLimitReached(false);
    }
  }, [questionData.question_text]);

  const onClickOpenSettingsButton = useCallback(() => {
    setShowSettingsButtons((prevState) => !prevState);
  }, [setShowSettingsButtons]);

  const onClickDeleteQuestion = useCallback(
    (event) => {
      event.stopPropagation();

      setDeleteQuestionModalData({ _id: question._id });
      setOpenDeleteQuestionModal(true);
    },
    [question]
  );

  const onClickEditeQuestion = useCallback(
    (event) => {
      event.stopPropagation();

      if (!editeMode) {
        setEditeMode(true);
      } else {
        setEditeMode(false);
        setQuestionData(questionMemoData);
      }
      setIsHoverOnCancel(false);
    },
    [editeMode, questionMemoData]
  );

  const onClickCancelEdite = useCallback(() => {
    setEditeMode(false);
    setQuestionData(questionMemoData);
    setShowSettingsButtons(false);
  }, [questionMemoData]);

  const onClickSaveEdite = useCallback(async () => {
    if (limitReached) return;

    const updatedQuestionText = {
      question_text: questionData.question_text,
      question_bible_verses: questionData.question_bible_verses,
    };

    try {
      await axios.patch(
        `${BASE_API_URL}/questions/${questionData._id}`,
        updatedQuestionText
      );

      setEditeMode(false);
      setShowSettingsButtons(false);
    } catch (error) {
      console.error("Error update question:", error);
    }
  }, [questionData, limitReached]);

  const handleClickVerseAddress = useCallback(
    (event, verses) => {
      event.stopPropagation();

      const selectedVerses = verses.versesData
        .map((verse) => verse.verse_num)
        .join(",");

      const bibleParams = {
        bibleId: verses.versesParams.bibleId,
        bookId: verses.versesParams.bookId,
        chapterId: verses.versesParams.chapterId,
        selectedVerses,
      };

      setTextBibleModalData(bibleParams);
    },
    [questionIsAnswered]
  );

  const toggleHoverOnCancelButton = useCallback(() => {
    setIsHoverOnCancel((prevState) => !prevState);
  }, [setIsHoverOnCancel]);

  return (
    <UserProfileQuestionWrapper
      answered={questionIsAnswered}
      editeMode={editeMode}
    >
      <QuestionHeader>
        {questionFromPlace === "course" && (
          <QuestionCourseInfo>
            <p>
              {t("user-profile-page.questions.question.course-name")}{" "}
              {course_name}.
            </p>
            <p>
              {t("user-profile-page.questions.question.lesson-name")}{" "}
              {lesson_num}. {lesson_name}
            </p>
          </QuestionCourseInfo>
        )}
        <QuestionCreationDate>{formattedDate}</QuestionCreationDate>
      </QuestionHeader>

      {editeMode && !questionIsAnswered ? (
        <EditQuestionContainer>
          <QuestionInput
            userQuestionData={questionData}
            setUserQuestionData={setQuestionData}
            limitReached={limitReached}
            inComponent={"profile"}
          />

          <UserProfileQuestionBible
            questionData={questionData}
            setQuestionData={setQuestionData}
          />

          <EditQuestionButtonsContainer>
            <CancelEditButton
              onMouseEnter={toggleHoverOnCancelButton}
              onMouseLeave={toggleHoverOnCancelButton}
              onClick={onClickCancelEdite}
            >
              {t("user-profile-page.questions.question.edit-buttons.cancel")}
            </CancelEditButton>
            <SaveEditButton
              isHoverOnCancel={isHoverOnCancel}
              onClick={onClickSaveEdite}
            >
              {t("user-profile-page.questions.question.edit-buttons.send")}
            </SaveEditButton>
          </EditQuestionButtonsContainer>
        </EditQuestionContainer>
      ) : (
        <QuestionText>{questionData.question_text}</QuestionText>
      )}

      {!editeMode && !!questionBibleVerses?.length && (
        <UserQuestionBibleVerses>
          {questionBibleVerses.map((verses, index) => {
            return (
              <BibleVerseAddress
                key={index}
                onClick={(e) => handleClickVerseAddress(e, verses)}
              >
                {verses.verseAddress}
              </BibleVerseAddress>
            );
          })}
        </UserQuestionBibleVerses>
      )}

      {!!question?.question_answer?.answer_created_at && (
        <UserProfileQuestionAnswer
          questionAnswer={question.question_answer}
          setTextBibleModalData={setTextBibleModalData}
        />
      )}

      <ThreeDotsButton onClick={onClickOpenSettingsButton} ref={threeDotsRef}>
        <ThreeDotsIcon color={"#725FED"} />
      </ThreeDotsButton>

      {showSettingsButtons && (
        <QuestionSettingsButtonsContainer ref={settingsContainerRef}>
          {!questionIsAnswered && (
            <QuestionSettingsButton onClick={onClickEditeQuestion}>
              <EditQuestionIconStyled />
            </QuestionSettingsButton>
          )}

          <QuestionSettingsButton onClick={onClickDeleteQuestion}>
            <DeleteQuestionIcon />
          </QuestionSettingsButton>
        </QuestionSettingsButtonsContainer>
      )}
    </UserProfileQuestionWrapper>
  );
};

export default memo(UserProfileQuestion);
