import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import {
  HeaderBibleIcon as DefaultBibleIcon,
  HeaderChatIcon as DefaultChatIcon,
  HeaderHomeIcon as DefaultHomeIcon,
  HeaderStudyIcon as DefaultStudyIcon,
  HeaderUserIcon as DefaultUserIcon,
} from "../../../assets/icons/header_icons";

export const HeaderLogoContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MainNavContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;
  margin-right: 60px;

  @media (max-width: 1024px) {
    margin-right: 0;
  }

  @media (max-width: 700px) {
    justify-content: space-around;
    gap: normal;
    width: 100%;
  }

  @media (max-width: 550px) {
    justify-content: space-between;
  }
`;

export const HomeIcon = styled(DefaultHomeIcon)`
  fill: ${({ $active }) => ($active ? "#1E1E1E" : "#8e8e8e")};
  transition: 0.2s;
`;

export const StudyIcon = styled(DefaultStudyIcon)`
  fill: ${({ $active }) => ($active ? "#1E1E1E" : "#8e8e8e")};
  transition: 0.2s;
`;

export const BibleIcon = styled(DefaultBibleIcon)`
  stroke: ${({ $active }) => ($active ? "#1E1E1E" : "#8e8e8e")};
  transition: 0.2s;
`;

export const ChatIcon = styled(DefaultChatIcon)`
  stroke: ${({ $active }) => ($active ? "#1E1E1E" : "#8e8e8e")};
  transition: 0.2s;
`;

export const UserIcon = styled(DefaultUserIcon)`
  stroke: ${({ $active }) => ($active ? "#1E1E1E" : "#8e8e8e")};
`;

const HeartBeatFrames = keyframes`
  to { transform: scale(1.1); }
`;

export const DonateIconContainer = styled(Link)`
  cursor: pointer;
  animation: ${HeartBeatFrames} 0.85s infinite alternate;
`;

export const AppHeaderContainer = styled.header`
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  height: 80px;
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  user-select: none;

  @media (max-width: 1024px) {
    height: 96px;
    justify-content: center;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 412px) {
    height: 103px;
    border-radius: 25px 25px 0px 0px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 0 20px;
  }
`;

export const AdminButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: rotate(20deg);
  }
`;

export const HideHeaderButtonWrapper = styled.div`
  position: absolute;
  top: ${({ showAppHeader }) => (!showAppHeader ? "-32px" : "-28px")};
  left: 50%;
  transform: translateX(-50%);
  z-index: 9997;
  padding: 14px 14px 6px;
  border-radius: 25px 25px 0px 0px;
  ${({ showAppHeader }) => !showAppHeader && "padding-bottom: 0;"}
`;

export const HideHeaderButton = styled.div`
  display: none;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.07);

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    padding-top: 2px;
    height: ${({ showAppHeader }) => (!showAppHeader ? "19px" : "20px")};
    width: 172px;
    border-radius: 15px 15px 0px 0px;
    background: #fff;

    svg {
      ${({ showAppHeader }) => !showAppHeader && "transform: rotate(180deg);"}
    }
  }
`;
