import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import BibleModalWindowVerses from "./bible_modal_window_verses";
import BibleModalSearchResults from "./bible_modal_search_results";
import BibleModalNavigation from "./bible_modal_navigation";
import InsertionVariantButtons from "./insertion_variant_buttons";

const BibleModalWindowDesktopContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 18px;
  padding: 18px;
  width: 691px;
  height: calc(100vh - 30px);
  max-height: 676px;
  border-radius: 25px;
  background: #eaeaea;
  z-index: 9999;
  cursor: grab;
`;

const BibleModalWindowRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 236px;
`;

const BibleModalWindowCancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 21px;
  min-height: 30px;
  border-radius: 8px;
  border: 2px solid #fff;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;

  > span {
    font-size: 16px;
    line-height: 16px;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background: rgb(247, 246, 246);
    border-color: rgb(229, 229, 229);
  }
`;

const BibleModalWindowDesktop = ({
  activeScreenDesktop,
  currentBibleParams,
  currentTranslation,
  selectedVerses,
  setSelectedVerses,
  bibleFontSettings,
  inComponent,
  searchIsLoading,
  bibleModalSearchResults,
  bibleModalSearchInput,
  bibleModalSearchPlace,
  bibleTranslations,
  currentTranslationData,
  searchWords,
  setSearchWords,
  setBibleModalSearchResults,
  setBibleModalSearchInput,
  setActiveScreenDesktop,
  setCurrentBibleParams,
  setBibleTranslations,
  setSearchIsLoading,
  setBibleModalSearchPlace,
  setBibleVersesData,
  setChatInputValue,
  handleAddBibleVerses,
  handleCloseBibleModalWindow,
  fromSearchToBiblePage,
}) => {
  const { t } = useTranslation();

  const [position, setPosition] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const modalWidth = 691;
    const modalHeight = 676;
    setPosition({
      x: window.innerWidth / 2 - modalWidth / 2,
      y: window.innerHeight / 2 - modalHeight / 2,
    });
  }, []);

  const handleMouseDown = (e) => {
    e.stopPropagation();
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    position && (
      <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
        <BibleModalWindowDesktopContainer
          onClick={stopPropagationOnClick}
          onMouseDown={handleMouseDown}
          style={{ left: `${position.x}px`, top: `${position.y}px` }}
        >
          {activeScreenDesktop === "bible_modal_chapter" && (
            <BibleModalWindowVerses
              currentBibleParams={currentBibleParams}
              currentTranslation={currentTranslation}
              currentTranslationData={currentTranslationData}
              bibleFontSettings={bibleFontSettings}
              searchWords={searchWords}
              selectedVerses={selectedVerses}
              setSelectedVerses={setSelectedVerses}
              setCurrentBibleParams={setCurrentBibleParams}
              inComponent={inComponent}
              handleCloseBibleModalWindow={handleCloseBibleModalWindow}
              fromSearchToBiblePage={fromSearchToBiblePage}
            />
          )}
          {activeScreenDesktop === "bible_modal_search" && (
            <BibleModalSearchResults
              bibleModalSearchInput={bibleModalSearchInput}
              searchIsLoading={searchIsLoading}
              bibleModalSearchResults={bibleModalSearchResults}
              currentTranslation={currentTranslation}
              setBibleModalSearchResults={setBibleModalSearchResults}
              setBibleModalSearchInput={setBibleModalSearchInput}
              setActiveScreenDesktop={setActiveScreenDesktop}
              setCurrentBibleParams={setCurrentBibleParams}
              setSelectedVerses={setSelectedVerses}
              setSearchWords={setSearchWords}
            />
          )}
          <BibleModalWindowRightBlock>
            <BibleModalNavigation
              bibleTranslations={bibleTranslations}
              currentTranslation={currentTranslation}
              setBibleTranslations={setBibleTranslations}
              currentBibleParams={currentBibleParams}
              setCurrentBibleParams={setCurrentBibleParams}
              setSelectedVerses={setSelectedVerses}
              bibleModalSearchPlace={bibleModalSearchPlace}
              bibleModalSearchInput={bibleModalSearchInput}
              setBibleModalSearchInput={setBibleModalSearchInput}
              setSearchIsLoading={setSearchIsLoading}
              activeScreenDesktop={activeScreenDesktop}
              setActiveScreenDesktop={setActiveScreenDesktop}
              setBibleModalSearchResults={setBibleModalSearchResults}
              setBibleModalSearchPlace={setBibleModalSearchPlace}
              currentTranslationData={currentTranslationData}
              inComponent={inComponent}
            />

            <BibleModalWindowCancelButton onClick={handleCloseBibleModalWindow}>
              <span>{t("bible-page.bible-modal-cancel-btn")}</span>
            </BibleModalWindowCancelButton>
          </BibleModalWindowRightBlock>

          {activeScreenDesktop === "bible_modal_chapter" &&
            inComponent !== "simple_modal" &&
            inComponent !== "search_modal" && (
              <InsertionVariantButtons
                selectedVerses={selectedVerses}
                setSelectedVerses={setSelectedVerses}
                currentTranslation={currentTranslation}
                currentBibleParams={currentBibleParams}
                handleAddBibleVerses={handleAddBibleVerses}
                handleCloseBibleModalWindow={handleCloseBibleModalWindow}
                setChatInputValue={setChatInputValue}
                setBibleVersesData={setBibleVersesData}
                inComponent={inComponent}
              />
            )}
        </BibleModalWindowDesktopContainer>
      </div>
    )
  );
};

export default memo(BibleModalWindowDesktop);
