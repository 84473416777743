import React, { useCallback, useState } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import Toggle from "../../../../../common/components/toggle";
import UserPenalty from "./user_penalty";
import UserMentor from "./user_mentor";
import AdminPageInfoIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_info_icon";
import { AdminPageSettingsHeaderArrow } from "../../../admin_page_components/admin_page_styled_components";

const UserPermitsContainer = styled.div`
  padding: 0 11px;
  border-radius: 8px;
  background: #fff;
  user-select: none;
  overflow: hidden;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const UserPermitsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px; /* 160% */
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const UserPermitsDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 5px;
  color: #383838;
  line-height: 120%;
`;

const ToggleWrapper = styled.div`
  margin-bottom: 10px;
`;

const UserWriteToMentorBan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  height: 28px;
  color: #383838;
  font-size: 10px;
  line-height: 12px;
`;

const BanEndInDays = styled.div`
  & > span {
    color: #828282;
  }
`;

const UserWriteToMentorBanInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ChatsBanDate = styled.div`
  & > span {
    color: #f12b2b;
  }
`;

const WriteToMentorBanInfo = styled.div`
  &:hover {
    transition: 0.2s;
    transform: scale(1.12);
    cursor: pointer;
  }
`;

const UserPenaltyWarning = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 10px;
`;

const UserPenaltyWarningCount = styled.div`
  color: #383838;
  font-size: 10px;
  line-height: 12px;

  & > span {
    color: #f12b2b;
  }
`;

const PenaltyInfo = styled.div`
  transition: 0.2s;

  &:hover {
    transform: scale(1.12);
    cursor: pointer;
  }
`;

const UserPermits = ({
  usersList,
  userRolesSettingsData,
  userRolesSettingsMemoData,
  setUserRolesSettingsData,
  setSelectedUserInfo,
  setUsersListByRole,
  setOpenUsersListModal,
  setOpenUnbindMentorModal,
  setSanctionUserModalWindow,
}) => {
  const [showPermits, setShowPermits] = useState(false);

  const isInvite = userRolesSettingsData.user_type === "invitation";
  const writeToMentor = userRolesSettingsData.user_permissions?.write_to_mentor;
  const writeInChats = userRolesSettingsData.user_permissions?.write_to_chat;
  const support = userRolesSettingsData.user_permissions?.write_to_support;
  const userWarnings =
    userRolesSettingsData.user_permissions?.amount_of_warnings;

  const handleShowPermits = useCallback(() => {
    setShowPermits((prevState) => !prevState);
  }, []);

  const handleOpenPermitsModal = useCallback((permitType) => {
    setSanctionUserModalWindow(permitType);
  }, []);

  const removeSanction = useCallback((permitType) => {
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_permissions: {
        ...prevState.user_permissions,
        [permitType]: {
          isEnabled: true,
          disabled_start_date: "",
          disabled_days: "",
        },
      },
    }));
  }, []);

  const onClickPermitToggle = useCallback(
    (event, permitType) => {
      event.stopPropagation();

      if (!isInvite) {
        if (userRolesSettingsData.user_permissions[permitType].isEnabled) {
          handleOpenPermitsModal(permitType);
        } else {
          removeSanction(permitType);
        }
      }
    },
    [userRolesSettingsData, isInvite]
  );

  const openUserPenaltyInfo = useCallback(() => {
    setSelectedUserInfo({
      data: userRolesSettingsData,
      component: "user_penalty",
    });
  }, [userRolesSettingsData]);

  return (
    <UserPermitsContainer>
      <UserPermitsHeaderContainer onClick={handleShowPermits}>
        Разрешения
        <AdminPageSettingsHeaderArrow extendedSection={showPermits}>
          <AskQuestionArrowIcon />
        </AdminPageSettingsHeaderArrow>
      </UserPermitsHeaderContainer>

      {showPermits && (
        <UserPermitsDropdownContainer>
          <ToggleWrapper>
            <Toggle
              title={"Общаться с наставником"}
              selected={writeToMentor.isEnabled}
              handleSelectToggle={(e) =>
                onClickPermitToggle(e, "write_to_mentor")
              }
            />
          </ToggleWrapper>

          {!writeToMentor.isEnabled && (
            <UserPenalty
              userPenaltyInfo={writeToMentor}
              openUserPenaltyInfo={openUserPenaltyInfo}
            />
          )}

          {!isInvite && (
            <UserMentor
              usersList={usersList}
              userRolesSettingsData={userRolesSettingsData}
              userRolesSettingsMemoData={userRolesSettingsMemoData}
              setUserRolesSettingsData={setUserRolesSettingsData}
              setUsersListByRole={setUsersListByRole}
              setOpenUsersListModal={setOpenUsersListModal}
              setSelectedUserInfo={setSelectedUserInfo}
              setOpenUnbindMentorModal={setOpenUnbindMentorModal}
            />
          )}

          <ToggleWrapper>
            <Toggle
              title={"Писать в чаты"}
              selected={writeInChats.isEnabled}
              handleSelectToggle={(e) =>
                onClickPermitToggle(e, "write_to_chat")
              }
            />
          </ToggleWrapper>

          {!writeInChats.isEnabled && (
            <UserPenalty
              userPenaltyInfo={writeInChats}
              openUserPenaltyInfo={openUserPenaltyInfo}
            />
          )}

          <ToggleWrapper>
            <Toggle
              title={"Поддержка"}
              selected={support.isEnabled}
              handleSelectToggle={(e) =>
                onClickPermitToggle(e, "write_to_support")
              }
            />
          </ToggleWrapper>

          {!support.isEnabled && (
            <UserPenalty
              userPenaltyInfo={support}
              openUserPenaltyInfo={openUserPenaltyInfo}
            />
          )}

          {!!userWarnings && (
            <UserPenaltyWarning>
              <UserPenaltyWarningCount>
                <span>Предупреждения:</span> {userWarnings}/3
              </UserPenaltyWarningCount>
              <PenaltyInfo onClick={openUserPenaltyInfo}>
                <AdminPageInfoIcon />
              </PenaltyInfo>
            </UserPenaltyWarning>
          )}
        </UserPermitsDropdownContainer>
      )}
    </UserPermitsContainer>
  );
};

export default UserPermits;
