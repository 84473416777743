export const BIBLE_DEFAULT_FONT = {
  fontVariant: "Inter",
  fontSize: 16,
};

export const BIBLE_TRANSLATIONS_LANGUAGES = {
  ru: { name: "Русский", abbreviation: "Ru", code: "ru" },
  uk: { name: "Українська", abbreviation: "Uk", code: "uk" },
  en: { name: "English", abbreviation: "En", code: "en" },
};

export const BIBLE_BOOK_NAMES_DATA = {
  ru: {
    Gen: { name: "Бытие", short_name: "Быт", number: 1 },
    Exo: { name: "Исход", short_name: "Исх", number: 2 },
    Lev: { name: "Левит", short_name: "Лев", number: 3 },
    Num: { name: "Числа", short_name: "Чис", number: 4 },
    Deu: { name: "Второзаконие", short_name: "Втор", number: 5 },
    Jos: { name: "Иисус Навин", short_name: "Иис", number: 6 },
    Jdg: { name: "Книга Cудей", short_name: "Суд", number: 7 },
    Rut: { name: "Руфь", short_name: "Руф", number: 8 },
    "1Sa": { name: "1 Царств", short_name: "1Цар", number: 9 },
    "2Sa": { name: "2 Царств", short_name: "2Цар", number: 10 },
    "1Ki": { name: "3 Царств", short_name: "3Цар", number: 11 },
    "2Ki": { name: "4 Царств", short_name: "4Цар", number: 12 },
    "1Ch": { name: "1 Паралипоменон", short_name: "1Пар", number: 13 },
    "2Ch": { name: "2 Паралипоменон", short_name: "2Пар", number: 14 },
    Ezr: { name: "Ездра", short_name: "Езд", number: 15 },
    Neh: { name: "Неемия", short_name: "Неем", number: 16 },
    Est: { name: "Есфирь", short_name: "Есф", number: 17 },
    Job: { name: "Иов", short_name: "Иов", number: 18 },
    Psa: {
      name: "Псалтирь",
      short_name: "Пс",
      secondName: "Псалом",
      number: 19,
    },
    Pro: { name: "Притчи", short_name: "Прит", number: 20 },
    Ecc: { name: "Екклесиаст", short_name: "Еккл", number: 21 },
    Son: { name: "Песня песней", short_name: "Песн", number: 22 },
    Isa: { name: "Исаия", short_name: "Иса", number: 23 },
    Jer: { name: "Иеремия", short_name: "Иер", number: 24 },
    Lam: { name: "Плач Иеремии", short_name: "Плач", number: 25 },
    Eze: { name: "Иезекииль", short_name: "Иез", number: 26 },
    Dan: { name: "Даниил", short_name: "Дан", number: 27 },
    Hos: { name: "Осия", short_name: "Ос", number: 28 },
    Joe: { name: "Иоиль", short_name: "Иоил", number: 29 },
    Amo: { name: "Амос", short_name: "Амос", number: 30 },
    Oba: { name: "Авдий", short_name: "Авд", number: 31 },
    Jon: { name: "Иона", short_name: "Иона", number: 32 },
    Mic: { name: "Михей", short_name: "Мих", number: 33 },
    Nah: { name: "Наум", short_name: "Наум", number: 34 },
    Hab: { name: "Аввакум", short_name: "Авв", number: 35 },
    Zep: { name: "Софония", short_name: "Соф", number: 36 },
    Hag: { name: "Аггей", short_name: "Агг", number: 37 },
    Zec: { name: "Захария", short_name: "Зах", number: 38 },
    Mal: { name: "Малахия", short_name: "Малах", number: 39 },
    Mat: { name: "Матфея", short_name: "Мат", number: 40 },
    Mar: { name: "Марка", short_name: "Мар", number: 41 },
    Luk: { name: "Луки", short_name: "Лук", number: 42 },
    Joh: { name: "Иоанна", short_name: "Иоан", number: 43 },
    Act: { name: "Деяния", short_name: "Дея", number: 44 },
    Jam: { name: "Иакова", short_name: "Иак", number: 45 },
    "1Pe": { name: "1 Петра", short_name: "1Петр", number: 46 },
    "2Pe": { name: "2 Петра", short_name: "2Петр", number: 47 },
    "1Jo": { name: "1 Иоанна", short_name: "1Иоан", number: 48 },
    "2Jo": { name: "2 Иоанна", short_name: "2Иоан", number: 49 },
    "3Jo": { name: "3 Иоанна", short_name: "3Иоан", number: 50 },
    Jud: { name: "Иуда", short_name: "Иуд", number: 51 },
    Rom: { name: "Римлянам", short_name: "Рим", number: 52 },
    "1Co": { name: "1 Коринфянам", short_name: "1Кор", number: 53 },
    "2Co": { name: "2 Коринфянам", short_name: "2Кор", number: 54 },
    Gal: { name: "Галатам", short_name: "Гал", number: 55 },
    Eph: { name: "Ефесянам", short_name: "Еф", number: 56 },
    Phi: { name: "Филиппийцам", short_name: "Филип", number: 57 },
    Col: { name: "Колоссянам", short_name: "Кол", number: 58 },
    "1Th": { name: "1 Фессалоникийцам", short_name: "1Фес", number: 59 },
    "2Th": { name: "2 Фессалоникийцам", short_name: "2Фес", number: 60 },
    "1Ti": { name: "1 Тимофею", short_name: "1Тим", number: 61 },
    "2Ti": { name: "2 Тимофею", short_name: "2Тим", number: 62 },
    Tit: { name: "Титу", short_name: "Тит", number: 63 },
    Phm: { name: "Филимону", short_name: "Фил", number: 64 },
    Heb: { name: "Евреям", short_name: "Евр", number: 65 },
    Rev: { name: "Откровение", short_name: "Откр", number: 66 },
  },
  en: {
    Gen: { name: "Genesis", short_name: "Gen", number: 1 },
    Exo: { name: "Exodus", short_name: "Exo", number: 2 },
    Lev: { name: "Leviticus", short_name: "Lev", number: 3 },
    Num: { name: "Numbers", short_name: "Num", number: 4 },
    Deu: { name: "Deuteronomy", short_name: "Deu", number: 5 },
    Jos: { name: "Joshua", short_name: "Jos", number: 6 },
    Jdg: { name: "Judges", short_name: "Jdg", number: 7 },
    Rut: { name: "Ruth", short_name: "Rut", number: 8 },
    "1Sa": { name: "1 Samuel", short_name: "1Sa", number: 9 },
    "2Sa": { name: "2 Samuel", short_name: "2Sa", number: 10 },
    "1Ki": { name: "1 Kings", short_name: "1Ki", number: 11 },
    "2Ki": { name: "2 Kings", short_name: "2Ki", number: 12 },
    "1Ch": { name: "1 Chronicles", short_name: "1Ch", number: 13 },
    "2Ch": { name: "2 Chronicles", short_name: "2Ch", number: 14 },
    Ezr: { name: "Ezra", short_name: "Ezr", number: 15 },
    Neh: { name: "Nehemiah", short_name: "Neh", number: 16 },
    Est: { name: "Esther", short_name: "Est", number: 17 },
    Job: { name: "Job", short_name: "Job", number: 18 },
    Psa: { name: "Psalms", short_name: "Psa", secondName: "Psalm", number: 19 },
    Pro: { name: "Proverbs", short_name: "Pro", number: 20 },
    Ecc: { name: "Ecclesiastes", short_name: "Ecc", number: 21 },
    Son: { name: "Song of Solomon", short_name: "Son", number: 22 },
    Isa: { name: "Isaiah", short_name: "Isa", number: 23 },
    Jer: { name: "Jeremiah", short_name: "Jer", number: 24 },
    Lam: { name: "Lamentations", short_name: "Lam", number: 25 },
    Eze: { name: "Ezekiel", short_name: "Eze", number: 26 },
    Dan: { name: "Daniel", short_name: "Dan", number: 27 },
    Hos: { name: "Hosea", short_name: "Hos", number: 28 },
    Joe: { name: "Joel", short_name: "Joe", number: 29 },
    Amo: { name: "Amos", short_name: "Amo", number: 30 },
    Oba: { name: "Obadiah", short_name: "Oba", number: 31 },
    Jon: { name: "Jonah", short_name: "Jon", number: 32 },
    Mic: { name: "Micah", short_name: "Mic", number: 33 },
    Nah: { name: "Nahum", short_name: "Nah", number: 34 },
    Hab: { name: "Habakkuk", short_name: "Hab", number: 35 },
    Zep: { name: "Zephaniah", short_name: "Zep", number: 36 },
    Hag: { name: "Haggai", short_name: "Hag", number: 37 },
    Zec: { name: "Zechariah", short_name: "Zec", number: 38 },
    Mal: { name: "Malachi", short_name: "Mal", number: 39 },
    Mat: { name: "Matthew", short_name: "Mat", number: 40 },
    Mar: { name: "Mark", short_name: "Mar", number: 41 },
    Luk: { name: "Luke", short_name: "Luk", number: 42 },
    Joh: { name: "John", short_name: "Joh", number: 43 },
    Act: { name: "Acts", short_name: "Act", number: 44 },
    Rom: { name: "Romans", short_name: "Rom", number: 45 },
    "1Co": { name: "1 Corinthians", short_name: "1Co", number: 46 },
    "2Co": { name: "2 Corinthians", short_name: "2Co", number: 47 },
    Gal: { name: "Galatians", short_name: "Gal", number: 48 },
    Eph: { name: "Ephesians", short_name: "Eph", number: 49 },
    Phi: { name: "Philippians", short_name: "Phi", number: 50 },
    Col: { name: "Colossians", short_name: "Col", number: 51 },
    "1Th": { name: "1 Thessalonians", short_name: "1Th", number: 52 },
    "2Th": { name: "2 Thessalonians", short_name: "2Th", number: 53 },
    "1Ti": { name: "1 Timothy", short_name: "1Ti", number: 54 },
    "2Ti": { name: "2 Timothy", short_name: "2Ti", number: 55 },
    Tit: { name: "Titus", short_name: "Tit", number: 56 },
    Phm: { name: "Philemon", short_name: "Phm", number: 57 },
    Heb: { name: "Hebrews", short_name: "Heb", number: 58 },
    Jam: { name: "James", short_name: "Jam", number: 59 },
    "1Pe": { name: "1 Peter", short_name: "1Pe", number: 60 },
    "2Pe": { name: "2 Peter", short_name: "2Pe", number: 61 },
    "1Jo": { name: "1 John", short_name: "1Jo", number: 62 },
    "2Jo": { name: "2 John", short_name: "2Jo", number: 63 },
    "3Jo": { name: "3 John", short_name: "3Jo", number: 64 },
    Jud: { name: "Jude", short_name: "Jud", number: 65 },
    Rev: { name: "Revelation", short_name: "Rev", number: 66 },
  },
  uk: {
    Gen: { name: "Буття", short_name: "Бут", number: 1 },
    Exo: { name: "Вихід", short_name: "Вих", number: 2 },
    Lev: { name: "Левит", short_name: "Лев", number: 3 },
    Num: { name: "Числа", short_name: "Чис", number: 4 },
    Deu: { name: "Повторення Закону", short_name: "Повт", number: 5 },
    Jos: { name: "Iсус Навин", short_name: "Нав", number: 6 },
    Jdg: { name: "Книга Суддiв", short_name: "Суд", number: 7 },
    Rut: { name: "Рут", short_name: "Рут", number: 8 },
    "1Sa": { name: "1-а Самуїлова", short_name: "1Сам", number: 9 },
    "2Sa": { name: "2-а Самуїлова", short_name: "2Сам", number: 10 },
    "1Ki": { name: "1-а царiв", short_name: "1Цар", number: 11 },
    "2Ki": { name: "2-а царiв", short_name: "2Цар", number: 12 },
    "1Ch": { name: "1-а хронiки", short_name: "1Хр", number: 13 },
    "2Ch": { name: "2-а хронiки", short_name: "2Хр", number: 14 },
    Ezr: { name: "Ездра", short_name: "Ездр", number: 15 },
    Neh: { name: "Неемія", short_name: "Неєм", number: 16 },
    Est: { name: "Естер", short_name: "Ест", number: 17 },
    Job: { name: "Йов", short_name: "Йов", number: 18 },
    Psa: {
      name: "Псалми",
      short_name: "Псал",
      secondName: "Псалом",
      number: 19,
    },
    Pro: { name: "Приповiстi", short_name: "Прип", number: 20 },
    Ecc: { name: "Екклезiяст", short_name: "Екл", number: 21 },
    Son: { name: "Пiсня над пiснями", short_name: "Пісн", number: 22 },
    Isa: { name: "Iсая", short_name: "Іс", number: 23 },
    Jer: { name: "Єремiя", short_name: "Єр", number: 24 },
    Lam: { name: "Плач Єремiї", short_name: "Плач", number: 25 },
    Eze: { name: "Єзекiїль", short_name: "Єзек", number: 26 },
    Dan: { name: "Даниїл", short_name: "Дан", number: 27 },
    Hos: { name: "Осiя", short_name: "Осі", number: 28 },
    Joe: { name: "Йоїл", short_name: "Йоїл", number: 29 },
    Amo: { name: "Амос", short_name: "Амос", number: 30 },
    Oba: { name: "Овдiй", short_name: "Овд", number: 31 },
    Jon: { name: "Йона", short_name: "Йона", number: 32 },
    Mic: { name: "Михей", short_name: "Мих", number: 33 },
    Nah: { name: "Наум", short_name: "Наум", number: 34 },
    Hab: { name: "Авакум", short_name: "Авак", number: 35 },
    Zep: { name: "Софонiя", short_name: "Соф", number: 36 },
    Hag: { name: "Огiй", short_name: "Огій", number: 37 },
    Zec: { name: "Захарiя", short_name: "Зах", number: 38 },
    Mal: { name: "Малахiї", short_name: "Мал", number: 39 },
    Mat: { name: "Вiд Матвiя", short_name: "Мат", number: 40 },
    Mar: { name: "Вiд Марка", short_name: "Мар", number: 41 },
    Luk: { name: "Вiд Луки", short_name: "Лук", number: 42 },
    Joh: { name: "Вiд Iвана", short_name: "Іван", number: 43 },
    Act: { name: "Дiї", short_name: "Дії", number: 44 },
    Rom: { name: "До римлян", short_name: "Рим", number: 45 },
    "1Co": { name: "1-е до коринтян", short_name: "1Кор", number: 46 },
    "2Co": { name: "2-е до коринтян", short_name: "2Кор", number: 47 },
    Gal: { name: "До галатiв", short_name: "Гал", number: 48 },
    Eph: { name: "До ефесян", short_name: "Еф", number: 49 },
    Phi: { name: "До филип'ян", short_name: "Фил", number: 50 },
    Col: { name: "До колоссян", short_name: "Кол", number: 51 },
    "1Th": { name: "1-е до солунян", short_name: "1Сол", number: 52 },
    "2Th": { name: "2-е до солунян", short_name: "2Сол", number: 53 },
    "1Ti": { name: "1-е Тимофiю", short_name: "1Тим", number: 54 },
    "2Ti": { name: "2-е Тимофiю", short_name: "2Тим", number: 55 },
    Tit: { name: "Титові", short_name: "Тит", number: 56 },
    Phm: { name: "Филимону", short_name: "Фил", number: 57 },
    Heb: { name: "До євреїв", short_name: "Євр", number: 58 },
    Jam: { name: "Якова", short_name: "Яків", number: 59 },
    "1Pe": { name: "1-е Петра", short_name: "1Пет", number: 60 },
    "2Pe": { name: "2-е Петра", short_name: "2Пет", number: 61 },
    "1Jo": { name: "1-е Iвана", short_name: "1Ів", number: 62 },
    "2Jo": { name: "2-е Iвана", short_name: "2Ів", number: 63 },
    "3Jo": { name: "3-е Iвана", short_name: "3Ів", number: 64 },
    Jud: { name: "Юда", short_name: "Юда", number: 65 },
    Rev: { name: "Об'явлення", short_name: "Об'яв", number: 66 },
  },
};
