import React, { useState, memo } from "react";
import styled from "styled-components";

const CoursePreviewContainer = styled.div`
  display: flex;
  width: 500px;
  margin: 0 auto;
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  font-size: 16px;
  transition: 0.2s;
  cursor: pointer;
  border: 2px solid blue;
  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.15);
  }
`;

const AdminCoursePreview = ({ course, onClickedCourse }) => {
  return (
    <>
      <CoursePreviewContainer onClick={() => onClickedCourse(course)}>
        <section>
          <header>
            <h6>{course.course_name}</h6>
          </header>
          <div>{course.course_description}</div>
        </section>
      </CoursePreviewContainer>
    </>
  );
};

export default memo(AdminCoursePreview);
