import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { highlightWords } from "../../../utils/functions/bible_page_functions";

const VerseContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 12px;
  border-radius: 5px;
  margin-right: 8px;
  background: ${({ isSelected }) => (isSelected ? "#cacaca" : "none")};
  user-select: none;
  cursor: pointer;

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? "#cacaca" : "rgb(223 223 223)"};
    transition: 0.2s;
  }

  @media (max-width: 774px) {
    padding: 0px;
  }
`;

const VerseTextContainer = styled.div`
  flex: 1;
  height: 100%;
`;

const VerseText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const VerseNumber = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const BibleVerse = ({
  verse,
  selectedVerses,
  inComponent,
  searchWords,
  setSelectedVerses,
}) => {
  const verseRef = useRef(null);
  const [verseIsSelected, setVerseIsSelected] = useState(null);

  const isFirstSelectedVerse =
    +verse.verse_num === +selectedVerses?.[0]?.verse_num;

  useEffect(() => {
    const isSelectedVerse = selectedVerses?.some(
      (item) => item.verse_num === verse.verse_num
    );

    if (isSelectedVerse) {
      setVerseIsSelected(true);
    } else {
      setVerseIsSelected(false);
    }
  }, [selectedVerses, verse]);

  useEffect(() => {
    if (isFirstSelectedVerse && verseRef.current) {
      verseRef.current.scrollIntoView({ block: "start" });
    }
  }, [verseRef.current]);

  const renderedVerseText = useMemo(() => {
    return inComponent === "bible_search"
      ? highlightWords(verse.verse_text, searchWords)
      : verse.verse_text;
  }, [verse.verse_text, searchWords, inComponent]);

  const handleSelectVerses = useCallback(
    (event) => {
      event.stopPropagation();

      if (inComponent !== "simple_modal") {
        if (selectedVerses?.some((v) => v.verse_num === verse.verse_num)) {
          const updatedVerses = selectedVerses.filter(
            (v) => v.verse_num !== verse.verse_num
          );
          setSelectedVerses(updatedVerses);
        } else {
          const updatedVerses = selectedVerses
            ? [...selectedVerses, verse]
            : [verse];
          setSelectedVerses(updatedVerses);
        }
      }
    },
    [selectedVerses, verse, inComponent, setSelectedVerses]
  );

  return (
    <VerseContainer
      ref={verseRef}
      isSelected={verseIsSelected}
      onClick={handleSelectVerses}
    >
      <VerseNumber>{verse.verse_num}.</VerseNumber>
      <VerseTextContainer>
        <VerseText>{renderedVerseText}</VerseText>
      </VerseTextContainer>
    </VerseContainer>
  );
};

export default memo(BibleVerse);
