import React, { useCallback } from "react";
import FramedBibleVerse from "../../../../common/components/framed_bible_verse";

const SearchVerse = ({
  verse,
  bibleSearchWord,
  currentTranslation,
  setBibleVersesData,
  setOpenBibleModalWindow,
}) => {
  const onClickBibleSearchVerse = useCallback(
    (verse) => {
      const bibleVersesData = {
        versesData: [
          {
            verse_num: verse.verse_num,
          },
        ],
        versesParams: {
          bibleId: verse.bible_code,
          bookId: verse.book_code,
          chapterId: verse.chapter_num,
        },
        searchWord: bibleSearchWord,
      };

      setBibleVersesData(bibleVersesData);
      setOpenBibleModalWindow(true);
    },
    [bibleSearchWord]
  );

  return (
    <div>
      <FramedBibleVerse
        background={"#EAEAEA"}
        bibleSearchWord={bibleSearchWord}
        inComponent={"bible_search"}
        verse={verse}
        currentTranslation={currentTranslation}
        onClickBibleSearchVerse={onClickBibleSearchVerse}
      />
    </div>
  );
};

export default SearchVerse;
