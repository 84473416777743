import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import AdminPageGroupInfoIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_group_info_icon";
import { UserRolesInfoButton } from "../user_roles_styled_components";
import MinusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";
import PlusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import CheckLargeIcon from "../../../../../common/assets/icons/admin_page_icons/check_large_icon";
import { BASE_API_URL } from "../../../../../common/endpoints";

const CourseSmallPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 6px;
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  background: ${({ background }) => background || ""};
  ${({ component }) => component === "modal_course" && "cursor: pointer;"}
`;

const CourseSmallPreviewImage = styled.div`
  width: 73px;
  height: 45px;
  background-size: cover;
  background-position: center;
  background-color: rgba(230, 230, 230);
  ${({ image }) => image && `background-image: url(${image});`}
  border-radius: 3px;
`;

const CourseSmallPreviewTitleContainer = styled.div`
  display: flex;
  gap: 5px;
  flex: 1;
  flex-wrap: wrap;
  user-select: none;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const CourseSmallPreview = ({
  component,
  course,
  selected,
  onConfirmItemDeletion = () => {},
  handleSelectItem,
}) => {
  const [courseData, setCourseData] = useState({});
  const [authors, setAuthors] = useState([]);
  const [authorsList, setAuthorsList] = useState("");
  const [background, setBackground] = useState("");

  const title = courseData?.course_name;
  const image = courseData?.course_preview_image;
  const courseAuthors = courseData?.course_authors;

  useEffect(() => {
    if (component === "course") {
      setBackground(
        `linear-gradient(249deg, rgba(219, 50, 234, 0.3) -11.61%, rgba(61, 26, 201, 0.3) 109.78%)`
      );
    } else if (selected && component === "modal_course") {
      setBackground(
        `linear-gradient(249deg, rgba(219, 50, 234, 0.3) -11.61%, rgba(61, 26, 201, 0.3) 109.78%)`
      );
    } else {
      setBackground("#CACACA");
    }
  }, [component, selected]);

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (course?._id) {
      fetchCourse(course._id);
    }
  }, [course]);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users?is_author=true`);
      setAuthors(response.data);
    } catch (error) {
      console.error("Ошибка при запросе авторов:", error);
    }
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  useEffect(() => {
    if (!authors?.length || !courseAuthors?.length) {
      setAuthorsList("");
      return;
    }

    const courseAuthorsNames = courseAuthors
      .map((courseAuthor) => {
        const author = authors.find((a) => a._id === courseAuthor._id);
        return author
          ? `${author.user_name} ${author.user_second_name || ""}`.trim()
          : null;
      })
      .filter(Boolean)
      .join(", ");

    setAuthorsList(courseAuthorsNames);
  }, [authors, courseAuthors]);

  const onSelectItem = useCallback(
    (event) => {
      event.stopPropagation();
      if (component === "modal_course") {
        handleSelectItem(courseData);
      }
    },
    [component, courseData]
  );

  const handleRemoveItem = useCallback(() => {
    onConfirmItemDeletion({
      _id: course._id,
      title: title,
    });
  }, [course, title]);

  return (
    <CourseSmallPreviewContainer
      background={background}
      component={component}
      onClick={onSelectItem}
    >
      <CourseSmallPreviewImage image={image} />

      <CourseSmallPreviewTitleContainer>
        <div>{title}</div>
        <h6>{authorsList && `(${authorsList})`}</h6>
      </CourseSmallPreviewTitleContainer>

      {component === "course" && (
        <ButtonsContainer>
          <UserRolesInfoButton onClick={handleRemoveItem}>
            <MinusIcon />
          </UserRolesInfoButton>

          <UserRolesInfoButton>
            <AdminPageGroupInfoIcon color={"#FFFFFF"} />
          </UserRolesInfoButton>
        </ButtonsContainer>
      )}
      {component === "modal_course" &&
        (!selected ? <PlusIcon /> : <CheckLargeIcon />)}
    </CourseSmallPreviewContainer>
  );
};

export default memo(CourseSmallPreview);
