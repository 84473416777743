import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import MinusIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";
import { UserRolesInfoButton } from "./user_roles_styled_components";
import AdminPageGroupInfoIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_group_info_icon";
import PlusIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import CheckLargeIcon from "../../../../common/assets/icons/admin_page_icons/check_large_icon";

const GroupSmallPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 6px;
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  background: ${({ background }) => background || ""};
  ${({ component }) =>
    (component === "modal_course" || component === "modal_group") &&
    "cursor: pointer;"}
`;

const GroupSmallPreviewImage = styled.div`
  width: 73px;
  height: 45px;
  background-size: cover;
  background-position: center;
  background-color: rgba(230, 230, 230);
  ${({ image }) => image && `background-image: url(${image});`}
  border-radius: 3px;
`;

const GroupSmallPreviewTitleContainer = styled.div`
  display: flex;
  gap: 5px;
  flex: 1;
  flex-wrap: wrap;
  user-select: none;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const GroupSmallPreview = ({
  component,
  item,
  selected,
  onConfirmItemDeletion,
  handleSelectItem,
}) => {
  const [groupData, setGroupData] = useState({});

  const [background, setBackground] = useState("");

  const title = "Название Группы";
  const image = "";

  useEffect(() => {
    let background;

    if (component === "group") {
      background = `linear-gradient(49deg, rgba(9, 45, 198, 0.20) -5.13%, rgba(10, 182, 161, 0.20) 105.54%)`;
    } else if (selected && component === "modal_group") {
      background = `linear-gradient(49deg, rgba(9, 45, 198, 0.20) -5.13%, rgba(10, 182, 161, 0.20) 105.54%)`;
    } else {
      background = "#CACACA";
    }

    setBackground(background);
  }, [component, selected]);

  const fetchGroup = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setGroupData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (item._id) {
      fetchGroup(item._id);
    }
  }, [item]);

  const onSelectItem = useCallback(
    (event) => {
      event.stopPropagation();
      if (component === "modal_group") {
        handleSelectItem(groupData);
      }
    },
    [component, groupData]
  );

  //   const handleRemoveItem = useCallback(() => {
  //     onConfirmItemDeletion({
  //       _id: item._id,
  //       title: title,
  //     });
  //   }, [item, title]);

  return (
    <GroupSmallPreviewContainer
      background={background}
      component={component}
      onClick={onSelectItem}
    >
      <GroupSmallPreviewImage image={""} />

      <GroupSmallPreviewTitleContainer>
        <div>{title}</div>
      </GroupSmallPreviewTitleContainer>

      {component === "group" && (
        <ButtonsContainer>
          <UserRolesInfoButton onClick={() => {}}>
            <MinusIcon />
          </UserRolesInfoButton>

          <UserRolesInfoButton>
            <AdminPageGroupInfoIcon color={"#FFFFFF"} />
          </UserRolesInfoButton>
        </ButtonsContainer>
      )}

      {component === "modal_group" &&
        (!selected ? <PlusIcon /> : <CheckLargeIcon />)}
    </GroupSmallPreviewContainer>
  );
};

export default GroupSmallPreview;
