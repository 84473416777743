import React, { memo } from "react";
import styled from "styled-components";
import { UserInfoSectionContainer } from "..";
import CourseActivity from "../../../../../../common/components/user_activity/course";

const UserActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EmptyActivity = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 15px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  user-select: none;
`;

const UserActivitySection = ({ userInfo }) => {
  const userActivity = userInfo?.user_activity;

  return (
    <UserInfoSectionContainer>
      Активность
      <UserActivityList>
        {userActivity?.length > 0 ? (
          <>
            {userActivity.map((activityItemInfo) => {
              if (activityItemInfo.type === "course") {
                return (
                  <CourseActivity
                    key={activityItemInfo.course_id}
                    userCourse={activityItemInfo}
                  />
                );
              }
            })}
          </>
        ) : (
          <EmptyActivity>Пока нет активности...</EmptyActivity>
        )}
      </UserActivityList>
    </UserInfoSectionContainer>
  );
};

export default memo(UserActivitySection);
