import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { Slide, toast } from "react-toastify";
import axios from "../../../axios_config";
import VerticalLine from "../../../common/assets/icons/vertical_line";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { BASE_API_URL } from "../../../common/endpoints";
import { useTranslation } from "react-i18next";

const LessonIsFinishedModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const LessonIsFinishedModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 520px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;
  font-family: Inter;

  @media (max-width: 530px) {
    width: 95%;
    border-radius: 15px;
    padding: 24px 20px 22px;
  }

  @media (max-width: 420px) {
    width: 95%;
    border-radius: 12px;
    padding: 16px 12px 16px;
  }

  h5 {
    color: #000;
    font-size: 28px;
    line-height: 130%;
    font-weight: 600;

    @media (max-width: 470px) {
      font-size: 24px;
    }

    @media (max-width: 415px) {
      font-size: 22px;
    }
  }

  h6 {
    width: 85%;
    color: #000;
    font-size: 18px;
    line-height: 130%;
    font-weight: 600;

    @media (max-width: 470px) {
      font-size: 16px;
    }

    @media (max-width: 415px) {
      font-size: 14px;
    }
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  @media (max-width: 415px) {
    font-size: 14px;
  }

  &:hover {
    font-size: 17px;
    color: #5172ea;

    @media (max-width: 415px) {
      font-size: 15px;
    }
  }
`;

const RetakeLessonButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
  width: 49%;
`;

const ReviewLessonButton = styled(ButtonStyles)`
  width: 49%;
  color: ${({ isHoverRetakeButton }) =>
    isHoverRetakeButton ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const ShareLessonButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
  width: 49%;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const LessonIsFinishedModalWindow = ({
  userData,
  lessonIsFinishedModalData,
  setLessonIsFinishedModalData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isHoverRetakeButton, setIsHoverRetakeButton] = useState(false);

  const { lessonId, lessonNum, userStudyId, isTest, testId } =
    lessonIsFinishedModalData;

  const topText = isTest
    ? t("course-page.lesson-is-finished-modal.top-text.test")
    : t("course-page.lesson-is-finished-modal.top-text.lesson");

  const middleText = isTest
    ? t("course-page.lesson-is-finished-modal.middle-text.test")
    : t("course-page.lesson-is-finished-modal.middle-text.lesson");

  const notificationText = isTest
    ? t("course-page.lesson-is-finished-modal.share-notification.test")
    : t("course-page.lesson-is-finished-modal.share-notification.lesson");

  const toggleHoverOnRestButton = () => {
    setIsHoverRetakeButton((prevState) => !prevState);
  };

  const onClickLessonReview = (e) => {
    e.stopPropagation();

    if (!isTest) {
      navigate(`${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`);
    } else {
      navigate(`${APPLICATION_ROUTES.USER_LESSON}/${testId}`);
    }
  };

  const onClickLessonRetake = async (e) => {
    e.stopPropagation();

    const newUserAnswersData = {
      study_answers: [],
    };

    try {
      await axios.patch(
        `${BASE_API_URL}/userstudy/${userStudyId}`,
        newUserAnswersData
      );

      localStorage.removeItem(lessonId);

      const lessonUrl = `${APPLICATION_ROUTES.USER_LESSON}/${
        isTest ? testId : lessonId
      }`;

      const queryParams = isTest ? "" : `?n=${lessonNum}`;

      navigate(lessonUrl + queryParams);
    } catch (error) {
      console.error("Ошибка создания перепрохождения Урока или Теста:", error);
    }
  };

  const showNotification = () => {
    toast(notificationText, {
      position: "top-left",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const onClickShareLesson = useCallback(
    (event) => {
      event.stopPropagation();

      const baseUrl = window.location.origin;
      const lessonPath = isTest
        ? `${baseUrl}/shared_lesson/${userData._id}/${testId}`
        : `${baseUrl}/shared_lesson/${userData._id}/${lessonId}?n=${lessonNum}`;

      try {
        copy(lessonPath);
        showNotification();
      } catch (err) {
        console.error("Ошибка при копировании: ", err);
        alert("Не удалось скопировать ссылку.");
      }
    },
    [userData, lessonId, lessonNum, isTest, testId]
  );

  const handleClose = (e) => {
    e.stopPropagation();
    setLessonIsFinishedModalData(null);
  };

  return (
    <LessonIsFinishedModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <LessonIsFinishedModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h5>{topText}</h5>

        <h6>{middleText}</h6>

        <ModalButtonsContainer>
          <ReviewLessonButton
            isHoverRetakeButton={isHoverRetakeButton}
            onClick={onClickLessonReview}
          >
            {t("course-page.lesson-is-finished-modal.buttons.review")}
          </ReviewLessonButton>

          <VerticalLineStyled color={"#5172EA"} />
          <RetakeLessonButton
            onMouseEnter={toggleHoverOnRestButton}
            onMouseLeave={toggleHoverOnRestButton}
            onClick={onClickLessonRetake}
          >
            {t("course-page.lesson-is-finished-modal.buttons.retake")}
          </RetakeLessonButton>

          <VerticalLineStyled color={"#5172EA"} />
          <ShareLessonButton
            isHoverRetakeButton={isHoverRetakeButton}
            onMouseEnter={toggleHoverOnRestButton}
            onMouseLeave={toggleHoverOnRestButton}
            onClick={onClickShareLesson}
          >
            {t("course-page.lesson-is-finished-modal.buttons.share")}
          </ShareLessonButton>
        </ModalButtonsContainer>
      </LessonIsFinishedModalContainer>
    </LessonIsFinishedModalWrapper>
  );
};

export default LessonIsFinishedModalWindow;
