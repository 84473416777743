import React, { memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import ImageDropzone from "../../../../../common/components/image_dropzone";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import { createComponentId } from "../../../../../common/components/lesson/lesson_utils";
import TextEditor from "../../../../../common/components/text_editor";
import BibleCopyRightArrowIcon from "../../../../../common/assets/icons/bible_page_icons/bible_copy_right_arrow_icon";
import BibleCopyLeftArrowIcon from "../../../../../common/assets/icons/bible_page_icons/bible_copy_left_arrow_icon";

const ImageSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 26px 24px;
`;

const TextPositionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: max-content;
  height: 30px;
  border-radius: 6px;
  background: #fff;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
`;

const ArrowIconContainer = styled.div`
  height: 100%;
  ${({ right }) =>
    right ? "padding: 8px 9px 0px 6px;" : "padding: 8px 6px 0px 9px;"}
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;

const IMAGE_SETTINGS_DEFAULT_DATA = {
  type: "IMAGE",
  id: "",
  image_url: "",
  image_text: {},
  image_text_position: "end",
  vocabulary_keys: [],
};

const ImageSettings = ({
  settingsBlockData,
  settingsBlockMemoData,
  editBlockIndex,
  lessonData,
  setSettingsBlockData,
  setLessonData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const isCurrentImage =
    settingsBlockData.image_url === settingsBlockMemoData.image_url;

  const currentTextPosition =
    settingsBlockData.image_text_position === "start"
      ? "Текст сверху"
      : "Текст снизу";

  useEffect(() => {
    if (editBlockIndex === null) {
      const defaultImageData = {
        ...IMAGE_SETTINGS_DEFAULT_DATA,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultImageData);
      setSettingsBlockMemoData(defaultImageData);
    }
  }, [editBlockIndex]);

  useEffect(() => {
    if (!settingsBlockData.image_text_position) {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        image_text_position: "end",
      }));
      setSettingsBlockMemoData((prevState) => ({
        ...prevState,
        image_text_position: "end",
      }));
    }
  }, [settingsBlockData]);

  const handleImageSettings = useCallback((image) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      image_url: image,
    }));
  }, []);

  const onEditorChange = useCallback(
    (updatedInputText) => {
      setSettingsBlockData((prevState) => {
        return {
          ...prevState,
          image_text: updatedInputText,
        };
      });
    },
    [setSettingsBlockData]
  );

  const handleTextPosition = useCallback(
    (event) => {
      event.stopPropagation();
      setSettingsBlockData((prevState) => {
        return {
          ...prevState,
          image_text_position:
            prevState.image_text_position === "start" ? "end" : "start",
        };
      });
    },
    [setSettingsBlockData]
  );

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <ImageSettingsContainer>
        <ImageDropzone
          imageUrl={settingsBlockData.image_url}
          isCurrentImage={isCurrentImage}
          handleImageSettings={handleImageSettings}
        />

        <TextEditor
          key={settingsBlockData.id}
          contentStateData={settingsBlockData.image_text}
          isEdite={editBlockIndex !== null}
          onEditorChange={onEditorChange}
          placeholder="Напишите текст..."
          lessonData={lessonData}
          setLessonData={setLessonData}
          setSettingsBlockData={setSettingsBlockData}
          inComponent={"image_component"}
        />

        <TextPositionButton onClick={handleTextPosition}>
          <ArrowIconContainer>
            <BibleCopyLeftArrowIcon />
          </ArrowIconContainer>
          {currentTextPosition}
          <ArrowIconContainer right={true}>
            <BibleCopyRightArrowIcon />
          </ArrowIconContainer>
        </TextPositionButton>

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={false}
        />
      </ImageSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(ImageSettings);
