import React from "react";

const ThreeDotsIcon = ({ className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_10595_10061)">
        <path
          d="M1.90476 6.0957C0.854476 6.0957 0 6.95018 0 8.00047C0 9.05075 0.854476 9.90523 1.90476 9.90523C2.95505 9.90523 3.80952 9.05075 3.80952 8.00047C3.80952 6.95018 2.95505 6.0957 1.90476 6.0957Z"
          fill={color || "white"}
        />
        <path
          d="M7.99949 6.0957C6.9492 6.0957 6.09473 6.95018 6.09473 8.00047C6.09473 9.05075 6.9492 9.90523 7.99949 9.90523C9.04977 9.90523 9.90425 9.05075 9.90425 8.00047C9.90425 6.95018 9.04977 6.0957 7.99949 6.0957Z"
          fill={color || "white"}
        />
        <path
          d="M14.0952 6.0957C13.0449 6.0957 12.1904 6.95018 12.1904 8.00047C12.1904 9.05075 13.0449 9.90523 14.0952 9.90523C15.1455 9.90523 16 9.05075 16 8.00047C16 6.95018 15.1455 6.0957 14.0952 6.0957Z"
          fill={color || "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_10595_10061">
          <rect width="16" height="16" fill={color || "white"} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThreeDotsIcon;
