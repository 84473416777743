import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import RequiredIcon from "../../../assets/icons/home_page_icons/required_icon";
import { AskQuestionLabel } from ".";
import { useTranslation } from "react-i18next";

const QuestionInputWrapper = styled.div`
  width: 100%;
`;

const QuestionInputStyles = styled.textarea`
  display: flex;
  padding: ;
  padding: ${({ inComponent }) =>
    inComponent === "profile" ? "8px" : "10px 15px"};
  min-height: 40px;
  max-height: 140px;
  width: 100%;
  border-radius: 8px;
  background: ${({ inComponent }) =>
    inComponent === "profile" ? "#D9D9D9" : "#fff"};
  font-size: 16px;
  line-height: 20px;
  resize: none;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const QuestionInput = ({
  userQuestionData,
  setUserQuestionData,
  limitReached,
  inComponent = "",
}) => {
  const { t } = useTranslation();

  const questionInputRef = useRef(null);
  const maxQuestionLength = 1000;

  const handleQuestionInputValue = useCallback(
    (event) => {
      const inputValue = event.target.value;

      if (inputValue.length <= maxQuestionLength) {
        setUserQuestionData((prevState) => ({
          ...prevState,
          question_text: inputValue,
        }));
      }
    },
    [setUserQuestionData]
  );

  useEffect(() => {
    if (questionInputRef.current) {
      questionInputRef.current.style.height = "auto";
      questionInputRef.current.style.height =
        questionInputRef.current.scrollHeight + "px";
    }
  }, [userQuestionData, questionInputRef]);

  useEffect(() => {
    const input = questionInputRef.current;
    if (input) {
      input.focus();
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }
  }, []);

  return (
    <QuestionInputWrapper>
      <AskQuestionLabel limitReached={limitReached}>
        {t("ask-question-modal.question-input-label")}
        <RequiredIcon />
      </AskQuestionLabel>

      <QuestionInputStyles
        inComponent={inComponent}
        placeholder={t("ask-question-modal.question-input-placeholder")}
        ref={questionInputRef}
        rows={1}
        type="text"
        value={userQuestionData.question_text}
        onChange={handleQuestionInputValue}
      />
    </QuestionInputWrapper>
  );
};

export default memo(QuestionInput);
