import React, { memo } from "react";
import styled from "styled-components";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";
import { componentsIconsArray } from "../../../common/components/lesson/lesson_utils";
import { useAppContext } from "../../../app_context";

const CreateLessonSidebarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  max-height: 630px;
  width: 90px;
  padding: 15px 0;
  z-index: 9999;
`;

const SidebarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  min-height: 52px;
  max-height: 52px;
  border-radius: 17px;
  background: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const ScrollToTopButtonStyled = styled(ScrollToTopButton)`
  bottom: 50px;
  left: 95px;
`;

const CreateLessonSidebar = ({ handleActiveSettingsBlock }) => {
  const { appContentContainerRef } = useAppContext();

  return (
    <CreateLessonSidebarContainer>
      {componentsIconsArray().map((item, index) => {
        const Icon = item.icon;
        return (
          <SidebarIconContainer
            key={index + item.type}
            onClick={(e) => handleActiveSettingsBlock(e, item.type)}
          >
            <Icon />
          </SidebarIconContainer>
        );
      })}
      <ScrollToTopButtonStyled parentRef={appContentContainerRef} />
    </CreateLessonSidebarContainer>
  );
};

export default memo(CreateLessonSidebar);
