import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import BibileMainButtons from "./bibile_main_buttons";
import { BASE_API_URL } from "../../../../common/endpoints";
import { transformTextWithStrongCode } from "../../bible_page_desktop/bible_chapter_desktop/verses";
import BibleVersesMobile from "./bible_verses_mobile";
import BibleNavigationMobile from "./bible_navigation_mobile";
import { useBiblePageContext } from "../../bible_page_context";
import BibleTranslationMobile from "../bible_translation_mobile";
import BibleStrongModalWindow from "../../../../common/components/modal_windows/bible_strong_modal_window";
import BibleSearchMobile from "./bible_search_mobile";
import BibleCompareMobile from "./bible_compare_mobile";
import BibleChapterArrows from "./bible_chapter_arrows";

const BibileChapterMobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ hasSecondTranslaition }) =>
    hasSecondTranslaition ? "50%" : "100%"};

  ${({ isFirstTranslation }) =>
    !isFirstTranslation && "border-top: 2px solid #fff;"}

  ${({ isSearchScreen }) => isSearchScreen && "height: 91%;"}

  box-shadow: ${({ isFirstTranslation, hasSecondTranslaition }) =>
    isFirstTranslation && hasSecondTranslaition
      ? "2px 4px 20px 0px rgba(0, 0, 0, 0.25)"
      : "none"};
`;

const BibileChapterMobile = ({
  selectedVerses,
  setSelectedVerses,
  isFirstTranslation,
  currentTranslation,
  bibleTranslations,
  bibleSearchPlace,
  bibleSearchInput,
  bibleSearchResults,
  setCurrentTranslation,
  setBibleTranslations,
  hasSecondTranslaition,
  setBibleSearchResults,
  setBibleSearchInput,
  setBibleSearchPlace,
}) => {
  const [searchParams] = useSearchParams();

  const {
    activeScreenMobile,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    setActiveScreenMobile,
    setCurrentTestament,
    currentTestament,
  } = useBiblePageContext();

  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [versesError, setVersesError] = useState(null);
  const [traslationLocale, setTraslationLocale] = useState("");
  const [openBibleNavigation, setOpenBibleNavigation] = useState(false);
  const [openBibleTranslation, setOpenBibleTranslation] = useState(false);
  const [openBibleSearch, setOpenBibleSearch] = useState(false);

  const bibleId = searchParams.get(isFirstTranslation ? "bibleId" : "2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");

  const showChapterArrows =
    isFirstTranslation &&
    currentTranslation?.bible_code &&
    activeScreenMobile === "bible_chapter";

  useEffect(() => {
    if (chapterData?.[0]?.book_place && isFirstTranslation) {
      setCurrentTestament(chapterData?.[0]?.book_place);
    }
  }, [chapterData, isFirstTranslation]);

  useEffect(() => {
    if (bibleTranslations && bibleId) {
      const translationLocale = bibleTranslations.find(
        (transl) => transl.bible_code === bibleId
      )?.bible_locale;

      setTraslationLocale(translationLocale);
    }
  }, [bibleTranslations, bibleId]);

  const fetchChapterData = async (bibleId, bookId, chapterId) => {
    try {
      const { data } = await axios.get(`${BASE_API_URL}/bible/verses`, {
        params: {
          bible: bibleId,
          book: bookId,
          chapter: chapterId,
        },
      });

      if (data.verses) {
        const parsedChapter = data.verses.map((verse) => ({
          ...verse,
          verse_text: transformTextWithStrongCode(verse.verse_text),
        }));
        return { data: parsedChapter, error: null };
      } else {
        return {
          data: [],
          error: "Данной книги/стиха нет в выбранном переводе",
        };
      }
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      return { data: [], error: "Произошла ошибка при загрузке данных" };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (bibleId && chapterId && bookId) {
        setIsLoading(true);
        try {
          const { data, error } = await fetchChapterData(
            bibleId,
            bookId,
            chapterId
          );
          setChapterData(data);
          setVersesError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [bibleId, chapterId, bookId]);

  return (
    <BibileChapterMobileContainer
      isFirstTranslation={isFirstTranslation}
      isSearchScreen={activeScreenMobile === "bible_search"}
      hasSecondTranslaition={hasSecondTranslaition}
    >
      <BibileMainButtons
        chapterData={chapterData}
        bibleId={bibleId}
        isFirstTranslation={isFirstTranslation}
        setOpenBibleSearch={setOpenBibleSearch}
        currentTranslation={currentTranslation}
        hasSecondTranslaition={hasSecondTranslaition}
        setOpenBibleNavigation={setOpenBibleNavigation}
        setOpenBibleTranslation={setOpenBibleTranslation}
        setShowStrongsCode={setShowStrongsCode}
        setActiveScreenMobile={setActiveScreenMobile}
      />

      {activeScreenMobile === "bible_chapter" && (
        <BibleVersesMobile
          isLoading={isLoading}
          versesError={versesError}
          chapterData={chapterData}
          isFirstTranslation={isFirstTranslation}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
        />
      )}

      {activeScreenMobile === "bible_navigation" && openBibleNavigation && (
        <BibleNavigationMobile
          traslationLocale={traslationLocale}
          bibleId={bibleId}
        />
      )}

      {activeScreenMobile === "bible_search" && openBibleSearch && (
        <BibleSearchMobile
          bibleSearchPlace={bibleSearchPlace}
          bibleSearchInput={bibleSearchInput}
          bibleSearchResults={bibleSearchResults}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setBibleSearchPlace={setBibleSearchPlace}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSelectedVerses={setSelectedVerses}
          currentTranslation={currentTranslation}
        />
      )}

      {activeScreenMobile === "bible_compare" && (
        <BibleCompareMobile
          selectedVerses={selectedVerses}
          bibleTranslations={bibleTranslations}
          setBibleTranslations={setBibleTranslations}
        />
      )}

      {activeScreenMobile === "bible_translation" && openBibleTranslation && (
        <BibleTranslationMobile
          currentTranslation={currentTranslation}
          isFirstTranslation={isFirstTranslation}
          bibleTranslations={bibleTranslations}
          setCurrentTranslation={setCurrentTranslation}
          setBibleTranslations={setBibleTranslations}
        />
      )}

      {showChapterArrows && <BibleChapterArrows chapterData={chapterData} />}

      {!!strongsCodeModalData &&
        currentTranslation?.bible_strong &&
        createPortal(
          <BibleStrongModalWindow
            strongsCodeModalData={strongsCodeModalData}
            currentTranslation={currentTranslation}
            currentTestament={currentTestament}
            setOpen={setStrongsCodeModalData}
            setShowStrongsCode={setShowStrongsCode}
          />,
          document.body
        )}
    </BibileChapterMobileContainer>
  );
};

export default memo(BibileChapterMobile);
