import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { BASE_API_URL } from "../../common/endpoints";
import InputControlButtons from "../../pages/user_profile/input_control_buttons";
import { useTranslation } from "react-i18next";

const UserLocationSelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const ShadowWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const LocationContainer = styled.div`
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  ${({ disabled }) => disabled && "opacity: 0.5;"}
  ${({ isOpen }) => isOpen && "border-radius: 10px 10px 0 0;"}

  ${({ disabled }) =>
    !disabled &&
    `
  &:focus-within {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  `}
`;

const LocationInputContainer = styled.div`
  position: relative;
  height: 100%;
`;

const LocationInput = styled.input`
  width: 100%;
  padding: 0 15px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  transition: 0.2s;
  height: ${({ inComponent }) =>
    inComponent === "activation_modal" || inComponent === "user_profile"
      ? "40px"
      : "52px"};
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: ${({ inComponent }) =>
    inComponent === "activation_modal" || inComponent === "user_profile"
      ? "40px"
      : "52px"};
  left: 0;
  width: 100%;
  overflow: hidden;
  max-height: max-content;
  border-radius: 0 0 10px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 999;
`;

const Dropdown = styled.ul`
  width: 100%;
  list-style: none;
  padding: 8px 4px;
  max-height: 168px;
  overflow-y: auto;
  border-top: 1px solid #cacaca;
  background-color: #fff;
  transition: 0.2s;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const DropdownItem = styled.li`
  padding: 8px 6px;
  font-size: 16px;
  border-radius: 6px;
  color: #1e1e1e;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(81, 114, 234, 0.2) -18.38%,
      rgba(122, 90, 238, 0.2) 108.82%
    );
  }

  span {
    display: inline-block;
    margin-top: 5px;
    color: #acacac;
    font-size: 14px;
  }
`;

const SearchErrorMessage = styled.div`
  border-top: 1px solid #cacaca;
  background: #fff;
  padding: 10px 15px;
  user-select: none;
  color: #1e1e1e;
`;

const RequireIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -1px;
  color: #5172ea;
  font-weight: 600;
  user-select: none;
`;

const removeLastPart = (text) => {
  if (!text) return "";

  const parts = text.split(",");

  if (parts.length <= 1) {
    return "";
  }

  return parts.slice(0, -1).join(",").trim();
};

const UserLocationSelect = ({
  inComponent = "",
  userData = {},
  handleUserData,
  onClickCancelChanges,
  onClickSaveChanges,
}) => {
  const { t } = useTranslation();

  const [countryInput, setCountryInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [shortCountryName, setShortCountryName] = useState(null);
  const [searchErrors, setSearchErrors] = useState({
    country: false,
    city: false,
  });
  const [showCountryControlButtons, setShowCountryControlButtons] =
    useState(null);
  const [showCityControlButtons, setShowCityControlButtons] = useState(null);

  const [debouncedCountryInput] = useDebounce(countryInput, 500);
  const [debouncedCityInput] = useDebounce(cityInput, 500);

  const setUserCountryData = () => {
    setCountryInput(userData.user_country);
    setSelectedCountry({
      place_id: userData?.user_place_details?.country?.place_id,
      description: userData.user_country,
    });
  };

  const setUserCityData = () => {
    setCityInput(userData.user_city);
    setSelectedCity({
      place_id: userData?.user_place_details?.locality?.place_id,
      description: userData.user_city,
    });
  };

  useEffect(() => {
    if (inComponent === "user_profile" && userData._id) {
      if (userData.user_country) {
        setUserCountryData();
      }

      if (userData.user_city) {
        setUserCityData();
      }
    }
  }, [inComponent, userData]);

  useEffect(() => {
    if (!selectedCountry && inComponent !== "user_profile") {
      setSelectedCity(null);
      setCityInput("");
      handleUserData("city", "");
      handleUserData("cityId", null);
    }
  }, [selectedCountry, inComponent]);

  const fetchCountries = async (query) => {
    if (
      query.trim() === "" ||
      countryInput.trim() === "" ||
      query.trim().length < 2
    ) {
      setCountries([]);
      return;
    }

    try {
      const response = await axios.get(`${BASE_API_URL}/places`, {
        params: { input: query, types: "country" },
      });

      if (response.data.status === "OK") {
        setCountries(response.data.places || []);
        setSearchErrors((prevState) => ({ ...prevState, country: false }));
      } else {
        setSearchErrors((prevState) => ({ ...prevState, country: true }));
        setCountries([]);
      }
    } catch (error) {
      console.error("Ошибка при загрузке стран:", error);
      setCountries([]);
    }
  };

  const fetchCountryDetails = async (countryId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/places/details`, {
        params: { placeId: countryId },
      });
      const shortName =
        response.data.address_components?.[0]?.short_name || null;

      setShortCountryName(shortName);
    } catch (error) {
      console.error("Ошибка при загрузке деталей страны:", error);
      setShortCountryName(null);
    }
  };

  const fetchCities = async (query) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/places`, {
        params: {
          input: query,
          types: "locality",
          components: `country:${shortCountryName}`,
        },
      });

      if (response.data.status === "OK") {
        setCities(response.data.places || []);
        setSearchErrors((prevState) => ({ ...prevState, city: false }));
      } else {
        setSearchErrors((prevState) => ({ ...prevState, city: true }));
        setCities([]);
      }
    } catch (error) {
      console.error("Ошибка при загрузке городов:", error);
      setCities([]);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      fetchCountryDetails(selectedCountry.place_id);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry || countryInput.trim() === "") {
      setCountries([]);
      setSearchErrors((prevState) => ({ ...prevState, country: false }));
      return;
    }

    fetchCountries(debouncedCountryInput);
  }, [debouncedCountryInput]);

  useEffect(() => {
    if (
      !selectedCity &&
      selectedCountry &&
      shortCountryName &&
      debouncedCityInput.length >= 2
    ) {
      fetchCities(debouncedCityInput);
    } else {
      setCities([]);
      setSearchErrors((prevState) => ({ ...prevState, city: false }));
      return;
    }
  }, [debouncedCityInput, selectedCountry, selectedCity, shortCountryName]);

  useEffect(() => {
    if (selectedCountry) {
      handleUserData("country", countryInput);
      handleUserData("countryId", selectedCountry.place_id);
    } else {
      handleUserData("country", "");
      handleUserData("countryId", null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCity) {
      handleUserData("city", cityInput);
      handleUserData("cityId", selectedCity.place_id);
    } else {
      handleUserData("city", "");
      handleUserData("cityId", null);
    }
  }, [selectedCity]);

  const handleCountryChange = useCallback(
    (value) => {
      setCountryInput(value.trim());

      if (value.trim() === "") {
        setSelectedCountry(null);
        setCountries([]);
        setSelectedCity(null);
        setCityInput("");
        return;
      }

      if (selectedCountry && value.trim() !== selectedCountry.description) {
        setSelectedCountry(null);
        setShortCountryName(null);
        setSelectedCity(null);
        setCityInput("");
      }
    },
    [selectedCountry]
  );

  const handleCityChange = (value) => {
    setCityInput(value.trim());
    setSelectedCity(null);
  };

  const selectCountry = (event, country) => {
    event.stopPropagation();
    setSelectedCountry(country);
    setCountryInput(country.description);
    setCountries([]);

    if (country.place_id !== userData?.user_place_details?.country?.place_id) {
      setSelectedCity(null);
      setCityInput("");
    }
  };

  const selectCity = (event, city) => {
    event.stopPropagation();
    setCityInput(city.structured_formatting?.main_text);
    setSelectedCity(city);
    setCities([]);
  };

  //                         Logic for control buttons (save/cancel)

  useEffect(() => {
    if (!selectedCountry && inComponent === "user_profile" && userData._id) {
      if (
        userData?.user_place_details?.country?.place_id !==
          selectedCountry?.place_id ||
        countryInput.trim() === "" ||
        !selectedCountry
      ) {
        setShowCityControlButtons(false);
      }
    }
  }, [selectedCountry, inComponent, userData, countryInput]);

  const onClickCountryCancelButton = (event) => {
    onClickCancelChanges(event, "country");
    setUserCountryData();
    setUserCityData();
  };

  const onClickCountrySaveButton = (event) => {
    onClickSaveChanges(event, "country");
  };

  const onClickCityCancelButton = (event) => {
    onClickCancelChanges(event, "city");
    setUserCityData();
  };

  const onClickCitySaveButton = (event) => {
    onClickSaveChanges(event, "city");
  };

  useEffect(() => {
    if (inComponent !== "user_profile" || !userData._id) return;

    const hasValidUserCountry =
      userData.user_country || userData.user_country === "";

    const isCountryChanged =
      hasValidUserCountry && userData.user_country !== countryInput;

    setShowCountryControlButtons((prevState) =>
      prevState === null ? false : isCountryChanged
    );
  }, [countryInput, inComponent, userData, selectedCountry]);

  useEffect(() => {
    if (inComponent !== "user_profile" || !userData._id) return;

    const hasValidUserCity = userData.user_city || userData.user_city === "";

    const isCityChanged =
      hasValidUserCity &&
      userData.user_city !== cityInput &&
      (cityInput === "" || selectedCity?.place_id);

    const countryIsChanged =
      selectedCountry?.place_id !==
        userData?.user_place_details?.country?.place_id || !selectedCountry;

    setShowCityControlButtons((prevState) =>
      prevState === null || countryIsChanged ? false : isCityChanged
    );
  }, [cityInput, inComponent, userData, selectedCity, selectedCountry]);

  return (
    <UserLocationSelectContainer>
      <ShadowWrapper>
        <LocationContainer isOpen={countries.length || searchErrors.country}>
          <LocationInputContainer>
            <LocationInput
              placeholder={t(
                "common-components.user-location-select.country-placeholder"
              )}
              value={countryInput}
              onChange={(e) => handleCountryChange(e.target.value)}
              autoComplete="off"
              inComponent={inComponent}
            />
            {showCountryControlButtons && (
              <InputControlButtons
                onClickCancelButton={onClickCountryCancelButton}
                onClickSaveButton={onClickCountrySaveButton}
                showSaveButton={
                  countryInput === "" || !!selectedCountry?.place_id
                }
              />
            )}
          </LocationInputContainer>

          <DropdownContainer inComponent={inComponent}>
            {countries.length > 0 && (
              <Dropdown>
                {countries.map((country) => (
                  <DropdownItem
                    key={country.place_id}
                    onClick={(e) => selectCountry(e, country)}
                  >
                    {country.description}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}

            {searchErrors.country && (
              <SearchErrorMessage>
                {t("common-components.user-location-select.country-not-found")}
              </SearchErrorMessage>
            )}
          </DropdownContainer>

          {inComponent === "activation_modal" && <RequireIcon>*</RequireIcon>}
        </LocationContainer>
      </ShadowWrapper>

      <ShadowWrapper>
        <LocationContainer
          disabled={
            !selectedCountry || !shortCountryName || showCountryControlButtons
          }
          isOpen={cities.length || searchErrors.city}
        >
          <LocationInputContainer>
            <LocationInput
              placeholder={t(
                "common-components.user-location-select.city-placeholder"
              )}
              value={cityInput}
              onChange={(e) => handleCityChange(e.target.value)}
              autoComplete="off"
              disabled={
                !selectedCountry ||
                !shortCountryName ||
                showCountryControlButtons
              }
              inComponent={inComponent}
            />
            {showCityControlButtons && (
              <InputControlButtons
                onClickCancelButton={onClickCityCancelButton}
                onClickSaveButton={onClickCitySaveButton}
                showSaveButton={cityInput === "" || !!selectedCity?.place_id}
              />
            )}
          </LocationInputContainer>

          <DropdownContainer inComponent={inComponent}>
            {cities.length > 0 && (
              <Dropdown>
                {cities.map((city) => (
                  <DropdownItem
                    key={city.place_id}
                    onClick={(e) => selectCity(e, city)}
                  >
                    {city.structured_formatting?.main_text}{" "}
                    <span>
                      {removeLastPart(
                        city.structured_formatting?.secondary_text
                      )}
                    </span>
                  </DropdownItem>
                ))}
              </Dropdown>
            )}

            {searchErrors.city && (
              <SearchErrorMessage>
                {t("common-components.user-location-select.city-not-found")}
              </SearchErrorMessage>
            )}
          </DropdownContainer>
        </LocationContainer>
      </ShadowWrapper>
    </UserLocationSelectContainer>
  );
};

export default memo(UserLocationSelect);
