import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../../../common/assets/icons";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";

const GroupsListHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 630px;
  margin-left: 64px;
`;

const CreateGroupButton = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: max-content;
  padding: 0 15px;
  border-radius: 8px;
  color: #fff;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const SearchGroupsInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 9px 0 12px;
  width: 230px;
  height: 40px;
  background: #fff;
  transition: 0.2s;

  :hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  input {
    width: 87%;
    font-size: 14px;
  }
`;

const SearchGroupsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 70%;
  background: #fff;
  padding: 0 4px;
  transition: 0.2s;

  :hover {
    transform: scale(1.1);
  }
`;

const GroupsListHeader = () => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon, remove_icon

  const handleSearchInputValue = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  return (
    <GroupsListHeaderContainer>
      <CreateGroupButton>Создать группу</CreateGroupButton>

      <SearchGroupsInput>
        <input
          placeholder="Поиск групп..."
          value={searchInputValue}
          onChange={handleSearchInputValue}
          onMouseDown={(e) => e.stopPropagation()}
          //  onKeyDown={(event) =>
          //    event.key === "Enter" && filterCoursesBySearch()
          //  }
        />

        {showedInputIcon === "search_icon" ? (
          <SearchGroupsButton onClick={() => {}}>
            <SearchIcon />
          </SearchGroupsButton>
        ) : (
          <SearchGroupsButton onClick={() => {}}>
            <RemoveIcon color="#383838" />
          </SearchGroupsButton>
        )}
      </SearchGroupsInput>
    </GroupsListHeaderContainer>
  );
};

export default memo(GroupsListHeader);
