import React from "react";

const QuestionsFiltersIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M17.4727 12.1437H16.2671C16.0109 10.389 14.4962 9.03711 12.6715 9.03711C10.8468 9.03711 9.33212 10.389 9.0759 12.1437H0.527344C0.236109 12.1437 0 12.3798 0 12.6711C0 12.9623 0.236109 13.1984 0.527344 13.1984H9.0759C9.33212 14.9532 10.8468 16.3051 12.6715 16.3051C14.4962 16.3051 16.0109 14.9532 16.2671 13.1984H17.4727C17.7639 13.1984 18 12.9623 18 12.6711C18 12.3798 17.7639 12.1437 17.4727 12.1437ZM12.6715 15.2504C11.2493 15.2504 10.0922 14.0933 10.0922 12.6711C10.0922 11.2489 11.2493 10.0918 12.6715 10.0918C14.0937 10.0918 15.2508 11.2489 15.2508 12.6711C15.2508 14.0933 14.0937 15.2504 12.6715 15.2504Z"
        fill="black"
      />
      <path
        d="M0.527344 5.85568H1.73292C1.98914 7.61039 3.50381 8.96229 5.32853 8.96229C7.15321 8.96229 8.66788 7.61039 8.9241 5.85568H17.4727C17.7639 5.85568 18 5.61957 18 5.32833C18 5.0371 17.7639 4.80099 17.4727 4.80099H8.9241C8.66788 3.04623 7.15321 1.69434 5.32853 1.69434C3.50381 1.69434 1.98914 3.04623 1.73292 4.80099H0.527344C0.236109 4.80099 0 5.0371 0 5.32833C0 5.61957 0.236109 5.85568 0.527344 5.85568ZM5.32853 2.74902C6.75074 2.74902 7.9078 3.90609 7.9078 5.32833C7.9078 6.75054 6.75074 7.90761 5.32853 7.90761C3.90628 7.90761 2.74922 6.75054 2.74922 5.32833C2.74922 3.90609 3.90628 2.74902 5.32853 2.74902Z"
        fill="black"
      />
    </svg>
  );
};

export default QuestionsFiltersIcon;
