import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import { Link, useSearchParams } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import PlayIcon from "../../../common/assets/icons/play_icon";

const CreateLessonHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  position: fixed;
  z-index: 9999;
`;

const CreateLessonHeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 auto;
  max-width: 945px;
  height: 100%;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
  width: 665px;
`;

const HeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
`;

const CreateLessonLessonTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #fff;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 2px 0;
`;

const LeftArrowIconIconContainer = styled.div`
  cursor: pointer;
  padding: 5px 15px;

  &:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const TestComponentsCount = styled.div`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
`;

const ToLessonPreviewButton = styled(Link)``;

const PlayIconStyles = styled(PlayIcon)`
  width: 20px;
  height: 20px;

  &:hover {
    transition: 0.2s;
    transform: scale(1.12);
  }
`;

const CreateLessonHeader = ({ lessonData }) => {
  const [searchParams] = useSearchParams();
  const lessonNumber = searchParams.get("n");

  const [testComponentsCount, setTestComponentsCount] = useState(null);

  const isTest =
    lessonData?.lesson_type === "test"
      ? true
      : lessonData?.lesson_type === "lesson"
      ? false
      : null;

  const lessonUrl = `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${lessonData?._id}`;
  const queryParams = isTest ? "?p=create" : `?n=${lessonNumber}&p=create`;

  useEffect(() => {
    const lessonType = lessonData.lesson_type;

    if (lessonType === "test") {
      const testsCount = lessonData.lesson_data?.filter(
        (el) => el.type === "TEST"
      )?.length;

      setTestComponentsCount(testsCount);
    }
  }, [lessonData]);

  return (
    <CreateLessonHeaderWrapper>
      <CreateLessonHeaderContainer onMouseDown={(e) => e.stopPropagation()}>
        <HeaderContentWrapper>
          <HeaderContentContainer>
            <Link
              to={`${APPLICATION_ROUTES.ADMIN_COURSES}?courseId=${lessonData?.lesson_course?._id}&lessons=true&lessonId=${lessonData?._id}`}
            >
              <LeftArrowIconIconContainer>
                <LeftArrowIcon />
              </LeftArrowIconIconContainer>
            </Link>

            <CreateLessonLessonTitle>
              {!isTest && isTest !== null && <>Урок {lessonNumber}. </>}{" "}
              {lessonData.lesson_name}
            </CreateLessonLessonTitle>
          </HeaderContentContainer>

          <ToLessonPreviewButton to={lessonUrl + queryParams}>
            <PlayIconStyles color={"#F8D254"} />
          </ToLessonPreviewButton>

          {testComponentsCount !== null && (
            <TestComponentsCount>
              Тестов: <b>{testComponentsCount}</b>
            </TestComponentsCount>
          )}
        </HeaderContentWrapper>
      </CreateLessonHeaderContainer>
    </CreateLessonHeaderWrapper>
  );
};

export default memo(CreateLessonHeader);
