import React from "react";
import styled from "styled-components";
import { Slide, toast } from "react-toastify";
import copy from "copy-to-clipboard";

const DoneteCardImage = styled.img`
  margin-bottom: 21px;
  border-radius: 15px;
  cursor: pointer;

  @media (max-width: 670px) {
    width: 100%;
    margin-bottom: 27px;
  }
`;

const Card = ({ card }) => {
  const showNotification = () => {
    toast("Номер карты скопирован!", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    try {
      const isCopied = copy(text);
      if (isCopied) {
        showNotification();
      } else {
        console.error("Не удалось скопировать.");
        alert("Ошибка при копировании");
      }
    } catch (error) {
      console.error("Ошибка копирования: ", error);
      alert("Ошибка при копировании");
    }
  };

  const handleCopy = () => {
    copyToClipboard(card.number);
  };

  return (
    <div onClick={handleCopy}>
      <DoneteCardImage src={card.image} alt={card.name} />
    </div>
  );
};

export default Card;
