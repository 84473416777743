import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "../../../../axios_config";
import styled from "styled-components";
import QuestionArrowIcon from "../../../assets/icons/home_page_icons/ask_question_arrow_icon";
import { BASE_API_URL } from "../../../endpoints";
import UserImage from "../../user_image";
import { useTranslation } from "react-i18next";

const QuestionRecipientContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  min-height: max-content;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const QuestionRecipientHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 14px 0 10px;
  align-items: center;
  gap: 10px;
  background: #fff;
  border-bottom: ${({ showRecipients }) =>
    showRecipients ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const RecepientInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;

  h6 {
    color: ${({ color }) => color || "#828282"};
    font-size: ${({ fontSize }) => fontSize || "16px"};
    line-height: 120%;
  }
`;

const QuestionArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showRecipients }) => showRecipients && "transform: rotate(180deg);"}
`;

const QuestionRecipientsContainer = styled.div`
  padding: 4px;
`;

const QuestionRecipientsItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;
  min-height: 32px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const TopLine = styled.div`
  border-top: 1px solid #cacaca;
  width: 97%;
  margin: 0 auto;
`;

const QuestionRecipient = ({
  userQuestionData,
  setUserQuestionData,
  userData,
  color,
  fontSize,
}) => {
  const { t } = useTranslation();
  const [authors, setAuthors] = useState([]);
  const [showRecipients, setShowRecipients] = useState(false);
  const [selectedRecipientName, setSelectedRecipientName] = useState("");

  const techSupportData = {
    _id: "tech_support",
    user_name: t("common-components.technical-support-name"),
    user_second_name: "",
  };

  const recepientAuthorData =
    authors.find(
      (author) => author._id === userQuestionData?.question_to_author?._id
    ) || null;

  useEffect(() => {
    if (userQuestionData?.question_to_author?._id === "tech_support") {
      setSelectedRecipientName(
        `${techSupportData.user_name} ${techSupportData.user_second_name}`
      );
    } else if (authors.length && recepientAuthorData) {
      setSelectedRecipientName(
        `${recepientAuthorData.user_name} ${recepientAuthorData.user_second_name}`
      );
    } else {
      setSelectedRecipientName(
        t("ask-question-modal.select-recipient-placeholder")
      );
    }
  }, [userQuestionData.question_to_author, authors]);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users?is_author=true`);
      const authors = response.data;
      setAuthors(authors);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    if (userData._id) {
      fetchAuthors();
    }
  }, [userData]);

  const handleShowRecipients = useCallback(() => {
    setShowRecipients((prevState) => !prevState);
  }, []);

  const handleSelectRecipient = useCallback(
    (recipient) => {
      setUserQuestionData((prevState) => ({
        ...prevState,
        question_to_author: { _id: recipient._id },
      }));
      setShowRecipients(false);
    },
    [setUserQuestionData]
  );

  return (
    <QuestionRecipientContainer>
      <QuestionRecipientHeader
        showRecipients={showRecipients}
        onClick={handleShowRecipients}
      >
        <RecepientInfoContainer color={color} fontSize={fontSize}>
          {recepientAuthorData && (
            <UserImage
              userData={recepientAuthorData}
              size={"22px"}
              fontSize={"8px"}
            />
          )}

          <h6>{selectedRecipientName}</h6>
        </RecepientInfoContainer>
        <QuestionArrowIconContainer showRecipients={showRecipients}>
          <QuestionArrowIcon />
        </QuestionArrowIconContainer>
      </QuestionRecipientHeader>

      {showRecipients && (
        <QuestionRecipientsContainer>
          {[{}, ...authors, techSupportData].map((recipient) => {
            const recipientName =
              recipient.user_name || recipient.user_second_name
                ? `${recipient.user_name} ${recipient.user_second_name}`
                : t("ask-question-modal.select-without-recipient");

            return (
              <div key={recipient._id || "whithout_recipient"}>
                {recipient._id === "tech_support" && <TopLine />}
                <QuestionRecipientsItem
                  onClick={() => handleSelectRecipient(recipient)}
                >
                  {recipientName}
                </QuestionRecipientsItem>
              </div>
            );
          })}
        </QuestionRecipientsContainer>
      )}
    </QuestionRecipientContainer>
  );
};

export default memo(QuestionRecipient);
