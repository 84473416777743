import React from "react";

const EditQuestionIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_10595_10082)">
        <path
          d="M17.9073 5.10479C18.8233 4.18873 18.8232 2.70619 17.9073 1.79021L16.8024 0.685371C15.8886 -0.228457 14.4017 -0.228457 13.4878 0.685371L3.17586 10.9974C2.58215 11.5911 2.18977 12.3758 2.07102 13.2071L1.51855 17.074L5.38559 16.5216C6.21676 16.4029 7.00152 16.0105 7.59523 15.4168L17.9073 5.10479ZM5.20141 11.1815L11.8305 4.55236L14.0402 6.76205L7.41113 13.3912L5.20141 11.1815ZM3.36 15.2326L3.61781 13.4281C3.67676 13.0156 3.84828 12.6224 4.10727 12.2971L6.29563 14.4854C5.97035 14.7444 5.57715 14.9159 5.16465 14.9749L3.36 15.2326ZM16.8024 3.9999L15.1451 5.65721L12.9354 3.44752L14.5927 1.79025C14.8973 1.48564 15.3929 1.48564 15.6975 1.79025L16.8023 2.8951C17.1078 3.20049 17.1077 3.69471 16.8024 3.9999Z"
          fill="#725FED"
        />
        <path d="M0 18.4365H20V19.999H0V18.4365Z" fill="#725FED" />
      </g>
      <defs>
        <clipPath id="clip0_10595_10082">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditQuestionIcon;
