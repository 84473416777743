import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "../../../../axios_config";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";
import { LESSON_BG_COLOR } from "../../../../pages/study/course_page";
import { BASE_API_URL } from "../../../endpoints";

const CourseActivityLessonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  border-top: 1px solid #eaeaea;
  padding: 0 31px;
  background: ${({ background }) => background};
  user-select: none;
  ${({ status }) => status === "closed" && "opacity: 0.7;"};
  cursor: ${({ status, inComponent }) =>
    status === "closed" || inComponent !== "user_profile"
      ? "default"
      : "pointer"};
  transition: 0.2s;

  & > p {
    color: ${({ status }) => (status === "finished" ? "#fff" : "#383838")};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`;

const CourseLesson = ({
  lesson,
  index,
  inComponent,
  userCourse,
  userData,
  allLessonsList,
  setLessonIsFinishedModalData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [lessonStatus, setLessonStatus] = useState("closed"); // finished, in_process, next, closed

  const lessonNum = index + 1;

  const lessonStatusName =
    lesson.lesson_status === "finished"
      ? t("user-profile-page.activity.course.lesson-status.finished")
      : lesson.lesson_status === "in_process"
      ? t("user-profile-page.activity.course.lesson-status.in-process")
      : t("user-profile-page.activity.course.lesson-status.unfinished");

  const lessonBackgroundColor =
    lessonStatus === "closed"
      ? LESSON_BG_COLOR.CLOSED
      : lessonStatus === "in_process" || lessonStatus === "next"
      ? LESSON_BG_COLOR.CURRENT
      : LESSON_BG_COLOR.FINISHED;

  useEffect(() => {
    if (
      lesson.lesson_status === "in_process" ||
      lesson.lesson_status === "finished"
    ) {
      setLessonStatus(lesson.lesson_status);
    } else {
      const lastFinishedIndex = allLessonsList.findLastIndex(
        (l) => l.lesson_status === "finished"
      );
      const hasInProcess = allLessonsList.some(
        (l) => l.lesson_status === "in_process"
      );
      const nextLessonIndex =
        lastFinishedIndex >= 0 ? lastFinishedIndex + 1 : -1;

      if (!hasInProcess && index === nextLessonIndex) {
        setLessonStatus("next");
      } else {
        setLessonStatus("closed");
      }
    }
  }, [index, allLessonsList, lesson._id]);

  const onClickLesson = useCallback(
    async (event) => {
      event.stopPropagation();

      if (lessonStatus === "closed" || inComponent !== "user_profile") return;

      if (lessonStatus === "finished") {
        if (lesson.lesson_type === "lesson") {
          setLessonIsFinishedModalData({
            lessonId: lesson._id,
            lessonNum,
            userStudyId: lesson.user_study_id,
            isTest: false,
            testId: null,
          });
        } else {
          setLessonIsFinishedModalData({
            lessonId: null,
            lessonNum: null,
            testId: lesson._id,
            userStudyId: lesson.user_study_id,
            isTest: true,
          });
        }
      } else if (lessonStatus === "in_process") {
        if (lesson.lesson_type === "lesson") {
          navigate(
            `${APPLICATION_ROUTES.USER_LESSON}/${lesson._id}?n=${lessonNum}`
          );
        } else {
          navigate(`${APPLICATION_ROUTES.USER_LESSON}/${lesson._id}`);
        }
      } else if (lessonStatus === "next") {
        try {
          const createNewUserLesson = {
            study_lesson: { _id: lesson._id, lesson_type: lesson.lesson_type },
            study_user: { _id: userData._id },
            study_answers: [],
            user_test_pass_rate: 0,
            course_pass_rate: userCourse?.course_pass_rate,
            study_course: {
              _id: userCourse.course_id,
              course_name: userCourse.course_name,
              course_lessons_count: userCourse.course_lessons_count,
              course_test_count: userCourse.course_test_count,
            },
          };
          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserLesson);

          if (lesson.lesson_type == "lesson") {
            navigate(
              `${APPLICATION_ROUTES.USER_LESSON}/${lesson._id}?n=${lessonNum}`
            );
          } else {
            navigate(`${APPLICATION_ROUTES.USER_LESSON}/${lesson._id}`);
          }
        } catch (error) {
          console.error("Ошибка создании урока/теста:", error);
        }
      }
    },
    [lesson, inComponent, lessonNum, lessonStatus, userCourse, userData]
  );

  return (
    <CourseActivityLessonContainer
      status={lessonStatus}
      inComponent={inComponent}
      background={lessonBackgroundColor}
      onClick={onClickLesson}
    >
      <p>
        {lesson.lesson_type === "test" ? (
          t("user-profile-page.activity.course.test-title")
        ) : (
          <>
            {t("user-profile-page.activity.course.lesson-title")} {lessonNum}
          </>
        )}
      </p>
      <p>{lessonStatusName}</p>
    </CourseActivityLessonContainer>
  );
};

export default memo(CourseLesson);
