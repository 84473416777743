import React, { memo, useEffect, useState } from "react";
import { convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import { stateToHTML } from "draft-js-export-html";
import styled from "styled-components";
import {
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  getCustomOptions,
} from "../../../text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../../../text_editor/editor_styled_components";

const ImageTextEditorViewComponent = styled(TextEditorViewComponent)`
  margin: ${({ marginBottom }) => (marginBottom ? "0 0 12px" : "12px 0 0")};
`;

const ImageText = ({
  userData,
  colorTheme,
  componentData,
  setTextEditorComponentHtml,
  courseMainColor,
  courseMainGradient,
  marginBottom,
}) => {
  const [html, setHtml] = useState("");

  const sanitizedHtml = DOMPurify.sanitize(html);
  const currentUserName = userData?.user_name || "Пользователь";

  useEffect(() => {
    if (componentData.image_text?.blocks?.length) {
      try {
        let imageText = convertFromRaw(componentData.image_text);

        const options = getCustomOptions(colorTheme);
        let htmlContent = stateToHTML(imageText, options);

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );

        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert image_text from raw:", error);
      }
    }
  }, [componentData.image_text, currentUserName, colorTheme]);

  useEffect(() => {
    setTextEditorComponentHtml({});
  }, [html]);

  return (
    <ImageTextEditorViewComponent
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      courseMainColor={courseMainColor}
      courseMainGradient={courseMainGradient}
      marginBottom={marginBottom}
      withBackground={false}
    />
  );
};

export default memo(ImageText);
