import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import axios from "../../axios_config";
import AuthPasswordInput from "./auth_components/auth_password_input";
import AuthTextInput from "./auth_components/auth_text_input";
import OAuthGoogleButton from "./auth_components/oauth_google_button";
import LoginButton from "./auth_components/auth_login_button";
import { BASE_API_URL } from "../../common/endpoints";
import { useAuthPageContext } from "./auth_page_context";
import { Dot, emailRegex, Loader, passwordRegex } from "./auth_page";
import { useUserDataContext } from "../../user_data_context";
import UserLocationSelect from "../../common/components/user_location_select";

const AuthInputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const AuthBottomButtonsContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthInputsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  color: #f12b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const RegistrationPage = () => {
  const { t } = useTranslation();

  const { userLanguage } = useUserDataContext();
  const {
    setCurrentAuthScreen,
    redirectPath,
    registrationData,
    setRegistrationData,
  } = useAuthPageContext();

  const [validationMessages, setValidationMessages] = useState({});
  const [loading, setLoading] = useState(false);

  const handleRegistrationData = useCallback((key, value) => {
    setRegistrationData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    if (["email", "name", "password", "confirmPassword"].includes(key)) {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        [key]: "",
      }));
    }
  }, []);

  const getValidationMessages = ({
    name,
    email,
    password,
    confirmPassword,
  }) => {
    const validationMsgObject = {};

    if (!name) {
      validationMsgObject.name = t("auth-page.errors.is-required");
    } else if (name.length < 2) {
      validationMsgObject.name = t("auth-page.errors.fewer-characters");
    }

    if (!email) {
      validationMsgObject.email = t("auth-page.errors.is-required");
    } else if (!emailRegex.test(email)) {
      validationMsgObject.email = t("auth-page.errors.uncorrect-email");
    }

    if (!password) {
      validationMsgObject.password = t("auth-page.errors.is-required");
    } else if (!passwordRegex.test(password)) {
      validationMsgObject.password = t(
        "auth-page.errors.password-verification"
      );
    }

    if (!confirmPassword) {
      validationMsgObject.confirmPassword = t("auth-page.errors.is-required");
    } else if (password && confirmPassword !== password) {
      validationMsgObject.confirmPassword = t(
        "auth-page.errors.passwords-not-equal"
      );
    }

    return validationMsgObject;
  };

  const onSendCodeToMail = async () => {
    if (loading) return;

    setLoading(true);
    setValidationMessages({});

    const newValidationMessages = getValidationMessages({
      name: registrationData.name,
      email: registrationData.email,
      password: registrationData.password,
      confirmPassword: registrationData.confirmPassword,
    });

    setValidationMessages(newValidationMessages);

    if (Object.keys(newValidationMessages).length === 0) {
      try {
        await axios.post(`${BASE_API_URL}/auth/sendcode/registration`, {
          user_email: registrationData.email,
          user_lang: userLanguage,
          user_name: registrationData.name,
        });

        setCurrentAuthScreen("registration_code");
      } catch (error) {
        const status = error.response?.status;

        if (status === 409) {
          setValidationMessages((prev) => ({
            ...prev,
            email: t("auth-page.errors.409"),
          }));
        } else {
          console.error(
            "Registration failed:",
            error.response?.data?.message || error.message
          );
          setValidationMessages((prev) => ({
            ...prev,
            server: t("auth-page.errors.registr-error"),
          }));
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthInputsContainer>
        {validationMessages.server && (
          <ErrorMessage>{validationMessages.server}</ErrorMessage>
        )}

        <AuthTextInput
          placeholder={t("auth-page.inputs.email-placeholder")}
          value={registrationData.email}
          onChange={(e) => handleRegistrationData("email", e.target.value)}
          type={"email"}
          validationMessage={validationMessages.email}
        />

        <AuthInputsSection>
          <AuthTextInput
            placeholder={t("auth-page.inputs.name-placeholder")}
            value={registrationData.name}
            onChange={(e) => handleRegistrationData("name", e.target.value)}
            type={"name"}
            validationMessage={validationMessages.name}
          />
          <AuthTextInput
            placeholder={t("auth-page.inputs.second-name-placeholder")}
            value={registrationData.secondName}
            onChange={(e) =>
              handleRegistrationData("secondName", e.target.value)
            }
            type={"text"}
            require={false}
          />
        </AuthInputsSection>

        <AuthPasswordInput
          placeholder={t("auth-page.inputs.password-placeholder")}
          value={registrationData.password}
          onChange={(e) => handleRegistrationData("password", e.target.value)}
          validationMessage={validationMessages.password}
        />
        <AuthPasswordInput
          placeholder={t("auth-page.inputs.confirm-password-placeholder")}
          value={registrationData.confirmPassword}
          onChange={(e) =>
            handleRegistrationData("confirmPassword", e.target.value)
          }
          validationMessage={validationMessages.confirmPassword}
        />

        <UserLocationSelect
          inComponent="registration"
          handleUserData={handleRegistrationData}
        />
      </AuthInputsContainer>

      <AuthBottomButtonsContainer>
        <LoginButton disabled={loading} onClick={onSendCodeToMail}>
          {!loading ? (
            t("auth-page.buttons.registration")
          ) : (
            <Loader>
              {t("auth-page.buttons.registration-loading")}
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          )}
        </LoginButton>
        <OAuthGoogleButton
          buttonText={t("auth-page.buttons.continue-with-google")}
          redirectPath={redirectPath}
        />
      </AuthBottomButtonsContainer>
    </div>
  );
};

export default RegistrationPage;
