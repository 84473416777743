import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import copy from "copy-to-clipboard";
import CopyPurpleIcon from "../../assets/icons/bible_chapter_modal_icons/copy_purple_icon";
import SharePurpleIcon from "../../assets/icons/bible_chapter_modal_icons/share_purple_icon";
import { Slide, toast } from "react-toastify";
import BibleCopyModalWindow from "./bible_copy_modal_window";

const SidebarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 139px;
  height: 57px;
  border-radius: 15px 15px 0 0;
  background: #fff;
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  cursor: pointer;
  transition: 0.2s;

  svg {
    transition: 0.2s;
  }

  :hover {
    svg {
      transform: scale(1.12);
    }
  }
`;

const CopyButton = styled(SidebarItem)`
  padding-left: 11px;
  ${({ leftPosition }) => leftPosition && "padding: 0"}
`;

const ShareButton = styled(SidebarItem)`
  padding-right: 11px;
  ${({ leftPosition }) => leftPosition && "padding: 0"}
`;

const BibleModalWindowSidebar = ({
  selectedVerses,
  bibleLocale,
  openCopyModal,
  setOpenCopyModal,
}) => {
  const { t } = useTranslation();

  const showNotification = (message) => {
    toast(message, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyVersesToClipboard = (text) => {
    try {
      const isCopied = copy(text);
      if (isCopied) {
        showNotification(t("bible-page.chapter-modal-copy.notification"));
      } else {
        console.error(t("bible-page.chapter-modal-copy.error"));
        alert(t("bible-page.chapter-modal-copy.error"));
      }
    } catch (error) {
      console.error(t("bible-page.chapter-modal-copy.error"), error);
      alert(t("bible-page.chapter-modal-copy.error"));
    }
  };

  const handleOpenCopyModal = useCallback(() => {
    setOpenCopyModal(true);
  }, []);

  const handleShareLink = useCallback(() => {
    const bibleId = selectedVerses?.[0]?.bible_code;
    const bookId = selectedVerses?.[0]?.book_code;
    const chapterId = selectedVerses?.[0]?.chapter_num;
    const selectVerses = selectedVerses
      ?.map((verse) => verse.verse_num)
      .join(",");

    const params = `/bible/?bibleId=${bibleId}&bookId=${bookId}&chapterId=${chapterId}&selectVerses=${selectVerses}&selectBible=${bibleId}`;
    const host = window.location.origin;
    const fullUrl = host + params;

    try {
      const isCopied = copy(fullUrl);
      if (isCopied) {
        showNotification(t("bible-page.chapter-modal-copy.notification"));
      } else {
        console.error(t("bible-page.chapter-modal-copy.error"));
        alert(t("bible-page.chapter-modal-copy.error"));
      }
    } catch (error) {
      console.error(t("bible-page.chapter-modal-copy.error"), error);
      alert(t("bible-page.chapter-modal-copy.error"));
    }
  }, [selectedVerses]);

  return (
    <>
      <SidebarContainer>
        <CopyButton onClick={handleOpenCopyModal}>
          <CopyPurpleIcon />
        </CopyButton>
        <ShareButton onClick={handleShareLink}>
          <SharePurpleIcon />
        </ShareButton>
      </SidebarContainer>

      {openCopyModal &&
        createPortal(
          <BibleCopyModalWindow
            currentBibleLocale={bibleLocale}
            currentBibleScreen={"bible_chapter_modal_window"}
            selectedVerses={selectedVerses}
            setOpenCopyModal={setOpenCopyModal}
            copyToClipboard={copyVersesToClipboard}
          />,
          document.body
        )}
    </>
  );
};

export default BibleModalWindowSidebar;
