import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "../../axios_config";
import AuthPasswordInput from "./auth_components/auth_password_input";
import { Dot, Loader, passwordRegex } from "./auth_page";
import { useUserDataContext } from "../../user_data_context";
import { useAuthPageContext } from "./auth_page_context";
import { BASE_API_URL } from "../../common/endpoints";

const AuthNewPasswordPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 402px;
  margin: 0 auto;
  padding-top: 70px;
  text-align: center;

  @media (max-width: 410px) {
    width: 100%;
    padding: 85px 10px 0;
  }
`;

const MessageContainer = styled.div`
  position: relative;
  height: 32px;
  color: #1e1e1e;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
`;

const ErrorMessageContainer = styled.div`
  position: absolute;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  color: #f12b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const AuthCodePageButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 12px;
`;

const AuthCodePageButtonButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 10px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  height: 55px;
  transition: 0.2s;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const CancelButton = styled(AuthCodePageButtonButton)`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const SendNewPasswordButton = styled(AuthCodePageButtonButton)`
  ${({ isHoverOnCancel }) => isHoverOnCancel && "opacity: 0.5;"}
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.25;
      cursor: default;
    
      &:hover {
         box-shadow: none;
      }
  `}
`;

const AuthNewPasswordPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { fetchUserData } = useUserDataContext();
  const {
    redirectPath,
    verificationToken,
    setCurrentAuthScreen,
    setVerificationToken,
    registrationData,
  } = useAuthPageContext();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleHoverOnCancelButton = useCallback(() => {
    setIsHoverOnCancel((prevState) => !prevState);
  }, []);

  const handleInputChange = useCallback((field, value) => {
    if (field === "password") setNewPassword(value);
    if (field === "confirmPassword") setConfirmNewPassword(value);
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [newPassword, confirmNewPassword]);

  const validatePasswords = useCallback(() => {
    if (!passwordRegex.test(newPassword)) {
      return t("auth-page.errors.password-verification");
    }
    if (newPassword !== confirmNewPassword) {
      return t("auth-page.errors.passwords-not-equal");
    }
    return "";
  }, [newPassword, confirmNewPassword]);

  const handleSubmit = async () => {
    const validationError = validatePasswords();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(`${BASE_API_URL}/auth/reset-password`, {
        user_email: registrationData.email,
        user_password: newPassword,
        verification_token: verificationToken,
      });

      const { access_token } = response.data;

      if (access_token) {
        localStorage.setItem("access_token", access_token);
        fetchUserData();
        navigate(redirectPath, { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("auth-page.new-password.error"));
    } finally {
      setLoading(false);
    }
  };

  const onClickCancelButton = useCallback((event) => {
    event.stopPropagation();
    setCurrentAuthScreen("");
    setVerificationToken("");
  }, []);

  return (
    <AuthNewPasswordPageContainer>
      <MessageContainer>
        {t("auth-page.new-password.title")}
        {!!errorMessage && (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}
      </MessageContainer>

      <AuthPasswordInput
        placeholder={t("auth-page.new-password.password-input-placeholder")}
        value={newPassword}
        onChange={(e) => handleInputChange("password", e.target.value)}
      />
      <AuthPasswordInput
        placeholder={t(
          "auth-page.new-password.confirm-password-input-placeholder"
        )}
        value={confirmNewPassword}
        onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
      />

      <AuthCodePageButtonsContainer>
        <CancelButton
          onMouseEnter={toggleHoverOnCancelButton}
          onMouseLeave={toggleHoverOnCancelButton}
          onClick={onClickCancelButton}
        >
          {t("auth-page.new-password.buttons.cancel")}
        </CancelButton>
        <SendNewPasswordButton
          isHoverOnCancel={isHoverOnCancel}
          disabled={!!errorMessage || loading}
          onClick={handleSubmit}
        >
          {!loading ? (
            t("auth-page.new-password.buttons.confirm")
          ) : (
            <Loader>
              {t("auth-page.new-password.buttons.loading")}
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          )}
        </SendNewPasswordButton>
      </AuthCodePageButtonsContainer>
    </AuthNewPasswordPageContainer>
  );
};

export default AuthNewPasswordPage;
