import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import CloseMessageIcon from "../../../../common/assets/icons/study_page_icons/close_message_icon";
import { LESSON_BG_COLOR } from "..";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "react-i18next";

const CourseTest = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 54px;
  height: 36px;
  color: #ffffff;
  border-radius: 11px;
  user-select: none;
  background: ${({ background }) => background};
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 412px) {
    min-width: 48px;
    height: 30px;
  }
`;

const CourseTestMessage = styled.div`
  width: 187px;
  height: 48px;
  padding: 6px 10px;
  background: #5172ea;
  border-radius: 12px 12px 12px 0px;
  z-index: 9;

  @media (max-width: 885px) {
    border-radius: 12px;
  }

  & > p {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
`;

const CloseMessageIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CourseTestItem = ({
  userCourseLessons,
  courseTestData,
  lessonsRef,
  inComponent,
  courseStatus,
  authError,
  lastLessonId,
  handleClickOnTest,
}) => {
  const { t } = useTranslation();

  const testContainerRef = useRef(null);
  const messageRef = useRef(null);
  const timerRef = useRef(null);

  const [messagePosition, setMessagePosition] = useState(null);
  const [testStatus, setTestStatus] = useState("closed");
  const [showTestCloseMessage, setShowTestCloseMessage] = useState(false);

  useOnclickOutside(() => setShowTestCloseMessage(false), {
    refs: [testContainerRef],
  });

  const userTest = userCourseLessons?.find(
    (lesson) => lesson.lesson_type === "test"
  );

  const testId = courseTestData._id;

  const lastLessonIsFinished =
    userCourseLessons?.find((lesson) => lesson._id === lastLessonId)
      ?.lesson_status === "finished";

  const userStudyId = userTest?.user_study_id;

  const lessonBackgroundColor =
    testStatus === "closed"
      ? LESSON_BG_COLOR.CLOSED
      : testStatus === "in_process" || testStatus === "next"
      ? LESSON_BG_COLOR.CURRENT
      : LESSON_BG_COLOR.FINISHED;

  useEffect(() => {
    if (showTestCloseMessage) {
      timerRef.current = setTimeout(() => {
        setShowTestCloseMessage(false);
      }, 4500);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [showTestCloseMessage]);

  useEffect(() => {
    if (inComponent === "admin_page") {
      setTestStatus("finished");
    } else if (
      courseStatus === "closed" ||
      courseStatus === "announcement" ||
      authError
    ) {
      setTestStatus("closed");
    } else {
      if (lastLessonIsFinished && !userTest) {
        setTestStatus("next");
      } else if (userTest) {
        if (userTest.lesson_status === "in_process") {
          setTestStatus("in_process");
        } else if (userTest.lesson_status === "finished") {
          setTestStatus("finished");
        }
      } else {
        setTestStatus("closed");
      }
    }
  }, [userTest, inComponent, courseStatus, authError, lastLessonIsFinished]);

  const handleClick = useCallback(
    (e) => {
      if (e.target.closest(".course-test") !== e.currentTarget) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const messageWidth = 187;
      const messageHeight = 48;

      const top = Math.max(rect.top - messageHeight + window.scrollY + 10, 0);
      let left;

      if (window.innerWidth < 775) {
        const parentRect = lessonsRef.current.getBoundingClientRect();
        left = parentRect.left + (parentRect.width - messageWidth) / 2;
      } else if (window.innerWidth < 885) {
        left = rect.left + (rect.width - messageWidth) / 2;
      } else {
        left = Math.min(rect.left + 26, window.innerWidth - messageWidth);
      }

      setMessagePosition({
        top: top,
        left: left,
      });

      if (testStatus === "closed") {
        setShowTestCloseMessage(true);
        return;
      }

      handleClickOnTest(e, testId, testStatus, userStudyId);
    },
    [testId, testStatus, userStudyId, handleClickOnTest]
  );

  return (
    <CourseTest
      ref={testContainerRef}
      className="course-test"
      background={lessonBackgroundColor}
      onClick={handleClick}
    >
      Тест
      {showTestCloseMessage &&
        messagePosition &&
        createPortal(
          <CourseTestMessage
            ref={messageRef}
            style={{
              position: "absolute",
              top: `${messagePosition.top}px`,
              left: `${messagePosition.left}px`,
            }}
          >
            <p>{t("course-page.closed-test-text")}</p>
            <CloseMessageIconContainer>
              <CloseMessageIcon />
            </CloseMessageIconContainer>
          </CourseTestMessage>,
          lessonsRef.current
        )}
    </CourseTest>
  );
};

export default memo(CourseTestItem);
