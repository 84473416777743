import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../../../../app_context";
import { useBibleModalWindowContext } from "../../bible_modal_window_context";
import useWindowBreakpoint from "../../../../../hooks/use_window_breakpoint";

const BibleModalStrongsCodeContainer = styled.div`
  display: ${({ showStrongsCode }) =>
    showStrongsCode ? "inline-block" : "none"};
  margin: 0 3px 0 1px;
  color: #5172ea;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 110%;
  vertical-align: top;
  cursor: pointer;

  @media (max-width: 744px) {
    vertical-align: baseline;
  }
`;

const BibleModalStrongsCode = ({ children }) => {
  const { bibleFontSettings } = useAppContext();
  const { showStrongsCode, setStrongsCodeModalData, currentTestament } =
    useBibleModalWindowContext();

  const isMobile = useWindowBreakpoint(744);
  const codeFontSize = !isMobile
    ? bibleFontSettings.fontSize * 0.88
    : bibleFontSettings.fontSize;

  const onClickStrongsCode = useCallback((e) => {
    e.stopPropagation();
    setStrongsCodeModalData(children);
  }, []);

  return (
    <BibleModalStrongsCodeContainer
      showStrongsCode={showStrongsCode}
      onClick={onClickStrongsCode}
      fontSize={codeFontSize}
    >
      {currentTestament === "ot" ? "H" : "G"}
      {children}
    </BibleModalStrongsCodeContainer>
  );
};

export default memo(BibleModalStrongsCode);
