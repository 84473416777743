import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FullscreenIcon from "../../../../assets/icons/fullscreen_icon";
import ImageFullscreenModal from "./image_fullscreen_modal";
import ImageText from "./image_text";

const ImagePreviewWrapper = styled.div`
  ${({ colorTheme }) => colorTheme === "DARK" && "color: #fff"};
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  transition: 0.2s;
  cursor: pointer;

  :hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`;

const Image = styled.img`
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
`;

const NoImage = styled.div`
  padding: 20px;
  color: #383838;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background: #fff;
`;

const FullscreenButton = styled.button`
  position: absolute;
  bottom: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  height: max-content;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  z-index: 10;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 600px) {
    bottom: 4px;
    right: 4px;
    border-radius: 10px;
  }
`;

const ImageLessonComponent = ({
  userData = {},
  componentData,
  inComponent,
  colorTheme = "LIGHT",
  setTextEditorComponentHtml = () => {},
  courseMainColor,
  courseMainGradient,
}) => {
  const { t } = useTranslation();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const withText = !!componentData?.image_text?.blocks?.[0]?.text;

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape" && isFullscreen) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isFullscreen]);

  const toggleFullscreen = (e) => {
    e.stopPropagation();
    setIsFullscreen(!isFullscreen);
  };

  const onDoubleClickImage = (e) => {
    e.stopPropagation();
    if (inComponent !== "create_lesson") {
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <ImagePreviewWrapper colorTheme={colorTheme}>
      {componentData.image_text_position === "start" && withText && (
        <ImageText
          userData={userData}
          componentData={componentData}
          colorTheme={colorTheme}
          courseMainColor={courseMainColor}
          courseMainGradient={courseMainGradient}
          setTextEditorComponentHtml={setTextEditorComponentHtml}
          marginBottom={true}
        />
      )}

      <ImagePreviewContainer>
        {componentData.image_url ? (
          <Image
            src={componentData.image_url}
            alt="Preview Image"
            onDoubleClick={onDoubleClickImage}
          />
        ) : (
          <NoImage>{t("lesson-page.components.image-text")}</NoImage>
        )}

        {componentData.image_url && (
          <FullscreenButton
            onClick={toggleFullscreen}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <FullscreenIcon color={"#fff"} />
          </FullscreenButton>
        )}

        {isFullscreen && componentData.image_url && (
          <ImageFullscreenModal
            colorTheme={colorTheme}
            componentData={componentData}
            toggleFullscreen={toggleFullscreen}
            onDoubleClickImage={onDoubleClickImage}
          />
        )}
      </ImagePreviewContainer>

      {componentData.image_text_position === "end" && withText && (
        <ImageText
          userData={userData}
          componentData={componentData}
          colorTheme={colorTheme}
          courseMainColor={courseMainColor}
          courseMainGradient={courseMainGradient}
          setTextEditorComponentHtml={setTextEditorComponentHtml}
        />
      )}
    </ImagePreviewWrapper>
  );
};

export default memo(ImageLessonComponent);
