import React from "react";

const GeneralIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2ZM6 6C6 2.68629 8.68629 0 12 0C15.3137 0 18 2.68629 18 6C18 9.31371 15.3137 12 12 12C8.68629 12 6 9.31371 6 6ZM2.78215 16.6358C3.91182 15.5814 5.43011 15 7 15H17C18.5699 15 20.0882 15.5814 21.2179 16.6358C22.3499 17.6923 23 19.1406 23 20.6667V23C23 23.5523 22.5523 24 22 24C21.4477 24 21 23.5523 21 23V20.6667C21 19.7173 20.5966 18.7917 19.8532 18.0979C19.1075 17.4019 18.0823 17 17 17H7C5.91773 17 4.89248 17.4019 4.14678 18.0979C3.40342 18.7917 3 19.7173 3 20.6667V23C3 23.5523 2.55228 24 2 24C1.44772 24 1 23.5523 1 23V20.6667C1 19.1406 1.65014 17.6923 2.78215 16.6358Z"
        fill="url(#paint0_linear_9293_9077)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9293_9077"
          x1="11.4683"
          y1="-4.41176"
          x2="11.4683"
          y2="26.1176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GeneralIcon;
