import React, { useCallback, useEffect, useState, memo } from "react";
import styled from "styled-components";
import RemoveIcon from "../../../../common/assets/icons/remove_icon";
import { SearchIcon } from "../../../../common/assets/icons";

const NEW_COURSE_DATA = {
  _id: "new_course",
  course_created_at: new Date().toISOString(),
  course_name: "",
  course_description: "",
  course_modified_at: "",
  course_modified_by: {},
  course_main_color: "PURPLE",
  course_creator: {},
  course_authors: [],
  course_admins: [],
  course_preview_image: "",
  course_header_image: "",
  course_video_enabled: true,
  course_video_image: "",
  course_video_url: "",
  course_level: "",
  course_duration_is_enabled: true,
  course_duration: 0,
  course_lessons: [],
  course_status: "closed",
  about_course: {
    blocks: [],
    entityMap: {},
  },
  course_user_rating_value: 0,
  course_user_rating_amount: 0,
  course_feedback_is_enabled: false,
  course_feedback_recipients: [],
  course_certificate: {
    certificate_is_enabled: false,
    certificate_description: "",
    certificate_image: "TYPE_1",
    certificate_after_test: false,
    certificate_pass_rate: 80,
  },
};

const CoursesListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateCourseButton = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: max-content;
  padding: 0 15px;
  border-radius: 8px;
  color: #fff;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const CoursesListSearchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 9px 0 12px;
  width: 230px;
  height: 40px;
  background: #fff;
  transition: 0.2s;

  :hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  input {
    width: 87%;
    font-size: 14px;
  }
`;

const CoursesListSearchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 70%;
  background: #fff;
  padding: 0 4px;
  transition: 0.2s;

  :hover {
    transform: scale(1.1);
  }
`;

const CoursesListHeader = ({
  courseSettingsData,
  setSearchWords,
  setCourseSettingsData,
  setCourseSettingsMemoData,
  setCoursesList,
  onCloseCourseSettings,
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon, remove_icon

  const handleSearchInputValue = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  const onCreateCourseButton = useCallback(
    (event) => {
      event.stopPropagation();
      if (courseSettingsData._id !== "new_course") {
        setCourseSettingsData(NEW_COURSE_DATA);
        setCoursesList((prevState) => [NEW_COURSE_DATA, ...prevState]);
      } else {
        onCloseCourseSettings();
      }
    },
    [courseSettingsData]
  );

  const filterCoursesBySearch = useCallback(() => {
    if (searchInputValue) {
      setSearchWords(searchInputValue);
      setShowedInputIcon("remove_icon");
    }
  }, [searchInputValue]);

  const clearSearchInput = useCallback(() => {
    setSearchWords("");
    setSearchInputValue("");
    setShowedInputIcon("search_icon");
  }, [searchInputValue]);

  useEffect(() => {
    setShowedInputIcon("search_icon");
  }, [searchInputValue]);

  useEffect(() => {
    if (!searchInputValue) {
      setSearchWords("");
    }
  }, [searchInputValue]);

  return (
    <CoursesListHeaderContainer>
      <CreateCourseButton
        onMouseDown={(e) => e.stopPropagation()}
        onClick={onCreateCourseButton}
      >
        Создать курс
      </CreateCourseButton>

      <CoursesListSearchInput>
        <input
          placeholder="Поиск курсов..."
          value={searchInputValue}
          onChange={handleSearchInputValue}
          onMouseDown={(e) => e.stopPropagation()}
          onKeyDown={(event) =>
            event.key === "Enter" && filterCoursesBySearch()
          }
        />

        {showedInputIcon === "search_icon" ? (
          <CoursesListSearchButton onClick={filterCoursesBySearch}>
            <SearchIcon />
          </CoursesListSearchButton>
        ) : (
          <CoursesListSearchButton onClick={clearSearchInput}>
            <RemoveIcon color="#383838" />
          </CoursesListSearchButton>
        )}
      </CoursesListSearchInput>
    </CoursesListHeaderContainer>
  );
};

export default memo(CoursesListHeader);
