import React from "react";

const FullscreenIcon = ({ color = "#CACACA" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_10865_10038)">
        <path
          d="M0.557232 4.83574C0.865048 4.83574 1.11446 4.58625 1.11446 4.2785V1.90261L4.70341 5.49082C4.81226 5.59959 4.95484 5.65398 5.09741 5.65398C5.24007 5.65398 5.38272 5.59959 5.49149 5.49075C5.70911 5.27313 5.70911 4.92029 5.49141 4.70274L1.90232 1.11446H4.27873C4.58647 1.11446 4.83596 0.864973 4.83596 0.557232C4.83596 0.249491 4.58654 0 4.27873 0H0.557232C0.249417 0 0 0.249491 0 0.557232V4.27858C0 4.58625 0.249417 4.83574 0.557232 4.83574Z"
          fill={color}
        />
        <path
          d="M15.4423 11.1638C15.1345 11.1638 14.8851 11.4133 14.8851 11.7211V14.097L11.1857 10.3976C10.968 10.18 10.6152 10.18 10.3976 10.3976C10.18 10.6152 10.18 10.968 10.3976 11.1856L14.0969 14.8849H11.7211C11.4133 14.8849 11.1639 15.1344 11.1639 15.4422C11.1639 15.7499 11.4133 15.9994 11.7211 15.9994H15.4424C15.7502 15.9994 15.9996 15.7499 15.9996 15.4422V11.7211C15.9996 11.4133 15.7501 11.1638 15.4423 11.1638Z"
          fill={color}
        />
        <path
          d="M4.81352 10.3976L1.11446 14.097V11.7211C1.11446 11.4133 0.865048 11.1638 0.557232 11.1638C0.249417 11.1638 0 11.4133 0 11.7211V15.4423C0 15.7501 0.249417 15.9996 0.557232 15.9996H4.27836C4.5861 15.9996 4.83559 15.7501 4.83559 15.4423C4.83559 15.1346 4.58617 14.8851 4.27836 14.8851H1.90247L5.6016 11.1856C5.81922 10.968 5.81922 10.6151 5.60152 10.3975C5.38405 10.18 5.03121 10.18 4.81352 10.3976Z"
          fill={color}
        />
        <path
          d="M15.4446 0H11.7233C11.4155 0 11.1661 0.249491 11.1661 0.557232C11.1661 0.864973 11.4155 1.11446 11.7233 1.11446H14.0993L10.5109 4.70312C10.2932 4.92073 10.2932 5.27357 10.5109 5.49119C10.6197 5.59996 10.7624 5.65435 10.9049 5.65435C11.0475 5.65435 11.1902 5.59989 11.299 5.49112L14.8874 1.90247V4.2785C14.8874 4.58625 15.1369 4.83574 15.4447 4.83574C15.7525 4.83574 16.0019 4.58625 16.0019 4.2785V0.557232C16.0018 0.249417 15.7523 0 15.4446 0Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10865_10038">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FullscreenIcon;
