import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "../../../../../axios_config";
import BlackArrowDownIcon from "../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
  QuestionsFilterOptionItem,
  QuestionsFilterOptionsContainer,
} from "../../admin_questions_styled_components";
import styled from "styled-components";
import { BASE_API_URL } from "../../../../../common/endpoints";
import { useTranslation } from "react-i18next";

const withoutRecipient = {
  _id: "without_recipient",
  user_name: "Без получателя",
  user_second_name: "",
};

const SearchRecipientInput = styled.input`
  height: 30px;
  min-width: 240px;
  padding: 0 8px;
  border-radius: 6px;
  border: 1px solid #5172ea;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;

  &::placeholder {
    color: rgba(81, 114, 234, 0.4);
  }
`;

const Line = styled.div`
  border-top: 1px solid #cacaca;
  width: 97%;
  margin: 0 auto;
`;

const QuestionsFilterByRecipient = ({
  extendedOption,
  handleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  const { t } = useTranslation();

  const [authors, setAuthors] = useState([]);
  const [recipientInputValue, setRecipientInputValue] = useState("");
  const [filteredRecipient, setFilteredRecipients] = useState([]);

  const techSupportData = {
    _id: "tech_support",
    user_name: t("common-components.technical-support-name"),
    user_second_name: "",
  };

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users?is_author=true`);
      const authors = response.data;

      setAuthors([withoutRecipient, ...authors, techSupportData]);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [techSupportData, withoutRecipient]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleRecipientInputValue = useCallback((event) => {
    setRecipientInputValue(event.target.value);
  }, []);

  const handleStatusFilter = useCallback(
    (event, recipient) => {
      setQuestionsFilterSettings(({ question_recipient, ...rest }) => ({
        ...rest,
        question_recipient: question_recipient.some(
          (r) => r._id === recipient._id
        )
          ? question_recipient
          : [...question_recipient, recipient],
      }));

      handleExtendOptions(event, "");
    },
    [setQuestionsFilterSettings]
  );

  useEffect(() => {
    if (recipientInputValue) {
      const filteredArray = authors.filter(
        (recipient) =>
          recipient.user_name
            .toLowerCase()
            .includes(recipientInputValue.toLowerCase()) ||
          recipient.user_second_name
            .toLowerCase()
            .includes(recipientInputValue.toLowerCase())
      );

      setFilteredRecipients(filteredArray);
    } else {
      setFilteredRecipients(authors);
    }
  }, [recipientInputValue, authors]);

  return (
    <QuestionsFilterOption
      type="recipient"
      isExtended={extendedOption === "recipient"}
      onClick={(e) => handleExtendOptions(e, "recipient")}
    >
      Адресат
      <QuestionsFilterOptionArrow isExtended={extendedOption === "recipient"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>
      {extendedOption === "recipient" && (
        <QuestionsFilterOptionsContainer onClick={(e) => e.stopPropagation()}>
          <SearchRecipientInput
            placeholder="Поиск"
            value={recipientInputValue}
            onChange={handleRecipientInputValue}
          />

          {filteredRecipient.map((recipient) => {
            return (
              <div key={recipient._id}>
                {recipient._id === "tech_support" && <Line />}
                <QuestionsFilterOptionItem
                  onClick={(e) => handleStatusFilter(e, recipient)}
                >
                  {recipient.user_name} {recipient.user_second_name}
                </QuestionsFilterOptionItem>
                {recipient._id === "without_recipient" && <Line />}
              </div>
            );
          })}
        </QuestionsFilterOptionsContainer>
      )}
    </QuestionsFilterOption>
  );
};

export default memo(QuestionsFilterByRecipient);
