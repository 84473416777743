import React, { memo } from "react";
import styled from "styled-components";
import RemoveIcon from "../../../../assets/icons/remove_icon";

const FullscreenModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;

const FullscreenModalContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: auto;
  border-radius: 15px;
  background-color: ${({ darkTheme }) => (darkTheme ? "#232323" : "#eaeaea")};
  overflow: hidden;
`;

const FullscreenImage = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-width: 96vw;
  max-height: 90vh;
  object-fit: contain;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background: ${({ courseMainColor }) => courseMainColor || "#5172ea"};
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  @media (max-width: 900px) {
    width: 26px;
    height: 26px;
  }

  @media (max-width: 412px) {
    width: 22px;
    height: 22px;
  }
`;

const CloseIconStyled = styled(RemoveIcon)`
  width: 22px;
  height: 22px;

  @media (max-width: 900px) {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 412px) {
    width: 14px;
    height: 14px;
  }
`;

const ImageFullscreenModal = ({
  colorTheme,
  componentData,
  toggleFullscreen,
  onDoubleClickImage,
}) => {
  return (
    <FullscreenModalWrapper onClick={toggleFullscreen}>
      <FullscreenModalContainer
        darkTheme={colorTheme === "DARK"}
        onDoubleClick={onDoubleClickImage}
      >
        <FullscreenImage
          src={componentData.image_url}
          alt="Preview Image"
          onClick={(e) => e.stopPropagation()}
        />
        <CloseButton onClick={toggleFullscreen}>
          <CloseIconStyled color="#fff" />
        </CloseButton>
      </FullscreenModalContainer>
    </FullscreenModalWrapper>
  );
};

export default memo(ImageFullscreenModal);
