import React, { memo, useCallback, useState } from "react";
import { toast, Slide } from "react-toastify";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import BiblePageCopyIcon from "../../../common/assets/icons/bible_page_icons/bible_page_copy_icon";
import { BiblePageSidebarItem } from ".";
import BibleCopyModalWindow from "../../../common/components/modal_windows/bible_copy_modal_window";

const BiblePageSidebarCopy = ({
  selectedVerses,
  currentBibleScreen,
  currentBibleLocale,
}) => {
  const { t } = useTranslation();

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const showNotification = () => {
    toast(t("bible-page.sidebar.copy.notification"), {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    try {
      const isCopied = copy(text);
      if (isCopied) {
        showNotification();
      } else {
        console.error(t("bible-page.sidebar.copy.copy-verse-error"));
        alert(t("bible-page.sidebar.copy.copy-verse-error"));
      }
    } catch (error) {
      console.error(t("bible-page.sidebar.copy.copy-verse-error"), error);
      alert(t("bible-page.sidebar.copy.copy-verse-error"));
    }
  };

  const handleOpenCopyModal = useCallback(() => {
    setOpenCopyModal(true);
  }, []);

  return (
    <>
      <BiblePageSidebarItem onClick={handleOpenCopyModal}>
        <BiblePageCopyIcon />
        <p>{t("bible-page.sidebar.copy.title")}</p>
      </BiblePageSidebarItem>

      {openCopyModal &&
        createPortal(
          <BibleCopyModalWindow
            currentBibleScreen={currentBibleScreen}
            selectedVerses={selectedVerses}
            setOpenCopyModal={setOpenCopyModal}
            copyToClipboard={copyToClipboard}
            currentBibleLocale={currentBibleLocale}
          />,
          document.body
        )}
    </>
  );
};

export default memo(BiblePageSidebarCopy);
