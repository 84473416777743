import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import {
  MinusTranslationIcon,
  PlusTranslationIcon,
} from "../../../../../common/assets/icons";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";
import { ListItemSkeleton } from "../book_list_navigation/simple_list_loader";

const TranslationHeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 30px;
  cursor: pointer;
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 14px;
  height: 100%;
  flex: 1;
  transition: 0.1s;
  overflow: hidden;
  cursor: pointer;
`;

const SelectedTranslationAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  user-select: none;
`;

const SelectedTranslationText = styled.div`
  color: #828282;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.2px;
`;

const TranslationHeaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: ${({ hidePadding }) => (hidePadding ? "3px" : "14px")};
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const TranslationHeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const TranslationHeader = ({
  toggleOpenBibleTranslationsPanel,
  isLoading,
  currentTranslation,
  isSecondTranslation,
  activeScreenDesktop,
  setSecondTranslateIsEnabled,
  setExpandedSectionName,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const openedSecondTranslation = searchParams.get("2bibleId");
  const hidePadding = !isSecondTranslation && openedSecondTranslation;
  const firstTranslation = searchParams.get("bibleId");

  const isCurrentBibleSelected =
    isSecondTranslation &&
    searchParams.get("selectBible") === openedSecondTranslation &&
    firstTranslation !== openedSecondTranslation;

  const handleSecondTranslPanel = useCallback(
    (event) => {
      event.stopPropagation();

      setSecondTranslateIsEnabled((prevState) => {
        if (!prevState) {
          setTimeout(() => {
            setSearchParams((prev) => {
              const newParams = new URLSearchParams(prev);
              newParams.set("2bibleId", searchParams.get("bibleId"));
              setExpandedSectionName("TRANSLATE_2");
              return newParams;
            });
          }, 0);
        } else {
          setTimeout(() => {
            setSearchParams((prev) => {
              if (isCurrentBibleSelected) {
                prev.delete("selectVerses");
                prev.delete("selectBible");
              }
              prev.delete("2bibleId");
              return prev;
            });
          }, 0);
        }
        return !prevState;
      });
    },
    [searchParams, setSearchParams, openedSecondTranslation]
  );

  return (
    <TranslationHeaderContainer onClick={toggleOpenBibleTranslationsPanel}>
      {!isLoading ? (
        <>
          <HeaderInfoContainer>
            <SelectedTranslationAbbreviation
              isActive={currentTranslation?.abbreviation}
            >
              {currentTranslation?.bible_code || "TN"}
            </SelectedTranslationAbbreviation>
            <VerticalLine height={"15px"} />
            <SelectedTranslationText>
              {currentTranslation?.bible_name || "Translation name"}
            </SelectedTranslationText>
          </HeaderInfoContainer>

          <TranslationHeaderIconContainer
            hidePadding={hidePadding}
            onClick={handleSecondTranslPanel}
          >
            <TranslationHeaderIcon>
              {isSecondTranslation && <MinusTranslationIcon />}
              {!openedSecondTranslation &&
                activeScreenDesktop === "bible_chapter" && (
                  <PlusTranslationIcon />
                )}
            </TranslationHeaderIcon>
          </TranslationHeaderIconContainer>
        </>
      ) : (
        <ListItemSkeleton margin={"4px"} />
      )}
    </TranslationHeaderContainer>
  );
};
export default memo(TranslationHeader);
