import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import axios from "../../../../axios_config";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import RatingAndFeedbackSection from "./rating_and_feedback_section";
import { BASE_API_URL } from "../../../../common/endpoints";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";
import { useTranslation } from "react-i18next";

const AfterLessonModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const AfterLessonModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 475px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;

  & > h5 {
    width: 95%;
    color: #000;
    font-size: 28px;
    line-height: 130%;
    font-weight: 600;
  }

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 15px;
    padding: 26px 24px 26px;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
  }
`;

const RestButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
  width: 40%;
`;

const ToNextLessonButton = styled(ButtonStyles)`
  width: 59%;
  color: ${({ isHoverRestButton }) =>
    isHoverRestButton ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const FinishCourseButton = styled(ButtonStyles)`
  width: 100%;
  color: #5172ea;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const getNextLessonId = (currentLessonId, lessons) => {
  const currentIndex = lessons?.findIndex(
    (lesson) => lesson._id === currentLessonId
  );

  if (currentIndex !== -1 && currentIndex < lessons?.length - 1) {
    return lessons[currentIndex + 1]._id;
  }

  return null;
};

const AfterFinishLessonModal = ({
  userData,
  lessonData,
  courseData,
  lessonNumber,
  isLastLesson,
  setAfterFinishLessonModal,
  setCourseFinishModal,
  setCourseFeedbackModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isHoverRestButton, setIsHoverOnRestButton] = useState(false);
  const [lessonRating, setLessonRating] = useState(0);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [nextLessonIsFinished, setNextLessonIsFinished] = useState(null);
  const [createdRatingId, setCreatedRatingId] = useState(null);
  const [debouncedFeedbackValue] = useDebounce(feedbackValue, 1000);

  const userCourseActivity = userData.user_activity?.find(
    (item) => item.type === "course" && item.course_id === courseData._id
  );

  const userStudyTest =
    userCourseActivity?.course_lessons?.find(
      (lesson) => lesson.lesson_type === "test"
    ) || null;

  const courseTest =
    courseData?.course_lessons?.find(
      (lesson) => lesson.lesson_type === "test"
    ) || null;

  const courseLessons = courseData.course_lessons?.filter(
    (lesson) => lesson.lesson_type !== "test"
  );

  const courseLessonsCount = courseLessons ? courseLessons.length : 0;

  const courseWithTest = Boolean(
    courseData?.course_certificate?.certificate_after_test
  );

  const nextLessonId = getNextLessonId(lessonData._id, courseLessons);

  const nextLessonInProcess = userCourseActivity?.course_lessons?.find(
    (lesson) => lesson._id === nextLessonId
  );

  useEffect(() => {
    if (!userCourseActivity || !nextLessonId) return;

    const nextLessonStatus = userCourseActivity.course_lessons?.find(
      (lesson) => lesson._id === nextLessonId
    )?.lesson_status;

    setNextLessonIsFinished(nextLessonStatus === "finished");
  }, [userCourseActivity, nextLessonId]);

  const createUserLessonRating = async () => {
    try {
      const newRating = {
        course: lessonData.lesson_course,
        lesson: { _id: lessonData._id },
        user: {
          _id: userData._id,
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
        },
        rating_value: lessonRating,
        rating_comment: feedbackValue,
      };

      const response = await axios.post(
        `${BASE_API_URL}/lesson-feedback`,
        newRating
      );

      setCreatedRatingId(response.data._id);
    } catch (error) {
      console.error("Error creating rating:", error);
    }
  };

  const updateUserLessonRating = async () => {
    try {
      const updatedRating = {
        rating_value: lessonRating,
        rating_comment: feedbackValue,
      };

      await axios.patch(
        `${BASE_API_URL}/lesson-feedback/${createdRatingId}`,
        updatedRating
      );
    } catch (error) {
      console.error("Error updating rating", error);
    }
  };

  useEffect(() => {
    if (lessonRating > 0 || feedbackValue) {
      if (!createdRatingId) {
        createUserLessonRating();
      } else {
        if (debouncedFeedbackValue || lessonRating > 0) {
          updateUserLessonRating();
        }
      }
    }
  }, [lessonRating, debouncedFeedbackValue, createdRatingId]);

  const toggleHoverOnRestButton = useCallback(() => {
    setIsHoverOnRestButton((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAfterFinishLessonModal(false);
  }, []);

  const onRestButton = useCallback(
    async (e) => {
      e.stopPropagation();
      navigate(APPLICATION_ROUTES.STUDY_COURSES + `/${courseData._id}`);
    },
    [courseData]
  );

  const onClickFinishCourse = useCallback(
    (e) => {
      e.stopPropagation();

      setAfterFinishLessonModal(false);

      if (courseData.course_certificate?.certificate_is_enabled) {
        setCourseFinishModal(true);
      } else {
        setCourseFeedbackModal(true);
      }
    },
    [userData, courseData]
  );

  const onClickToNextLesson = useCallback(
    async (e) => {
      e.stopPropagation();
      const nextLessonNumber = +lessonNumber + 1;

      try {
        if (
          !nextLessonIsFinished &&
          nextLessonIsFinished !== null &&
          nextLessonId !== null &&
          !nextLessonInProcess
        ) {
          const createNewUserLesson = {
            study_lesson: { _id: nextLessonId },
            study_user: { _id: userData._id },
            study_answers: [],
            user_test_pass_rate: 0,
            course_pass_rate:
              courseData.course_certificate?.certificate_pass_rate,
            study_course: {
              _id: courseData._id,
              course_name: courseData.course_name,
              course_lessons_count: courseLessonsCount,
              course_test_count: courseWithTest ? 1 : 0,
            },
          };

          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserLesson);
        }

        setAfterFinishLessonModal(false);
        navigate(
          `${APPLICATION_ROUTES.USER_LESSON}/${nextLessonId}?n=${nextLessonNumber}`
        );
      } catch (error) {
        console.error("Ошибка при создании урока пользователя:", error);
      }
    },
    [
      nextLessonId,
      nextLessonInProcess,
      lessonNumber,
      userData,
      courseData,
      nextLessonIsFinished,
      navigate,
      courseLessonsCount,
      courseWithTest,
    ]
  );

  const onClickToTest = useCallback(
    async (e) => {
      e.stopPropagation();

      try {
        if (!userStudyTest) {
          const createNewUserTest = {
            study_lesson: { _id: courseTest._id, lesson_type: "test" },
            study_user: { _id: userData._id },
            study_answers: [],
            user_test_pass_rate: 0,
            course_pass_rate:
              courseData.course_certificate?.certificate_pass_rate,
            study_course: {
              _id: courseData._id,
              course_name: courseData.course_name,
              course_lessons_count: courseLessonsCount,
              course_test_count: courseWithTest ? 1 : 0,
            },
          };

          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserTest);
        }

        setAfterFinishLessonModal(false);
        navigate(`${APPLICATION_ROUTES.USER_LESSON}/${courseTest._id}`);
      } catch (error) {
        console.error("Ошибка при создании Теста пользователя:", error);
      }
    },
    [
      nextLessonInProcess,
      userStudyTest,
      courseTest,
      userData,
      courseData,
      nextLessonIsFinished,
      navigate,
      courseLessonsCount,
      courseWithTest,
    ]
  );

  return (
    <AfterLessonModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <AfterLessonModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        {isLastLesson ? (
          <h5>
            {t("lesson-page.after-lesson-modal.congratulations.last-lesson")}
          </h5>
        ) : (
          <h5>
            {t(
              "lesson-page.after-lesson-modal.congratulations.not-last-lesson"
            )}
          </h5>
        )}

        <RatingAndFeedbackSection
          lessonRating={lessonRating}
          setLessonRating={setLessonRating}
          feedbackValue={feedbackValue}
          setFeedbackValue={setFeedbackValue}
        />

        <ModalButtonsContainer>
          {(!isLastLesson || courseWithTest) && (
            <>
              <RestButton
                onMouseEnter={toggleHoverOnRestButton}
                onMouseLeave={toggleHoverOnRestButton}
                onClick={onRestButton}
              >
                {t("lesson-page.after-lesson-modal.buttons.rest")}
              </RestButton>
              <VerticalLineStyled color={"#5172EA"} />
            </>
          )}

          {!isLastLesson ? (
            <ToNextLessonButton
              isHoverRestButton={isHoverRestButton}
              onClick={onClickToNextLesson}
            >
              {t("lesson-page.after-lesson-modal.buttons.to-next-lesson")}
            </ToNextLessonButton>
          ) : courseWithTest ? (
            <ToNextLessonButton
              isHoverRestButton={isHoverRestButton}
              onClick={onClickToTest}
            >
              {userStudyTest?.lesson_status == "finished"
                ? t("lesson-page.after-lesson-modal.buttons.review-test")
                : t("lesson-page.after-lesson-modal.buttons.to-test")}
            </ToNextLessonButton>
          ) : (
            <FinishCourseButton onClick={onClickFinishCourse}>
              {t("lesson-page.after-lesson-modal.buttons.finish-course")}
            </FinishCourseButton>
          )}
        </ModalButtonsContainer>
      </AfterLessonModalContainer>
    </AfterLessonModalWrapper>
  );
};

export default memo(AfterFinishLessonModal);
