import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CourseAnnouncementMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 508px;
  border: 2px solid #f8d254;
  border-top: none;
  border-radius: 0px 0px 25px 25px;
  background: #ffffff;
  box-shadow: 2px 4px 25px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 22px;

  @media (max-width: 514px) {
    width: 100%;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #f8d254;
    padding-bottom: 20px;
  }

  @media (max-width: 412px) {
    width: 100%;
    padding-bottom: 19px;
    border-radius: 0;
    border-bottom: 2px solid #f8d254;
  }
`;

const AnnouncementMessageText = styled.div`
  width: 435px;
  padding: 17px 10px 0;
  color: #5172ea;

  & > h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 12px;

    @media (max-width: 412px) {
      font-size: 16px;
    }
  }

  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 125%;

    & > span {
      font-weight: 600;
    }

    @media (max-width: 412px) {
      font-size: 14px;
    }
  }
`;

const CourseAnnouncementMessage = () => {
  const { t } = useTranslation();

  return (
    <CourseAnnouncementMessageContainer>
      <AnnouncementMessageText>
        <h5>{t("course-page.course-announcement-message.top")}</h5>
        <p>
          {t("course-page.course-announcement-message.middle")}
          <span> “{t("course-page.header-btn.notify")}”</span>.
        </p>
        <p>{t("course-page.course-announcement-message.bottom")}</p>
      </AnnouncementMessageText>
    </CourseAnnouncementMessageContainer>
  );
};

export default memo(CourseAnnouncementMessage);
