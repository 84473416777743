import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_API_URL } from "../../common/endpoints";
import styled from "styled-components";
import BibleChapterModalWindow from "../../common/components/modal_windows/bible_chapter_modal_window";
import BibleModalWindow from "../../common/components/modal_windows/bible_modal_window";
import { LESSON_COLORS_THEME } from "../../common/utils/enums";
import SharedLessonComponentsList from "./shared_lesson_components_list";
import SharedLessonHeader from "./shared_lesson_header";
import { useAppContext } from "../../app_context";
import useWindowBreakpoint from "../../common/hooks/use_window_breakpoint";

const UserSharedLessonPageContainer = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  ${({ darkTheme }) => darkTheme && "background: #232323;"}

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ darkTheme }) => (darkTheme ? "#cacaca" : "#B0B0B0")};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ darkTheme }) =>
      darkTheme ? "#444444" : "rgb(227 227 227)"};
  }

  @media (max-width: 1024px) {
    ::-webkit-scrollbar {
      width: 4px;
    }
  }
`;

const UserSharedLessonPage = () => {
  const { userId, lessonId } = useParams();

  const { appColorTheme, setAppColorTheme, setShowAppHeader } = useAppContext();

  const [lessonData, setLessonData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [authors, setAuthors] = useState([]);

  //                      Modal windows states
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [bibleVersesData, setBibleVersesData] = useState({});

  const isMobile = useWindowBreakpoint(1024);

  const courseMainGradient =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      courseData.course_main_color
    ];
  const courseMainColor =
    LESSON_COLORS_THEME[appColorTheme]?.COLORS?.[courseData.course_main_color];

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/users/${userId}`);

      setUserData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных пользователя:", error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const fetchUserLessonData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(
        BASE_API_URL +
          `/userstudy/find?studyLessonId=${lessonId}&studyUserId=${userId}`
      );

      const { lesson } = response.data;

      setLessonData(lesson);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [lessonId, userId]);

  useEffect(() => {
    if (isMobile) {
      setShowAppHeader(false);
    }
    return () => setShowAppHeader(true);
  }, [isMobile]);

  useEffect(() => {
    if (lessonData._id !== lessonId && lessonId && userId) {
      fetchUserLessonData();
    }
  }, [lessonId, userId, lessonData._id]);

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (lessonData?.lesson_course?._id) {
      const courseId = lessonData.lesson_course._id;
      fetchCourse(courseId);
    }
  }, [lessonData?.lesson_course?._id]);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users?is_author=true`);
      const authors = response.data;
      setAuthors(authors);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  return (
    <UserSharedLessonPageContainer darkTheme={appColorTheme === "DARK"}>
      <SharedLessonHeader
        lessonData={lessonData}
        appColorTheme={appColorTheme}
        setAppColorTheme={setAppColorTheme}
      />

      <SharedLessonComponentsList
        userData={userData}
        isLoading={isLoading}
        lessonData={lessonData}
        appColorTheme={appColorTheme}
        courseMainColor={courseMainColor}
        authors={authors}
        courseMainGradient={courseMainGradient}
        setTextBibleModalData={setTextBibleModalData}
      />

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
          setBibleVersesData={setBibleVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
        />
      )}

      {openBibleModalWindow && (
        <BibleModalWindow
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          bibleVersesData={bibleVersesData}
          setBibleVersesData={setBibleVersesData}
          inComponent={"simple_modal"}
        />
      )}
    </UserSharedLessonPageContainer>
  );
};

export default memo(UserSharedLessonPage);
