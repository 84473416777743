import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.0165 7.147L18.591 8.72148C19.3155 9.44594 19.3155 10.6244 18.591 11.3488L17.0165 12.9233C16.8597 13.0801 16.654 13.1586 16.4486 13.1586C16.2432 13.1586 16.0375 13.0801 15.8807 12.9233M17.0165 7.147L15.9514 12.8526M17.0165 7.147C16.7029 6.83331 16.1943 6.83328 15.8807 7.14698M17.0165 7.147L15.8807 7.14698M15.8807 12.9233L15.9514 12.8526M15.8807 12.9233C15.8807 12.9233 15.8807 12.9233 15.8807 12.9233L15.9514 12.8526M15.8807 12.9233C15.567 12.6097 15.567 12.1012 15.8807 11.7876L15.9514 11.8583C15.6768 12.1329 15.6768 12.5781 15.9514 12.8526M16.8299 10.8383L15.8807 11.7876L16.9714 10.8383H16.8299ZM16.8299 10.8383H8.62891C8.18531 10.8383 7.82578 10.4788 7.82578 10.0352C7.82578 9.59156 8.18531 9.23203 8.62891 9.23203H16.8299M16.8299 10.8383L16.9299 10.7383H8.62891C8.24054 10.7383 7.92578 10.4235 7.92578 10.0352C7.92578 9.64679 8.24054 9.33203 8.62891 9.33203L16.8299 9.23203M16.8299 9.23203L15.8807 8.2827C15.8807 8.2827 15.8807 8.28269 15.8807 8.28268C15.567 7.96912 15.567 7.46059 15.8807 7.14702C15.8807 7.14701 15.8807 7.147 15.8807 7.14698M16.8299 9.23203L15.9514 7.21771L15.8807 7.14698M13.8266 16.1875V14.7812C13.8266 14.3377 13.467 13.9781 13.0234 13.9781C12.5798 13.9781 12.2203 14.3377 12.2203 14.7812V16.1875C12.2203 16.9076 11.6342 17.4937 10.9141 17.4937H3.84766C3.12752 17.4937 2.54141 16.9076 2.54141 16.1875V3.8125C2.54141 3.09237 3.12752 2.50625 3.84766 2.50625H10.9141C11.6342 2.50625 12.2203 3.09237 12.2203 3.8125V5.21875C12.2203 5.66235 12.5798 6.02187 13.0234 6.02187C13.467 6.02187 13.8266 5.66235 13.8266 5.21875V3.8125C13.8266 2.20641 12.5201 0.9 10.9141 0.9H3.84766C2.24157 0.9 0.935156 2.20641 0.935156 3.8125V16.1875C0.935156 17.7936 2.24157 19.1 3.84766 19.1H10.9141C12.5201 19.1 13.8266 17.7936 13.8266 16.1875Z"
        fill="#F24A24"
        stroke="#F24A24"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default LogoutIcon;
