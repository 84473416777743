import React, { memo } from "react";
import styled from "styled-components";
import GroupCard from "./group_card";
import fotoAndrew from "../../../common/assets/images/andrew.png";
import StudyPageSortButton from "../study_page_components/study_page_sort_button";
import { useTranslation } from "react-i18next";

const StudyPageGroupsContainer = styled.div`
  width: 100%;
  padding-bottom: 110px;

  @media (max-width: 920px) {
    padding-bottom: 60px;
  }
`;

const StudyPageGroupsSortButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 30px;

  @media (max-width: 670px) {
    gap: 9px;
  }

  @media (max-width: 412px) {
    margin-top: 25px;
  }
`;

const StudyPageGroupsList = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 84px;
  margin-top: 53px;

  @media (max-width: 1150px) {
    gap: 40px;
  }

  @media (max-width: 670px) {
    gap: 45px;
    margin-top: 45px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 412px) {
    margin-top: 31px;
  }
`;

const fakeGroups = [
  {
    cardBackground: `linear-gradient(180deg, #2440d2 0%, #334653 100%),
   linear-gradient(248.99deg, #db32ea -11.61%, #3d1ac9 109.78%),
   linear-gradient(32.45deg, #1944ff -28.24%, #9a71ef 43.15%)`,
    teacher: fotoAndrew,
  },
  {
    cardBackground: `linear-gradient(48.73deg, #092DC6 -5.13%, #0AB6A1 105.54%);`,
    teacher: fotoAndrew,
  },
  {
    cardBackground: `linear-gradient(248.99deg, #DB32EA -11.61%, #3D1AC9 109.78%);`,
    teacher: fotoAndrew,
  },
  {
    cardBackground: `linear-gradient(252.41deg, #0AB6A1 -4.79%, #235EB7 47.61%, #B10AC0 104.97%);`,
    teacher: fotoAndrew,
  },
  {
    cardBackground: `linear-gradient(73.37deg, #3E5EED -4.85%, #A04DF2 103.1%);`,
    teacher: fotoAndrew,
  },
  {
    cardBackground: `linear-gradient(180deg, #05BAA4 0%, #288BCE 31.04%);`,
    teacher: fotoAndrew,
  },
];

const StudyPageGroups = () => {
  const { t } = useTranslation();

  return (
    <StudyPageGroupsContainer>
      <StudyPageGroupsSortButtons>
        <StudyPageSortButton isActive={true}>
          {t("study-page.open-sort-btn")}
        </StudyPageSortButton>
        <StudyPageSortButton>
          {t("study-page.announcement-sort-btn")}
        </StudyPageSortButton>
        {/* <StudyPageSortButton>Выбрать тему</StudyPageSortButton> */}
      </StudyPageGroupsSortButtons>

      <StudyPageGroupsList>
        {fakeGroups.map((group, index) => (
          <GroupCard
            key={index}
            cardBackground={group.cardBackground}
            teacher={group.teacher}
          />
        ))}
      </StudyPageGroupsList>
    </StudyPageGroupsContainer>
  );
};

export default memo(StudyPageGroups);
