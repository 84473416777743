import React, { useCallback } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import UserImage from "../../user_image";
import { APPLICATION_ROUTES } from "../../../utils/routes";
import { UserIcon } from "./header_styled_components";
import useWindowBreakpoint from "../../../hooks/use_window_breakpoint";

const HeaderUserProfileButtonsContainer = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    height: 53px;
  }

  @media (max-width: 530px) {
    height: max-content;
  }
`;

const UserProfileButtonContainer = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const UnreadAnswersCount = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #f8d254;

  p {
    color: #000;
    font-size: 9px;
    line-height: 130%;
  }

  @media (max-width: 1024px) {
    top: -5px;
    right: -5px;
  }
`;

const AuthPageButton = styled(Link)`
  cursor: pointer;

  ${({ $active }) =>
    !$active &&
    `
    :hover {
    ${UserIcon} {
      transition: 0.2s;
      stroke: #6e6e6e;
    }
  }
  `}

  @media (max-width: 1024px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const UserName = styled.div`
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
  font-size: 16px;
  color: rgb(142, 142, 142);
  letter-spacing: 0.4px;
  white-space: nowrap;

  @media (max-width: 1024px) {
    position: static;
    transform: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: ${({ $active }) => ($active ? "#1e1e1e" : "rgb(142, 142, 142)")};
    letter-spacing: 0px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

const AuthButtonText = styled.div`
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
  font-size: 16px;
  color: rgb(142, 142, 142);

  @media (max-width: 1024px) {
    position: static;
    transform: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: ${({ $active }) => ($active ? "#1e1e1e" : "rgb(142, 142, 142)")};
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

const HeaderUserProfileButtons = ({
  userData,
  isTablet,
  unreadAnswersCount,
  setIsMoreMenuOpened = () => {},
}) => {
  const location = useLocation();
  const isLoginActive = location.pathname === APPLICATION_ROUTES.LOGIN;
  const isRegitrationActive =
    location.pathname === APPLICATION_ROUTES.REGISTRATION;
  const isProfileActive = location.pathname === APPLICATION_ROUTES.PROFILE;

  const isMobile = useWindowBreakpoint(530);

  const closeMoreMenu = useCallback(
    (event) => {
      event.stopPropagation();

      if (isMobile || isTablet) {
        setIsMoreMenuOpened(false);
      }
    },
    [isMobile, isTablet]
  );

  return (
    <HeaderUserProfileButtonsContainer onClick={closeMoreMenu}>
      {!userData._id ? (
        <AuthPageButton
          to={APPLICATION_ROUTES.LOGIN}
          $active={isLoginActive || isRegitrationActive}
        >
          <UserIcon $active={isLoginActive || isRegitrationActive} />
          {isTablet && (
            <AuthButtonText $active={isLoginActive || isRegitrationActive}>
              Войти
            </AuthButtonText>
          )}
        </AuthPageButton>
      ) : (
        <UserProfileButtonContainer>
          <Link to={APPLICATION_ROUTES.PROFILE}>
            <UserImage
              userData={userData}
              size={isMobile ? "36px" : isTablet ? "26px" : "26px"}
              fontSize={isMobile ? "14px" : isTablet ? "12px" : "10px"}
              color={"#fff"}
              fontWeight={400}
              background={
                "linear-gradient(88deg, #05baa4 -19%, #2046e9 129.9%)"
              }
              isButton={true}
              hasUnreadAnswers={unreadAnswersCount > 0}
            />
          </Link>
          {!isMobile && (
            <UserName isActive={isProfileActive}>{userData.user_name}</UserName>
          )}

          {unreadAnswersCount > 0 && (
            <UnreadAnswersCount>
              <p>{unreadAnswersCount}</p>
            </UnreadAnswersCount>
          )}
        </UserProfileButtonContainer>
      )}
    </HeaderUserProfileButtonsContainer>
  );
};

export default HeaderUserProfileButtons;
