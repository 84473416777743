import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../common/assets/icons/vertical_line";

const DeleteTestModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const DeleteTestModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  width: 420px;
  padding: 42px;
  border-radius: 25px;
  background: #fff;
  text-align: center;

  h5 {
    color: #f12b2b;
    font-weight: 600;
    font-size: 16px;
    line-height: 145%;
    letter-spacing: 0.24px;
  }

  h6 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.42px;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: 52%;
  }
`;

const DeleteButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnDelete }) =>
    isHoverOnDelete ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 4px;
`;

const DeleteTestModalWindow = ({
  setOpenDeleteTestModal,
  handleCourseCertificateTest,
}) => {
  const [isHoverOnDelete, setIsHoverOnDelete] = useState(false);

  const toggleHoverOnDeleteButton = useCallback(() => {
    setIsHoverOnDelete((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpenDeleteTestModal(false);
  }, []);

  const onClickDeleteButton = useCallback(
    (event) => {
      event.stopPropagation();
      handleCourseCertificateTest(false);
      setOpenDeleteTestModal(false);
    },
    [handleCourseCertificateTest]
  );

  return (
    <DeleteTestModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <DeleteTestModalWindowContainer
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div>
          <h5>Вы точно хотите удалить Тест?</h5>
          <h6>Это действие нельзя отменить!</h6>
        </div>
        <ModalButtonsContainer>
          <DeleteButton
            onMouseEnter={toggleHoverOnDeleteButton}
            onMouseLeave={toggleHoverOnDeleteButton}
            onClick={onClickDeleteButton}
          >
            Удалить
          </DeleteButton>
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton isHoverOnDelete={isHoverOnDelete} onClick={handleClose}>
            Отмена
          </CancelButton>
        </ModalButtonsContainer>
      </DeleteTestModalWindowContainer>
    </DeleteTestModalWindowWrapper>
  );
};

export default memo(DeleteTestModalWindow);
