import React from "react";
import styled from "styled-components";

const ScrollToTopButtonContainer = styled.button`
  position: absolute;
  bottom: 40px;
  left: 5px;
  width: 51px;
  height: 51px;
  padding: 8px 10px;
  border: none;
  border-radius: 25px;
  color: #5172ea;
  cursor: pointer;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: 0.2s;
  z-index: 999;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.12);
    background-color: white;
  }

  @media (max-width: 1250px) {
    left: 10px;
    bottom: 0px;
  }

  @media (max-width: 800px) {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.12);
    background-color: white;
    left: 10px;
    bottom: 12px;
  }
`;

const ScrollToTopVirtuoso = ({ isVisible, onClick }) => {
  return (
    <ScrollToTopButtonContainer visible={isVisible} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
      >
        <path
          d="M27.125 21.959L15.5 10.334L3.87501 21.959"
          stroke="#5172EA"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ScrollToTopButtonContainer>
  );
};

export default ScrollToTopVirtuoso;
