import React, { memo, useEffect, useRef } from "react";
import styled from "styled-components";
import CourseLesson from "./course_lesson";
import CourseTest from "./course_test";
import { useTranslation } from "react-i18next";

const CourseLessonsContainer = styled.div`
  width: 506px;
  margin: 62px auto 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 514px) {
    margin: 38px auto 0;
    width: 380px;
    gap: 21px;
  }

  @media (max-width: 412px) {
    width: 330px;
  }
`;

const NoLessonInCourseMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #1e1e1e;
`;

const CourseLessons = ({
  courseData,
  courseTestData,
  courseLessonsList,
  inComponent,
  showMessageId,
  setShowMessageId,
  userCourse,
  authError,
  handleClickOnLesson,
  handleClickOnTest,
}) => {
  const { t } = useTranslation();
  const timerRef = useRef(null);

  const showLessonDuration = courseData.course_duration_is_enabled;

  const isNewCourse = !userCourse;

  useEffect(() => {
    if (showMessageId) {
      timerRef.current = setTimeout(() => {
        setShowMessageId(null);
      }, 4500);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [showMessageId]);

  return (
    <CourseLessonsContainer>
      {courseLessonsList.length ? (
        <>
          {courseLessonsList.map((lesson, index) => (
            <CourseLesson
              key={lesson._id}
              isNewCourse={isNewCourse}
              authError={authError}
              lessonId={lesson._id}
              index={index}
              lessonData={lesson}
              showLessonDuration={showLessonDuration}
              inComponent={inComponent}
              showMessageId={showMessageId}
              courseStatus={courseData.course_status}
              userCourseLessons={userCourse?.course_lessons}
              setShowMessageId={setShowMessageId}
              handleClickOnLesson={handleClickOnLesson}
            />
          ))}

          {courseTestData?._id && (
            <CourseTest
              courseTestData={courseTestData}
              userCourseLessons={userCourse?.course_lessons}
              inComponent={inComponent}
              courseStatus={courseData.course_status}
              authError={authError}
              handleClickOnTest={handleClickOnTest}
              showLessonDuration={showLessonDuration}
              lastLessonId={
                courseLessonsList?.[courseLessonsList?.length - 1]?._id
              }
            />
          )}
        </>
      ) : (
        <NoLessonInCourseMessage>
          {t("course-page.empty-lessons-list-text")}
        </NoLessonInCourseMessage>
      )}
    </CourseLessonsContainer>
  );
};

export default memo(CourseLessons);
