import React from "react";
import styled from "styled-components";

const QuestionSettingsQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h6 {
    color: #828282;
    font-size: 10px;
    line-height: 110%;
  }
`;

const QuestionTextWrapper = styled.div`
  overflow: hidden;
  height: fit-content;

  border-radius: 8px;
`;

const QuestionText = styled.div`
  min-height: 40px;
  max-height: 200px;
  border-radius: 8px;
  background: #fff;
  padding: 10px 15px;
  color: #383838;
  font-size: 16px;
  line-height: 24px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const QuestionSettingsQuestion = ({ questionSettingsData }) => {
  const questionText = questionSettingsData.question_text;

  return (
    <QuestionSettingsQuestionContainer>
      <h6>Вопрос</h6>
      <QuestionTextWrapper>
        <QuestionText>{questionText}</QuestionText>
      </QuestionTextWrapper>
    </QuestionSettingsQuestionContainer>
  );
};

export default QuestionSettingsQuestion;
