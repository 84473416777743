import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { Slide, toast } from "react-toastify";
import LeftArrowIcon from "../../../../common/assets/icons/left_arrow_icon";
import DarkThemeIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/dark_theme_icon";
import LightThemeIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/light_theme_icon";
import LightShareIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/light_share_icon";
import DarkShareIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/dark_share_icon";
import DarkAnimatedModeIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/dark_animated_mode_icon";
import LightAnimatedModeIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/light_animated_mode_icon";
import DarkNotAnimatedModeIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/dark_not_animated_mode_icon";
import LightNotAnimatedModeIcon from "../../../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/light_not_animated_mode_icon";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";
import useWindowBreakpoint from "../../../../common/hooks/use_window_breakpoint";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 4px 0;
  background: ${({ background }) => background};
  position: fixed;
  z-index: 9999;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
  max-width: 710px;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 80%;
  }

  @media (max-width: 830px) {
    max-width: 94%;
    flex-wrap: wrap;
  }

  @media (max-width: 720px) {
    max-width: 100%;
    margin: 0 6px;
  }
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  height: 100%;

  @media (max-width: 1024px) {
    gap: 35px;
  }

  @media (max-width: 830px) {
    gap: 20px;
  }

  @media (max-width: 420px) {
    gap: 10px;
  }
`;

const LeftArrowIconContainer = styled.div`
  cursor: pointer;
  padding: 6px 13px;
  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

const LessonPreviewHeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  color: #fff;

  padding: 2px 0;
  margin-right: 10px;

  @media (max-width: 730px) {
    font-size: 18px;
  }

  & > span {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const HeaderButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  ${({ background }) => `background: ${background};`}

  &:hover {
    transition: 0.2s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const LessonPlayModeButton = styled(HeaderButton)``;

const LessonShareButton = styled(HeaderButton)``;

const ColorThemeButton = styled(HeaderButton)`
  ${({ background }) => `background: ${background};`}
`;

const LessonPreviewPageHeader = ({
  lessonData,
  appColorTheme,
  withAnimation,
  setAppColorTheme,
  setWithAnimation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const lessonNumber = searchParams.get("n");
  const placeFrom = searchParams.get("p");
  const questionId = searchParams.get("questionId");

  const [isShare, setIsShare] = useState(false);

  const isTablet = useWindowBreakpoint(1024);

  const isTest =
    lessonData?.lesson_type === "test"
      ? true
      : lessonData?.lesson_type === "lesson"
      ? false
      : null;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("share")) {
      setIsShare(true);
    } else {
      setIsShare(false);
    }
  }, [location.search]);

  const headerBackground =
    appColorTheme === "DARK"
      ? "linear-gradient(180deg, rgba(81, 114, 234, 0.60) -18.38%, rgba(122, 90, 238, 0.60) 108.82%), #232323"
      : "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)";

  const headerButtonsBackground =
    appColorTheme === "LIGHT" ? "#fff" : "rgba(255, 255, 255, 0.25)";

  const goBack = useCallback(() => {
    const courseId = lessonData.lesson_course._id;
    const url =
      placeFrom === "course"
        ? `${APPLICATION_ROUTES.ADMIN_COURSE_PREVIEW}/${courseId}`
        : placeFrom === "list"
        ? `${APPLICATION_ROUTES.ADMIN_COURSES}/?courseId=${courseId}&lessons=true`
        : placeFrom === "question"
        ? `${APPLICATION_ROUTES.ADMIN_QUESTIONS}/?q=${questionId}`
        : placeFrom === "create"
        ? !isTest
          ? `${APPLICATION_ROUTES.ADMIN_CREATE_LESSON}/${lessonData._id}?n=${lessonNumber}`
          : `${APPLICATION_ROUTES.ADMIN_CREATE_LESSON}/${lessonData._id}`
        : APPLICATION_ROUTES.ADMIN_COURSES;

    navigate(url);
  }, [placeFrom, lessonData, questionId]);

  const handleLessonPlayMode = useCallback((event) => {
    event.stopPropagation();
    setWithAnimation((prevState) => !prevState);
  }, []);

  const handleLessonPreviewTheme = useCallback((event) => {
    event.stopPropagation();
    setAppColorTheme((prevState) => (prevState === "LIGHT" ? "DARK" : "LIGHT"));
  }, []);

  const showNotification = () => {
    toast("Ссылка скопирована!", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const onClickLessonShareButton = useCallback((event) => {
    event.stopPropagation();

    const currentUrl = window.location.href;

    const [baseUrl, queryString] = currentUrl.split("?");

    const updatedUrl = queryString
      ? `${baseUrl}?share&${queryString}`
      : `${baseUrl}?share`;

    try {
      const isCopied = copy(updatedUrl);
      if (isCopied) {
        showNotification();
      } else {
        console.error("Не удалось скопировать ссылку.");
        alert("Ошибка при копировании ссылки.");
      }
    } catch (err) {
      console.error("Ошибка при копировании: ", err);
      alert("Ошибка при копировании ссылки.");
    }
  }, []);

  return (
    <HeaderWrapper background={headerBackground}>
      <HeaderContainer>
        <HeaderContentWrapper>
          {!isShare && (
            <LeftArrowIconContainer onClick={goBack}>
              <LeftArrowIcon />
            </LeftArrowIconContainer>
          )}

          <LessonPreviewHeaderTitle>
            {!isTest && isTest !== null && (
              <>
                {!isTablet && "Урок"} {lessonNumber}.{" "}
              </>
            )}
            <span>{lessonData.lesson_name}</span>
          </LessonPreviewHeaderTitle>
        </HeaderContentWrapper>

        <div style={{ display: "flex", gap: "7px" }}>
          <LessonPlayModeButton
            background={headerButtonsBackground}
            onClick={handleLessonPlayMode}
          >
            {!withAnimation &&
              (appColorTheme === "LIGHT" ? (
                <LightNotAnimatedModeIcon />
              ) : (
                <DarkNotAnimatedModeIcon />
              ))}
            {withAnimation &&
              (appColorTheme === "LIGHT" ? (
                <LightAnimatedModeIcon />
              ) : (
                <DarkAnimatedModeIcon />
              ))}
          </LessonPlayModeButton>

          <LessonShareButton
            background={headerButtonsBackground}
            onClick={onClickLessonShareButton}
          >
            {appColorTheme === "LIGHT" ? <LightShareIcon /> : <DarkShareIcon />}
          </LessonShareButton>

          <ColorThemeButton
            background={
              appColorTheme === "LIGHT"
                ? "linear-gradient(234deg, #9E00FF -17.21%, #63218E 36.71%, #55187C 52.72%, #301E46 103.33%, #1B1B1F 151.29%)"
                : "linear-gradient(50deg, #0C7CAC -11.7%, #0EC5ED 110.56%)"
            }
            onClick={handleLessonPreviewTheme}
          >
            {appColorTheme === "DARK" ? <LightThemeIcon /> : <DarkThemeIcon />}
          </ColorThemeButton>
        </div>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default memo(LessonPreviewPageHeader);
