import React, { Fragment } from "react";
import styled from "styled-components";
import { BIBLE_BOOK_NAMES_DATA } from "../../../pages/bible/bible_page_data";
import { createComponentId } from "../../components/lesson/lesson_utils";
import { BASE_API_URL } from "../../endpoints";
import axios from "axios";

export const bibleVersesWithoutTags = (verse) => {
  return verse
    .replace(/<S>.*?<\/S>/g, "")
    .replace(/<n>.*?<\/n>/g, "")
    .replace(/<J>(.*?)<\/J>/g, "$1")
    .replace(/<R>(.*?)<\/R>/g, "$1")
    .replace(/<br\/?>/g, "");
};

export const extractingTextOfBibleVerses = (versesTextData) => {
  if (Array.isArray(versesTextData)) {
    return versesTextData
      .map((el) => el.props.children)
      .filter((text) => {
        const trimmedText = text.trim();
        return trimmedText !== "" && !/^\d+$/.test(trimmedText);
      })
      .join(" ")
      .replace(/\s+/g, " ");
  } else {
    return versesTextData;
  }
};

export const formatVerseNumbers = (verses) => {
  const result = [];
  let rangeStart = verses[0];
  let previous = verses[0];

  for (let i = 1; i <= verses.length; i++) {
    if (verses[i] !== previous + 1) {
      if (rangeStart === previous) {
        result.push(`${rangeStart}`);
      } else {
        result.push(`${rangeStart}-${previous}`);
      }
      rangeStart = verses[i];
    }
    previous = verses[i];
  }

  return result.join(",");
};

export const isCombinedVerses = (versesArray, lang) => {
  if (versesArray.length) {
    const combinedVerses = versesArray.reduce(
      (acc, verse) => acc + " " + verse.verse_text,
      ""
    );
    const bookNameData =
      BIBLE_BOOK_NAMES_DATA?.[lang]?.[versesArray[0]?.book_code];

    const currentBibleName = bookNameData?.secondName
      ? bookNameData.secondName
      : bookNameData?.name;

    const verseNumbers = formatVerseNumbers(
      versesArray.map((verse) => verse.verse_num)
    );
    const verseText = `«${combinedVerses.trim()}»`;
    const verseAddress = `${
      currentBibleName || versesArray[0]?.book_code_orig
    } ${versesArray[0].chapter_num}:${verseNumbers}`;

    return {
      verseText,
      verseAddress,
      verseId: "bible_" + createComponentId(),
      versesData: versesArray,
      versesParams: {
        bibleId: versesArray[0].bible_code,
        bookId: versesArray[0].book_code,
        chapterId: versesArray[0].chapter_num,
      },
    };
  }
};

export const insertWholeChapter = (bibleParams, lang) => {
  const bibleAddress =
    BIBLE_BOOK_NAMES_DATA?.[lang]?.[bibleParams?.bookId]?.name +
    " " +
    bibleParams?.chapterId;

  return {
    verseText: "",
    verseAddress: bibleAddress,
    verseId: "bible_" + createComponentId(),
    versesData: [],
    versesParams: bibleParams,
  };
};

export const insertAsSeparateVerses = (bibleParams, versesArray, lang) => {
  const bookNameData =
    BIBLE_BOOK_NAMES_DATA?.[lang]?.[versesArray[0].book_code];

  const currentBibleName = bookNameData?.secondName
    ? bookNameData.secondName
    : bookNameData?.name || versesArray[0].book_code_orig;

  const verseNumbers = formatVerseNumbers(
    versesArray.map((verse) => verse.verse_num)
  );

  const verseAddress = `${currentBibleName} ${versesArray[0].chapter_num}:${verseNumbers}`;

  return {
    verseText: "",
    verseAddress,
    verseId: "bible_" + createComponentId(),
    versesData: versesArray,
    versesParams: bibleParams,
  };
};

export const formatBibleBookShortName = (name) => {
  if (!name) return "";
  const formattedName = name.replace(/^(\d)([А-Яа-яA-Za-z])/, "$1 $2");
  return formattedName.endsWith(".") ? formattedName : `${formattedName}.`;
};

const BoldText = styled.span`
  border-radius: 2px;
  background: rgba(248, 210, 84, 0.5);
`;

export const highlightWords = (text, targetWords) => {
  const words = text?.split(" ");
  const targetWordsArray = targetWords
    .split(/\s+/)
    .map((word) => word.toLowerCase());

  return words.map((word, index) => {
    const matchesTarget = targetWordsArray.some((targetWord) =>
      word.toLowerCase().includes(targetWord)
    );

    return (
      <Fragment key={index}>
        {matchesTarget ? <BoldText>{word}</BoldText> : <span>{word}</span>}
        {index < words.length - 1 && " "}{" "}
      </Fragment>
    );
  });
};

export const fetchAndSortBibleBooks = async (bibleCode, bibleLocale) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/bible/books`, {
      params: { bible: bibleCode },
    });

    const booksData = response.data.books.map((book) => {
      if (BIBLE_BOOK_NAMES_DATA[bibleLocale]) {
        return {
          ...book,
          book_name: BIBLE_BOOK_NAMES_DATA[bibleLocale][book.book_code]?.name,
          number: BIBLE_BOOK_NAMES_DATA[bibleLocale][book.book_code]?.number,
        };
      }
      return book;
    });

    return booksData.sort((a, b) => a.number - b.number);
  } catch (error) {
    console.error("Error fetching books:", error);
    throw error;
  }
};
