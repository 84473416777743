import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../common/components/checkbox";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import TextEditor from "../../../../../common/components/text_editor";
import { createComponentId } from "../../../../../common/components/lesson/lesson_utils";

const NoteAndQuestionSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 219px;
  gap: 30px;
  padding: 26px 24px;
  max-height: 83vh;
  overflow: auto;
  margin-right: -60px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const QuestionInputContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  background: #fff;
`;

const QuestionInput = styled.textarea`
  resize: none;
  max-height: 268px;
  padding: 8px 15px;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
  max-height: 80px;
  width: 100%;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const DEFAULT_SETTINGS_DATA_NOTE_AND_QUESTION = {
  type: "NOTE_AND_QUESTION",
  note: {},
  question: "",
  with_question: false,
  vocabulary_keys: [],
  id: "",
};

const NoteAndQuestionSettings = ({
  settingsBlockData,
  lessonData,
  editBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  setLessonData,
  onClickSaveButton,
}) => {
  const questionRef = useRef(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  useEffect(() => {
    if (editBlockIndex === null) {
      const defaultNoteAndQuestionData = {
        ...DEFAULT_SETTINGS_DATA_NOTE_AND_QUESTION,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultNoteAndQuestionData);
      setSettingsBlockMemoData(defaultNoteAndQuestionData);
    }
  }, [editBlockIndex]);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      with_question: !prevState.with_question,
    }));
  }, []);

  const handleQuestionInput = useCallback((event) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      question: event.target.value,
    }));
  }, []);

  useEffect(() => {
    if (!settingsBlockData.with_question) {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        question: "",
      }));
    }
  }, [settingsBlockData.with_question]);

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.style.height = "auto";
      questionRef.current.style.height =
        questionRef.current.scrollHeight + "px";
    }
  }, [settingsBlockData.question, questionRef]);

  useEffect(() => {
    const blocks = settingsBlockData.note?.blocks;
    const questionText = settingsBlockData.question?.trim();

    const hasNoteText =
      blocks && blocks.some((block) => block.text.trim().length > 0);

    if (settingsBlockData.with_question) {
      setDisabledSaveButton(!questionText.length || !hasNoteText);
    } else {
      setDisabledSaveButton(!hasNoteText);
    }
  }, [settingsBlockData]);

  const onEditorChange = useCallback(
    (updatedInputText) => {
      setSettingsBlockData((prevState) => {
        return {
          ...prevState,
          note: updatedInputText,
        };
      });
    },
    [setSettingsBlockData]
  );

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <NoteAndQuestionSettingsContainer>
        <Checkbox
          checked={settingsBlockData.with_question}
          onClickCheckbox={onClickCheckbox}
        >
          Вопрос
        </Checkbox>

        {settingsBlockData?.with_question && (
          <QuestionInputContainer>
            <QuestionInput
              placeholder="Напишите вопрос..."
              ref={questionRef}
              rows={1}
              type="text"
              value={settingsBlockData.question}
              onChange={handleQuestionInput}
            />
          </QuestionInputContainer>
        )}

        <TextEditor
          contentStateData={settingsBlockData.note}
          isEdite={editBlockIndex !== null}
          onEditorChange={onEditorChange}
          placeholder="Напишите заметку..."
          inComponent={"note"}
          lessonData={lessonData}
          setLessonData={setLessonData}
          setSettingsBlockData={setSettingsBlockData}
          key={settingsBlockData.id}
        />

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </NoteAndQuestionSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(NoteAndQuestionSettings);
