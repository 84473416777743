import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";
import styled from "styled-components";
import merge from "lodash/merge";
import { debounce } from "lodash";
import axios from "../../axios_config";
import { LESSON_COLORS_THEME } from "../../common/utils/enums";
import { useAppContext } from "../../app_context";
import { useUserDataContext } from "../../user_data_context";
import { BASE_API_URL } from "../../common/endpoints";
import BibleChapterModalWindow from "../../common/components/modal_windows/bible_chapter_modal_window";
import LessonPageHeader from "./user_lesson_page_header";
import UserLessonComponentsList from "./user_lesson_components_list";
import AfterFinishLessonModal from "./user_lesson_modal_windows/after_finish_lesson_modal";
import AfterFinishCourseModal from "./user_lesson_modal_windows/after_finish_course_modal";
import CertificateProgressBarModal from "./user_lesson_modal_windows/certificate_progress_bar_modal";
import CourseCertificateModal from "./user_lesson_modal_windows/course_certificate_modal";
import CourseFeedbackModal from "./user_lesson_modal_windows/course_feedback_modal";
import BibleModalWindow from "../../common/components/modal_windows/bible_modal_window";
import useWindowBreakpoint from "../../common/hooks/use_window_breakpoint";
import TestSuccessModal from "./user_lesson_modal_windows/test_success_modal";
import TestFailedModal from "./user_lesson_modal_windows/test_failed_modal";
import { ToastNotification } from "../../common/components/toast_notification";
import AskQuestionModalWindow from "../../common/components/modal_windows/ask_question_modal_window";

const UserLessonPageContainer = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  ${({ darkTheme }) => darkTheme && "background: #232323;"}

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ darkTheme }) => (darkTheme ? "#cacaca" : "#B0B0B0")};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ darkTheme }) =>
      darkTheme ? "#444444" : "rgb(227 227 227)"};
  }

  @media (max-width: 1024px) {
    ::-webkit-scrollbar {
      width: 4px;
    }
  }
`;

const UserLessonPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const lessonNumber = searchParams.get("n");

  const { userData, fetchUserData } = useUserDataContext();
  const { appColorTheme, setAppColorTheme, setShowAppHeader } = useAppContext();

  const userLessonPageRef = useRef(null);

  const [lessonData, setLessonData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowFinishButton, setShouldShowFinishButton] = useState(false);
  const [stopDialogueIndex, setStopDialogueIndex] = useState(null);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [userLessonInfo, setUserLessonInfo] = useState({});
  const [lessonIsFinished, setLessonIsFinished] = useState(null);
  const [isLastLesson, setIsLastLesson] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [userAnswersData, setUserAnswersData] = useState([]);
  //                      Modal windows states
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [bibleVersesData, setBibleVersesData] = useState({});
  const [afterFinishLessonModal, setAfterFinishLessonModal] = useState(false);
  const [courseFinishModal, setCourseFinishModal] = useState(false);
  const [certificateProgressBarModal, setCertificateProgressBarModal] =
    useState(false);
  const [courseCertificateModal, setCourseCertificateModal] = useState(false);
  const [courseFeedbackModal, setCourseFeedbackModal] = useState(false);
  const [testResults, setTestResults] = useState(0);
  const [testComponentsCount, setTestComponentsCount] = useState(null);
  const [testSuccessModal, setTestSuccessModal] = useState(false);
  const [testFailedModal, setTestFailedModal] = useState(false);
  const [openUserQuestionModal, setOpenUserQuestionModal] = useState(false);
  const [courseInfoForQuestion, setCourseInfoForQuestion] = useState({});

  const isMobile = useWindowBreakpoint(1024);
  const lessonId = id;
  const userId = userData._id;

  const courseLessons = courseData.course_lessons?.filter(
    (lesson) => lesson.lesson_type !== "test"
  );

  const courseLessonsCount = courseLessons?.length || null;

  const courseMainGradient =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      courseData.course_main_color
    ];
  const courseMainColor =
    LESSON_COLORS_THEME[appColorTheme]?.COLORS?.[courseData.course_main_color];

  useEffect(() => {
    if (courseData._id && lessonData._id && lessonNumber) {
      setCourseInfoForQuestion({
        course_id: courseData._id,
        course_name: courseData.course_name,
        lesson_id: lessonData._id,
        lesson_name: lessonData.lesson_name,
        lesson_num: lessonNumber,
      });
    }
  }, [courseData, lessonData, lessonNumber]);

  const savedScrollPosition =
    Number(localStorage.getItem(`pos-${lessonId}`)) || 0;

  useEffect(() => {
    if (isMobile) {
      setShowAppHeader(false);
    }
    return () => setShowAppHeader(true);
  }, [isMobile]);

  useEffect(() => {
    if (lessonIsFinished) {
      localStorage.removeItem(`pos-${lessonId}`);
      setUserAnswersData([]);
    }
  }, [lessonIsFinished]);

  useEffect(() => {
    if (lessonData?.lesson_data?.length && lessonData?.lesson_type === "test") {
      const testCount = lessonData.lesson_data.filter(
        (elem) => elem.type === "TEST"
      ).length;

      setTestComponentsCount(testCount);
    }
  }, [lessonData]);

  useEffect(() => {
    if (
      lessonData?.lesson_data?.length &&
      lessonData?.lesson_type === "test" &&
      testComponentsCount > 0
    ) {
      const correctAnswersCount = lessonData.lesson_data.filter(
        (elem) => elem.type === "TEST" && elem.is_correct === true
      ).length;

      const userTestResult = Math.round(
        (correctAnswersCount / testComponentsCount) * 100
      );

      setTestResults(userTestResult);
    }
  }, [lessonData, testComponentsCount]);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users?is_author=true`);
      const authors = response.data;
      setAuthors(authors);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  //   useEffect(() => {
  //     if (
  //       savedScrollPosition !== null &&
  //       userLessonPageRef.current &&
  //       filteredComponents?.length > 0
  //     ) {
  //       userLessonPageRef.current.scrollTo({
  //         top: savedScrollPosition,
  //       });
  //     }
  //   }, [userLessonPageRef.current, savedScrollPosition, filteredComponents]);

  useEffect(() => {
    const container = userLessonPageRef.current;

    const handleScroll = debounce(() => {
      localStorage.setItem(`pos-${lessonId}`, container.scrollTop);
    }, 400);

    if (
      lessonIsFinished !== null &&
      !lessonIsFinished &&
      lessonId &&
      container
    ) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [userLessonPageRef, lessonIsFinished, lessonId]);

  useEffect(() => {
    if (certificateProgressBarModal) {
      const timer = setTimeout(() => {
        setCourseCertificateModal(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [certificateProgressBarModal]);

  //! Возможно удалить
  //   useEffect(() => {
  //     if (!courseData._id || !userData._id) return;

  //     const courseIsFinished =
  //       userData.user_activity?.find(
  //         (item) => item.type === "course" && item.course_id === courseData._id
  //       )?.course_status === "finished";

  //     if (isLastLesson && lessonIsFinished && !courseIsFinished) {
  //       setAfterFinishLessonModal(true);
  //     }
  //   }, [isLastLesson, lessonIsFinished, courseData._id, userData]);

  useEffect(() => {
    if (
      courseLessons?.length &&
      lessonData._id &&
      courseLessonsCount > 0 &&
      lessonData.lesson_type === "lesson"
    ) {
      const lessonIsLast =
        courseLessons[courseLessonsCount - 1]._id === lessonData._id;

      setIsLastLesson(lessonIsLast);
    }

    if (lessonData.lesson_type === "test") {
      setIsLastLesson(true);
    }
  }, [courseLessons, lessonData, courseLessonsCount]);

  useEffect(() => {
    fetchUserData();
  }, [lessonIsFinished]);

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (lessonData?.lesson_course?._id) {
      const courseId = lessonData.lesson_course._id;
      fetchCourse(courseId);
    }
  }, [lessonData?.lesson_course?._id]);

  useEffect(() => {
    if (userData._id && courseData._id && lessonData._id) {
      const currentCourseAtUser = userData.user_activity.find(
        (item) => item.type === "course" && item.course_id === courseData._id
      );

      if (currentCourseAtUser?.course_id) {
        const lessonStatusAtUser = currentCourseAtUser?.course_lessons?.find(
          (lesson) => lesson._id === lessonData._id
        )?.lesson_status;

        const lessonIsFinished =
          lessonStatusAtUser === "finished" ? true : false;

        setLessonIsFinished(lessonIsFinished);
      }
    }
  }, [userData, courseData, lessonData]);

  useEffect(() => {
    setShouldShowFinishButton(
      lessonData.lesson_data?.length === filteredComponents?.length &&
        !isLoading &&
        !lessonIsFinished &&
        lessonIsFinished !== null
    );
  }, [
    lessonData.lesson_data?.length,
    filteredComponents?.length,
    isLoading,
    lessonIsFinished,
  ]);

  useEffect(() => {
    if (stopDialogueIndex === null) {
      setFilteredComponents(lessonData.lesson_data);
    } else {
      const index = stopDialogueIndex;
      if (index >= 0 && index < lessonData.lesson_data.length) {
        setFilteredComponents(lessonData.lesson_data.slice(0, index + 1));
      }
    }
  }, [stopDialogueIndex, lessonData.lesson_data]);

  useEffect(() => {
    if (lessonData.lesson_data?.length) {
      const index = lessonData.lesson_data.findIndex((component) => {
        if (component?.type === "DIALOGUE") {
          const lastSelectedBranch =
            component.selectedBranches?.[component.selectedBranches.length - 1];

          if (lastSelectedBranch && component.data[lastSelectedBranch]) {
            const hasOtherBranches =
              component.data[lastSelectedBranch].answers?.answers_data?.length >
              0;
            return hasOtherBranches;
          }
        }
        return false;
      });

      if (index !== -1) {
        setStopDialogueIndex(index);
      } else {
        setStopDialogueIndex(null);
      }
    }
  }, [lessonData.lesson_data]);

  const fetchUserLessonData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(
        BASE_API_URL +
          `/userstudy/find?studyLessonId=${lessonId}&studyUserId=${userId}`
      );

      const { lesson, ...userLessonInfo } = response.data;

      setUserLessonInfo(userLessonInfo);
      setLessonData(lesson);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [lessonId, userId]);

  useEffect(() => {
    if (lessonData._id !== lessonId && lessonId && userId) {
      fetchUserLessonData();
    }
  }, [lessonId, userId, lessonData._id]);

  useEffect(() => {
    if (userLessonInfo._id && userAnswersData?.length) {
      localStorage.setItem(userLessonInfo._id, JSON.stringify(userAnswersData));
    }
  }, [userAnswersData, userLessonInfo]);

  useEffect(() => {
    if (userLessonInfo._id && !lessonIsFinished) {
      const storageData = JSON.parse(localStorage.getItem(userLessonInfo._id));

      if (storageData?.length) {
        setUserAnswersData(storageData);
      } else {
        localStorage.setItem(userLessonInfo._id, JSON.stringify([]));
      }
    }
  }, [userLessonInfo._id, lessonIsFinished]);

  useEffect(() => {
    if (
      userLessonInfo._id &&
      lessonData._id &&
      userAnswersData?.length &&
      !lessonIsFinished
    ) {
      const updatedLessonData = lessonData.lesson_data.map(
        (lessonComponent) => {
          const matchingAnswer = userAnswersData.find(
            (answer) => answer.id === lessonComponent.id
          );

          return matchingAnswer
            ? merge({}, lessonComponent, matchingAnswer)
            : lessonComponent;
        }
      );

      setLessonData((prevState) => ({
        ...prevState,
        lesson_data: updatedLessonData,
      }));
    }
  }, [userLessonInfo._id, lessonIsFinished, userAnswersData, lessonData._id]);

  return (
    <UserLessonPageContainer
      ref={userLessonPageRef}
      darkTheme={appColorTheme === "DARK"}
    >
      <LessonPageHeader
        userData={userData}
        appColorTheme={appColorTheme}
        lessonData={lessonData}
        courseData={courseData}
        lessonNumber={lessonNumber}
        lessonIsFinished={lessonIsFinished}
        setAppColorTheme={setAppColorTheme}
        setOpenUserQuestionModal={setOpenUserQuestionModal}
      />

      <UserLessonComponentsList
        userData={userData}
        lessonData={lessonData}
        userLessonPageRef={userLessonPageRef}
        isLoading={isLoading}
        authors={authors}
        filteredComponents={filteredComponents}
        appColorTheme={appColorTheme}
        courseMainColor={courseMainColor}
        courseMainGradient={courseMainGradient}
        lessonIsFinished={lessonIsFinished}
        lessonIsTest={lessonData?.lesson_type === "test"}
        userAnswersData={userAnswersData}
        setLessonData={setLessonData}
        setUserAnswersData={setUserAnswersData}
        setTextBibleModalData={setTextBibleModalData}
        shouldShowFinishButton={shouldShowFinishButton}
        userLessonInfo={userLessonInfo}
        setLessonIsFinished={setLessonIsFinished}
        testResults={testResults}
        setAfterFinishLessonModal={setAfterFinishLessonModal}
        setTestSuccessModal={setTestSuccessModal}
        setTestFailedModal={setTestFailedModal}
        certificatePassRate={
          courseData?.course_certificate?.certificate_pass_rate
        }
      />

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
          setBibleVersesData={setBibleVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
        />
      )}

      {openBibleModalWindow && (
        <BibleModalWindow
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          bibleVersesData={bibleVersesData}
          setBibleVersesData={setBibleVersesData}
          inComponent={"simple_modal"}
        />
      )}

      {afterFinishLessonModal &&
        createPortal(
          <AfterFinishLessonModal
            userData={userData}
            lessonData={lessonData}
            courseData={courseData}
            lessonNumber={lessonNumber}
            isLastLesson={isLastLesson}
            setAfterFinishLessonModal={setAfterFinishLessonModal}
            setCourseFinishModal={setCourseFinishModal}
            setCourseFeedbackModal={setCourseFeedbackModal}
          />,
          document.body
        )}

      {testSuccessModal &&
        createPortal(
          <TestSuccessModal
            userTestResult={testResults}
            userLessonPageRef={userLessonPageRef}
            setTestSuccessModal={setTestSuccessModal}
            setCertificateProgressBarModal={setCertificateProgressBarModal}
          />,
          document.body
        )}

      {testFailedModal &&
        createPortal(
          <TestFailedModal
            userTestResult={testResults}
            userLessonPageRef={userLessonPageRef}
            lessonId={lessonId}
            setTestFailedModal={setTestFailedModal}
            userStudyId={userLessonInfo?._id}
            fetchUserLessonData={fetchUserLessonData}
            setLessonIsFinished={setLessonIsFinished}
            setUserAnswersData={setUserAnswersData}
          />,
          document.body
        )}

      {courseFinishModal &&
        createPortal(
          <AfterFinishCourseModal
            courseData={courseData}
            setCourseFinishModal={setCourseFinishModal}
            setCertificateProgressBarModal={setCertificateProgressBarModal}
          />,
          document.body
        )}

      {certificateProgressBarModal &&
        createPortal(
          <CertificateProgressBarModal
            setCertificateProgressBarModal={setCertificateProgressBarModal}
          />,
          document.body
        )}

      {courseCertificateModal &&
        createPortal(
          <CourseCertificateModal
            courseData={courseData}
            userData={userData}
            setCourseCertificateModal={setCourseCertificateModal}
            setCourseFeedbackModal={setCourseFeedbackModal}
          />,
          document.body
        )}

      {courseFeedbackModal &&
        createPortal(
          <CourseFeedbackModal
            courseData={courseData}
            userData={userData}
            setCourseFeedbackModal={setCourseFeedbackModal}
          />,
          document.body
        )}

      {openUserQuestionModal &&
        createPortal(
          <AskQuestionModalWindow
            inComponent={"course"}
            setOpenUserQuestionModal={setOpenUserQuestionModal}
            courseInfoForQuestion={courseInfoForQuestion}
            courseAuthors={courseData.course_authors}
          />,
          document.body
        )}

      <ToastNotification />
    </UserLessonPageContainer>
  );
};

export default memo(UserLessonPage);
