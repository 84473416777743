import React, { memo, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import BibleModalWindow from "../../../../common/components/modal_windows/bible_modal_window";
import { HeaderBibleIcon } from "../../../../common/assets/icons/header_icons";
import UserProfileRemoveVerseIcon from "../../../../common/assets/icons/user_profile_page_icons/user_profile_remove_verse_icon";

const UserQuestionBibleVerses = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const BibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 30px;
  padding-left 10px;
  color: #383838;
  font-size: 14px;
  font-style: normal;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;

  &:hover {
    background: #eaeaea;
  }
`;

const RemoveVerseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 100%;
`;

const PlusBibleButton = styled(BibleVerseAddress)`
  padding: 0px;
  width: 30px;
`;

const HeaderBibleIconStyled = styled(HeaderBibleIcon)`
  width: 14px;
  height: 16px;
`;

const UserProfileQuestionBible = ({ questionData, setQuestionData }) => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});
  const [clickedButton, setClickedButton] = useState({});

  const questionBibleVerses = questionData.question_bible_verses;

  const handleClickVerseAddress = useCallback((event, verses) => {
    event.stopPropagation();

    const bibleVersesData = {
      versesData: verses.versesData.map((verse) => ({
        verse_num: +verse.verse_num,
      })),
      versesParams: {
        bibleId: verses.versesParams.bibleId,
        bookId: verses.versesParams.bookId,
        chapterId: verses.versesParams.chapterId,
      },
    };

    setBibleVersesData(bibleVersesData);
    setOpenBibleModalWindow(true);
    setClickedButton({
      button: "address_button",
      verseId: verses.verseId,
    });
  }, []);

  const onClickPlusButton = useCallback((event) => {
    event.stopPropagation();
    setOpenBibleModalWindow(true);
    setClickedButton({ button: "plus_button" });
  }, []);

  const handleAddBibleVerses = useCallback(
    (addedVerse) => {
      if (clickedButton.button === "plus_button") {
        setQuestionData((prevState) => {
          const notIsDublicate = !prevState.question_bible_verses.some(
            (verse) => verse.verseId === addedVerse.verseId
          );

          if (notIsDublicate) {
            return {
              ...prevState,
              question_bible_verses: [
                ...prevState.question_bible_verses,
                addedVerse,
              ],
            };
          } else return prevState;
        });
      }

      if (clickedButton.button === "address_button") {
        setQuestionData((prevState) => {
          const updatedVerses = prevState.question_bible_verses.map((verse) =>
            verse.verseId === clickedButton.verseId ? addedVerse : verse
          );
          return { ...prevState, question_bible_verses: updatedVerses };
        });
      }
      setClickedButton({});
    },
    [clickedButton, setQuestionData]
  );

  const handleRemoveVerse = useCallback(
    (event, verseInfo) => {
      event.stopPropagation();

      setQuestionData((prevState) => {
        const updatedBibleVerses = prevState.question_bible_verses.filter(
          (verse) => verse.verseId !== verseInfo.verseId
        );

        return { ...prevState, question_bible_verses: updatedBibleVerses };
      });
    },
    [setQuestionData]
  );

  return (
    <UserQuestionBibleVerses>
      {!!questionBibleVerses?.length &&
        questionBibleVerses.map((verses, index) => {
          return (
            <BibleVerseAddress
              key={index}
              onClick={(e) => handleClickVerseAddress(e, verses)}
            >
              {verses.verseAddress}

              <RemoveVerseButton onClick={(e) => handleRemoveVerse(e, verses)}>
                <UserProfileRemoveVerseIcon />
              </RemoveVerseButton>
            </BibleVerseAddress>
          );
        })}
      <PlusBibleButton onClick={onClickPlusButton}>
        <HeaderBibleIconStyled stroke={"#383838"} />
      </PlusBibleButton>

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            bibleVersesData={bibleVersesData}
            setBibleVersesData={setBibleVersesData}
            inComponent={"home_page"}
            handleAddBibleVerses={handleAddBibleVerses}
          />,
          document.body
        )}
    </UserQuestionBibleVerses>
  );
};

export default memo(UserProfileQuestionBible);
