import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import QuestionSettingsTags from "./question_settings_tags";
import QuestionSettingsBibleVerses from "./question_settings_bible_verses";
import QuestionSettingsButtons from "./question_settings_buttons";
import QuestionSettingsContacts from "./question_settings_contacts";
import QuestionSettingsAnswer from "./question_settings_answer";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";
import { useUserDataContext } from "../../../../user_data_context";
import { BASE_API_URL } from "../../../../common/endpoints";
import RemoveIcon from "../../../../common/assets/icons/remove_icon";
import { useAppContext } from "../../../../app_context";
import useWindowBreakpoint from "../../../../common/hooks/use_window_breakpoint";
import QuestionSettingsQuestion from "./question_settings_question";

const AdminPageQuestionSettingsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 999;

  @media (max-width: 1200px) {
    right: 0px;
    width: 100%;
    background: #eaeaea;
    overflow: auto;
    height: 100%;
  }

  @media (max-width: 1024px) {
    position: fixed;
    height: ${({ showAppHeader }) =>
      showAppHeader ? "calc(100% - 96px)" : "100%"};
  }

  @media (max-width: 450px) {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (max-width: 412px) {
    height: ${({ showAppHeader }) =>
      showAppHeader ? "calc(100% - 103px)" : "100%"};
  }
`;

const AdminPageQuestionSettingsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  padding: 26px 24px 46px;
  width: 430px;
  margin: 0 auto;
  border-left: 1px solid #828282;
  max-height: calc(100vh - 80px);
  background: #eaeaea;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 1200px) {
    max-height: max-content;
    overflow: hidden;
    border: none;
  }

  @media (max-width: 450px) {
    max-width: 100%;
    padding: 15px 15px 46px;
  }
`;

const CloseSettingsButton = styled.div`
  display: none;

  @media (max-width: 1200px) {
    position: fixed;
    top: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background: #fff;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
    cursor: pointer;
    z-index: 999;
  }

  @media (max-width: 1024px) {
    top: 25px;
    right: 25px;
  }

  @media (max-width: 700px) {
    top: 20px;
    right: 20px;
  }

  @media (max-width: 450px) {
    top: 10px;
    right: 13px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
`;

const CloseIconStyled = styled(RemoveIcon)`
  width: 22px;
  height: 22px;
`;

const AdminPageQuestionSettings = ({
  questionSettingsData,
  setQuestionSettingsData,
  questionSettingsMemoData,
  setQuestionSettingsMemoData,
  openEditQuestionModal,
  showUsersName,
  setOpenEditQuestionModal,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
  setSelectedUserInfo,
  closeAllSettings,
  setBibleVersesData,
  setOpenBibleModalWindow,
  fetchQuestions,
}) => {
  const { userData } = useUserDataContext();
  const { showAppHeader, setShowAppHeader } = useAppContext();

  const [hasNewAnswer, setHasNewAnswer] = useState(false);
  const [openUnsentAnswerModal, setOpenUnsentAnswerModal] = useState(false);
  const [textEditorKey, setTextEditorKey] = useState(null);

  const isTablet = useWindowBreakpoint(1200);

  useEffect(() => {
    if (questionSettingsData._id && isTablet) {
      setShowAppHeader(false);
    } else {
      setShowAppHeader(true);
    }
  }, [questionSettingsData._id, isTablet]);

  const answerAuthorName =
    questionSettingsData.question_to_author?._id === userData._id
      ? `${userData.user_name} ${userData.user_second_name}`
      : "OnBible";

  useEffect(() => {
    if (
      !compareObjects(
        questionSettingsData.question_answer?.answer_text,
        questionSettingsMemoData.question_answer?.answer_text
      )
    ) {
      setHasNewAnswer(true);
    } else {
      setHasNewAnswer(false);
    }
  }, [questionSettingsData, questionSettingsMemoData]);

  const onClickCancelButton = useCallback(
    (event) => {
      event.stopPropagation();

      const prevAnswerData =
        questionSettingsMemoData.question_answer.answer_text;

      setQuestionSettingsData((prevState) => ({
        ...prevState,
        question_answer: {
          ...prevState.question_answer,
          answer_text: prevAnswerData,
        },
      }));
      setTextEditorKey((prevState) => prevState + 1);
    },
    [questionSettingsMemoData, setTextEditorKey, setQuestionSettingsData]
  );

  const onClickSendAnswerButton = useCallback(
    async (event, saveType) => {
      event.stopPropagation();

      if (!hasNewAnswer) return;

      const updatedDate = new Date().toISOString();

      const updatedAnswer = {
        answer_text: questionSettingsData.question_answer.answer_text,
        answer_author: {
          _id: userData._id,
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
          answer_from: answerAuthorName,
        },
        answer_read: false,
        answer_created_at: updatedDate,
      };

      const answer =
        saveType === "answer"
          ? {
              question_updated_at: updatedDate,
              question_status: "answered",
              question_answer: updatedAnswer,
            }
          : {
              ...questionSettingsData,
              question_updated_at: updatedDate,
              question_status: "answered",
              question_answer: updatedAnswer,
            };

      try {
        const response = await axios.patch(
          `${BASE_API_URL}/questions/${questionSettingsData._id}`,
          answer
        );

        setQuestionSettingsData((prevState) =>
          saveType === "answer"
            ? { ...prevState, question_answer: response.data.question_answer }
            : response.data
        );

        setQuestionSettingsMemoData((prevState) =>
          saveType === "answer"
            ? { ...prevState, question_answer: response.data.question_answer }
            : response.data
        );

        setOpenUnsentAnswerModal(false);
        setOpenEditQuestionModal(false);
        fetchQuestions();
      } catch (error) {
        console.error("Error saving an answer to a question:", error);
      }
    },
    [
      hasNewAnswer,
      questionSettingsData,
      userData,
      fetchQuestions,
      answerAuthorName,
    ]
  );

  return (
    <>
      {questionSettingsData._id && (
        <AdminPageQuestionSettingsWrapper
          showAppHeader={showAppHeader}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <AdminPageQuestionSettingsContainer>
            <CloseSettingsButton onClick={closeAllSettings}>
              <CloseIconStyled color="#383838" />
            </CloseSettingsButton>

            <QuestionSettingsBibleVerses
              questionBibleVerses={questionSettingsData.question_bible_verses}
              setBibleVersesData={setBibleVersesData}
              setOpenBibleModalWindow={setOpenBibleModalWindow}
            />

            {isTablet && (
              <QuestionSettingsQuestion
                questionSettingsData={questionSettingsData}
              />
            )}

            {isTablet && (
              <QuestionSettingsAnswer
                key={questionSettingsData._id}
                questionSettingsData={questionSettingsData}
                textEditorKey={textEditorKey}
                setTextEditorKey={setTextEditorKey}
                hasNewAnswer={hasNewAnswer}
                setQuestionSettingsData={setQuestionSettingsData}
                setQuestionSettingsMemoData={setQuestionSettingsMemoData}
                fetchQuestions={fetchQuestions}
                onClickCancelButton={onClickCancelButton}
                onClickSendAnswerButton={onClickSendAnswerButton}
              />
            )}

            <QuestionSettingsContacts
              questionSettingsData={questionSettingsData}
              setSelectedUserInfo={setSelectedUserInfo}
              setQuestionSettingsData={setQuestionSettingsData}
              userData={userData}
              showUsersName={showUsersName}
            />

            <QuestionSettingsTags
              questionTags={questionSettingsData.question_tags}
              setQuestionSettingsData={setQuestionSettingsData}
            />

            {!isTablet && (
              <QuestionSettingsAnswer
                questionSettingsData={questionSettingsData}
                textEditorKey={textEditorKey}
                setTextEditorKey={setTextEditorKey}
                hasNewAnswer={hasNewAnswer}
                setQuestionSettingsData={setQuestionSettingsData}
                setQuestionSettingsMemoData={setQuestionSettingsMemoData}
                fetchQuestions={fetchQuestions}
                onClickCancelButton={onClickCancelButton}
                onClickSendAnswerButton={onClickSendAnswerButton}
                key={questionSettingsData._id}
              />
            )}

            <QuestionSettingsButtons
              questionSettingsData={questionSettingsData}
              questionSettingsMemoData={questionSettingsMemoData}
              openEditQuestionModal={openEditQuestionModal}
              setOpenEditQuestionModal={setOpenEditQuestionModal}
              setQuestionSettingsData={setQuestionSettingsData}
              setQuestionSettingsMemoData={setQuestionSettingsMemoData}
              openDeleteQuestionModal={openDeleteQuestionModal}
              setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
              openUnsentAnswerModal={openUnsentAnswerModal}
              onClickSendAnswerButton={onClickSendAnswerButton}
              setOpenUnsentAnswerModal={setOpenUnsentAnswerModal}
              setTextEditorKey={setTextEditorKey}
              closeAllSettings={closeAllSettings}
              fetchQuestions={fetchQuestions}
            />
          </AdminPageQuestionSettingsContainer>
        </AdminPageQuestionSettingsWrapper>
      )}
    </>
  );
};

export default memo(AdminPageQuestionSettings);
