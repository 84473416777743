import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../common/assets/icons/vertical_line";
import CheckWhiteIcon from "../../../common/assets/icons/admin_page_icons/check_white_icon";

const AdminPageSettingsButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  cursor: default;
  user-select: none;
`;

const SaveButton = styled(ButtonStyles)`
  position: relative;
  color: ${({ isHoverOnCancel }) =>
    isHoverOnCancel ? "rgba(255, 255, 255, 0.5)" : "#fff"};

  ${({ showCheckIcon }) =>
    !showCheckIcon &&
    `
    &:hover {
    font-size: 18px;
    color: #fff;
    width: 53%;
    cursor: pointer;
  }
  `}
`;

const CancelButton = styled(ButtonStyles)`
  color: rgba(255, 255, 255, 0.5);

  ${({ showCheckIcon }) =>
    showCheckIcon &&
    `
  color: #fff;
  `}

  &:hover {
    font-size: 18px;
    color: #fff;
    width: 53%;
    cursor: pointer;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const CheckIconContainer = styled.div`
  position: absolute;
  top: 52%;
  left: 13px;
  transform: translateY(-50%);

  @media (max-width: 420px) {
    left: 7px;
  }

  @media (max-width: 380px) {
    left: 5px;
  }
`;

const AdminPageSettingsButtons = ({
  onClickSaveButton,
  onClickCancelButton,
  showCheckIcon,
}) => {
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);

  const toggleHoverOnSaveButton = useCallback(() => {
    setIsHoverOnCancel((prevState) => !prevState);
  }, []);

  return (
    <AdminPageSettingsButtonsContainer>
      <SaveButton
        isHoverOnCancel={isHoverOnCancel || showCheckIcon}
        showCheckIcon={showCheckIcon}
        onClick={onClickSaveButton}
      >
        {showCheckIcon && (
          <CheckIconContainer>
            <CheckWhiteIcon />
          </CheckIconContainer>
        )}
        Сохранить
      </SaveButton>
      <VerticalLineStyled />
      <CancelButton
        showCheckIcon={showCheckIcon}
        onMouseEnter={toggleHoverOnSaveButton}
        onMouseLeave={toggleHoverOnSaveButton}
        onClick={onClickCancelButton}
      >
        Отмена
      </CancelButton>
    </AdminPageSettingsButtonsContainer>
  );
};

export default memo(AdminPageSettingsButtons);
