import React, { memo, useCallback } from "react";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
  QuestionsFilterOptionItem,
  QuestionsFilterOptionsContainer,
} from "../../admin_questions_styled_components";
import BlackArrowDownIcon from "../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";

const QuestionsFilterByPlace = ({
  extendedOption,
  handleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  const handlePlaceFilter = useCallback(
    (place) => {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_place: place,
      }));
    },
    [setQuestionsFilterSettings]
  );

  return (
    <QuestionsFilterOption
      type="place"
      isExtended={extendedOption === "place"}
      onClick={(e) => handleExtendOptions(e, "place")}
    >
      Раздел
      <QuestionsFilterOptionArrow isExtended={extendedOption === "place"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>
      {extendedOption === "place" && (
        <QuestionsFilterOptionsContainer>
          <QuestionsFilterOptionItem onClick={() => handlePlaceFilter("home")}>
            Главная страница
          </QuestionsFilterOptionItem>
          <QuestionsFilterOptionItem
            onClick={() => handlePlaceFilter("course")}
          >
            Курс
          </QuestionsFilterOptionItem>
        </QuestionsFilterOptionsContainer>
      )}
    </QuestionsFilterOption>
  );
};

export default memo(QuestionsFilterByPlace);
