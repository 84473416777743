import React, { memo } from "react";
import styled from "styled-components";
import { getUserNameInitials } from "../utils/functions/admin_page_functions";

const UserImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size, fontSize }) =>
    size &&
    `
     min-width: ${size};
     max-width: ${size};
     height: ${size};
     font-size: ${fontSize};
  `}
  background: ${({ background }) =>
    background ||
    `linear-gradient(
    180deg,
    rgba(81, 114, 234, 0.4) -18.38%,
    rgba(122, 90, 238, 0.4) 108.82%
  )`};
  border-radius: 50%;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  overflow: hidden;

  ${({ hasUnreadAnswers }) => hasUnreadAnswers && " border: 2px solid #f8d254;"}

  ${({ isButton }) =>
    isButton &&
    `
    cursor: pointer;
    transition: 0.2s;
    
    &: hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
   }
  `}
`;

const UserImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
`;

const UserImage = ({
  userData,
  size = "30px",
  fontSize = "13px",
  color = "#1e1e1e",
  fontWeight = 600,
  background,
  isButton = false,
  hasUnreadAnswers = false,
}) => {
  const userInitials = getUserNameInitials(
    userData?.user_name,
    userData?.user_second_name
  );

  return (
    <UserImageWrapper
      size={size}
      fontSize={fontSize}
      color={color}
      background={background}
      fontWeight={fontWeight}
      isButton={isButton}
      hasUnreadAnswers={hasUnreadAnswers}
    >
      {userData?.user_image ? (
        <UserImageContainer image={userData.user_image} />
      ) : (
        <p>{userInitials}</p>
      )}
    </UserImageWrapper>
  );
};

export default memo(UserImage);
