import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../../axios_config";
import AdminPageQuestionsList from "./admin_page_questions_list";
import AdminPageQuestionSettings from "./admin_page_question_settings";
import { BASE_API_URL } from "../../../common/endpoints";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import { ToastNotification } from "../../../common/components/toast_notification";
import UserInfoModalWindow from "../../../common/components/modal_windows/user_info_modal_window";
import { useUserDataContext } from "../../../user_data_context";
import BibleModalWindow from "../../../common/components/modal_windows/bible_modal_window";
import BibleChapterModalWindow from "../../../common/components/modal_windows/bible_chapter_modal_window";

const DEFAULT_QUESTIONS_FILTERS = {
  question_status: "",
  question_by_answer_date: "",
  question_recipient: [],
  question_place: "",
  question_date_range: [],
  question_bible: [],
  question_by_author: {},
  question_by_favorite: false,
  search_words: [],
};

const FILTERS_STORAGE_KEY = "questionsFilters";

const AdminPageQuestionsWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageQuestionsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

const getInitialFilters = () => {
  const savedFilters = localStorage.getItem(FILTERS_STORAGE_KEY);
  return savedFilters
    ? { ...JSON.parse(savedFilters), search_words: [] }
    : DEFAULT_QUESTIONS_FILTERS;
};

const AdminPageQuestions = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const questionId = searchParams.get("q");

  const { userData, accessRights, authError } = useUserDataContext();

  const [questionsList, setQuestionsList] = useState([]);
  const [questionSettingsData, setQuestionSettingsData] = useState({});
  const [questionSettingsMemoData, setQuestionSettingsMemoData] = useState({});
  const [questionsFilterSettings, setQuestionsFilterSettings] =
    useState(getInitialFilters);
  const [showUsersName, setShowUsersName] = useState(() => {
    return JSON.parse(localStorage.getItem("showUsersName")) ?? true;
  });
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  //                     bible_modal_windows_states
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  const hasAccess =
    accessRights.isSuperadmin ||
    accessRights.isAdminOfPlatformQuestions ||
    accessRights.isAuthor;

  const showAllQuestions =
    accessRights.isAdminOfPlatformQuestions || accessRights.isSuperadmin;

  useEffect(() => {
    if (!hasAccess && (authError || userData._id)) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, authError, userData]);

  useEffect(() => {
    localStorage.setItem("showUsersName", JSON.stringify(showUsersName));
  }, [showUsersName]);

  useEffect(() => {
    const { search_words, ...filtersToSave } = questionsFilterSettings;
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filtersToSave));
  }, [questionsFilterSettings]);

  useEffect(() => {
    if (
      questionsFilterSettings.question_status === "" &&
      questionsFilterSettings.question_by_answer_date
    ) {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_by_answer_date: "",
      }));
    }
  }, [questionsFilterSettings]);

  const fetchQuestions = async () => {
    try {
      const url = showAllQuestions
        ? `${BASE_API_URL}/questions`
        : `${BASE_API_URL}/questions/toauthor/${userData._id}`;

      const response = await axios.get(url);
      setQuestionsList(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    if (userData._id && !showAllQuestions) {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_recipient: userData,
      }));
    }
  }, [userData, showAllQuestions]);

  useEffect(() => {
    const loadQuestions = async () => {
      setIsLoading(true);
      await fetchQuestions();
      setIsLoading(false);
    };

    if (userData._id) {
      loadQuestions();
    }
  }, [userData]);

  const fetchQuestion = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/questions/${questionId}`
      );

      setQuestionSettingsData(response.data);
      setQuestionSettingsMemoData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе вопроса:", error);
    }
  };

  useEffect(() => {
    if (questionId) {
      fetchQuestion(questionId);
    }
  }, [questionId]);

  const closeAllSettings = useCallback(() => {
    setQuestionSettingsData({});
    setQuestionSettingsMemoData({});
    setOpenEditQuestionModal(false);
    setOpenDeleteQuestionModal(false);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("q");
    setSearchParams(newSearchParams);
  }, [searchParams]);

  const onCloseQuestionSettings = useCallback(() => {
    if (compareObjects(questionSettingsData, questionSettingsMemoData)) {
      closeAllSettings();
    } else {
      setOpenEditQuestionModal(true);
    }
  }, [questionSettingsData, questionSettingsMemoData]);

  if (!hasAccess) return;

  return (
    <AdminPageQuestionsWrapper onMouseDown={onCloseQuestionSettings}>
      <AdminPageQuestionsContainer>
        <AdminPageQuestionsList
          questionsList={questionsList}
          questionsFilterSettings={questionsFilterSettings}
          questionSettingsData={questionSettingsData}
          showUsersName={showUsersName}
          setShowUsersName={setShowUsersName}
          questionSettingsMemoData={questionSettingsMemoData}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
          setQuestionSettingsData={setQuestionSettingsData}
          setQuestionSettingsMemoData={setQuestionSettingsMemoData}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          setTextBibleModalData={setTextBibleModalData}
          isLoading={isLoading}
          fetchQuestions={fetchQuestions}
        />
        <AdminPageQuestionSettings
          questionSettingsData={questionSettingsData}
          setQuestionSettingsData={setQuestionSettingsData}
          setQuestionSettingsMemoData={setQuestionSettingsMemoData}
          questionSettingsMemoData={questionSettingsMemoData}
          openEditQuestionModal={openEditQuestionModal}
          showUsersName={showUsersName}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          closeAllSettings={closeAllSettings}
          openDeleteQuestionModal={openDeleteQuestionModal}
          setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          setSelectedUserInfo={setSelectedUserInfo}
          setBibleVersesData={setBibleVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          fetchQuestions={fetchQuestions}
        />
      </AdminPageQuestionsContainer>

      <ToastNotification />

      {selectedUserInfo._id &&
        createPortal(
          <UserInfoModalWindow
            selectedUserInfo={selectedUserInfo}
            setSelectedUserInfo={setSelectedUserInfo}
          />,
          document.body
        )}

      {textBibleModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={textBibleModalData}
            setOpen={setTextBibleModalData}
            setBibleVersesData={setBibleVersesData}
            setOpenBibleModalWindow={setOpenBibleModalWindow}
          />,
          document.body
        )}

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            bibleVersesData={bibleVersesData}
            setBibleVersesData={setBibleVersesData}
            inComponent={"simple_modal"}
          />,
          document.body
        )}
    </AdminPageQuestionsWrapper>
  );
};

export default memo(AdminPageQuestions);
