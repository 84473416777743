import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import PinnedIcon from "../../assets/icons/admin_page_icons/pinned_icon";
import { LESSON_COLORS_THEME } from "../../utils/enums";
import ArticleTitle from "./article_title";
import ArticleText from "./article_text";

const ArticleContainer = styled.div`
  width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "310px" : "540px"};
  display: inline-block;
  vertical-align: top;
  padding-bottom: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "15px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "15px" : "25px"};
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  cursor: ${({ inComponent }) => inComponent === "admin_page" && "pointer"};
  opacity: ${({ opacity }) => opacity};

  @media (max-width: 1124px) {
    width: auto;
  }

  @media (max-width: 744px) {
    width: 100%;
    padding-bottom: 10px;
  }
`;

const ArticleHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 13px;
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "15px" : "25px"};
  width: 100%;
  background: ${({ background }) =>
    background || "linear-gradient(30.13deg, #05baa4 11.17%, #2046e9 92.55%)"};
  background-size: cover;
  background-position: center;
  height: max-content;
  overflow: hidden;

  @media (max-width: 1024px) {
    border-radius: 15px;
  }
`;

const ArticleImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const ArticleImage = styled.div`
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const ArticleVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "12px" : "20px"};

  @media (max-width: 1024px) {
    border-radius: 15px;
  }

  &::-webkit-media-controls {
    display: none !important;
  }

  &::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
`;

const ArticleShowStatus = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ isShow }) => (isShow ? "#F12B2B" : "#A1A1A1")};
  z-index: 9999;
`;

const ArticleIsPinned = styled.div`
  position: absolute;
  top: 7px;
  left: 23px;
  z-index: 9999;
`;

const Article = ({
  article,
  inComponent,
  onClick,
  editedArticleId,
  appColorTheme,
  setTextBibleModalData = () => {},
}) => {
  const [previewOpacity, setPreviewOpacity] = useState("1");

  const isVideo = article.article_image_url?.endsWith(".mp4");

  const backgroundColor =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      article.article_background_color
    ];

  useEffect(() => {
    if (inComponent === "admin_page") {
      setPreviewOpacity(
        editedArticleId && article._id !== editedArticleId ? "0.5" : "1"
      );
    }
  }, [editedArticleId, article._id, inComponent]);

  const onClickArticle = (event, article) => {
    event.stopPropagation();
    if (inComponent === "admin_page") {
      onClick(event, article);
    }
  };

  const headerBackground = article.article_image_url
    ? article.article_image_url
    : article.article_video_url
    ? article.article_video_url
    : backgroundColor;

  return (
    <ArticleContainer
      inComponent={inComponent}
      opacity={previewOpacity}
      onClick={(e) => onClickArticle(e, article)}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ArticleHeader background={headerBackground} inComponent={inComponent}>
        <ArticleImageContainer inComponent={inComponent}>
          {article.article_image_url || article.article_video_url ? (
            <>
              {isVideo ? (
                <ArticleVideo
                  src={article.article_video_url || article.article_image_url}
                  inComponent={inComponent}
                  autoPlay
                  loop
                  muted
                  playsInline
                  controls={false}
                  disablePictureInPicture
                />
              ) : (
                <ArticleImage
                  backgroundImage={article.article_image_url}
                  inComponent={inComponent}
                />
              )}
            </>
          ) : null}
        </ArticleImageContainer>

        <ArticleTitle
          article={article}
          inComponent={inComponent}
          appColorTheme={appColorTheme}
        />

        {inComponent === "admin_page" && (
          <ArticleShowStatus isShow={article.article_is_visible} />
        )}

        {inComponent === "admin_page" && article.article_is_pinned && (
          <ArticleIsPinned>
            <PinnedIcon />
          </ArticleIsPinned>
        )}
      </ArticleHeader>

      <ArticleText
        article={article}
        inComponent={inComponent}
        appColorTheme={appColorTheme}
        setTextBibleModalData={setTextBibleModalData}
      />
    </ArticleContainer>
  );
};

export default memo(Article);
