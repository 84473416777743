import React, { memo, useCallback } from "react";
import styled from "styled-components";
import SmallArrowIcon from "../../../../common/assets/icons/text_editor_icons/small_arrow_icon";

export const TextSettingsButtonsHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 34px;
  width: 50px;
  border-radius: 6px;

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
      cursor: pointer;
      box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
         rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
       transition: 0.2s;
   }`}
`;

export const TextSettingsButtonsHeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2px;
  padding: 5px 6px;
  ${({ buttonsGroup }) =>
    buttonsGroup === "text_color" && "padding: 5px 2px 5px 0;"}
  height: 100%;
  width: max-content;
`;

const SmallArrowIconContainer = styled.div`
  margin-bottom: -2px;

  ${({ isOpen }) => isOpen && "transform: rotate(180deg);"}
  ${({ disabled }) => disabled && "opacity: 0;"}
`;

const TextSettingsButtonsHeader = ({
  children,
  isOpen,
  setOpen,
  buttonsGroup,
  disabled,
}) => {
  const handleOpenHeader = useCallback(
    (event) => {
      event.stopPropagation();
      setOpen((prevState) => {
        if ((!prevState || prevState !== buttonsGroup) && !disabled) {
          return buttonsGroup;
        } else {
          return "";
        }
      });
    },
    [buttonsGroup, disabled, setOpen]
  );

  return (
    <TextSettingsButtonsHeaderWrapper disabled={disabled}>
      <TextSettingsButtonsHeaderContainer
        onClick={handleOpenHeader}
        buttonsGroup={buttonsGroup}
      >
        {children}
        <SmallArrowIconContainer isOpen={isOpen} disabled={disabled}>
          <SmallArrowIcon />
        </SmallArrowIconContainer>
      </TextSettingsButtonsHeaderContainer>
    </TextSettingsButtonsHeaderWrapper>
  );
};

export default memo(TextSettingsButtonsHeader);
