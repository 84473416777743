import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../assets/icons/vertical_line";
import { SearchIcon } from "../../../../assets/icons";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../../pages/bible/bible_page_data";
import { StrongButton } from "../bible_modal_window_desktop/bible_modal_window_verses";
import { useTranslation } from "react-i18next";
import { useBibleModalWindowContext } from "../bible_modal_window_context";
import RemoveIcon from "../../../../assets/icons/remove_icon";
import { formatBibleBookShortName } from "../../../../utils/functions/bible_page_functions";

const BibileModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 12px 10px;
  user-select: none;
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
`;

const BiblePlaceButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 66px;
  padding: 0 10px 0 17px;
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  line-height: 122%;
  letter-spacing: 0.14px;
`;

const BibleTranslationButton = styled.button`
  display: flex;
  align-items: center;
  min-width: 50px;
  width: max-content;
  height: 100%;

  p {
    text-transform: uppercase;
    padding: 0 14px 0 12px;
    color: #f8d254;
    font-size: 14px;
    font-weight: 600;
    line-height: 122%;
    letter-spacing: 0.4px;
    flex: 1;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  width: 1px;
`;

const RightButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 430px) {
    gap: 10px;
  }
`;

const BibleSearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 32px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
`;

const CloseTranslationButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 30px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 16px;
  height: 16px;
`;

const BibileModalHeader = ({
  chapterData,
  setActiveScreenMobile,
  currentTranslation,
  currentBibleParams,
  inComponent,
  handleCloseBibleModalWindow,
}) => {
  const { t } = useTranslation();

  const { showStrongsCode, setShowStrongsCode } = useBibleModalWindowContext();

  const [bibleChapterAddress, setBibleChapterAddress] = useState("");

  const abbreviation = chapterData?.[0]?.bible_code;
  const strongButtonName = t("bible-page.strong-btn");

  useEffect(() => {
    if (currentTranslation?.bible_locale && currentBibleParams?.bookId) {
      const bookNameData =
        BIBLE_BOOK_NAMES_DATA[currentTranslation?.bible_locale]?.[
          chapterData?.[0]?.book_code
        ]?.short_name;

      const formatedBookName = formatBibleBookShortName(bookNameData);

      setBibleChapterAddress(
        formatedBookName + " " + currentBibleParams?.chapterId
      );
    }
  }, [currentTranslation, currentBibleParams]);

  const handleOpenBibleNavigation = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_modal_navigation");
  }, []);

  const handleOpenBibleTranslation = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_modal_translation");
  }, []);

  const handleOpenBibleSearch = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_modal_search");
  }, []);

  const handleShowStrongsCodes = useCallback((event) => {
    event.stopPropagation();
    setShowStrongsCode((prevState) => !prevState);
  }, []);

  return (
    <BibileModalHeaderContainer>
      <LeftButtonsContainer>
        <BiblePlaceButton onClick={handleOpenBibleNavigation}>
          {bibleChapterAddress}
        </BiblePlaceButton>
        <VerticalLineStyled height="24" />

        <BibleTranslationButton onClick={handleOpenBibleTranslation}>
          <p>{abbreviation || ""}</p>
        </BibleTranslationButton>
      </LeftButtonsContainer>

      <RightButtonsContainer>
        {currentTranslation?.bible_code === "rst" && (
          <StrongButton
            showStrongsCode={showStrongsCode}
            onClick={handleShowStrongsCodes}
          >
            {strongButtonName}
          </StrongButton>
        )}

        {inComponent !== "search_modal" && (
          <BibleSearchButton onClick={handleOpenBibleSearch}>
            <SearchIcon />
          </BibleSearchButton>
        )}

        {
          <CloseTranslationButton onClick={handleCloseBibleModalWindow}>
            <RemoveIconStyled color="#383838" />
          </CloseTranslationButton>
        }
      </RightButtonsContainer>
    </BibileModalHeaderContainer>
  );
};

export default memo(BibileModalHeader);
