import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../../app_context.js";
import BibleModalWindowDesktop from "./bible_modal_window_desktop/index.js";
import useWindowBreakpoint from "../../../hooks/use_window_breakpoint.js";
import BibleModalWindowMobile from "./bible_modal_window_mobile/index.js";
import { fetchAndSortBibleBooks } from "../../../utils/functions/bible_page_functions.js";
import { useUserDataContext } from "../../../../user_data_context.js";
import {
  BibleModalWindowProvider,
  useBibleModalWindowContext,
} from "./bible_modal_window_context.js";

const BibleModalWindowHOC = (WrappedComponent) => (props) => {
  return (
    <BibleModalWindowProvider>
      <WrappedComponent {...props} />
    </BibleModalWindowProvider>
  );
};

const BibleModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const BibleModalWindow = ({
  bibleVersesData = {},
  setBibleVersesData,
  setOpenBibleModalWindow,
  setChatInputValue,
  handleAddBibleVerses = () => {},
  fromSearchToBiblePage = () => {},
  inComponent,
}) => {
  const { bibleFontSettings } = useAppContext();
  const { userLanguage } = useUserDataContext();
  const { strongsCodeModalData } = useBibleModalWindowContext();

  const [bibleTranslations, setBibleTranslations] = useState([]);
  const [bibleModalSearchPlace, setBibleModalSearchPlace] = useState("");
  const [bibleModalSearchInput, setBibleModalSearchInput] = useState("");
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [bibleModalSearchResults, setBibleModalSearchResults] = useState({});
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [currentTranslationData, setCurrentTranslationData] = useState([]);
  const [activeScreenDesktop, setActiveScreenDesktop] = useState(
    "bible_modal_chapter"
  ); // bible_modal_chapter, bible_modal_search
  const [activeScreenMobile, setActiveScreenMobile] = useState(
    "bible_modal_chapter"
  ); // bible_modal_chapter, bible_modal_search, bible_modal_navigation, bible_modal_translation
  const [currentBibleParams, setCurrentBibleParams] = useState({});
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [searchWords, setSearchWords] = useState(null);

  const isMobile = useWindowBreakpoint(744);

  const defaultBibleId =
    userLanguage === "ru"
      ? "rst"
      : userLanguage === "uk"
      ? "ytt"
      : userLanguage === "en"
      ? "kjv"
      : "rst";

  const DEFAULT_BIBLE_PARAMS = {
    bibleId: defaultBibleId,
    bookId: "Gen",
    chapterId: 1,
  };

  useEffect(() => {
    if (Object.keys(currentBibleParams).length) {
      localStorage.setItem(
        "bibleModalParams",
        JSON.stringify(currentBibleParams)
      );
    }
  }, [currentBibleParams]);

  useEffect(() => {
    if (
      Object.values(bibleVersesData)?.length &&
      currentBibleParams?.chapterId &&
      bibleVersesData.versesParams?.chapterId !== currentBibleParams?.chapterId
    ) {
      setSelectedVerses([]);
    } else if (Object.values(bibleVersesData).length) {
      setSelectedVerses(bibleVersesData.versesData);
    }
  }, [currentBibleParams?.chapterId, bibleVersesData]);

  useEffect(() => {
    const savedParams = localStorage.getItem("bibleModalParams");

    if (Object.keys(bibleVersesData).length) {
      setCurrentBibleParams(bibleVersesData.versesParams);
      setSelectedVerses(bibleVersesData.versesData);
      bibleVersesData.searchWord && setSearchWords(bibleVersesData.searchWord);
    } else if (savedParams) {
      if (Object.keys(JSON.parse(savedParams)).length) {
        setCurrentBibleParams(JSON.parse(savedParams));
      } else {
        setCurrentBibleParams(DEFAULT_BIBLE_PARAMS);
      }
    } else {
      setCurrentBibleParams(DEFAULT_BIBLE_PARAMS);
    }
  }, [bibleVersesData]);

  useEffect(() => {
    if (isMobile) {
      setActiveScreenMobile("bible_modal_chapter");
    }
  }, [currentBibleParams]);

  useEffect(() => {
    if (currentBibleParams.bibleId && bibleTranslations.length) {
      const translation = bibleTranslations.find(
        (transl) => transl.bible_code === currentBibleParams.bibleId
      );
      setCurrentTranslation(translation);
    }
  }, [currentBibleParams, bibleTranslations]);

  useEffect(() => {
    const loadTranslationData = async () => {
      if (currentTranslation) {
        try {
          const sortedBooks = await fetchAndSortBibleBooks(
            currentTranslation.bible_code,
            currentTranslation.bible_locale
          );

          setCurrentTranslationData(sortedBooks);
        } catch (error) {
          console.error("Ошибка загрузки книг Библии", error);
        }
      }
    };

    loadTranslationData();
  }, [currentTranslation]);

  const handleCloseBibleModalWindow = useCallback((e) => {
    e.stopPropagation();
    setOpenBibleModalWindow(false);
    setSelectedVerses([]);
    setActiveScreenDesktop("bible_modal_chapter");
    setBibleModalSearchResults({});
    setBibleVersesData({});
  }, []);

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape" && strongsCodeModalData === null) {
        handleCloseBibleModalWindow(e);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [strongsCodeModalData]);

  return (
    <BibleModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleCloseBibleModalWindow}
    >
      {!isMobile ? (
        <BibleModalWindowDesktop
          activeScreenDesktop={activeScreenDesktop}
          currentBibleParams={currentBibleParams}
          currentTranslation={currentTranslation}
          selectedVerses={selectedVerses}
          currentTranslationData={currentTranslationData}
          setSelectedVerses={setSelectedVerses}
          bibleFontSettings={bibleFontSettings}
          inComponent={inComponent}
          searchIsLoading={searchIsLoading}
          bibleModalSearchResults={bibleModalSearchResults}
          bibleModalSearchInput={bibleModalSearchInput}
          bibleModalSearchPlace={bibleModalSearchPlace}
          bibleTranslations={bibleTranslations}
          searchWords={searchWords}
          setSearchWords={setSearchWords}
          setBibleModalSearchResults={setBibleModalSearchResults}
          setBibleModalSearchInput={setBibleModalSearchInput}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setCurrentBibleParams={setCurrentBibleParams}
          setBibleTranslations={setBibleTranslations}
          setSearchIsLoading={setSearchIsLoading}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
          setBibleVersesData={setBibleVersesData}
          setChatInputValue={setChatInputValue}
          handleAddBibleVerses={handleAddBibleVerses}
          handleCloseBibleModalWindow={handleCloseBibleModalWindow}
          fromSearchToBiblePage={fromSearchToBiblePage}
        />
      ) : (
        <BibleModalWindowMobile
          activeScreenMobile={activeScreenMobile}
          setActiveScreenMobile={setActiveScreenMobile}
          bibleFontSettings={bibleFontSettings}
          currentBibleParams={currentBibleParams}
          inComponent={inComponent}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          bibleTranslations={bibleTranslations}
          searchWords={searchWords}
          setSearchWords={setSearchWords}
          currentTranslation={currentTranslation}
          currentTranslationData={currentTranslationData}
          setCurrentTranslationData={setCurrentTranslationData}
          setBibleTranslations={setBibleTranslations}
          setCurrentBibleParams={setCurrentBibleParams}
          setCurrentTranslation={setCurrentTranslation}
          bibleModalSearchPlace={bibleModalSearchPlace}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
          bibleModalSearchInput={bibleModalSearchInput}
          setBibleModalSearchInput={setBibleModalSearchInput}
          bibleModalSearchResults={bibleModalSearchResults}
          setBibleModalSearchResults={setBibleModalSearchResults}
          searchIsLoading={searchIsLoading}
          setSearchIsLoading={setSearchIsLoading}
          handleCloseBibleModalWindow={handleCloseBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          setChatInputValue={setChatInputValue}
          setBibleVersesData={setBibleVersesData}
        />
      )}
    </BibleModalWindowWrapper>
  );
};

export default BibleModalWindowHOC(BibleModalWindow);
