import React, { memo, useCallback } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker_calendar_styles.css";

const RangeCalendarsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const RangeCalendars = ({
  startRangeDate,
  setStartRangeDate,
  endRangeDate,
  setEndRangeDate,
}) => {
  const handleStartDateChange = useCallback(
    (date) => {
      setStartRangeDate(date);
      if (!endRangeDate || date > endRangeDate) {
        setEndRangeDate(date);
      }
    },
    [endRangeDate]
  );

  const handleEndDateChange = useCallback((date) => {
    setEndRangeDate(date);
  }, []);

  return (
    <RangeCalendarsContainer>
      <DatePicker
        selected={startRangeDate}
        onChange={handleStartDateChange}
        focusSelectedMonth
        selectsStart
        startDate={startRangeDate}
        endDate={endRangeDate}
        locale={ru}
        minDate={null}
        maxDate={new Date()}
        inline
        calendarClassName="calendar-container calendar-container-left"
        dayClassName={() => "calendar-day"}
        weekDayClassName={() => "calendar-week-days-container"}
      />

      <DatePicker
        selected={endRangeDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startRangeDate}
        endDate={endRangeDate}
        maxDate={new Date()}
        minDate={startRangeDate}
        locale={ru}
        inline
        calendarClassName="calendar-container"
        dayClassName={() => "calendar-day"}
        weekDayClassName={() => "calendar-week-days-container"}
      />
    </RangeCalendarsContainer>
  );
};

export default memo(RangeCalendars);
