import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LESSON_BG_COLOR } from "..";
import { convertMinutesToHoursAndMinutes } from "../../../../common/utils/functions/time_functions";
import CloseMessageIcon from "../../../../common/assets/icons/study_page_icons/close_message_icon";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "react-i18next";

const CourseLessonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 78px;
  padding: 0 15px 0 25px;
  color: #fff;
  border-radius: 25px;
  background: ${({ background }) => background};
  cursor: pointer;

  &:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    transition: 0.2s;
  }

  @media (max-width: 412px) {
    height: 78px;
    padding-left: 22px;
  }
`;

const CourseLessonNum = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.445px;
  margin-right: 16px;
  user-select: none;

  @media (max-width: 412px) {
    font-size: 26px;
    line-height: 130%;
    letter-spacing: -0.39px;
    margin-right: 14px;
  }
`;

const CourseLessonDuration = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.114px;
  user-select: none;

  @media (max-width: 412px) {
    font-size: 10px;
    line-height: 140%;
  }
`;

const CourseTestMessage = styled.div`
  position: absolute;
  top: -15px;
  right: -45px;
  width: 187px;
  height: 48px;
  padding: 6px 10px;
  background: #5172ea;
  border-radius: 12px 12px 12px 0px;
  z-index: 9;

  @media (max-width: 630px) {
    right: -5px;
  }

  & > p {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
`;

const CloseMessageIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CourseTest = ({
  userCourseLessons,
  courseTestData,
  lastLessonId,
  showLessonDuration,
  handleClickOnTest,
  authError,
  courseStatus,
  inComponent,
}) => {
  const { t } = useTranslation();

  const testContainerRef = useRef(null);
  const timerRef = useRef(null);

  const [testStatus, setTestStatus] = useState("closed");
  const [showTestCloseMessage, setShowTestCloseMessage] = useState(false);

  useOnclickOutside(() => setShowTestCloseMessage(false), {
    refs: [testContainerRef],
  });

  const userTest = userCourseLessons?.find(
    (lesson) => lesson.lesson_type === "test"
  );

  const testId = courseTestData._id;

  const lastLessonIsFinished =
    userCourseLessons?.find((lesson) => lesson._id === lastLessonId)
      ?.lesson_status === "finished";

  const userStudyId = userTest?.user_study_id;

  const duration = convertMinutesToHoursAndMinutes(
    courseTestData?.lesson_duration_min
  );

  const lessonBackgroundColor =
    testStatus === "closed"
      ? LESSON_BG_COLOR.CLOSED
      : testStatus === "in_process" || testStatus === "next"
      ? LESSON_BG_COLOR.CURRENT
      : LESSON_BG_COLOR.FINISHED;

  useEffect(() => {
    if (showTestCloseMessage) {
      timerRef.current = setTimeout(() => {
        setShowTestCloseMessage(false);
      }, 4500);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [showTestCloseMessage]);

  useEffect(() => {
    if (inComponent === "admin_page") {
      setTestStatus("finished");
    } else if (
      courseStatus === "closed" ||
      courseStatus === "announcement" ||
      authError
    ) {
      setTestStatus("closed");
    } else {
      if (lastLessonIsFinished && !userTest) {
        setTestStatus("next");
      } else if (userTest) {
        if (userTest.lesson_status === "in_process") {
          setTestStatus("in_process");
        } else if (userTest.lesson_status === "finished") {
          setTestStatus("finished");
        }
      } else {
        setTestStatus("closed");
      }
    }
  }, [userTest, inComponent, courseStatus, authError, lastLessonIsFinished]);

  const handleMessageClick = (e) => {
    e.stopPropagation();
    setShowTestCloseMessage(false);
  };

  const handleClick = useCallback(
    (e) => {
      if (e.target.closest(".course-test") !== e.currentTarget) return;

      if (testStatus === "closed") {
        setShowTestCloseMessage(true);
        return;
      }

      handleClickOnTest(e, testId, testStatus, userStudyId);
    },
    [testId, testStatus, userStudyId, handleClickOnTest]
  );

  return (
    <CourseLessonContainer
      ref={testContainerRef}
      className="course-test"
      background={lessonBackgroundColor}
      onClick={handleClick}
    >
      <CourseLessonNum>ТЕСТ</CourseLessonNum>
      <div>
        {showLessonDuration && (
          <CourseLessonDuration>
            {!!duration.hours && (
              <span>
                {duration.hours}{" "}
                {duration.hours === 1
                  ? t("course-page.lesson-duration.hours.one")
                  : t("course-page.lesson-duration.hours.several")}{" "}
              </span>
            )}
            {!!duration.minutes && (
              <span>
                {duration.minutes} {t("course-page.lesson-duration.minutes")}
              </span>
            )}
            {!duration.hours && !duration.minutes && <span> - </span>}
          </CourseLessonDuration>
        )}
      </div>

      {showTestCloseMessage && (
        <CourseTestMessage onClick={handleMessageClick}>
          <p>{t("course-page.closed-test-text")}</p>
          <CloseMessageIconContainer>
            <CloseMessageIcon />
          </CloseMessageIconContainer>
        </CourseTestMessage>
      )}
    </CourseLessonContainer>
  );
};

export default memo(CourseTest);
