import React from "react";

const TextDefinitionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <mask id="path-1-inside-1_10965_8281" fill="white">
        <path d="M0.140625 9.77778C0.140625 4.37766 4.51828 0 9.9184 0H12.3628C17.763 0 22.1406 4.37766 22.1406 9.77778V12.2222C22.1406 17.6223 17.763 22 12.3628 22H1.14063C0.588341 22 0.140625 21.5523 0.140625 21V9.77778Z" />
      </mask>
      <path
        d="M0.140625 9.77778C0.140625 4.37766 4.51828 0 9.9184 0H12.3628C17.763 0 22.1406 4.37766 22.1406 9.77778V12.2222C22.1406 17.6223 17.763 22 12.3628 22H1.14063C0.588341 22 0.140625 21.5523 0.140625 21V9.77778Z"
        stroke="#6666EC"
        strokeWidth="4"
        mask="url(#path-1-inside-1_10965_8281)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47917 11.6117C7.47917 12.6242 6.65836 13.445 5.64583 13.445C4.63331 13.445 3.8125 12.6242 3.8125 11.6117C3.8125 10.5991 4.63331 9.77832 5.64583 9.77832C6.65836 9.77832 7.47917 10.5991 7.47917 11.6117ZM12.3604 11.6117C12.3604 12.6242 11.5396 13.445 10.5271 13.445C9.51456 13.445 8.69375 12.6242 8.69375 11.6117C8.69375 10.5991 9.51456 9.77832 10.5271 9.77832C11.5396 9.77832 12.3604 10.5991 12.3604 11.6117ZM15.4198 13.445C16.4323 13.445 17.2531 12.6242 17.2531 11.6117C17.2531 10.5991 16.4323 9.77832 15.4198 9.77832C14.4073 9.77832 13.5865 10.5991 13.5865 11.6117C13.5865 12.6242 14.4073 13.445 15.4198 13.445Z"
        fill="#5D6CEB"
      />
    </svg>
  );
};

export default TextDefinitionIcon;
