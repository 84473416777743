import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import axios from "../../../../axios_config";
import CertificateDownloadIcon from "../../../assets/icons/course_certificate_icons/certificate_download_icon";
import { getFormattedDate } from "../../../utils/functions/time_functions";
import { BASE_API_URL } from "../../../endpoints";

const CourseCertificateDownloadButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 25px;
  width: 100%;
  padding: 0 10px;
  border-radius: 0 0 8px 8px;
  background: #fff;
  cursor: pointer;
  transition: 0.2s;
  border-top: 1px solid #eaeaea;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  h6 {
    transition: 0.2s;
    font-family: Montserrat;
    color: #725fed;
    font-size: 12px;
    font-weight: 600;
    line-height: 125%;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 3px;
  left: 8px;
  text-align: center;
  color: red;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const CourseCertificateButton = ({ courseData, userCourse, userData }) => {
  const { t } = useTranslation();

  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState("");

  const certificateDate = getFormattedDate(
    userCourse?.course_completed_at || new Date().toISOString()
  );

  const onClickDownloadCertificate = useCallback(
    async (event) => {
      event.stopPropagation();
      setIsDownloading(true);
      setError(null);

      try {
        const certificateData = {
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
          course_name: courseData.course_name,
          certificate_description:
            courseData.course_certificate?.certificate_description,
          certificate_type: courseData.course_certificate?.certificate_image,
          course_completed_at: certificateDate,
        };

        const response = await axios.post(
          `${BASE_API_URL}/courses/certificate`,
          certificateData,
          { responseType: "blob" }
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "certificate.pdf");
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setError(t("user-profile-page.activity.course.certificate-btn.error"));
      } finally {
        setIsDownloading(false);
      }
    },
    [userData, courseData, certificateDate]
  );

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 4000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <CourseCertificateDownloadButton
      onClick={onClickDownloadCertificate}
      disabled={isDownloading}
    >
      <h6>
        {isDownloading
          ? t("user-profile-page.activity.course.certificate-btn.loading")
          : t("user-profile-page.activity.course.certificate-btn.download")}
      </h6>
      <CertificateDownloadIcon color={"#725fed"} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CourseCertificateDownloadButton>
  );
};

export default CourseCertificateButton;
