import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LESSON_COMPONENTS_MAPPING } from "../../common/components/lesson/lesson_utils";
import SpinnerLoader from "../../common/components/loaders/spinner_loader";
import {
  handleBibleVerseClick,
  handleDefinitionClick,
} from "../../common/components/text_editor/text_editor_functions";
import { createPortal } from "react-dom";
import LessonTextDefinitionModal from "../../common/components/lesson/lesson_text_definition_modal";

const LessonComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 80px 15px 100px;
  max-width: 620px;
  margin: 0 auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
`;

const SharedLessonComponentsList = ({
  userData,
  isLoading,
  lessonData,
  appColorTheme,
  authors,
  courseMainColor,
  courseMainGradient,
  setTextBibleModalData,
}) => {
  const containerRef = useRef(null);
  const [textEditorComponentHtml, setTextEditorComponentHtml] = useState(null);
  const [textDefinitionModal, setTextDefinitionModal] = useState({});

  useEffect(() => {
    const container = containerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");
    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [textEditorComponentHtml]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const definitionElements = container.querySelectorAll(".highlighted-text");
    if (!definitionElements.length) return;

    const handleClick = (event) => {
      handleDefinitionClick(event, (definitionId) => {
        const definitionData = lessonData?.lesson_vocabulary?.[definitionId];
        if (definitionData) {
          setTextDefinitionModal(definitionData);
        }
      });
    };

    definitionElements.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      definitionElements.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [textEditorComponentHtml, lessonData?.lesson_vocabulary]);

  return (
    <>
      {!isLoading ? (
        <LessonComponentList ref={containerRef}>
          {lessonData?.lesson_data?.map((component) => {
            const CurrentLessonComponent =
              LESSON_COMPONENTS_MAPPING?.[component?.type]?.component;

            return (
              <CurrentLessonComponent
                key={component.id}
                userData={userData}
                componentData={component}
                withAnimation={false}
                lessonIsFinished={true}
                authors={authors}
                setLessonData={() => {}}
                setTextEditorComponentHtml={setTextEditorComponentHtml}
                setTextBibleModalData={setTextBibleModalData}
                inComponent="user_lesson"
                colorTheme={appColorTheme}
                courseMainColor={courseMainColor}
                courseMainGradient={courseMainGradient}
                setUserAnswersData={() => {}}
              />
            );
          })}
        </LessonComponentList>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {!!textDefinitionModal.text &&
        createPortal(
          <LessonTextDefinitionModal
            textDefinitionModal={textDefinitionModal}
            userData={userData}
            setOpen={setTextDefinitionModal}
            setTextBibleModalData={setTextBibleModalData}
            colorTheme={appColorTheme}
            courseMainColor={courseMainColor}
            courseMainGradient={courseMainGradient}
          />,
          document.body
        )}
    </>
  );
};

export default memo(SharedLessonComponentsList);
