import React, { memo } from "react";
import styled from "styled-components";
import { getPluralForm } from "../../../common/utils/functions/admin_page_functions";
import Feedback from "./admin_course_feedback";

const FeedbackContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  background: #f9f9f9;
  border: 1px solid #ddd;
`;

const FeedbackCounter = styled.p`
  color: #000;
  text-align: left;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px;
`;

const Feedbacks = ({ feedbacks }) => {
  const wordForms = ["отзыв", "отзыва", "отзывов"];
  const feedbacksCounter = getPluralForm(feedbacks.length, wordForms);

  return (
    <div>
      <FeedbackCounter>{feedbacksCounter}</FeedbackCounter>
      <ul>
        {feedbacks.map((feedback) => (
          <Feedback key={feedback._id} feedback={feedback} />
        ))}
      </ul>
    </div>
  );
};

export default memo(Feedbacks);
