import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../axios_config";
import UserLocationSelect from "../../common/components/user_location_select";
import InputControlButtons from "./input_control_buttons";
import { BASE_API_URL } from "../../common/endpoints";
import LogoutIcon from "../../common/assets/icons/user_profile_page_icons/logout_icon";
import { useTranslation } from "react-i18next";

const UserProfileGeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 432px;
  margin: 0 auto;
`;

const UserNameInputs = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

const TextInput = styled.input`
  height: 40px;
  width: 100%;
  padding: 0 15px;
  border-radius: 8px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  transition: 0.2s;

  &:focus,
  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const GeneralInfoItem = styled.div`
  h6 {
    color: #828282;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.1px;
  }
`;

const LogoutButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 20px;
  color: #828282;
  user-select: none;
`;

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.12);
  }
`;

const UserProfileGeneral = ({ userData, fetchUserData }) => {
  const { t } = useTranslation();

  const [profileGeneralData, setProfileGeneralData] = useState({});
  const [profileGeneralDataMemo, setProfileGeneralDataMemo] = useState({});

  useEffect(() => {
    localStorage.setItem("lastProfileTab", "general");
  }, []);

  const showNameControlButtons =
    profileGeneralDataMemo.name !== profileGeneralData?.name;
  const showSecondNameControlButtons =
    profileGeneralDataMemo.secondName !== profileGeneralData?.secondName;
  const showContactControlButtons =
    profileGeneralDataMemo.phone !== profileGeneralData?.phone;

  useEffect(() => {
    if (!userData.user_name) return;

    const currentUserData = {
      name: userData.user_name,
      secondName: userData.user_second_name,
      country: userData.user_country,
      city: userData.user_city,
      phone: userData.user_phone,
      countryId: userData.user_place_details?.country?.place_id || null,
      cityId: userData.user_place_details?.locality?.place_id || null,
    };

    setProfileGeneralData(currentUserData);
    setProfileGeneralDataMemo(currentUserData);
  }, [userData]);

  const handleUserGeneralData = useCallback(
    (key, value) => {
      setProfileGeneralData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setProfileGeneralData]
  );

  const onClickCancelChanges = useCallback(
    (event, param) => {
      event.stopPropagation();

      const paramMapping = {
        country: {
          country: profileGeneralDataMemo.country,
          countryId: profileGeneralDataMemo.countryId,
          city: profileGeneralDataMemo.city,
          cityId: profileGeneralDataMemo.cityId,
        },
        city: {
          city: profileGeneralDataMemo.city,
          cityId: profileGeneralDataMemo.cityId,
        },
        name: { name: profileGeneralDataMemo.name },
        secondName: { secondName: profileGeneralDataMemo.secondName },
        phone: { phone: profileGeneralDataMemo.phone },
      };

      const memoParamData = paramMapping[param];

      if (memoParamData) {
        setProfileGeneralData((prevState) => ({
          ...prevState,
          ...memoParamData,
        }));
      }
    },
    [setProfileGeneralData, profileGeneralDataMemo]
  );

  const onClickSaveChanges = useCallback(
    async (event, param) => {
      event.stopPropagation();

      if (!userData._id) return;

      try {
        const saveParamMapping = {
          country: {
            user_country: profileGeneralData.country,
            user_country_place_id: profileGeneralData.countryId,
            user_city: "",
            user_locality_place_id: null,
          },
          city: {
            user_city: profileGeneralData.city,
            user_locality_place_id: profileGeneralData.cityId,
            user_country: profileGeneralData.country,
            user_country_place_id: profileGeneralData.countryId,
          },
          name: { user_name: profileGeneralData.name },
          secondName: { user_second_name: profileGeneralData.secondName },
          phone: { user_phone: profileGeneralData.phone },
        };

        const updatedParamData = saveParamMapping[param];

        await axios.patch(
          `${BASE_API_URL}/users/${userData._id}`,
          updatedParamData
        );

        fetchUserData();
      } catch (error) {
        console.error(error);
      }
    },
    [profileGeneralData, setProfileGeneralDataMemo, userData]
  );

  const onClickLogoutButton = useCallback((event) => {
    event.stopPropagation();
    localStorage.removeItem("access_token");
    fetchUserData();
  }, []);

  return (
    <UserProfileGeneralContainer>
      <UserNameInputs>
        <InputWrapper>
          <TextInput
            placeholder={t("user-profile-page.general.name-placeholder")}
            value={profileGeneralData?.name || ""}
            onChange={(e) => handleUserGeneralData("name", e.target.value)}
            type={"name"}
          />
          {showNameControlButtons && (
            <InputControlButtons
              onClickCancelButton={(e) => onClickCancelChanges(e, "name")}
              onClickSaveButton={(e) => onClickSaveChanges(e, "name")}
              showSaveButton={profileGeneralData.name.length > 2}
            />
          )}
        </InputWrapper>

        <InputWrapper>
          <TextInput
            placeholder={t("user-profile-page.general.second-name-placeholder")}
            value={profileGeneralData?.secondName || ""}
            onChange={(e) =>
              handleUserGeneralData("secondName", e.target.value)
            }
            type={"secondName"}
          />
          {showSecondNameControlButtons && (
            <InputControlButtons
              onClickCancelButton={(e) => onClickCancelChanges(e, "secondName")}
              onClickSaveButton={(e) => onClickSaveChanges(e, "secondName")}
            />
          )}
        </InputWrapper>
      </UserNameInputs>

      <GeneralInfoItem>
        <h6>{t("user-profile-page.general.location-label")}</h6>
        <InputWrapper>
          <UserLocationSelect
            inComponent="user_profile"
            userData={userData}
            handleUserData={handleUserGeneralData}
            onClickCancelChanges={onClickCancelChanges}
            onClickSaveChanges={onClickSaveChanges}
          />
        </InputWrapper>
      </GeneralInfoItem>

      <GeneralInfoItem>
        <h6>{t("user-profile-page.general.contacts-label")}</h6>
        <InputWrapper>
          <TextInput
            placeholder={t("user-profile-page.general.contacts-label")}
            value={profileGeneralData?.phone || ""}
            onChange={(e) => handleUserGeneralData("phone", e.target.value)}
            type={"phone"}
          />
          {showContactControlButtons && (
            <InputControlButtons
              onClickCancelButton={(e) => onClickCancelChanges(e, "phone")}
              onClickSaveButton={(e) => onClickSaveChanges(e, "phone")}
            />
          )}
        </InputWrapper>
      </GeneralInfoItem>

      <LogoutButtonContainer>
        <LogoutButton onClick={onClickLogoutButton}>
          <LogoutIcon />
        </LogoutButton>
        {t("user-profile-page.general.logout-btn")}
      </LogoutButtonContainer>
    </UserProfileGeneralContainer>
  );
};

export default memo(UserProfileGeneral);
