import React, { memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../../common/components/checkbox";

const QuestionSettingsBibleContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #fff;
`;

const AddedBibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  color: #383838;
  font-size: 14px;
  opacity: 0.4;
  cursor: not-allowed;
  ${({ enabled }) =>
    enabled &&
    `opacity: 1;
    cursor: pointer;

  &:hover {
    background: rgb(223, 223, 223);
    transition: all 0.2s ease 0s;
  }`}
`;

const QuestionSettingsBible = ({
  settingsBlockData,
  setSettingsBlockData,
  setOpenBibleModalWindow,
  setBibleVersesData,
}) => {
  const withBibleVerse = settingsBlockData.with_bible;

  const handleOpenBibleModalWindow = useCallback(
    (event) => {
      event.stopPropagation();
      if (withBibleVerse) {
        setOpenBibleModalWindow(true);
        setBibleVersesData(settingsBlockData?.data?.bible_verse);
      }
    },
    [settingsBlockData, withBibleVerse]
  );

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();

    setSettingsBlockData((prevState) => {
      const newWithBible = !prevState.with_bible;

      const updatedState = {
        ...prevState,
        with_bible: newWithBible,
        data: newWithBible
          ? prevState.data
          : { ...prevState.data, bible_verse: {} },
      };

      setTimeout(() => {
        if (newWithBible) {
          setOpenBibleModalWindow(true);
        } else {
          setBibleVersesData({});
        }
      }, 0);

      return updatedState;
    });
  }, []);

  return (
    <QuestionSettingsBibleContainer>
      <Checkbox checked={withBibleVerse} onClickCheckbox={onClickCheckbox}>
        Библия
      </Checkbox>

      <AddedBibleVerseAddress
        enabled={withBibleVerse}
        onClick={(e) => handleOpenBibleModalWindow(e, withBibleVerse)}
      >
        {settingsBlockData?.data?.bible_verse?.verseAddress || "Выбрать текст"}
      </AddedBibleVerseAddress>
    </QuestionSettingsBibleContainer>
  );
};

export default memo(QuestionSettingsBible);
