import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Virtuoso } from "react-virtuoso";
import UserQuestion from "./user_question";
import SpinnerLoader from "../../../../../common/components/loaders/spinner_loader";
import ScrollToTopVirtuoso from "../../../../../common/components/scroll_to_top_virtuoso";
import { useAppContext } from "../../../../../app_context";

const UsersQuestionsListContainer = styled.div`
  position: relative;
  flex: 1;
  height: ${({ showAppHeader }) =>
    showAppHeader ? "calc(100vh - 220px)" : "calc(100vh - 136px)"};
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    height: ${({ showAppHeader }) =>
      showAppHeader ? "calc(100vh - 260px)" : "calc(100vh - 200px)"};
  }

  @media (max-width: 650px) {
    height: ${({ showAppHeader }) =>
      showAppHeader ? "calc(100vh - 270px)" : "calc(100vh - 165px)"};
  }
`;

const UsersQuestionsCount = styled.div`
  color: #000;
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  max-width: 635px;
  margin-left: 70px;
  padding-bottom: 6px;
  text-align: end;

  @media (max-width: 1250px) {
    margin-left: 50px;
  }

  @media (max-width: 1200px) {
    margin: 8px auto 0;
  }

  @media (max-width: 650px) {
    margin: 4px 15px 0;
    padding-bottom: 3px;
  }
`;

const QuestionsListIsEmpty = styled.div`
  text-align: center;
  color: #383838;
  padding: 30px;
  font-size: 16px;
  margin-left: 70px;
  width: 670px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65%;
  width: 628px;
  margin-left: 70px;
`;

const UsersQuestionsList = ({
  questionsList,
  questionsFilterSettings,
  questionSettingsData,
  questionSettingsMemoData,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  showUsersName,
  setOpenEditQuestionModal,
  setQuestionsFilterSettings,
  setTextBibleModalData,
  isLoading,
  fetchQuestions,
}) => {
  const virtuosoRef = useRef(null);
  const { showAppHeader } = useAppContext();

  const [filteredQuestionsList, setFilteredQuestionsList] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const [shouldShowScroll, setShouldShowScroll] = useState(false);

  const topMostIndex = Math.max(
    filteredQuestionsList.findIndex(
      (question) => question._id === questionSettingsData._id
    ),
    0
  );

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowMessage(true), 0);
    }
  }, [isLoading]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsScrollVisible(shouldShowScroll);
    }, 100);

    return () => clearTimeout(timeout);
  }, [shouldShowScroll]);

  const scrollToTop = () => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex(0);
    }
  };

  useEffect(() => {
    setIsScrollVisible(false);
    scrollToTop();
  }, [filteredQuestionsList.length]);

  useEffect(() => {
    let filtered = [...questionsList];

    if (questionsList.length) {
      const statusFilter = questionsFilterSettings?.question_status;
      const placeFilter = questionsFilterSettings?.question_place;
      const recipientFilter = questionsFilterSettings?.question_recipient || [];
      const searchWords = questionsFilterSettings?.search_words || [];
      const questionBible = questionsFilterSettings?.question_bible || [];
      const dateRange = questionsFilterSettings?.question_date_range || [];
      const answerDateFilter = questionsFilterSettings?.question_by_answer_date;
      const questionAuthorId = questionsFilterSettings?.question_by_author?._id;
      const byFavorite = questionsFilterSettings?.question_by_favorite;

      if (statusFilter !== "") {
        filtered = filtered.filter(
          (question) => question.question_status === statusFilter
        );
      }

      if (recipientFilter.length > 0) {
        filtered = filtered.filter((question) =>
          recipientFilter.some(
            (recipient) =>
              (recipient._id === "without_recipient" &&
                !question.question_to_author?._id) ||
              (recipient._id !== "without_recipient" &&
                recipient._id === question.question_to_author?._id)
          )
        );
      }

      if (placeFilter) {
        filtered = filtered.filter(
          (question) =>
            question.question_from_place?.toLowerCase().trim() ===
            placeFilter.toLowerCase().trim()
        );
      }

      if (searchWords.length > 0) {
        filtered = filtered.filter((question) => {
          const questionText =
            question.question_text?.toLowerCase().trim() || "";
          const questionTags =
            question.question_tags
              ?.toLowerCase()
              .trim()
              .split(/\s*,\s*/) || [];

          const userEmail =
            question.question_from_author?.user_email?.toLowerCase().trim() ||
            "";
          const userName =
            question.question_from_author?.user_name?.toLowerCase().trim() ||
            "";
          const userSecondName =
            question.question_from_author?.user_second_name
              ?.toLowerCase()
              .trim() || "";

          const cleanedSearchWords = searchWords.map((word) =>
            word.trim().toLowerCase()
          );

          const matchesNormal = cleanedSearchWords.some((word) => {
            return (
              questionText.includes(word) ||
              questionTags.some((tag) => tag.includes(word)) ||
              userEmail.includes(word) ||
              userName.includes(word) ||
              userSecondName.includes(word)
            );
          });

          let matchesNameSurname = false;

          cleanedSearchWords.forEach((searchQuery) => {
            const words = searchQuery.split(/\s+/).filter(Boolean);

            if (words.length === 2) {
              const [firstName, lastName] = words;

              if (
                userName.includes(firstName) &&
                userSecondName.includes(lastName)
              ) {
                matchesNameSurname = true;
              }
            }
          });

          return matchesNormal || matchesNameSurname;
        });
      }

      if (questionBible.length > 0) {
        filtered = filtered.filter((question) => {
          return questionBible.some((filter) => {
            const matchingVerse = question.question_bible_verses.find(
              (verse) => {
                return (
                  verse.versesParams.bookId === filter.bibleBookCode &&
                  (!filter.bibleChapters.length ||
                    filter.bibleChapters.includes(verse.versesParams.chapterId))
                );
              }
            );
            return matchingVerse;
          });
        });
      }

      if (dateRange.length > 0) {
        filtered = filtered.filter((question) => {
          const createdAt = new Date(question.question_created_at);
          return dateRange.some((range) => {
            if (!range.year) return true;
            const questionYear = createdAt.getFullYear();
            if (range.year !== questionYear) return false;
            if (range.monthOfYear !== null) {
              const questionMonth = createdAt.getMonth();
              if (range.monthOfYear !== questionMonth) return false;
            }
            if (
              range.dateRange.startRangeDate &&
              range.dateRange.endRangeDate
            ) {
              const startDate = new Date(range.dateRange.startRangeDate);
              startDate.setHours(0, 0, 0, 0);
              const endDate = new Date(range.dateRange.endRangeDate);
              endDate.setHours(23, 59, 59, 999);
              return createdAt >= startDate && createdAt <= endDate;
            }
            return true;
          });
        });
      }

      if (statusFilter === "answered" && answerDateFilter) {
        filtered = filtered.sort((a, b) => {
          const dateA = new Date(a.question_answer?.answer_created_at);
          const dateB = new Date(b.question_answer?.answer_created_at);

          if (answerDateFilter === "newest") {
            return dateB - dateA;
          } else if (answerDateFilter === "oldest") {
            return dateA - dateB;
          }
          return 0;
        });
      }

      if (questionAuthorId) {
        filtered = filtered.filter(
          (question) => question.question_from_author?._id === questionAuthorId
        );
      }

      if (byFavorite) {
        filtered = filtered.filter(
          (question) => question?.question_is_favorite
        );
      }
    }

    setFilteredQuestionsList(filtered);
  }, [questionsList, questionsFilterSettings]);

  return (
    <UsersQuestionsListContainer showAppHeader={showAppHeader}>
      {!isLoading && (
        <UsersQuestionsCount>
          Найдено: {filteredQuestionsList?.length || 0}
        </UsersQuestionsCount>
      )}

      {!isLoading ? (
        <>
          {filteredQuestionsList?.length > 0 ? (
            <Virtuoso
              ref={virtuosoRef}
              style={{ height: "100%", flex: 1 }}
              totalCount={filteredQuestionsList.length}
              atTopStateChange={(atTop) => {
                setIsScrollVisible(!atTop);
              }}
              rangeChanged={({ startIndex }) => {
                setShouldShowScroll(startIndex > 5);
              }}
              components={{
                Footer: () => <div style={{ height: "60px" }} />,
              }}
              initialTopMostItemIndex={topMostIndex}
              itemContent={(index) => {
                const question = filteredQuestionsList[index];
                return (
                  <UserQuestion
                    key={question._id}
                    question={question}
                    showUsersName={showUsersName}
                    setQuestionSettingsData={setQuestionSettingsData}
                    setQuestionSettingsMemoData={setQuestionSettingsMemoData}
                    setOpenEditQuestionModal={setOpenEditQuestionModal}
                    questionSettingsData={questionSettingsData}
                    questionSettingsMemoData={questionSettingsMemoData}
                    questionsFilterSettings={questionsFilterSettings}
                    setTextBibleModalData={setTextBibleModalData}
                    setQuestionsFilterSettings={setQuestionsFilterSettings}
                    fetchQuestions={fetchQuestions}
                  />
                );
              }}
            />
          ) : (
            showMessage && (
              <QuestionsListIsEmpty>
                По заданным фильтрам вопрос не найден
              </QuestionsListIsEmpty>
            )
          )}
        </>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      <ScrollToTopVirtuoso isVisible={isScrollVisible} onClick={scrollToTop} />
    </UsersQuestionsListContainer>
  );
};

export default memo(UsersQuestionsList);
