import React, { memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import AdminPageCalendarArrow from "../../../../../../common/assets/icons/admin_page_icons/admin_page_calendar_arrow";
import RangeCalendars from "./range_calendars";

export const monthsOfYear = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const CalendarDropdownMonthsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    overflow: auto;
    max-height: calc(100vh - 90px);
  }
`;

const CalendarDropdownMonthsList = styled.div`
  padding: 10px 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: space-between;

  @media (max-width: 800px) {
    justify-content: start;
  }
`;

const CalendarDropdownMonth = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 0px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "rgb(97 105 236)" : "#cacaca")};
  transition: 0.2s;

  &:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

  @media (max-width: 500px) {
    height: 36px;
    font-size: 14px;
  }
`;

const CalendarArrowDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  height: 18px;
  width: 20px;
  transform: ${({ extendedRangeCalendars }) =>
    extendedRangeCalendars ? "rotate(90deg)" : "rotate(-90deg)"};
  cursor: pointer;
  transition: 0.2s;

  &: hover {
    transform: scale(1.2)
      ${({ extendedRangeCalendars }) =>
        extendedRangeCalendars ? "rotate(90deg)" : "rotate(-90deg)"};
  }
`;

const CalendarDropdownMonths = ({
  selectedMonth,
  setSelectedMonth,
  endRangeDate,
  setEndRangeDate,
  startRangeDate,
  setStartRangeDate,
  extendedRangeCalendars,
  setExtendedRangeCalendars,
}) => {
  useEffect(() => {
    if (!extendedRangeCalendars) {
      setStartRangeDate(null);
      setEndRangeDate(null);
    } else {
      setStartRangeDate(new Date());
      setEndRangeDate(new Date());
      setSelectedMonth(null);
    }
  }, [extendedRangeCalendars]);

  useEffect(() => {
    if (selectedMonth) {
      setExtendedRangeCalendars(false);
    }
  }, [selectedMonth]);

  const handleExtendRangeCalendars = useCallback(() => {
    setExtendedRangeCalendars((prevState) => !prevState);
  }, []);

  const handleSelectMonth = useCallback(
    (month) => {
      if (month === selectedMonth) {
        setSelectedMonth(null);
      } else {
        setSelectedMonth(month);
      }
    },
    [selectedMonth]
  );

  return (
    <CalendarDropdownMonthsContainer>
      <CalendarDropdownMonthsList>
        {monthsOfYear.map((month, index) => {
          return (
            <CalendarDropdownMonth
              key={month}
              selected={index === selectedMonth}
              onClick={() => handleSelectMonth(index)}
            >
              {month}
            </CalendarDropdownMonth>
          );
        })}
      </CalendarDropdownMonthsList>

      <CalendarArrowDown
        extendedRangeCalendars={extendedRangeCalendars}
        onClick={handleExtendRangeCalendars}
      >
        <AdminPageCalendarArrow />
      </CalendarArrowDown>

      {extendedRangeCalendars && (
        <RangeCalendars
          startRangeDate={startRangeDate}
          setStartRangeDate={setStartRangeDate}
          endRangeDate={endRangeDate}
          setEndRangeDate={setEndRangeDate}
        />
      )}
    </CalendarDropdownMonthsContainer>
  );
};

export default memo(CalendarDropdownMonths);
