import React, { memo } from "react";
import styled from "styled-components";
import AdminPagePersonPreview from "../../admin_page_components/admin_page_person_preview";
import QuestionRecipient from "../../../../common/components/modal_windows/ask_question_modal_window/question_recipient";

const QuestionSettingsPerson = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #828282;
  font-size: 10px;
  line-height: 110%;
`;

const QuestionSettingsContacts = ({
  questionSettingsData,
  setSelectedUserInfo,
  setQuestionSettingsData,
  showUsersName,
  userData,
}) => {
  const userMentorId = questionSettingsData.question_from_author_mentor?._id;

  return (
    <>
      {showUsersName && (
        <QuestionSettingsPerson>
          Ученик
          <AdminPagePersonPreview
            personId={questionSettingsData.question_from_author?._id}
            setSelectedUserInfo={setSelectedUserInfo}
            withoutBorder={true}
            component={"question"}
          />
        </QuestionSettingsPerson>
      )}

      {userMentorId && (
        <QuestionSettingsPerson>
          Наставник
          <AdminPagePersonPreview
            personId={userMentorId}
            setSelectedUserInfo={setSelectedUserInfo}
            withoutBorder={true}
            component={"question"}
          />
        </QuestionSettingsPerson>
      )}

      <QuestionSettingsPerson key={questionSettingsData._id}>
        Адресат
        <QuestionRecipient
          userQuestionData={questionSettingsData}
          setUserQuestionData={setQuestionSettingsData}
          userData={userData}
          color={"#383838"}
          fontSize={"14px"}
        />
      </QuestionSettingsPerson>
    </>
  );
};

export default memo(QuestionSettingsContacts);
