import React, { createContext, useContext, useState } from "react";

const BibleModalWindowContext = createContext();

export const useBibleModalWindowState = () => {
  const [showStrongsCode, setShowStrongsCode] = useState(false);
  const [strongsCodeModalData, setStrongsCodeModalData] = useState(null);
  const [currentTestament, setCurrentTestament] = useState(""); // ot, nt

  return {
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  };
};

export const BibleModalWindowProvider = ({ children }) => {
  const {
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  } = useBibleModalWindowState();

  return (
    <BibleModalWindowContext.Provider
      value={{
        showStrongsCode,
        setShowStrongsCode,
        strongsCodeModalData,
        setStrongsCodeModalData,
        currentTestament,
        setCurrentTestament,
      }}
    >
      {children}
    </BibleModalWindowContext.Provider>
  );
};

export const useBibleModalWindowContext = () => {
  return useContext(BibleModalWindowContext);
};
