import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../user_data_context";
import UserProfilePageSidebar from "./user_profile_page_sidebar";
import UserProfileGeneral from "./user_profile_general";
import { useNavigate } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../common/utils/routes";
import UserProfileQuestions from "./user_profile_questions";
import { ToastNotification } from "../../common/components/toast_notification";
import UserProfileActivity from "./user_profile_activity";

const UserProfilePageContainer = styled.div`
  position: relative;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding-top: 54px;

  @media (max-width: 855px) {
    padding: 100px 12px 60px;
  }
`;

const UserProfilePage = () => {
  const {
    fetchUserData,
    userData,
    authError,
    userQuestionsList,
    unreadAnswersCount,
    fetchUserQuestions,
  } = useUserDataContext();

  const navigate = useNavigate();

  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [activeProfileSection, setActiveProfileSection] = useState(() => {
    return localStorage.getItem("lastProfileTab") || "general";
  }); // general, security, activity, questions

  useEffect(() => {
    if (authError) {
      navigate(APPLICATION_ROUTES.HOME);
      return;
    }

    const lastTab = localStorage.getItem("lastProfileTab");

    if (lastTab) {
      setActiveProfileSection(lastTab);
    }
  }, [authError]);

  useEffect(() => {
    const loadQuestions = async () => {
      if (!userData._id) return;
      setLoadingQuestions(true);
      try {
        await fetchUserQuestions();
      } catch (error) {
        console.error("Ошибка загрузки вопросов:", error);
      } finally {
        setLoadingQuestions(false);
      }
    };

    loadQuestions();
  }, [userData?._id]);

  if (!userData._id) return;

  return (
    <UserProfilePageContainer>
      <UserProfilePageSidebar
        activeProfileSection={activeProfileSection}
        setActiveProfileSection={setActiveProfileSection}
        unreadAnswersCount={unreadAnswersCount}
      />

      {activeProfileSection === "general" && (
        <UserProfileGeneral userData={userData} fetchUserData={fetchUserData} />
      )}

      {activeProfileSection === "questions" && (
        <UserProfileQuestions
          userQuestionsList={userQuestionsList}
          loadingQuestions={loadingQuestions}
          fetchUserQuestions={fetchUserQuestions}
        />
      )}

      {activeProfileSection === "activity" && (
        <UserProfileActivity userData={userData} />
      )}

      <ToastNotification />
    </UserProfilePageContainer>
  );
};

export default memo(UserProfilePage);
