import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BASE_API_URL } from "../../../endpoints";
import BlackArrowDownIcon from "../../../assets/icons/admin_page_icons/black_arrow_down_icon";
import CourseLesson from "./course_lesson";
import CourseCertificateButton from "./course_certificate_button";

const CourseActivityContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }
`;

const CourseActivityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  padding: ${({ inUserProfile }) =>
    inUserProfile ? "8px 12px 8px 8px" : "8px 15px"};
  height: ${({ inUserProfile }) => (inUserProfile ? "max-content" : "42px")};
  background: ${({ inUserProfile }) =>
    inUserProfile
      ? "linear-gradient(249deg, rgba(219, 50, 234, 0.3) -11.61%, rgba(61, 26, 201, 0.3) 109.78%)"
      : "#fff"};
  cursor: pointer;
`;

const CourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  user-select: none;

  h5 {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }

  h6 {
    color: #383838;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
  }
`;

const CourseActivityHeaderArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  transform: scale(1.12);

  ${({ isExtended }) =>
    isExtended &&
    `
    padding-bottom: 3px;
    transform: rotate(180deg) scale(1.12);
  `}
`;

const CourseImage = styled.div`
  width: 73px;
  height: 45px;
  background-size: cover;
  background-position: center;
  background-color: rgba(230, 230, 230);
  ${({ image }) => image && `background-image: url(${image});`}
  border-radius: 3px;
`;

const CourseActivity = ({
  userCourse,
  userData = {},
  inComponent = "",
  setLessonIsFinishedModalData = () => {},
}) => {
  const { t } = useTranslation();

  const [extendCourseInfo, setExtendCourseInfo] = useState(false);
  const [courseData, setCourseData] = useState({});
  const [authors, setAuthors] = useState([]);
  const [authorsList, setAuthorsList] = useState("");

  const inUserProfile = inComponent === "user_profile";
  const image = courseData?.course_preview_image;
  const courseAuthors = courseData?.course_authors;

  const finishedLessonsSet = new Set(
    userCourse?.course_lessons?.map((l) => l._id)
  );

  const unfinishedLessonsArray =
    courseData?.course_lessons
      ?.filter((lesson) => !finishedLessonsSet.has(lesson._id))
      ?.map((lesson) => ({
        _id: lesson._id,
        lesson_type: lesson.lesson_type || "lesson",
        lesson_status: "unfinished",
      })) || [];

  const allLessonsList = [
    ...userCourse.course_lessons,
    ...unfinishedLessonsArray,
  ];

  const courseWithCertificate =
    courseData.course_certificate?.certificate_is_enabled;

  const showCertificateDownloadButton =
    inUserProfile &&
    courseWithCertificate &&
    userCourse?.course_status === "finished";

  const fetchCourse = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/courses/${userCourse?.course_id}`
      );
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [userCourse]);

  useEffect(() => {
    if (userCourse?.course_id) {
      fetchCourse();
    }
  }, [userCourse]);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users?is_author=true`);
      setAuthors(response.data);
    } catch (error) {
      console.error("Ошибка при запросе авторов:", error);
    }
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  useEffect(() => {
    if (!authors?.length || !courseAuthors?.length) {
      setAuthorsList("");
      return;
    }

    const courseAuthorsNames = courseAuthors
      .map((courseAuthor) => {
        const author = authors.find((a) => a._id === courseAuthor._id);
        return author
          ? `${author.user_name} ${author.user_second_name || ""}`.trim()
          : null;
      })
      .filter(Boolean)
      .join(", ");

    setAuthorsList(courseAuthorsNames);
  }, [authors, courseAuthors]);

  const handleExtendCourseInfo = useCallback((event) => {
    event.stopPropagation();
    setExtendCourseInfo((prevState) => !prevState);
  }, []);

  return (
    <CourseActivityContainer>
      <CourseActivityHeader
        inUserProfile={inUserProfile}
        onClick={handleExtendCourseInfo}
      >
        {inUserProfile && <CourseImage image={image} />}

        <CourseInfo>
          <h5>
            {t("user-profile-page.activity.course.course-title")}{" "}
            {`"${userCourse.course_name}"`}
          </h5>
          {inUserProfile && <h6>{authorsList && `(${authorsList})`}</h6>}
        </CourseInfo>

        <CourseActivityHeaderArrow isExtended={extendCourseInfo}>
          <BlackArrowDownIcon color={inUserProfile ? "#7A5AEE" : "#1E1E1E"} />
        </CourseActivityHeaderArrow>
      </CourseActivityHeader>

      {extendCourseInfo && (
        <>
          {allLessonsList.map((lesson, index) => {
            return (
              <CourseLesson
                key={index + "lesson"}
                lesson={lesson}
                index={index}
                inComponent={inComponent}
                userCourse={userCourse}
                userData={userData}
                allLessonsList={allLessonsList}
                setLessonIsFinishedModalData={setLessonIsFinishedModalData}
              />
            );
          })}

          {showCertificateDownloadButton && (
            <CourseCertificateButton
              courseData={courseData}
              userCourse={userCourse}
              userData={userData}
            />
          )}
        </>
      )}
    </CourseActivityContainer>
  );
};

export default memo(CourseActivity);
