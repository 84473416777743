import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslationsList from "../../../../../../../pages/bible/bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/translations_list";
import TranslationPanelInput from "../../../../../../../pages/bible/bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/translation_panel_input";

const ModalWindowTranslations = ({
  bibleTranslations,
  currentBibleLanguage,
  translation,
  setCurrentScreen,
  setBibleTranslations,
  setCurrentBibleParams,
  setSelectedVerses,
}) => {
  const { t } = useTranslation();

  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedTranslations, setSelectedTranslations] = useState([]);

  const getFilteredTranslation = useCallback(
    (bibleTranslations, inputSearchValue, currentBibleLanguage) => {
      const sortedTranslationByFavorite = bibleTranslations?.sort(
        (tr1, tr2) => tr2.isFavorite - tr1.isFavorite
      );
      const filteredByLanguage = sortedTranslationByFavorite?.filter(
        (translation) =>
          translation.bible_locale === currentBibleLanguage?.code ||
          translation.isFavorite
      );

      const filteredBySearch = filteredByLanguage?.filter(
        (translation) =>
          translation.bible_code
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.bible_name
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.isFavorite
      );

      return filteredBySearch;
    },
    []
  );

  useEffect(() => {
    if (bibleTranslations) {
      setSelectedTranslations(
        getFilteredTranslation(
          bibleTranslations,
          inputSearchValue,
          currentBibleLanguage
        )
      );
    }
  }, [bibleTranslations, inputSearchValue, currentBibleLanguage]);

  const onTranslationClick = useCallback(
    (translation) => {
      setCurrentBibleParams((prevState) => ({
        ...prevState,
        bibleId: translation.bible_code,
      }));
      setSelectedVerses([]);
    },
    [setCurrentBibleParams]
  );

  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    <div onClick={stopPropagationOnClick}>
      <TranslationPanelInput
        placeholder={t(
          "bible-page.translation-panel.input-placeholder.translation"
        )}
        onClickButton={() => setCurrentScreen("languages")}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
        section={"translation"}
        currentBibleLanguage={currentBibleLanguage}
      />
      <TranslationsList
        selectedTranslations={selectedTranslations}
        currentTranslation={translation}
        setBibleTranslations={setBibleTranslations}
        onTranslationClick={onTranslationClick}
      />
    </div>
  );
};

export default memo(ModalWindowTranslations);
