import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import CopyUserQuestion from "./copy_user_question";
import axios from "../../../../../axios_config";
import { BASE_API_URL } from "../../../../../common/endpoints";
import { APPLICATION_ROUTES } from "../../../../../common/utils/routes";
import { compareObjects } from "../../../../../common/utils/functions/compare_objects";
import { getFormattedDate } from "../../../../../common/utils/functions/time_functions";
import CheckIcon from "../../../../../common/assets/icons/bible_modal_window_icons/check_icon";
import ShareUserQuestion from "./share_user_question";
import QuestionModalWindow from "./question_modal_window";
import FavoriteIcon from "../../../../../common/assets/icons/chat_page_icons/favorite_icon";
import UnFavoriteIcon from "../../../../../common/assets/icons/chat_page_icons/unfavorite_icon";

const UserQuestionContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border-left: 2px solid;
  border-color: ${({ answered }) =>
    answered ? "#5172EA" : "rgba(241, 43, 43, 0.6)"};
  background: #fff;
  padding: 12px 11px;
  cursor: pointer;
  opacity: ${({ unselected }) => (unselected ? "0.5" : "1")};
  transition: 0.2s;
  margin-bottom: 16px;
  max-width: 635px;
  margin-left: 70px;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 1250px) {
    margin-left: 50px;
  }

  @media (max-width: 1200px) {
    margin: 0 auto 16px;
  }

  @media (max-width: 670px) {
    margin: 0 10px 16px;
  }
`;

const UserQuestionText = styled.div`
  color: #1e1e1e;
  line-height: 20px;
  padding-right: 55px;
  margin-bottom: 10px;
  overflow-wrap: break-word;

  @media (max-width: 670px) {
    padding-right: 10px;
    ${({ paddingTop }) => paddingTop && "padding-top: 5px;"}
  }
`;

const UserQuestionInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #cacaca;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
  margin-top: 10px;
`;

const UserQuestionDate = styled.div``;

const UserQuestionAuthor = styled.div`
  ${({ unselected }) =>
    !unselected &&
    `
   :hover {
      text-decoration: underline;
      text-underline-offset: 2px;
   }`}
`;

const HighlightedText = styled.span`
  background: #f8d25480;
`;

const UserQuestionBibleVerses = styled.div`
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const BibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 0 10px;
  color: #383838;
  font-size: 14px;
  font-style: normal;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #eaeaea;
  }
`;

const QuestionTopButtons = styled.div`
  position: absolute;
  top: 4px;
  right: 7px;
  display: flex;
  align-items: center;
  gap: 7px;
  height: max-content;
  width: max-content;
`;

const QuestionAswerRead = styled.div``;

const FavoriteButton = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    height: 16px;
  }
`;

const UnFavoriteIconStyled = styled(UnFavoriteIcon)`
  opacity: 0.4;
  transition: 0.2s;

  :hover {
    opacity: 1;
  }
`;

const QuestionCourseInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.4px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.2s;

  ${({ unselected }) =>
    !unselected &&
    `
  :hover {
    p {
      text-decoration: underline 1px #7a5aee;
    }
  }`}
`;

const CheckIconStyled = styled(CheckIcon)`
  height: 15px;
  width: 15px;
`;

const QuestionBottomButtons = styled.div`
  position: absolute;
  bottom: 10px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const UserQuestion = ({
  question,
  showUsersName,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  setOpenEditQuestionModal,
  questionSettingsData,
  questionSettingsMemoData,
  questionsFilterSettings,
  setTextBibleModalData,
  setQuestionsFilterSettings,
  fetchQuestions,
}) => {
  const navigate = useNavigate();
  const questionRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [questionIsFavorite, setQuestionIsFavorite] = useState(false);

  const unselected =
    questionSettingsData.question_text &&
    questionSettingsData._id !== question._id;

  const questionBibleVerses = question.question_bible_verses;

  const questionFromPlace = question.question_from_place;
  const { course_name, lesson_num, lesson_name } =
    question.question_from_place_data || {};

  const questionAuthorName = `${
    question.question_from_author?.user_name || ""
  } ${question.question_from_author?.user_second_name || ""}`;

  const questionAuthorNameId = question.question_from_author._id;

  const formattedDate = getFormattedDate(question.question_created_at);

  const questionAnswerRead = !!question.question_answer?.answer_read;

  useEffect(() => {
    if (questionSettingsData._id === question._id && questionRef.current) {
      questionRef.current.scrollIntoView({
        block: "center",
      });
    }
  }, []);

  useEffect(() => {
    if (question._id) {
      setQuestionIsFavorite(question.question_is_favorite);
    }
  }, [question]);

  const onClickQuestion = useCallback(
    (event) => {
      event.stopPropagation();

      if (compareObjects(questionSettingsData, questionSettingsMemoData)) {
        if (question._id === questionSettingsData._id) {
          setQuestionSettingsData({});
          setQuestionSettingsMemoData({});
          setOpenEditQuestionModal(false);

          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.delete("q");
          setSearchParams(newSearchParams);
        } else {
          setSearchParams({ q: question._id });
        }
      } else {
        setOpenEditQuestionModal(true);
      }
    },
    [question, questionSettingsData, questionSettingsMemoData, searchParams]
  );

  const handleClickVerseAddress = useCallback((event, verses) => {
    event.stopPropagation();

    const selectedVerses = verses.versesData
      .map((verse) => verse.verse_num)
      .join(",");

    const bibleParams = {
      bibleId: verses.versesParams.bibleId,
      bookId: verses.versesParams.bookId,
      chapterId: verses.versesParams.chapterId,
      selectedVerses,
    };

    setTextBibleModalData(bibleParams);
  }, []);

  const onClickQuestionCourseTitle = useCallback(
    (event) => {
      if (question.question_from_place === "course" && !unselected) {
        event.stopPropagation();
        const lessonId = question.question_from_place_data.lesson_id;

        navigate(
          `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${lessonId}?p=question&questionId=${question._id}&n=${lesson_num}`
        );
      }
    },
    [question, unselected]
  );

  const highlightWords = (text, targetWords) => {
    const words = text.split(" ");

    const targetWordsArray = targetWords
      .trim()
      .split(/\s+/)
      .map((word) => word.toLowerCase());

    return words.map((word, index) => {
      const cleanedWord = word.trim();

      const matchesTarget = targetWordsArray.some((targetWord) =>
        cleanedWord.toLowerCase().includes(targetWord)
      );

      return (
        <React.Fragment key={index}>
          {matchesTarget ? (
            <HighlightedText>{word}</HighlightedText>
          ) : (
            <span>{word}</span>
          )}
          {index < words.length - 1 && " "}
        </React.Fragment>
      );
    });
  };

  const searchWords = questionsFilterSettings.search_words.join(" ").trim();

  const highlightedText =
    searchWords.length > 0
      ? highlightWords(question.question_text, searchWords)
      : question.question_text;

  const onClickAuthorName = useCallback(
    (event) => {
      if (!unselected) {
        event.stopPropagation();

        setQuestionsFilterSettings((prevState) => ({
          ...prevState,
          question_by_author: {
            _id: questionAuthorNameId,
            author_name: questionAuthorName,
          },
        }));
      }
    },
    [
      setQuestionsFilterSettings,
      questionAuthorNameId,
      questionAuthorName,
      unselected,
    ]
  );

  const toggleQuestionFavoriteButton = useCallback(
    async (event) => {
      event.stopPropagation();

      const updatedIsFavotite = !question.question_is_favorite;

      const updatedQuestionData = {
        ...question,
        question_is_favorite: updatedIsFavotite,
      };

      try {
        await axios.patch(
          `${BASE_API_URL}/questions/${question._id}`,
          updatedQuestionData
        );

        fetchQuestions();
        setQuestionIsFavorite(updatedIsFavotite);

        if (question._id === questionSettingsData._id) {
          setQuestionSettingsData((prevState) => ({
            ...prevState,
            question_is_favorite: updatedIsFavotite,
          }));
          setQuestionSettingsMemoData((prevState) => ({
            ...prevState,
            question_is_favorite: updatedIsFavotite,
          }));
        }
      } catch (error) {
        console.error("Error making PATCH request:", error);
      }
    },
    [question, setQuestionSettingsData, setQuestionSettingsMemoData]
  );

  return (
    <UserQuestionContainer
      ref={questionRef}
      answered={question.question_status === "answered"}
      unselected={unselected}
      onClick={onClickQuestion}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {questionFromPlace === "course" && (
        <QuestionCourseInfo
          unselected={unselected}
          onClick={onClickQuestionCourseTitle}
        >
          <p>Курс {`"${course_name}"`}.</p>
          <p>
            Урок {lesson_num}. {lesson_name}
          </p>
        </QuestionCourseInfo>
      )}

      <UserQuestionText paddingTop={questionAnswerRead}>
        {highlightedText}
      </UserQuestionText>

      {!!questionBibleVerses.length && (
        <UserQuestionBibleVerses>
          {questionBibleVerses.map((verses, index) => {
            return (
              <BibleVerseAddress
                key={index}
                onClick={(e) => handleClickVerseAddress(e, verses)}
              >
                {verses.verseAddress}
              </BibleVerseAddress>
            );
          })}
        </UserQuestionBibleVerses>
      )}

      <UserQuestionInfoContainer>
        <UserQuestionDate>{formattedDate}</UserQuestionDate>
        {showUsersName && (
          <UserQuestionAuthor
            unselected={unselected}
            onClick={onClickAuthorName}
          >
            {questionAuthorName}
          </UserQuestionAuthor>
        )}
      </UserQuestionInfoContainer>

      <QuestionTopButtons>
        {questionAnswerRead && (
          <QuestionAswerRead>
            <CheckIconStyled />
          </QuestionAswerRead>
        )}

        <FavoriteButton onClick={toggleQuestionFavoriteButton}>
          {questionIsFavorite ? <FavoriteIcon /> : <UnFavoriteIconStyled />}
        </FavoriteButton>
      </QuestionTopButtons>

      <QuestionBottomButtons>
        <CopyUserQuestion question={question} unselected={unselected} />
        <ShareUserQuestion question={question} unselected={unselected} />
        <QuestionModalWindow
          question={question}
          unselected={unselected}
          setTextBibleModalData={setTextBibleModalData}
        />
      </QuestionBottomButtons>
    </UserQuestionContainer>
  );
};

export default memo(UserQuestion);
