import React from "react";

const TextAlignLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.8125 1.2C0.8125 0.537258 1.34976 0 2.0125 0H21.0125C21.6752 0 22.2125 0.537258 22.2125 1.2C22.2125 1.86274 21.6752 2.4 21.0125 2.4H2.0125C1.34976 2.4 0.8125 1.86274 0.8125 1.2Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.8125 8.2001C0.8125 7.53736 1.34976 7.0001 2.0125 7.0001H14.0125C14.6752 7.0001 15.2125 7.53736 15.2125 8.2001C15.2125 8.86284 14.6752 9.4001 14.0125 9.4001H2.0125C1.34976 9.4001 0.8125 8.86284 0.8125 8.2001Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.8125 15.8C0.8125 15.1373 1.34976 14.6 2.0125 14.6H17.0125C17.6752 14.6 18.2125 15.1373 18.2125 15.8C18.2125 16.4627 17.6752 17 17.0125 17H2.0125C1.34976 17 0.8125 16.4627 0.8125 15.8Z"
        fill="#383838"
      />
    </svg>
  );
};

export default TextAlignLeftIcon;
