import React from "react";

const QuestionLinkIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_10675_15759)">
        <path
          d="M13.633 5.95096e-05C14.7829 2.50768e-05 15.8688 0.455683 16.7066 1.29346C17.5839 2.17074 18.0421 3.32017 17.997 4.53007C17.9503 5.77963 17.3858 6.97997 16.3644 8.00132L14.5255 9.84776C14.2515 10.1229 13.8063 10.1238 13.5311 9.8498C13.256 9.57579 13.2551 9.13058 13.5291 8.85544L15.369 7.00795C16.86 5.51694 17.0007 3.57636 15.7122 2.28781C14.4236 0.999163 12.483 1.13989 10.9931 2.62995L8.46583 5.15722C7.69982 5.92321 7.27738 6.79786 7.24423 7.68654C7.21382 8.50111 7.52615 9.27879 8.12366 9.87631C8.32387 10.0765 8.54539 10.2464 8.7821 10.3812C9.11953 10.5734 9.23727 11.0027 9.04507 11.3401C8.85283 11.6776 8.42347 11.7953 8.08611 11.6031C7.74025 11.4061 7.41832 11.1597 7.12927 10.8706C6.25202 9.99338 5.79379 8.84395 5.83893 7.63405C5.88558 6.38449 6.45008 5.18412 7.47144 4.1628L9.99871 1.63553C11.0201 0.614167 12.2204 0.0497003 13.47 0.0030121C13.5244 0.00107804 13.5788 5.95143e-05 13.633 5.95096e-05ZM8.95486 6.65991C8.76266 6.99733 8.88043 7.4267 9.21782 7.61886C9.45453 7.75368 9.67609 7.92356 9.87627 8.1237C11.1648 9.41228 11.0241 11.3529 9.53413 12.8429L7.00685 15.3701C5.51683 16.8601 3.57628 17.0008 2.28777 15.7122C0.999227 14.4237 1.13992 12.4831 2.63093 10.9921L4.47086 9.1446C4.7449 8.86947 4.74399 8.42425 4.46882 8.15025C4.19369 7.8762 3.74851 7.87719 3.47447 8.15229L1.63552 9.99876C0.61416 11.0201 0.0496936 12.2205 0.00300559 13.47C-0.0421355 14.6799 0.41609 15.8293 1.29334 16.7066C2.17063 17.5839 3.32066 18.042 4.52996 17.997C5.77952 17.9503 6.97985 17.3858 8.00118 16.3645L10.5285 13.8372C11.5498 12.8159 12.1143 11.6155 12.161 10.366C12.2061 9.1561 11.7479 8.00663 10.8707 7.12935C10.5816 6.84036 10.2597 6.59395 9.91381 6.3969C9.80398 6.33436 9.68445 6.30462 9.56647 6.30462C9.32203 6.30465 9.08451 6.43234 8.95486 6.65991Z"
          fill="#CACACA"
        />
      </g>
      <defs>
        <clipPath id="clip0_10675_15759">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(18 18) rotate(180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuestionLinkIcon;
