import React from "react";
import CertificateCloseIcon from "../../../../common/assets/icons/course_certificate_icons/certificate_close_icon";
import styled from "styled-components";
import CertificateDownloadIcon from "../../../../common/assets/icons/course_certificate_icons/certificate_download_icon";
import { useTranslation } from "react-i18next";

const CertificateHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 13px 0 25px;
`;

const CertificateDownloadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 18px;
  color: #5172ea;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  cursor: pointer;
  transition: text-underline-offset 0.2s ease, text-decoration-color 0.2s ease;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 2px;
  color: red;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const CertificateCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 21.458px;
  height: 21.458px;
  background: #5172ea;
  box-shadow: 0px 0.894px 8.941px 0px rgba(0, 0, 0, 0.15);
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    transform: scale(1.05);
  }
`;

const CertificateHeaderButtons = ({
  isDownloading,
  onClickDownloadCertificate,
  error,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CertificateHeaderButtonsContainer>
      <CertificateDownloadButton
        onClick={onClickDownloadCertificate}
        disabled={isDownloading}
      >
        {isDownloading
          ? t("lesson-page.certificate-modal.certificate-btn.loading")
          : t("lesson-page.certificate-modal.certificate-btn.download")}
        <CertificateDownloadIcon color={"#5172EA"} />
      </CertificateDownloadButton>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <CertificateCloseButton onClick={onClose}>
        <CertificateCloseIcon />
      </CertificateCloseButton>
    </CertificateHeaderButtonsContainer>
  );
};

export default CertificateHeaderButtons;
