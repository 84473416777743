import React, { memo } from "react";
import styled from "styled-components";
import BibleNavigationPanelDesktop from "./bible_navigation_panel_desktop";
import BibleSearchDesktop from "./bible_page_search_desktop";
import BibleChapter from "./bible_chapter_desktop";
import BibleCompareDesktop from "./bible_page_compare_desktop";
import { useAppContext } from "../../../app_context";

const BiblePageDesktopContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  height: 100%;
  padding: 30px;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 30px 30px 12px 30px;
    margin-top: 0px;
  }
`;

const BiblePageDesktop = ({
  activeScreenDesktop,
  bibleTranslations,
  bibleSearchPlace,
  bibleSearchInput,
  selectedVerses,
  setSelectedVerses,
  currentTranslationFirst,
  currentTranslationSecond,
  bibleSearchResults,
  setBibleSearchResults,
  setBibleSearchInput,
  setActiveScreenDesktop,
  setBibleTranslations,
  setBibleSearchPlace,
  setCurrentTranslationFirst,
  setCurrentTranslationSecond,
  isLoading,
  setIsLoading,
}) => {
  const { showAppHeader } = useAppContext();

  return (
    <BiblePageDesktopContainer showAppHeader={showAppHeader}>
      {activeScreenDesktop === "bible_chapter" && (
        <BibleChapter
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslationFirst={currentTranslationFirst}
          currentTranslationSecond={currentTranslationSecond}
        />
      )}

      {activeScreenDesktop === "bible_search" && (
        <BibleSearchDesktop
          isLoading={isLoading}
          bibleSearchResults={bibleSearchResults}
          bibleSearchInput={bibleSearchInput}
          currentTranslation={currentTranslationFirst}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setActiveScreenDesktop={setActiveScreenDesktop}
        />
      )}

      {activeScreenDesktop === "bible_compare" && (
        <BibleCompareDesktop
          isLoading={isLoading}
          selectedVerses={selectedVerses}
          bibleTranslations={bibleTranslations}
          setBibleTranslations={setBibleTranslations}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setIsLoading={setIsLoading}
        />
      )}

      {activeScreenDesktop !== "bible_compare" && (
        <BibleNavigationPanelDesktop
          activeScreenDesktop={activeScreenDesktop}
          currentTranslationFirst={currentTranslationFirst}
          currentTranslationSecond={currentTranslationSecond}
          bibleSearchPlace={bibleSearchPlace}
          bibleTranslations={bibleTranslations}
          bibleSearchInput={bibleSearchInput}
          setBibleTranslations={setBibleTranslations}
          setCurrentTranslationFirst={setCurrentTranslationFirst}
          setCurrentTranslationSecond={setCurrentTranslationSecond}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setBibleSearchPlace={setBibleSearchPlace}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setIsLoading={setIsLoading}
        />
      )}
    </BiblePageDesktopContainer>
  );
};

export default memo(BiblePageDesktop);
