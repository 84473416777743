import React, { useCallback, useEffect, useRef, memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const VerseContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 8px;
  min-height: ${({ blockHeight }) =>
    blockHeight ? blockHeight + "px" : "auto"};
  background: ${({ isSelected }) => (isSelected ? "#cacaca" : "none")};
  user-select: none;

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? "#cacaca" : "rgb(223 223 223)"};
    transition: 0.2s;
  }

  @media (max-width: 744px) {
    padding: 0 4px 0 6px;
    margin-right: 6px;
  }
`;

const VerseTextContainer = styled.div`
  flex: 1;
  height: 100%;
`;

const VerseText = styled.div`
  & > span {
    font-family: ${({ fontFamily }) => fontFamily} !important;
    font-size: ${({ fontSize }) => fontSize}px;
    line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
    color: #1e1e1e;
  }
`;

const VerseNumber = styled.div`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
  color: #1e1e1e;
`;

const Verse = ({
  verse,
  verseIndex,
  bibleFontSettings,
  selectedVerses,
  setVerseHeights,
  blockHeight,
  isFirstTranslation,
  verseHeightsFirstTransl,
  verseHeightsSecondTransl,
}) => {
  if (!verse.verse_num) {
    return;
  }

  const verseRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [verseIsSelected, setVerseIsSelected] = useState(false);

  const bibleId = searchParams.get("bibleId");
  const bibleId2 = searchParams.get("2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");

  useEffect(() => {
    const includes = selectedVerses?.verses?.filter(
      (obj) => obj.verse_num === verse.verse_num
    ).length;

    if (includes && verse.bible_code === selectedVerses.bible) {
      setVerseIsSelected(true);
    } else setVerseIsSelected(false);
  }, [selectedVerses, verse]);

  const handleVerseHeight = useCallback(() => {
    if (verseRef.current) {
      setVerseHeights((prevHeights) => {
        const newState = [...prevHeights];
        newState[verseIndex] = verseRef.current.offsetHeight;
        return newState;
      });
    }
  }, [verseRef.current, verseIndex, setVerseHeights]);

  useEffect(() => {
    if (!isFirstTranslation) {
      handleVerseHeight();
    }
  }, [verseHeightsFirstTransl]);

  useEffect(() => {
    if (
      isFirstTranslation &&
      verseHeightsFirstTransl.length === 0 &&
      verseHeightsSecondTransl.length > 0
    ) {
      handleVerseHeight();
    }
  }, [verseHeightsSecondTransl]);

  useEffect(() => {
    handleVerseHeight();
  }, [verseRef.current, bibleId, bibleId2, bookId, chapterId, verse._id]);

  const handleSelectVerses = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (selectedVerses?.verses?.some((v) => v.verse_num === verse.verse_num)) {
      const updatedVerses = selectedVerses.verses.filter(
        (v) => v.verse_num !== verse.verse_num
      );
      if (updatedVerses.length) {
        newSearchParams.set(
          "selectVerses",
          updatedVerses.map((v) => v.verse_num).toString()
        );
      } else {
        newSearchParams.delete("selectVerses");
        newSearchParams.delete("selectBible");
      }
    } else {
      const updatedVerses = selectedVerses?.verses
        ? [...selectedVerses.verses, verse]
        : [verse];
      newSearchParams.set(
        "selectVerses",
        updatedVerses.map((v) => v.verse_num).toString()
      );
      newSearchParams.set("selectBible", verse.bible_code.toString());
    }

    setSearchParams(newSearchParams);
  }, [searchParams, selectedVerses, verse]);

  useEffect(() => {
    if (verseIsSelected && verseRef.current) {
      verseRef.current.scrollIntoView({ block: "center" });
    }
  }, [verseRef.current, blockHeight]);

  return (
    <VerseContainer
      ref={verseRef}
      isSelected={verseIsSelected}
      blockHeight={blockHeight}
      onClick={handleSelectVerses}
    >
      <VerseNumber
        fontFamily={bibleFontSettings.fontVariant}
        fontSize={bibleFontSettings.fontSize}
      >
        {verse.verse_num}.
      </VerseNumber>
      <VerseTextContainer>
        <VerseText
          fontFamily={bibleFontSettings.fontVariant}
          fontSize={bibleFontSettings.fontSize}
        >
          {verse.verse_text}
        </VerseText>
      </VerseTextContainer>
    </VerseContainer>
  );
};

export default memo(Verse);
