import React, { memo, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { EditorState, Modifier } from "draft-js";
import {
  TextSettingsButtonsHeaderContainer,
  TextSettingsButtonsHeaderWrapper,
} from "./text_settings_buttons_header";
import TextDefinitionIcon from "../../../assets/icons/text_editor_icons/text_definition_icon";
import TextDefinitionModal from "../text_definition_modal_window";
import { createComponentId } from "../../lesson/lesson_utils";

const TextDefinitionButtonWrapper = styled(TextSettingsButtonsHeaderWrapper)`
  width: 42px;
`;

const TextDefinitionButtonContainer = styled(
  TextSettingsButtonsHeaderContainer
)`
  align-items: center;
`;

const TextDefinitionButton = ({
  textDefinitionModal,
  setTextDefinitionModal,
  editorState,
  setEditorState,
  lessonData,
  setLessonData,
  setSettingsBlockData,
}) => {
  const [selectedDefinitionData, setSelectedDefinitionData] = useState(null);
  const [definitionEditorState, setDefinitionEditorState] = useState({});

  const handleOpenDefinitionModal = (e) => {
    e.stopPropagation();

    const selectionState = editorState.getSelection();
    if (selectionState.isCollapsed()) return;

    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();
    const block = contentState.getBlockForKey(startKey);
    let selectedText = block.getText().slice(startOffset, endOffset).trim();

    if (!selectedText) return;

    let definitionId = null;

    for (let i = startOffset; i < endOffset; i++) {
      const entityKey = block.getEntityAt(i);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (entity.getType() === "DEFINITION") {
          definitionId = entity.getData().definitionId;
          break;
        }
      }
    }

    if (definitionId && lessonData.lesson_vocabulary[definitionId]) {
      selectedText = lessonData.lesson_vocabulary[definitionId].text;
    }

    setSelectedDefinitionData({
      definitionId,
      selectedText,
    });

    setDefinitionEditorState(
      definitionId
        ? lessonData?.lesson_vocabulary?.[definitionId]?.definition || {}
        : {}
    );

    setTextDefinitionModal(true);
  };

  const updateSelectedTextWithId = (definitionId) => {
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      let contentState = editorState.getCurrentContent();

      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();
      const block = contentState.getBlockForKey(selection.getStartKey());
      const entityKey = block.getEntityAt(startOffset);

      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (entity.getData().definitionId === definitionId) {
          return;
        }
      }

      const contentWithEntity = contentState.createEntity(
        "DEFINITION",
        "MUTABLE",
        { definitionId }
      );
      const entityKeyForNew = contentWithEntity.getLastCreatedEntityKey();

      const newContentState = Modifier.applyEntity(
        contentWithEntity,
        selection,
        entityKeyForNew
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );
      setEditorState(newEditorState);
    }
  };

  const handleSaveDefinition = () => {
    if (!selectedDefinitionData || !definitionEditorState) return;

    const definitionId =
      selectedDefinitionData.definitionId || createComponentId();

    setLessonData((prevLessonData) => ({
      ...prevLessonData,
      lesson_vocabulary: {
        ...prevLessonData.lesson_vocabulary,
        [definitionId]: {
          text: selectedDefinitionData.selectedText,
          definition: definitionEditorState,
        },
      },
    }));

    setSettingsBlockData((prevElementState) => {
      const updatedKeys = prevElementState.vocabulary_keys || [];
      return {
        ...prevElementState,
        vocabulary_keys: updatedKeys.includes(definitionId)
          ? updatedKeys
          : [...updatedKeys, definitionId],
      };
    });

    updateSelectedTextWithId(definitionId);

    setSelectedDefinitionData(null);
    setTextDefinitionModal(false);
  };

  const handleDeleteDefinition = () => {
    if (!selectedDefinitionData?.definitionId) return;

    let contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const newContentState = Modifier.applyEntity(contentState, selection, null);

    setEditorState(
      EditorState.push(editorState, newContentState, "apply-entity")
    );

    setSelectedDefinitionData(null);
    setDefinitionEditorState({});
    setTextDefinitionModal(false);
  };

  return (
    <div>
      <TextDefinitionButtonWrapper onClick={handleOpenDefinitionModal}>
        <TextDefinitionButtonContainer>
          <TextDefinitionIcon />
        </TextDefinitionButtonContainer>
      </TextDefinitionButtonWrapper>

      {textDefinitionModal &&
        createPortal(
          <TextDefinitionModal
            definitionEditorState={definitionEditorState}
            showDeleteButton={!!selectedDefinitionData.definitionId}
            onSave={handleSaveDefinition}
            onDelete={handleDeleteDefinition}
            setTextDefinitionModal={setTextDefinitionModal}
            setDefinitionEditorState={setDefinitionEditorState}
          />,
          document.body
        )}
    </div>
  );
};

export default memo(TextDefinitionButton);
