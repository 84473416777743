import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AskQuestionButton } from "../../../../common/components/modal_windows/ask_question_modal_window";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";
import { useTranslation } from "react-i18next";

const AuthModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 10000;
`;

const AuthModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 21px;
  width: 420px;
  height: 170px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
  cursor: default;
  z-index: 10000;

  @media (max-width: 440px) {
    width: 94%;
    margin: 0 auto;
    padding: 15px;
  }
`;

const AuthModalWindowTitle = styled.div`
  width: 300px;
  color: #1e1e1e;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

const AuthModalWindowButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 21px;
`;

const AuthModalWindow = ({ onClose }) => {
  const { t } = useTranslation();

  const handleCloseAuthModalWindow = useCallback((event) => {
    event.stopPropagation();
    onClose(false);
  }, []);

  return (
    <AuthModalWindowWrapper onClick={handleCloseAuthModalWindow}>
      <AuthModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <AuthModalWindowTitle>
          {t("ask-question-auth-modal.title")}
        </AuthModalWindowTitle>

        <AuthModalWindowButtons>
          <Link to={APPLICATION_ROUTES.REGISTRATION}>
            <AskQuestionButton>
              {t("ask-question-auth-modal.registr-btn")}
            </AskQuestionButton>
          </Link>
          <Link to={APPLICATION_ROUTES.AUTH}>
            <AskQuestionButton>
              {t("ask-question-auth-modal.login-btn")}
            </AskQuestionButton>
          </Link>
        </AuthModalWindowButtons>
      </AuthModalWindowContainer>
    </AuthModalWindowWrapper>
  );
};

export default AuthModalWindow;
