import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import CoursesButtonIcon from "../../../common/assets/icons/study_page_icons/courses_button_icon";
import GroupsButtonIcon from "../../../common/assets/icons/study_page_icons/groups_button_icon";
import { useTranslation } from "react-i18next";

const StudyPageMainButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 60px;

  @media (max-width: 1024px) {
    padding-top: 40px;
  }

  @media (max-width: 744px) {
    padding-top: 30px;
  }

  @media (max-width: 420px) {
    width: 100%;

    //justify-content: space-around;
  }
`;

const StudyPageMainButton = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 0 25px;
  height: 52px;
  border-radius: 15px;
  background: #a6aaec;
  text-decoration: none;
  cursor: pointer;

  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: 0.2s;
  }

  &.active {
    background: #5172ea;
  }

  @media (max-width: 670px) {
    height: 44px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  @media (max-width: 412px) {
    font-size: 16px;
    height: 40px;
    padding: 0 30px;
  }
`;

const StudyPageMainButtons = () => {
  const { t } = useTranslation();

  return (
    <StudyPageMainButtonsContainer>
      <StudyPageMainButton to={"courses"}>
        <CoursesButtonIcon />
        {t("study-page.courses-btn")}
      </StudyPageMainButton>
      {/* <StudyPageMainButton to={"groups"}>
        <GroupsButtonIcon />
        {t("study-page.groups-btn")}
      </StudyPageMainButton> */}
    </StudyPageMainButtonsContainer>
  );
};

export default memo(StudyPageMainButtons);
