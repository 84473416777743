import React, { memo, useCallback } from "react";
import styled from "styled-components";
import PlayIcon from "../../../common/assets/icons/play_icon";
import { useTranslation } from "react-i18next";

const CourseVideoContainer = styled.div`
  width: 506px;
  height: 113px;
  margin: 62px auto 0;
  border-radius: 25px;
  background-image: url(${({ videoImage }) => videoImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0px -3.5138890743255615px 10.541667938232422px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 506px) {
    width: 100%;
    border-radius: 0;
  }

  @media (max-width: 412px) {
    height: 96px;
    margin: 60px auto 0;
  }
`;

const ShadowWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(
    250deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.18) 100%
  );

  @media (max-width: 506px) {
    border-radius: 0;
  }
`;

const CoursePlayButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  padding-top: 21px;
  margin-left: 216px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  user-select: none;

  @media (max-width: 514px) {
    padding-top: 10px;
  }

  @media (max-width: 460px) {
    margin-left: 170px;
  }

  @media (max-width: 412px) {
    padding-top: 15px;
    margin-left: 160px;
    font-size: 20px;
    line-height: 140%;
  }
`;
const PlayButton = styled.div`
  margin-top: 7px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  width: 31px;
  height: 32px;

  @media (max-width: 412px) {
    width: 24px;
    height: 25px;
  }
`;

const CourseVideo = ({ videoImage, setOpenCourseVideoModal }) => {
  const { t } = useTranslation();

  const onClickPlayVideoButton = useCallback((event) => {
    event.stopPropagation();
    setOpenCourseVideoModal(true);
  }, []);

  return (
    <CourseVideoContainer videoImage={videoImage}>
      <ShadowWrapper>
        <CoursePlayButtonContainer>
          <h3>{t("course-page.watch-video")}</h3>
          <PlayButton onClick={onClickPlayVideoButton}>
            <PlayIconStyles />
          </PlayButton>
        </CoursePlayButtonContainer>
      </ShadowWrapper>
    </CourseVideoContainer>
  );
};

export default memo(CourseVideo);
