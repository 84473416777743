import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../../user_data_context";
import { useNavigate } from "react-router-dom";
import AdminGroupsMainBlock from "./admin_groups_main_block";
import AdminGroupsSettings from "./admin_groups_settings";

const AdminPageGroupsWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
`;

const AdminPageGroupsContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: 100%;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
`;

const AdminPageGroups = () => {
  const navigate = useNavigate();

  const { userData, accessRights } = useUserDataContext();

  const hasAccess =
    accessRights.isSuperadmin ||
    accessRights.isAdminOfPlatformGroups ||
    accessRights.isAdminOfGroups ||
    accessRights.isAuthor;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, userData]);

  if (!hasAccess) return;

  return (
    <AdminPageGroupsWrapper>
      <AdminPageGroupsContainer>
        <AdminGroupsMainBlock />
        {/* <AdminGroupsSettings /> */}
      </AdminPageGroupsContainer>
    </AdminPageGroupsWrapper>
  );
};

export default memo(AdminPageGroups);
