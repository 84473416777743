import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import axios from "../../../../axios_config";
import TextEditor from "../../../../common/components/text_editor";
import { getFormattedDate } from "../../../../common/utils/functions/time_functions";
import DeleteQuestionAnswerIcon from "../../../../common/assets/icons/admin_page_icons/delete_question_answer_icon";
import DeleteQuestionModalWindow from "../../../../common/components/modal_windows/delete_modal_window";
import { BASE_API_URL } from "../../../../common/endpoints";

const QuestionSettingsAnswerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 10px;
    color: #828282;
    font-size: 10px;
    line-height: 110%;
  }
`;

const QuestionAnswerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  background: #fff;
  border-top: 1px solid #cacaca;
  border-radius: 0 0 8px 8px;
  padding: 0 5px 0 8px;
  height: max-content;
`;

const QuestionAnswerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  h6 {
    color: #828282;
    font-size: 10px;
    line-height: 140%;
  }

  h5 {
    color: #828282;
    font-size: 11px;
    line-height: 140%;
  }

  @media (max-width: 412px) {
    gap: 0px;
    padding: 3px 0;
  }
`;

const QuestionAnswerDateAndRead = styled.div`
  display: flex;
  gap: 5px;

  @media (max-width: 412px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const QuestionAnswerButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  flex: 1;
  padding: 7px 0;

  @media (max-width: 420px) {
    flex-wrap: wrap;
  }
`;

const QuestionAnswerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
  height: 30px;
  width: max-content;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: 0.4px;
  cursor: default;
  user-select: none;
  transition: 0.2s;
`;

const SendAnswerButton = styled(QuestionAnswerButton)`
  background: #eaeaea;

  ${({ enabled, isHoverOnCancel }) =>
    enabled &&
    `
   cursor: pointer;
   background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
   opacity: ${isHoverOnCancel ? "0.5" : "1"};

   &:hover {
      box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
   }
   `}
`;
const CancelAnswerButton = styled(QuestionAnswerButton)`
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const DeleteQuestionAnswerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 36px;
  border-radius: 8px;
  background: #e85d5d;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const QuestionSettingsAnswer = ({
  questionSettingsData,
  textEditorKey,
  setTextEditorKey,
  setQuestionSettingsData,
  hasNewAnswer,
  setQuestionSettingsMemoData,
  fetchQuestions,
  onClickCancelButton,
  onClickSendAnswerButton,
}) => {
  const [answerDate, setAnswerDate] = useState("");
  const [answerAuthorName, setAnswerAuthorName] = useState("");
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const hasAnswer = !!questionSettingsData?.question_answer?.answer_created_at;
  const answerRead = questionSettingsData?.question_answer?.answer_read;

  useEffect(() => {
    if (questionSettingsData?.question_answer?.answer_created_at) {
      setAnswerDate(
        getFormattedDate(
          questionSettingsData?.question_answer?.answer_created_at
        )
      );
    } else {
      setAnswerDate("");
    }
  }, [questionSettingsData]);

  const fetchAuthorInfoData = useCallback(async (authorId) => {
    try {
      const response = await axios(`${BASE_API_URL}/users/${authorId}`);
      const authorData = response.data;
      const name = `${authorData.user_name} ${authorData.user_second_name}`;

      setAnswerAuthorName(name);
    } catch (error) {
      console.error("Ошибка загрузки данных автора:", error);
      setAnswerAuthorName("");
    }
  }, []);

  useEffect(() => {
    if (hasAnswer) {
      const author = questionSettingsData?.question_answer?.answer_author;

      if (author?._id) {
        if (author.user_name) {
          const name = `${author.user_name} ${author.user_second_name}`;
          setAnswerAuthorName(name);
        } else {
          fetchAuthorInfoData(author._id);
        }
      } else {
        setAnswerAuthorName("");
      }
    } else {
      setAnswerAuthorName("");
    }
  }, [questionSettingsData, hasAnswer]);

  const toggleHoverOnCancelButton = useCallback(() => {
    setIsHoverOnCancel((prevState) => !prevState);
  }, [setIsHoverOnCancel]);

  const onEditorChange = useCallback(
    (updatedInputText) => {
      if (
        !questionSettingsData.question_answer?.answer_text &&
        updatedInputText?.blocks?.[0]?.text?.length < 1
      )
        return;

      setQuestionSettingsData((prevState) => ({
        ...prevState,
        question_answer: {
          ...prevState.question_answer,
          answer_text: updatedInputText,
        },
      }));
    },
    [questionSettingsData, setQuestionSettingsData]
  );

  const onClickDeleteAnswerButton = useCallback((event) => {
    event.stopPropagation();
    setShowDeleteModal(true);
  }, []);

  const onDeleteAnswer = useCallback(async () => {
    const deletedAnswer = {
      question_answer: {},
      question_status: "noanswer",
    };

    try {
      await axios.patch(
        `${BASE_API_URL}/questions/${questionSettingsData._id}`,
        deletedAnswer
      );

      setQuestionSettingsData((prevState) => ({
        ...prevState,
        question_answer: {},
      }));

      setQuestionSettingsMemoData((prevState) => ({
        ...prevState,
        question_answer: {},
      }));

      setShowDeleteModal(false);
      setTextEditorKey((prevState) => prevState + 1);

      fetchQuestions();
    } catch (error) {
      console.error("Error delete answer from question:", error);
    }
  }, [
    questionSettingsData,
    setQuestionSettingsData,
    setQuestionSettingsMemoData,
    fetchQuestions,
  ]);

  return (
    <QuestionSettingsAnswerContainer>
      <h4>Ответ</h4>

      <TextEditor
        contentStateData={questionSettingsData.question_answer.answer_text}
        onEditorChange={onEditorChange}
        placeholder="Напишите ответ"
        inComponent={"answer_settings"}
        withoutTextSize={true}
        borderRadius={"8px 8px 0 0"}
        key={textEditorKey || questionSettingsData._id}
      />

      <QuestionAnswerFooter>
        <QuestionAnswerInfo>
          <h5>{answerAuthorName}</h5>
          <QuestionAnswerDateAndRead>
            <h6>{answerDate}</h6>
            <h6>
              {hasAnswer ? (answerRead ? "(Прочитан)" : "(Не прочитан)") : null}
            </h6>
          </QuestionAnswerDateAndRead>
        </QuestionAnswerInfo>

        <QuestionAnswerButtonContainer>
          {hasAnswer && (
            <DeleteQuestionAnswerButton onClick={onClickDeleteAnswerButton}>
              <DeleteQuestionAnswerIcon />
            </DeleteQuestionAnswerButton>
          )}

          {hasNewAnswer && (
            <CancelAnswerButton
              onMouseEnter={toggleHoverOnCancelButton}
              onMouseLeave={toggleHoverOnCancelButton}
              onClick={onClickCancelButton}
            >
              Отмена
            </CancelAnswerButton>
          )}
          <SendAnswerButton
            enabled={hasNewAnswer}
            isHoverOnCancel={isHoverOnCancel}
            onClick={(e) => onClickSendAnswerButton(e, "answer")}
          >
            Отправить
          </SendAnswerButton>
        </QuestionAnswerButtonContainer>
      </QuestionAnswerFooter>

      {showDeleteModal &&
        createPortal(
          <DeleteQuestionModalWindow
            setOpen={setShowDeleteModal}
            handleDelete={onDeleteAnswer}
            deletionObject={"ответ"}
          />,
          document.body
        )}
    </QuestionSettingsAnswerContainer>
  );
};

export default memo(QuestionSettingsAnswer);
