import React, { useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../assets/icons/vertical_line";
import { useTranslation } from "react-i18next";

const DeleteQuestionModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const DeleteQuestionModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 42px;
  border-radius: 25px;
  background: #eaeaea;
`;

const DeleteQuestionText = styled.div`
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: 0.24px;

  > h5 {
    color: #f12b2b;
    font-weight: 600;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;

  &:hover {
    font-size: 18px;
    color: #5172ea;
    transition: 0.2s;
    width: 53%;
  }
`;

const DeleteButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSave }) =>
    isHoverOnSave ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const DeleteQuestionModalWindow = ({
  setOpen,
  handleDelete,
  deletionObject = "",
}) => {
  const { t } = useTranslation();

  const [isHoverOnSave, setIsHoverOnSave] = useState(false);

  const toggleHoverOnDeleteButton = useCallback(() => {
    setIsHoverOnSave((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <DeleteQuestionModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <DeleteQuestionModalWindowContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <DeleteQuestionText>
          {t("delete-question-modal.modal-question")} {deletionObject}?
          <h5>{t("delete-question-modal.text")}</h5>
        </DeleteQuestionText>

        <ModalButtonsContainer>
          <DeleteButton
            onMouseEnter={toggleHoverOnDeleteButton}
            onMouseLeave={toggleHoverOnDeleteButton}
            onClick={handleDelete}
          >
            {t("delete-question-modal.buttons.delete")}
          </DeleteButton>
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton isHoverOnSave={isHoverOnSave} onClick={handleClose}>
            {t("delete-question-modal.buttons.cancel")}
          </CancelButton>
        </ModalButtonsContainer>
      </DeleteQuestionModalWindowContainer>
    </DeleteQuestionModalWindowWrapper>
  );
};

export default DeleteQuestionModalWindow;
