import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationRU from "./locales/ru/translation.json";
import translationUK from "./locales/uk/translation.json";
import translationEN from "./locales/en/translation.json";

const resources = {
  ru: { translation: translationRU },
  uk: { translation: translationUK },
  en: { translation: translationEN },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ru",
  fallbackLng: "ru",
  interpolation: { escapeValue: false },
});

if (typeof window !== "undefined") {
  const savedLanguage = localStorage.getItem("i18nextLng") || "ru";
  i18n.changeLanguage(savedLanguage);
}

export default i18n;
