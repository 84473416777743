import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import UserProfileQuestion from "./user_profile_question";
import { createPortal } from "react-dom";
import BibleModalWindow from "../../../common/components/modal_windows/bible_modal_window";
import BibleChapterModalWindow from "../../../common/components/modal_windows/bible_chapter_modal_window";
import QuestionsFilterByStatus from "../../admin/admin_page_questions/admin_page_questions_list/questions_filters/questions_filter_by_status";
import SpinnerLoader from "../../../common/components/loaders/spinner_loader";
import { BASE_API_URL } from "../../../common/endpoints";
import DeleteQuestionModalWindow from "../../../common/components/modal_windows/delete_modal_window";
import QuestionsSelectedFilters from "../../admin/admin_page_questions/admin_page_questions_list/questions_filters/questions_selected_filters";
import { useTranslation } from "react-i18next";

const UserProfileQuestionsContainer = styled.div`
  max-width: 628px;
  margin: 0 auto;
`;

const UserProfileQuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
`;

const QuestionsFiltersContainer = styled.div``;

const FilterByStatusWrapper = styled.div`
  width: 110px;
`;

const QuestionsFiltersWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const UsersQuestionsCount = styled.div`
  padding-bottom: 6px;
  color: #000;
  text-align: right;
  font-size: 12px;
  line-height: 16px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  width: 100%;
`;

const QuestionsListIsEmpty = styled.div`
  text-align: center;
  color: #383838;
  padding: 30px;
  font-size: 16px;
`;

const UserProfileQuestions = ({
  userQuestionsList,
  loadingQuestions,
  fetchUserQuestions,
}) => {
  const { t } = useTranslation();

  const [filteredQuestionsList, setFilteredQuestionsList] = useState([]);
  const [processedQuestions, setProcessedQuestions] = useState(new Set());
  const [extendedOption, setExtendedOption] = useState("");
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [deleteQuestionModalData, setDeleteQuestionModalData] = useState({});
  const [questionsFilterSettings, setQuestionsFilterSettings] = useState({
    question_status: "",
  });
  //                     bible_modal_windows_states
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  useEffect(() => {
    localStorage.setItem("lastProfileTab", "questions");
  }, []);

  useEffect(() => {
    if (!userQuestionsList.length) {
      setFilteredQuestionsList([]);
      return;
    }

    let updatedQuestionsList = [...userQuestionsList];

    updatedQuestionsList.sort(
      (a, b) =>
        new Date(b.question_updated_at) - new Date(a.question_updated_at)
    );

    const statusFilter = questionsFilterSettings?.question_status;
    if (statusFilter !== "") {
      updatedQuestionsList = updatedQuestionsList.filter(
        (question) => question.question_status === statusFilter
      );
    }

    setFilteredQuestionsList(updatedQuestionsList);
  }, [userQuestionsList, questionsFilterSettings]);

  const updateUnreadQuestions = useCallback(async () => {
    const unreadQuestions = userQuestionsList.filter(
      (question) =>
        question.question_answer?.answer_read === false &&
        !processedQuestions.has(question._id)
    );

    if (unreadQuestions.length === 0) return;

    try {
      const updatePromises = unreadQuestions.map((question) => {
        const updatedQuestion = {
          ...question,
          question_answer: {
            ...question.question_answer,
            answer_read: true,
          },
        };

        return axios
          .patch(`${BASE_API_URL}/questions/${question._id}`, updatedQuestion)
          .then(() => {
            setProcessedQuestions((prev) => new Set(prev).add(question._id));
          });
      });

      await Promise.all(updatePromises);

      await fetchUserQuestions();
    } catch (error) {
      console.error("Ошибка при обновлении вопросов:", error);
    }
  }, [userQuestionsList, processedQuestions, fetchUserQuestions]);

  useEffect(() => {
    if (
      userQuestionsList.some((q) => q.question_answer?.answer_read === false)
    ) {
      updateUnreadQuestions();
    }
  }, [userQuestionsList]);

  const handleDeleteQuestion = useCallback(async () => {
    try {
      await axios.delete(
        `${BASE_API_URL}/questions/${deleteQuestionModalData._id}`
      );

      await fetchUserQuestions();
      setOpenDeleteQuestionModal(false);
    } catch (error) {
      console.error("Ошибка при удалении вопроса:", error);
    }
  }, [deleteQuestionModalData, fetchUserQuestions]);

  const handleExtendOptions = useCallback(
    (event, optionName) => {
      event.stopPropagation();

      if (extendedOption === optionName) {
        setExtendedOption("");
      } else {
        setExtendedOption(optionName);
      }
    },
    [extendedOption]
  );

  //  Функция сделана на будущее, если появятся ещё фильтры (как в админке)
  const removeSelectedFilterOption = useCallback(
    (option, itemIndex) => {
      setQuestionsFilterSettings((prevState) => {
        const isArrayFilter = [
          "question_bible",
          "search_words",
          "question_date_range",
          "question_recipient",
        ].includes(option);

        return {
          ...prevState,
          [option]: isArrayFilter
            ? prevState[option].filter((_, index) => index !== itemIndex)
            : "",
        };
      });
    },
    [setQuestionsFilterSettings]
  );

  return (
    <UserProfileQuestionsContainer>
      {userQuestionsList.length > 0 && (
        <QuestionsFiltersContainer>
          <FilterByStatusWrapper>
            <QuestionsFilterByStatus
              extendedOption={extendedOption}
              handleExtendOptions={handleExtendOptions}
              setQuestionsFilterSettings={setQuestionsFilterSettings}
            />
          </FilterByStatusWrapper>

          <QuestionsSelectedFilters
            questionsFilterSettings={questionsFilterSettings}
            removeSelectedFilterOption={removeSelectedFilterOption}
            accessRights={{}}
          />
        </QuestionsFiltersContainer>
      )}

      {userQuestionsList.length > 0 && (
        <UsersQuestionsCount>
          {t("user-profile-page.questions.count-found")}:{" "}
          {filteredQuestionsList?.length || 0}
        </UsersQuestionsCount>
      )}

      <UserProfileQuestionList>
        {loadingQuestions ? (
          <LoaderContainer>
            <SpinnerLoader />
          </LoaderContainer>
        ) : userQuestionsList.length === 0 ? (
          <QuestionsListIsEmpty>
            {t("user-profile-page.questions.empty-questions-list")}
          </QuestionsListIsEmpty>
        ) : filteredQuestionsList.length > 0 ? (
          filteredQuestionsList.map((question) => (
            <UserProfileQuestion
              key={question._id}
              question={question}
              setTextBibleModalData={setTextBibleModalData}
              setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
              setDeleteQuestionModalData={setDeleteQuestionModalData}
            />
          ))
        ) : (
          <QuestionsListIsEmpty>
            {t("user-profile-page.questions.questions-not-found")}
          </QuestionsListIsEmpty>
        )}

        {!!extendedOption && (
          <QuestionsFiltersWrapper onClick={() => setExtendedOption("")} />
        )}
      </UserProfileQuestionList>

      {textBibleModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={textBibleModalData}
            setOpen={setTextBibleModalData}
            setBibleVersesData={setBibleVersesData}
            setOpenBibleModalWindow={setOpenBibleModalWindow}
          />,
          document.body
        )}

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            bibleVersesData={bibleVersesData}
            setBibleVersesData={setBibleVersesData}
            inComponent={"simple_modal"}
          />,
          document.body
        )}

      {openDeleteQuestionModal &&
        createPortal(
          <DeleteQuestionModalWindow
            setOpen={setOpenDeleteQuestionModal}
            handleDelete={handleDeleteQuestion}
            deletionObject={t(
              "user-profile-page.questions.question.delete-obj"
            )}
          />,
          document.body
        )}
    </UserProfileQuestionsContainer>
  );
};

export default memo(UserProfileQuestions);
