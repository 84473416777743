import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../axios_config";
import styled from "styled-components";
import CoursePageHeader from "./course_page_header";
import { BASE_API_URL } from "../../../common/endpoints";
import CourseLessons from "./course_lessons";
import AboutCourse from "./about_course";
import CourseVideoModalWindow from "./course_video_modal_window";
import AboutCourseAuthors from "./about_course_authors";
import CourseVideo from "./course_video";
import CourseCertificate from "./course_certificate";
import CourseAnnouncementMessage from "./course_announcement_message";
import CourseLevelAndLessonsInfo from "./course_level_and_lessons_info";
import { useUserDataContext } from "../../../user_data_context";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { ToastNotification } from "../../../common/components/toast_notification";
import LessonIsFinishedModalWindow from "./lesson_is_finished_modal_window";
import DownloadCerificateButton from "./download_cerificate_button";

export const LESSON_BG_COLOR = {
  FINISHED: "#5172EA",
  CURRENT: "#F8D254",
  CLOSED: "#CACACA",
};

const CoursePageContainer = styled.div`
  padding-bottom: 80px;
  overflow: auto;
  height: 100%;

  @media (max-width: 770px) {
    padding-bottom: 50px;
  }
`;

const CoursePage = ({ inComponent = "" }) => {
  const coursePageRef = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const { userData, fetchUserData, authError } = useUserDataContext();

  const [courseData, setCourseData] = useState({});
  const [courseLessonsList, setCourseLessonsList] = useState([]);
  const [courseTestData, setCourseTestData] = useState(null);
  const [openCourseVideoModal, setOpenCourseVideoModal] = useState(false);
  const [lessonIsFinishedModalData, setLessonIsFinishedModalData] =
    useState(null);
  const [showMessageId, setShowMessageId] = useState(null);

  const userCourse = userData.user_activity?.find(
    (course) => course.course_id === courseData._id
  );

  const courseWithTest = courseData.course_certificate?.certificate_after_test;

  const courseLessonsCount = courseLessonsList?.length || null;

  const courseWithCertificate =
    courseData.course_certificate?.certificate_is_enabled;

  const showCertificateDownloadButton =
    inComponent !== "admin_page" &&
    courseWithCertificate &&
    userCourse?.course_status === "finished";

  const fetchCourseLessons = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/lessons/findby?course_id=${id}`
      );

      const lessons = response.data;
      const testData =
        lessons.find((lesson) => lesson.lesson_type === "test") || null;
      const filteredLessons = lessons.filter(
        (lesson) => lesson.lesson_type !== "test"
      );

      setCourseTestData(testData);
      setCourseLessonsList(filteredLessons);
    } catch (error) {
      console.error("Ошибка при запросе уроков курса:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCourseLessons();
    }
  }, [id]);

  const fetchLessonData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/courses/${id}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных курса:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserData();
  }, []);

  const handleClickOnLesson = useCallback(
    async (event, lessonId, lessonStatus, lessonNum, userStudyId) => {
      event.stopPropagation();

      if (inComponent === "admin_page") {
        navigate(
          `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${lessonId}?n=${lessonNum}&p=course`
        );
        return;
      }

      if (lessonStatus === "next") {
        try {
          const createNewUserLesson = {
            study_lesson: { _id: lessonId },
            study_user: { _id: userData._id },
            study_answers: [],
            user_test_pass_rate: 0,
            course_pass_rate:
              courseData.course_certificate?.certificate_pass_rate,
            study_course: {
              _id: courseData._id,
              course_name: courseData.course_name,
              course_lessons_count: courseLessonsCount,
              course_test_count: courseWithTest ? 1 : 0,
            },
          };

          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserLesson);

          navigate(
            `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
          );
        } catch (error) {
          console.error("Ошибка создании урока пользователя:", error);
        }
      }

      if (lessonStatus === "in_process") {
        navigate(
          `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
        );
      }

      if (lessonStatus === "finished") {
        setLessonIsFinishedModalData({
          lessonId,
          lessonNum,
          userStudyId,
          isTest: false,
          testId: null,
        });
      }

      if (lessonStatus === "closed") {
        setShowMessageId(lessonId);
        return;
      }
    },
    [inComponent, userData, courseData, courseWithTest, courseLessonsCount]
  );

  const handleClickOnTest = useCallback(
    async (event, testId, testStatus, userStudyId) => {
      event.stopPropagation();

      if (inComponent === "admin_page") {
        navigate(
          `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${testId}?p=course`
        );
        return;
      }

      if (testStatus === "in_process") {
        navigate(`${APPLICATION_ROUTES.USER_LESSON}/${testId}`);
      }

      if (testStatus === "finished") {
        setLessonIsFinishedModalData({
          lessonId: null,
          lessonNum: null,
          testId,
          userStudyId,
          isTest: true,
        });
      }

      if (testStatus === "next") {
        try {
          const createNewUserTest = {
            study_lesson: { _id: testId, lesson_type: "test" },
            study_user: { _id: userData._id },
            study_answers: [],
            user_test_pass_rate: 0,
            study_course: {
              _id: courseData._id,
              course_name: courseData.course_name,
              course_lessons_count: courseLessonsCount,
              course_test_count: courseWithTest ? 1 : 0,
              course_pass_rate:
                courseData.course_certificate?.certificate_pass_rate,
            },
          };
          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserTest);

          navigate(`${APPLICATION_ROUTES.USER_LESSON}/${testId}`);
        } catch (error) {
          console.error("Ошибка при создании Теста пользователя:", error);
        }
      }
    },
    [inComponent, userData, courseWithTest, courseData, courseLessonsCount]
  );

  return (
    <CoursePageContainer ref={coursePageRef}>
      <CoursePageHeader
        courseData={courseData}
        inComponent={inComponent}
        userCourse={userCourse}
        authError={authError}
        handleClickOnLesson={handleClickOnLesson}
        handleClickOnTest={handleClickOnTest}
      />

      {(courseData.course_status === "open" ||
        inComponent === "admin_page") && (
        <CourseLevelAndLessonsInfo
          courseData={courseData}
          courseLessonsList={courseLessonsList}
          courseTestData={courseTestData}
          inComponent={inComponent}
          showMessageId={showMessageId}
          userCourse={userCourse}
          authError={authError}
          setShowMessageId={setShowMessageId}
          handleClickOnLesson={handleClickOnLesson}
          handleClickOnTest={handleClickOnTest}
        />
      )}

      {courseData.course_status === "announcement" && (
        <CourseAnnouncementMessage />
      )}

      {showCertificateDownloadButton && (
        <DownloadCerificateButton
          userData={userData}
          userCourse={userCourse}
          courseData={courseData}
        />
      )}

      <AboutCourse courseData={courseData} />

      {courseData.course_video_enabled && (
        <CourseVideo
          videoImage={courseData?.course_video_image}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}

      <AboutCourseAuthors courseData={courseData} />

      <CourseLessons
        courseData={courseData}
        courseTestData={courseTestData}
        courseLessonsList={courseLessonsList}
        inComponent={inComponent}
        showMessageId={showMessageId}
        setShowMessageId={setShowMessageId}
        userCourse={userCourse}
        authError={authError}
        handleClickOnLesson={handleClickOnLesson}
        handleClickOnTest={handleClickOnTest}
      />

      {courseData.course_certificate?.certificate_is_enabled && (
        <CourseCertificate />
      )}

      {lessonIsFinishedModalData && (
        <LessonIsFinishedModalWindow
          userData={userData}
          lessonIsFinishedModalData={lessonIsFinishedModalData}
          setLessonIsFinishedModalData={setLessonIsFinishedModalData}
        />
      )}

      {openCourseVideoModal && courseData?.course_video_url && (
        <CourseVideoModalWindow
          videoUrl={courseData?.course_video_url}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}

      <ToastNotification />
    </CoursePageContainer>
  );
};

export default memo(CoursePage);
