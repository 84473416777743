import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import CourseCard from "./course_card";
import StudyPageSortButton from "../study_page_components/study_page_sort_button";
import { BASE_API_URL } from "../../../common/endpoints";
import SpinnerLoader from "../../../common/components/loaders/spinner_loader";
import { ToastNotification } from "../../../common/components/toast_notification";
import { useUserDataContext } from "../../../user_data_context";
import { useTranslation } from "react-i18next";

const StudyPageCoursesContainer = styled.div`
  width: 100%;
  padding-bottom: 110px;

  @media (max-width: 920px) {
    padding-bottom: 60px;
  }
`;

const StudyPageCoursesSortButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 30px;

  @media (max-width: 670px) {
    gap: 9px;
  }

  @media (max-width: 412px) {
    margin-top: 25px;
  }
`;

const CoursePageCoursesList = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 84px;
  margin-top: 53px;

  @media (max-width: 1150px) {
    gap: 40px;
  }

  @media (max-width: 670px) {
    gap: 45px;
    margin-top: 45px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 412px) {
    margin-top: 31px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
`;

const StudyPageCourses = () => {
  const { t } = useTranslation();
  const { userData } = useUserDataContext();

  const [coursesList, setCoursesList] = useState([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);
  const [selectedCoursesStatus, setSelectedCoursesStatus] = useState("open");
  const [coursesByStatusList, setCoursesByStatusList] = useState([]);

  const TESTcourse = coursesList.find(
    (course) => course._id === "66ab62097d0118197071b3a7"
  );

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axios.get(BASE_API_URL + "/courses");
      setCoursesList(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCourses(true);
      await fetchCourses();
      setIsLoadingCourses(false);
    };

    fetchData();
  }, [fetchCourses]);

  useEffect(() => {
    const filteredCourses = coursesList.filter(
      (course) =>
        course.course_status === selectedCoursesStatus &&
        course._id !== "66ab62097d0118197071b3a7"
    );
    setCoursesByStatusList(filteredCourses);
  }, [coursesList, selectedCoursesStatus]);

  const handleSelectCoursesByStatus = useCallback(
    (event, status) => {
      event.stopPropagation();

      if (selectedCoursesStatus !== status) {
        setSelectedCoursesStatus(status);
      }
    },
    [selectedCoursesStatus]
  );

  return (
    <StudyPageCoursesContainer>
      <StudyPageCoursesSortButtons>
        <StudyPageSortButton
          isActive={selectedCoursesStatus === "open"}
          onClick={(e) => handleSelectCoursesByStatus(e, "open")}
        >
          {t("study-page.open-sort-btn")}
        </StudyPageSortButton>
        <StudyPageSortButton
          isActive={selectedCoursesStatus === "announcement"}
          onClick={(e) => handleSelectCoursesByStatus(e, "announcement")}
        >
          {t("study-page.announcement-sort-btn")}
        </StudyPageSortButton>
      </StudyPageCoursesSortButtons>

      {!isLoadingCourses ? (
        <>
          <CoursePageCoursesList>
            {coursesByStatusList.map((course) => (
              <CourseCard key={course._id} course={course} />
            ))}

            {userData.user_role === "superadmin" && TESTcourse && (
              <CourseCard key={"test_test_123"} course={TESTcourse} />
            )}
          </CoursePageCoursesList>
        </>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      <ToastNotification />
    </StudyPageCoursesContainer>
  );
};

export default memo(StudyPageCourses);
