import React, { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SearchVerse from "./search_verse";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";
import BibleChapterModalWindow from "../../../../common/components/modal_windows/bible_chapter_modal_window";
import BackToBibleChapterButton from "../../back_to_bible_chapter_button";
import BibleModalWindow from "../../../../common/components/modal_windows/bible_modal_window";

const BiblePageSearchResultsContainer = styled.div`
  flex: 1;
  padding: 15px 0 0px 20px;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
`;

const BiblePageSearchInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BibleAndTestamentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  min-width: 215px;
  width: max-content;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const BibleSearchAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const BibleSearchTestament = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BiblePageSearchResultCount = styled.div`
  display: flex;
  align-items: center;
  padding-right: 22px;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.1px;
  color: #1e1e1e;
`;

const SearchVersesResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding-right: 15px;
  padding-bottom: 20px;
  height: calc(100% - 40px);
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
`;

const EmptySearchResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 0 50px 0 40px;
  font-size: 16px;
  line-height: 140%;
  color: #383838;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const BibleSearchDesktop = ({
  isLoading,
  bibleSearchResults,
  currentTranslation,
  bibleSearchInput,
  setBibleSearchResults,
  setBibleSearchInput,
  setActiveScreenDesktop,
}) => {
  const { t } = useTranslation();

  const searchVersesResultsRef = useRef(null);
  const [visibleResultsCount, setVisibleResultsCount] = useState(20);
  //                     bible_modal_windows_states
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  const totalResults = bibleSearchResults?.searchResults?.length || 0;
  const visibleSearchResults =
    bibleSearchResults?.searchResults?.slice(0, visibleResultsCount) || [];

  const searchTestament =
    bibleSearchResults.searchTestament === "ot"
      ? t("bible-page.place.old-testaments")
      : bibleSearchResults.searchTestament === "nt"
      ? t("bible-page.place.new-testaments")
      : t("bible-page.place.whole-bible");

  const bibleId = currentTranslation.bible_code;

  useEffect(() => {
    return () => {
      setBibleSearchResults({});
      setBibleSearchInput("");
    };
  }, []);

  useEffect(() => {
    if (searchVersesResultsRef.current) {
      searchVersesResultsRef.current.scrollTop = 0;
    }
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    if (bibleSearchResults?.searchResults?.length > 0) {
      setVisibleResultsCount(20);
    }
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    const scrollContainer = searchVersesResultsRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      const scrolledHeight =
        scrollContainer.scrollTop + scrollContainer.clientHeight;
      const totalHeight = scrollContainer.scrollHeight;

      if (scrolledHeight >= totalHeight - 10) {
        setVisibleResultsCount((prev) => Math.min(prev + 20, totalResults));
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [visibleResultsCount, totalResults]);

  return (
    <BiblePageSearchResultsContainer>
      {!isLoading && (
        <BiblePageSearchInfoContainer>
          <Wrapper>
            <BackToBibleChapterButton
              setActiveScreenDesktop={setActiveScreenDesktop}
              navigatePath={"bible_chapter"}
            />

            <BibleAndTestamentContainer>
              <BibleSearchAbbreviation>{bibleId}</BibleSearchAbbreviation>
              <VerticalLineStyled />
              <BibleSearchTestament>{searchTestament}</BibleSearchTestament>
            </BibleAndTestamentContainer>
          </Wrapper>

          <BiblePageSearchResultCount>
            {t("bible-page.search.count-found")}:{" "}
            {bibleSearchResults?.searchResults?.length || 0}
          </BiblePageSearchResultCount>
        </BiblePageSearchInfoContainer>
      )}

      {!isLoading &&
        !bibleSearchResults?.searchResults?.length &&
        bibleSearchInput && (
          <EmptySearchResult>
            <p>
              {t("bible-page.search.empty-result", {
                searchWord: bibleSearchResults.searchWord || bibleSearchInput,
              })}
            </p>
          </EmptySearchResult>
        )}

      {!isLoading ? (
        <SearchVersesResults ref={searchVersesResultsRef}>
          {!!bibleSearchResults?.searchResults?.length &&
            visibleSearchResults?.map((verse) => (
              <SearchVerse
                key={verse._id}
                verse={verse}
                bibleSearchWord={bibleSearchResults.searchWord}
                currentTranslation={currentTranslation}
                setBibleVersesData={setBibleVersesData}
                setOpenBibleModalWindow={setOpenBibleModalWindow}
              />
            ))}
        </SearchVersesResults>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            bibleVersesData={bibleVersesData}
            setBibleVersesData={setBibleVersesData}
            inComponent={"search_modal"}
            fromSearchToBiblePage={() =>
              setActiveScreenDesktop("bible_chapter")
            }
          />,
          document.body
        )}
    </BiblePageSearchResultsContainer>
  );
};

export default memo(BibleSearchDesktop);
