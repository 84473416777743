import React, { useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../assets/icons/vertical_line";
import { useTranslation } from "react-i18next";

const AskQuestionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
    width: 53%;
    cursor: pointer;
  }
`;

const SendButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSend, activeSendButton }) =>
    isHoverOnSend && activeSendButton ? "#fff" : "rgba(255, 255, 255, 0.5)"};

  ${({ activeSendButton }) =>
    !activeSendButton &&
    `
    &:hover {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      width: 49%;
      cursor: default;
    }
    `}
`;

const CancelButton = styled(ButtonStyles)`
  color: rgba(255, 255, 255, 0.5);
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -16px;
  left: 0;
  font-size: 12px;
  color: red;
`;

const AskQuestionButtons = ({
  activeSendButton,
  onCreateNewQuestion,
  userQuestionData,
  error,
  onCloseAskQuestionModalWindow,
}) => {
  const { t } = useTranslation();

  const [isHoverOnSend, setIsHoverOnSend] = useState(true);

  const handleClickSendButton = useCallback(
    (event) => {
      event.stopPropagation();

      if (activeSendButton) {
        onCreateNewQuestion(userQuestionData);
      }
    },
    [activeSendButton, userQuestionData]
  );

  const toggleHoverOnDeleteButton = useCallback(() => {
    setIsHoverOnSend((prevState) => !prevState);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {error && (
        <ErrorMessage>
          {t("ask-question-modal.send-error-message")}
        </ErrorMessage>
      )}

      <AskQuestionButtonsContainer>
        <SendButton
          activeSendButton={activeSendButton}
          isHoverOnSend={isHoverOnSend}
          onClick={handleClickSendButton}
        >
          {t("ask-question-modal.send-btn")}
        </SendButton>
        <VerticalLineStyled color={"#fff"} />
        <CancelButton
          onMouseEnter={toggleHoverOnDeleteButton}
          onMouseLeave={toggleHoverOnDeleteButton}
          onClick={onCloseAskQuestionModalWindow}
        >
          {t("ask-question-modal.cancel-btn")}
        </CancelButton>
      </AskQuestionButtonsContainer>
    </div>
  );
};

export default AskQuestionButtons;
