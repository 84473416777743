import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Virtuoso } from "react-virtuoso";
import UserPreview from "./user_preview";
import UsersListHeader from "./users_list_header";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";
import ScrollToTopVirtuoso from "../../../../common/components/scroll_to_top_virtuoso";

const UsersListWrapper = styled.div`
  position: relative;
  flex: 1;
  padding-top: 110px;
  height: calc(100vh - 80px);
  margin: 0 auto;
`;

const UserNotFoundMessage = styled.div`
  padding-top: 60px;
  text-align: center;
  color: #383838;
  font-size: 16px;
  width: 628px;
  margin-left: 70px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 628px;
  margin-left: 70px;
`;

const UsersList = ({
  loadingUsers,
  usersList,
  activeSettingsBlock,
  userRolesSettingsData,
  userRolesSettingsMemoData,
  setActiveSettingsBlock,
  setUserRolesSettingsData,
  setUserRolesSettingsMemoData,
  setSelectedUserInfo,
  setOpenEditUserModal,
}) => {
  const virtuosoRef = useRef(null);

  const [filteredUsersBySearch, setFilteredUsersBySearch] = useState([]);
  const [searchWords, setSearchWords] = useState("");
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  const usersCount = usersList.length;
  const searchResultCount = searchWords ? filteredUsersBySearch.length : null;

  const topMostIndex = Math.max(
    filteredUsersBySearch.findIndex(
      (user) => user._id === userRolesSettingsData._id
    ),
    0
  );

  useEffect(() => {
    if (!usersList) return;

    const formattedSearchWords = searchWords
      .toLowerCase()
      .trim()
      .split(" ")
      .filter(Boolean);

    if (formattedSearchWords.length === 0) {
      setFilteredUsersBySearch(usersList);
      return;
    }

    setFilteredUsersBySearch(
      usersList.filter(
        ({ user_name = "", user_second_name = "", user_email = "" }) => {
          const userName = user_name.toLowerCase().trim();
          const userSecondName = user_second_name.toLowerCase().trim();
          const userEmail = user_email.toLowerCase().trim();

          if (formattedSearchWords.length === 2) {
            return (
              (userName.includes(formattedSearchWords[0]) &&
                userSecondName.includes(formattedSearchWords[1])) ||
              (userName.includes(formattedSearchWords[1]) &&
                userSecondName.includes(formattedSearchWords[0]))
            );
          }

          return (
            userName.includes(formattedSearchWords[0]) ||
            userSecondName.includes(formattedSearchWords[0]) ||
            userEmail.includes(formattedSearchWords[0])
          );
        }
      )
    );
  }, [usersList, searchWords]);

  const scrollToTop = () => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex(0);
    }
  };

  return (
    <>
      <UsersListWrapper>
        <UsersListHeader
          activeSettingsBlock={activeSettingsBlock}
          setActiveSettingsBlock={setActiveSettingsBlock}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setUserRolesSettingsMemoData={setUserRolesSettingsMemoData}
          setSearchWords={setSearchWords}
          usersCount={usersCount}
          searchResultCount={searchResultCount}
        />

        {!loadingUsers ? (
          <>
            {filteredUsersBySearch.length > 0 ? (
              <Virtuoso
                ref={virtuosoRef}
                style={{ height: "100%", flex: 1 }}
                totalCount={filteredUsersBySearch.length}
                components={{
                  Footer: () => <div style={{ height: "60px" }} />,
                }}
                atTopStateChange={(atTop) => {
                  setIsScrollVisible(!atTop);
                }}
                rangeChanged={({ startIndex }) => {
                  setIsScrollVisible(startIndex > 5);
                }}
                initialTopMostItemIndex={topMostIndex}
                itemContent={(index) => {
                  const user = filteredUsersBySearch[index];
                  return (
                    <UserPreview
                      key={user._id}
                      userInfo={user}
                      userRolesSettingsData={userRolesSettingsData}
                      userRolesSettingsMemoData={userRolesSettingsMemoData}
                      setActiveSettingsBlock={setActiveSettingsBlock}
                      setUserRolesSettingsData={setUserRolesSettingsData}
                      setUserRolesSettingsMemoData={
                        setUserRolesSettingsMemoData
                      }
                      setSelectedUserInfo={setSelectedUserInfo}
                      setOpenEditUserModal={setOpenEditUserModal}
                    />
                  );
                }}
              />
            ) : (
              searchWords && (
                <UserNotFoundMessage>
                  По запросу <b>«{searchWords.trim()}»</b> - пользователь не
                  найден
                </UserNotFoundMessage>
              )
            )}
          </>
        ) : (
          <LoaderContainer>
            <SpinnerLoader />
          </LoaderContainer>
        )}
      </UsersListWrapper>

      <ScrollToTopVirtuoso isVisible={isScrollVisible} onClick={scrollToTop} />
    </>
  );
};

export default memo(UsersList);
