import React, { memo } from "react";
import AboutCourseAuthorsItem from "./about_course_authors_item";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const AboutCourseAuthorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 506px;
  margin: 60px auto 0;

  @media (max-width: 570px) {
    padding: 0 30px;
  }

  @media (max-width: 514px) {
    width: 100%;
    margin: 50px auto 0;
  }

  @media (max-width: 412px) {
    margin: 40px auto 0;
    padding: 0 20px;
  }

  & > h5 {
    color: #1e1e1e;
    font-size: 23px;
    font-weight: 600;
    line-height: 120%;

    @media (max-width: 420px) {
      font-size: 20px;
    }
  }
`;

const AboutCourseAuthors = ({ courseData }) => {
  const { t } = useTranslation();

  const authorsBlockTitle =
    courseData?.course_authors?.length === 1
      ? t("course-page.course-authors-label.one")
      : t("course-page.course-authors-label.several");

  return (
    <>
      {!!courseData?.course_authors?.length && (
        <AboutCourseAuthorsContainer>
          <h5>{authorsBlockTitle}</h5>
          {courseData?.course_authors?.map((author, index) => {
            return (
              <AboutCourseAuthorsItem
                key={"course_author-" + index}
                authorId={author._id}
              />
            );
          })}
        </AboutCourseAuthorsContainer>
      )}
    </>
  );
};

export default memo(AboutCourseAuthors);
