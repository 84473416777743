import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import AdminPageCalendarArrow from "../../../../../../common/assets/icons/admin_page_icons/admin_page_calendar_arrow";

const BiblePlaceDropdownBooksContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    overflow: auto;
    max-height: calc(100vh - 165px);
  }

  @media (max-width: 800px) {
    max-height: calc(100vh - 84px);
  }
`;

const BiblePlaceDropdownBookList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`;

const BiblePlaceDropdownBook = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 0px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "rgb(97 105 236)" : "#cacaca")};
  transition: 0.2s;

  &:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

  @media (max-width: 1024px) {
    height: 35px;
    font-size: 14px;
  }
`;

const BibleBookChaptersArrowDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
  height: 18px;
  width: 20px;
  transform: ${({ extendedBook }) =>
    extendedBook ? "rotate(90deg)" : "rotate(-90deg)"};
  cursor: pointer;

  &: hover {
    transform: scale(1.2)
      ${({ extendedBook }) =>
        extendedBook ? "rotate(90deg)" : "rotate(-90deg)"};
    transition: 0.2s;
  }
`;

const BibleBookChapterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media (max-width: 1024px) {
    gap: 7px;
  }
`;

const BibleBookChapter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background: ${({ isSelected }) =>
    isSelected ? "rgb(97 105 236)" : "#cacaca"};
  color: #fff;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    transition: 0.2s;
  }

  @media (max-width: 1024px) {
    height: 40px;
    width: 40px;
    font-size: 16px;
  }
`;

const BiblePlaceDropdownBooks = ({
  bibleBooks,
  selectedBook,
  setSelectedBook,
  selectedChapters,
  setSelectedChapters,
}) => {
  const [chaptersList, setChaptersList] = useState([]);
  const [extendedBook, setExtendedBook] = useState(false);

  const createChaptersArray = (number) =>
    Array.from({ length: number }, (_, index) => index + 1);

  useEffect(() => {
    if (selectedBook?.book_name) {
      setChaptersList(createChaptersArray(selectedBook.book_chapters));
    } else {
      setChaptersList([]);
    }
  }, [selectedBook]);

  useEffect(() => {
    setSelectedChapters([]);
    setExtendedBook(false);
  }, [selectedBook]);

  const handleExtendBook = useCallback(() => {
    setExtendedBook((prevState) => !prevState);
  }, []);

  const handleSelectChapters = useCallback(
    (chapter) => {
      if (selectedChapters?.length) {
        selectedChapters.sort((a, b) => a - b);
        const firstNum = selectedChapters[0];
        const lastNum = selectedChapters[selectedChapters.length - 1];

        if (!selectedChapters.includes(chapter)) {
          if (chapter === firstNum - 1) {
            setSelectedChapters((prevState) => [chapter, ...prevState]);
          } else if (chapter === lastNum + 1) {
            setSelectedChapters((prevState) => [...prevState, chapter]);
          } else {
            setSelectedChapters([chapter]);
          }
        } else {
          if (chapter === firstNum || chapter === lastNum) {
            const withoutCurrentChapter = selectedChapters
              .filter((curChapter) => curChapter !== chapter)
              .sort();
            if (withoutCurrentChapter.length) {
              setSelectedChapters(withoutCurrentChapter);
            } else {
              setSelectedChapters([]);
            }
          } else {
            setSelectedChapters([]);
          }
        }
      } else {
        setSelectedChapters([chapter]);
      }
    },
    [selectedChapters]
  );

  return (
    <BiblePlaceDropdownBooksContainer>
      <BiblePlaceDropdownBookList>
        {bibleBooks.map((book) => {
          return (
            <BiblePlaceDropdownBook
              key={book.book_code}
              selected={book.book_code === selectedBook.book_code}
              onClick={() => setSelectedBook(book)}
            >
              {book.book_name}
            </BiblePlaceDropdownBook>
          );
        })}
      </BiblePlaceDropdownBookList>

      {selectedBook?.book_name && (
        <BibleBookChaptersArrowDown
          extendedBook={extendedBook}
          onClick={handleExtendBook}
        >
          <AdminPageCalendarArrow />
        </BibleBookChaptersArrowDown>
      )}

      {chaptersList && extendedBook && (
        <BibleBookChapterList>
          {chaptersList.map((chapter) => {
            const isSelected = selectedChapters.includes(chapter);
            return (
              <BibleBookChapter
                key={chapter + "chapter"}
                isSelected={isSelected}
                onClick={() => handleSelectChapters(chapter)}
              >
                {chapter}
              </BibleBookChapter>
            );
          })}
        </BibleBookChapterList>
      )}
    </BiblePlaceDropdownBooksContainer>
  );
};

export default BiblePlaceDropdownBooks;
