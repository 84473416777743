import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import SpinnerLoader from "../../../../../common/components/loaders/spinner_loader";
import SearchVerse from "../../../bible_page_desktop/bible_page_search_desktop/search_verse";
import BibleChapterModalWindow from "../../../../../common/components/modal_windows/bible_chapter_modal_window";
import { useBiblePageContext } from "../../../bible_page_context";
import BibleModalWindow from "../../../../../common/components/modal_windows/bible_modal_window";

const BiblePageSearchResultsContainer = styled.div`
  height: calc(100% - 52px);
  overflow: hidden;
`;

const SearchVersesResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding: 0 12px 100px;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eaeaea;
  }
`;

const EmptySearchResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 0 50px 0 40px;
  font-size: 16px;
  line-height: 140%;
  color: #383838;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const BibleSearchResultsMobile = ({
  bibleSearchResults,
  currentTranslation,
  bibleSearchInput,
  isLoading,
  setActiveScreenMobile,
}) => {
  const { t } = useTranslation();

  const searchVersesResultsRef = useRef(null);

  const [visibleResultsCount, setVisibleResultsCount] = useState(20);
  //                     bible_modal_windows_states
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  const totalResults = bibleSearchResults?.searchResults?.length || 0;
  const visibleSearchResults =
    bibleSearchResults?.searchResults?.slice(0, visibleResultsCount) || [];

  useEffect(() => {
    if (searchVersesResultsRef.current) {
      searchVersesResultsRef.current.scrollTop = 0;
    }
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    setVisibleResultsCount(20);
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    const scrollContainer = searchVersesResultsRef.current;
    const handleScroll = () => {
      if (scrollContainer) {
        const scrolledHeight =
          scrollContainer.scrollTop + scrollContainer.clientHeight;
        const totalHeight = scrollContainer.scrollHeight;
        if (scrolledHeight >= totalHeight) {
          const newVisibleResults = visibleResultsCount + 20;
          if (newVisibleResults <= totalResults) {
            setVisibleResultsCount(newVisibleResults);
          }
        }
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleResultsCount, totalResults]);

  return (
    <BiblePageSearchResultsContainer>
      {!isLoading &&
        !bibleSearchResults?.searchResults?.length &&
        bibleSearchInput && (
          <EmptySearchResult>
            <p>
              {t("bible-page.search.empty-result", {
                searchWord: bibleSearchResults.searchWord || bibleSearchInput,
              })}
            </p>
          </EmptySearchResult>
        )}

      {!isLoading ? (
        <SearchVersesResults ref={searchVersesResultsRef}>
          {visibleSearchResults?.map((verse) => (
            <SearchVerse
              key={verse._id}
              verse={verse}
              bibleSearchWord={bibleSearchResults.searchWord.trim()}
              currentTranslation={currentTranslation}
              setBibleVersesData={setBibleVersesData}
              setOpenBibleModalWindow={setOpenBibleModalWindow}
            />
          ))}
        </SearchVersesResults>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            bibleVersesData={bibleVersesData}
            setBibleVersesData={setBibleVersesData}
            inComponent={"search_modal"}
            fromSearchToBiblePage={() => setActiveScreenMobile("bible_chapter")}
          />,
          document.body
        )}
    </BiblePageSearchResultsContainer>
  );
};

export default memo(BibleSearchResultsMobile);
