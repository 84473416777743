import React, { memo } from "react";
import styled from "styled-components";
import TranslationPanel from "../bible_modal_navigation/translation_panel";
import BibleModalBooksNav from "./bible_modal_books_nav";
import BibleModalSearchPanel from "./bible_modal_search_panel";
import BibleModalSearchPlaceButtons from "./bible_modal_search_place_buttons";

const BibleModalNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 42px);
`;

const BibleModalNavigation = ({
  bibleTranslations,
  currentTranslation,
  currentBibleParams,
  setBibleTranslations,
  setCurrentBibleParams,
  setSelectedVerses,
  bibleModalSearchPlace,
  bibleModalSearchInput,
  currentTranslationData,
  setBibleModalSearchInput,
  setSearchIsLoading,
  activeScreenDesktop,
  setActiveScreenDesktop,
  setBibleModalSearchResults,
  setBibleModalSearchPlace,
  inComponent,
}) => {
  return (
    <BibleModalNavigationContainer>
      {inComponent !== "search_modal" && (
        <BibleModalSearchPanel
          currentBibleParams={currentBibleParams}
          bibleModalSearchPlace={bibleModalSearchPlace}
          bibleModalSearchInput={bibleModalSearchInput}
          setBibleModalSearchInput={setBibleModalSearchInput}
          setSearchIsLoading={setSearchIsLoading}
          activeScreenDesktop={activeScreenDesktop}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setBibleModalSearchResults={setBibleModalSearchResults}
        />
      )}

      <TranslationPanel
        currentTranslation={currentTranslation}
        bibleTranslations={bibleTranslations}
        setBibleTranslations={setBibleTranslations}
        setCurrentBibleParams={setCurrentBibleParams}
        setSelectedVerses={setSelectedVerses}
      />

      {activeScreenDesktop === "bible_modal_chapter" && (
        <BibleModalBooksNav
          currentTranslation={currentTranslation}
          currentBibleParams={currentBibleParams}
          setSelectedVerses={setSelectedVerses}
          setCurrentBibleParams={setCurrentBibleParams}
          currentTranslationData={currentTranslationData}
        />
      )}

      {activeScreenDesktop === "bible_modal_search" && (
        <BibleModalSearchPlaceButtons
          bibleModalSearchPlace={bibleModalSearchPlace}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
        />
      )}
    </BibleModalNavigationContainer>
  );
};

export default memo(BibleModalNavigation);
