import React, { useCallback, useState } from "react";
import styled from "styled-components";
import BibleModalWindow from "../bible_modal_window";
import AddBibleVerse from "../../added_bible_verse";
import { AskQuestionLabel } from ".";
import { useTranslation } from "react-i18next";

const QuestionBibleVersesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 5px;
  max-height: 42vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

const QuestionBibleVerses = ({ userQuestionData, setUserQuestionData }) => {
  const { t } = useTranslation();
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [clickedButton, setClickedButton] = useState({});
  const [bibleVersesData, setBibleVersesData] = useState({});

  const handleRemoveVerse = useCallback(
    (verseInfo) => {
      setUserQuestionData((prevState) => {
        if (prevState?.question_bible_verses?.length !== 1) {
          const updatedBibleVerses = prevState.question_bible_verses.filter(
            (verse) => verse.verseId !== verseInfo.verseId
          );

          return { ...prevState, question_bible_verses: updatedBibleVerses };
        } else return { ...prevState, question_bible_verses: [1] };
      });
    },
    [setUserQuestionData]
  );

  const handleOpenBibleModalWindow = useCallback(() => {
    setOpenBibleModalWindow(true);
  }, []);

  const handleAddBibleVerses = useCallback(
    (addedVerse) => {
      if (clickedButton.button === "plus_button") {
        setUserQuestionData((prevState) => {
          const notIsDublicate = !prevState.question_bible_verses.some(
            (verse) => verse.verseId === addedVerse.verseId
          );

          if (notIsDublicate) {
            return prevState.question_bible_verses?.[0] !== 1
              ? {
                  ...prevState,
                  question_bible_verses: [
                    ...prevState.question_bible_verses,
                    addedVerse,
                  ],
                }
              : { ...prevState, question_bible_verses: [addedVerse] };
          } else return prevState;
        });
      }

      if (clickedButton.button === "address_button") {
        setUserQuestionData((prevState) => {
          if (prevState.question_bible_verses[0] !== 1) {
            const updatedVerses = prevState.question_bible_verses.map((verse) =>
              verse.verseId === clickedButton.verseId ? addedVerse : verse
            );
            return { ...prevState, question_bible_verses: updatedVerses };
          } else return { ...prevState, question_bible_verses: [addedVerse] };
        });
      }
      setClickedButton({});
    },
    [clickedButton, setUserQuestionData]
  );

  return (
    <div>
      <AskQuestionLabel>
        {t("ask-question-modal.bible-verses-label")}
      </AskQuestionLabel>

      <QuestionBibleVersesContainer>
        {userQuestionData?.question_bible_verses?.map((verse, index, array) => {
          return (
            <AddBibleVerse
              key={index * 4444}
              verseInfo={verse}
              isLastElement={index === array.length - 1}
              handleRemoveVerse={handleRemoveVerse}
              setBibleVersesData={setBibleVersesData}
              handleOpenBibleModalWindow={handleOpenBibleModalWindow}
              setClickedButton={setClickedButton}
              inComponent={"home_page"}
            />
          );
        })}
      </QuestionBibleVersesContainer>

      {openBibleModalWindow && (
        <BibleModalWindow
          bibleVersesData={bibleVersesData}
          setBibleVersesData={setBibleVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          inComponent={"home_page"}
        />
      )}
    </div>
  );
};

export default QuestionBibleVerses;
