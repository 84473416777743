import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import QuestionsFilterByStatus from "./questions_filter_by_status";
import QuestionsFilterByRecipient from "./questions_filter_by_recipient";
import QuestionsFilterByBible from "./questions_filter_by_bible";
import QuestionsSelectedFilters from "./questions_selected_filters";
import QuestionsFilterBySearchWords from "./questions_filter_by_search_words";
import QuestionsFilterByDate from "./questions_filter_by_date";
import { useUserDataContext } from "../../../../../user_data_context";
import QuestionsFilterByPlace from "./questions_filter_by_place";
import QuestionsFiltersIcon from "../../../../../common/assets/icons/admin_page_icons/questions_filters_icon";
import QuestionsMoreSettings from "./questions_more_settings";
import useWindowBreakpoint from "../../../../../common/hooks/use_window_breakpoint";

const QuestionsFiltersContainer = styled.div`
  max-width: 635px;
  margin-left: 70px;
  height: max-content;

  @media (max-width: 1250px) {
    margin-left: 50px;
  }

  @media (max-width: 1200px) {
    margin: 0 auto;
  }

  @media (max-width: 660px) {
    margin: 0 10px;
  }
`;

const QuestionsFiltersSettings = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  min-height: 36px;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    margin: 0 auto 10px;
  }
`;

const FiltersSettingsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const QuestionsFiltersWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
`;

const QuestionsFiltersButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  min-width: 42px;
  height: 38px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  span {
    font-size: 18px;
    color: #383838;
  }
`;

const CloseFiltersButton = styled(QuestionsFiltersButton)`
  display: none;

  @media (max-width: 650px) {
    display: flex;
  }
`;

const QuestionsFilter = ({
  questionsFilterSettings,
  setQuestionsFilterSettings,
  showUsersName,
  setShowUsersName,
}) => {
  const { accessRights } = useUserDataContext();
  const [extendedOption, setExtendedOption] = useState(""); // status, recipient, place, date, bible, more
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false); // Новое состояние

  const isMobile = useWindowBreakpoint(650);

  useEffect(() => {
    if (!isMobile) {
      if (!showSearchInput) {
        setShowFilters(true);
      } else {
        setShowFilters(false);
      }
    } else {
      setIsMobileFiltersOpen(false);
    }
  }, [isMobile, showSearchInput]);

  const handleExtendOptions = useCallback(
    (event, optionName) => {
      event.stopPropagation();

      if (extendedOption === optionName) {
        setExtendedOption("");
      } else {
        setExtendedOption(optionName);
      }
    },
    [extendedOption]
  );

  const removeSelectedFilterOption = useCallback(
    (option, itemIndex) => {
      setQuestionsFilterSettings((prevState) => {
        const isArrayFilter = [
          "question_bible",
          "search_words",
          "question_date_range",
          "question_recipient",
        ].includes(option);

        return {
          ...prevState,
          [option]: isArrayFilter
            ? prevState[option].filter((_, index) => index !== itemIndex)
            : "",
        };
      });
    },
    [setQuestionsFilterSettings]
  );

  const handleShowFiltersSettings = useCallback(
    (event) => {
      event.stopPropagation();

      if (!isMobile) {
        setShowSearchInput(false);
        setShowFilters(true);
      } else {
        setIsMobileFiltersOpen((prevState) => !prevState);
      }
    },
    [isMobile]
  );

  return (
    <QuestionsFiltersContainer>
      <QuestionsFiltersSettings onMouseDown={(e) => e.stopPropagation()}>
        {isMobile && showSearchInput && (
          <QuestionsFiltersButton onClick={() => setShowSearchInput(false)}>
            <span>✖</span>
          </QuestionsFiltersButton>
        )}

        {isMobile && !showSearchInput && !isMobileFiltersOpen && (
          <QuestionsFiltersButton onClick={handleShowFiltersSettings}>
            <QuestionsFiltersIcon />
          </QuestionsFiltersButton>
        )}

        {!isMobile && !showFilters && (
          <QuestionsFiltersButton onClick={handleShowFiltersSettings}>
            <QuestionsFiltersIcon />
          </QuestionsFiltersButton>
        )}

        {(isMobileFiltersOpen || (!isMobile && showFilters)) && (
          <>
            <FiltersSettingsWrapper>
              <QuestionsFilterByStatus
                extendedOption={extendedOption}
                handleExtendOptions={handleExtendOptions}
                setQuestionsFilterSettings={setQuestionsFilterSettings}
                inComponent={"admin"}
              />

              {(accessRights.isAdminOfPlatformQuestions ||
                accessRights.isSuperadmin) && (
                <QuestionsFilterByRecipient
                  extendedOption={extendedOption}
                  handleExtendOptions={handleExtendOptions}
                  setQuestionsFilterSettings={setQuestionsFilterSettings}
                />
              )}

              <QuestionsFilterByPlace
                extendedOption={extendedOption}
                handleExtendOptions={handleExtendOptions}
                setQuestionsFilterSettings={setQuestionsFilterSettings}
              />

              <QuestionsFilterByDate
                extendedOption={extendedOption}
                handleExtendOptions={handleExtendOptions}
                setQuestionsFilterSettings={setQuestionsFilterSettings}
              />

              <QuestionsFilterByBible
                extendedOption={extendedOption}
                handleExtendOptions={handleExtendOptions}
                setQuestionsFilterSettings={setQuestionsFilterSettings}
              />

              <QuestionsMoreSettings
                extendedOption={extendedOption}
                handleExtendOptions={handleExtendOptions}
                showUsersName={showUsersName}
                setShowUsersName={setShowUsersName}
                questionsFilterSettings={questionsFilterSettings}
                setQuestionsFilterSettings={setQuestionsFilterSettings}
              />
            </FiltersSettingsWrapper>

            {isMobileFiltersOpen && (
              <CloseFiltersButton onClick={handleShowFiltersSettings}>
                <span>✖</span>
              </CloseFiltersButton>
            )}
          </>
        )}

        {(!isMobile || !isMobileFiltersOpen) && (
          <QuestionsFilterBySearchWords
            setQuestionsFilterSettings={setQuestionsFilterSettings}
            showSearchInput={showSearchInput}
            setShowSearchInput={setShowSearchInput}
          />
        )}

        {!!extendedOption && (
          <QuestionsFiltersWrapper onClick={() => setExtendedOption("")} />
        )}
      </QuestionsFiltersSettings>

      <QuestionsSelectedFilters
        questionsFilterSettings={questionsFilterSettings}
        removeSelectedFilterOption={removeSelectedFilterOption}
        accessRights={accessRights}
      />
    </QuestionsFiltersContainer>
  );
};

export default memo(QuestionsFilter);
