import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import useWindowBreakpoint from "../../hooks/use_window_breakpoint";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  handleBibleVerseClick,
  getCustomOptions,
} from "../text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../text_editor/editor_styled_components";
import CertificateCloseIcon from "../../assets/icons/course_certificate_icons/certificate_close_icon";

const TextDefinitionModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const TextDefinitionModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 600px;
  max-height: 98vh;
  padding: 24px 25px;
  border-radius: 20px;
  background: ${({ darkTheme }) => (darkTheme ? "rgb(41 41 41)" : "#eaeaea")};
  ${({ colorTheme }) => colorTheme === "DARK" && "color: #fff"};

  @media (max-width: 610px) {
    width: 100%;
    margin: 15px;
    border-radius: 15px;
    padding: 20px 16px 20px 20px;
  }

  @media (max-width: 420px) {
    border-radius: 0px;
    margin: 0px;
    max-height: 100%;
  }
`;

const TextDefinition = styled(TextEditorViewComponent)`
  font-size: 16px;
  line-height: 135%;
  overflow: auto;
  padding-right: 3px;

  ::-webkit-scrollbar-thumb {
    background-color: ${({ darkTheme }) => (darkTheme ? "#cacaca" : "#B0B0B0")};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ darkTheme }) =>
      darkTheme ? "#444444" : "rgb(227 227 227)"};
  }

  @media (max-width: 1024px) {
    ::-webkit-scrollbar {
      width: 4px;
    }
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: ${({ courseMainColor }) => courseMainColor || "#5172ea"};
  box-shadow: 0px 0.894px 8.941px 0px rgba(0, 0, 0, 0.15);
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 430px) {
    top: 10px;
    right: 10px;
  }
`;

const LessonTextDefinitionModal = ({
  textDefinitionModal,
  userData,
  colorTheme,
  setOpen,
  setTextBibleModalData,
  courseMainColor,
  courseMainGradient,
}) => {
  const containerRef = useRef(null);
  const [html, setHtml] = useState("");

  const sanitizedHtml = DOMPurify.sanitize(html);
  const isTablet = useWindowBreakpoint(610);
  const currentUserName = userData?.user_name || "Пользователь";

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen({});
  }, []);

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        handleClose(e);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  useEffect(() => {
    if (textDefinitionModal?.definition?.blocks?.length) {
      try {
        let definition = convertFromRaw(textDefinitionModal.definition);

        const options = getCustomOptions(colorTheme);
        let htmlContent = stateToHTML(definition, options);

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert note from raw:", error);
      }
    }
  }, [textDefinitionModal?.definition, currentUserName, colorTheme]);

  useEffect(() => {
    const container = containerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");
    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [html]);

  return (
    <TextDefinitionModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <TextDefinitionModalContainer
        ref={containerRef}
        darkTheme={colorTheme === "DARK"}
        colorTheme={colorTheme}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <TextDefinition
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
          courseMainColor={courseMainColor}
          courseMainGradient={courseMainGradient}
          colorTheme={colorTheme}
          darkTheme={colorTheme === "DARK"}
        />

        {isTablet && (
          <CloseButton courseMainColor={courseMainColor} onClick={handleClose}>
            <CertificateCloseIcon color="#fff" />
          </CloseButton>
        )}
      </TextDefinitionModalContainer>
    </TextDefinitionModalWrapper>
  );
};

export default memo(LessonTextDefinitionModal);
