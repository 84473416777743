import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../assets/icons/vertical_line";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../pages/bible/bible_page_data";
import { fetchAndSortBibleBooks } from "../../../utils/functions/bible_page_functions";
import BibleChapterArrows from "../bible_modal_window/bible_modal_window_desktop/bible_modal_window_verses/bible_chapter_arrows";

const BibleChapterModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const BibleChapterAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  width: 215px;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;

  &: hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.2s;
  }
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 140%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BibleChapterModalHeader = ({
  chapterData,
  bibleLocale,
  currentBibleParams,
  selectedVerses,
  setBibleVersesData,
  setCurrentBibleParams,
  setOpenBibleModalWindow,
  setSelectedVerses,
  setOpen,
  inComponent,
}) => {
  const [currentTranslationData, setCurrentTranslationData] = useState([]);

  const abbreviation = chapterData?.[0]?.bible_code.toUpperCase();
  const bibleChapterAddress =
    BIBLE_BOOK_NAMES_DATA?.[bibleLocale]?.[currentBibleParams?.bookId]?.name +
    " " +
    currentBibleParams?.chapterId;

  const onClickChapterAddress = useCallback(
    (event) => {
      event.stopPropagation();
      const { bibleId, bookId, chapterId } = currentBibleParams;

      const bibleVersesData = {
        versesData: selectedVerses.map((verse) => ({
          verse_num: +verse.verse_num,
        })),
        versesParams: {
          bibleId: bibleId,
          bookId: bookId,
          chapterId: chapterId,
        },
      };

      setBibleVersesData(bibleVersesData);
      setOpenBibleModalWindow(true);
      setOpen({});
    },
    [currentBibleParams, inComponent, selectedVerses]
  );

  useEffect(() => {
    const loadTranslationData = async () => {
      if (bibleLocale && chapterData?.length) {
        try {
          const sortedBooks = await fetchAndSortBibleBooks(
            chapterData?.[0]?.bible_code,
            bibleLocale
          );

          setCurrentTranslationData(sortedBooks);
        } catch (error) {
          console.error("Ошибка загрузки книг Библии", error);
        }
      }
    };

    loadTranslationData();
  }, [bibleLocale, chapterData]);

  return (
    <BibleChapterModalHeaderContainer>
      <BibleChapterAddressContainer onClick={onClickChapterAddress}>
        <BibleTranslationVersion>{abbreviation}</BibleTranslationVersion>
        <VerticalLineStyled />
        <BibleChapterAddress>{bibleChapterAddress}</BibleChapterAddress>
      </BibleChapterAddressContainer>

      <BibleChapterArrows
        chapterData={chapterData}
        currentTranslationData={currentTranslationData}
        currentBibleParams={currentBibleParams}
        setCurrentBibleParams={setCurrentBibleParams}
        setSelectedVerses={setSelectedVerses}
      />
    </BibleChapterModalHeaderContainer>
  );
};

export default memo(BibleChapterModalHeader);
