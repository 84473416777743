import React from "react";

const TextAlignCenterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.8125 4.2C0.8125 3.53726 1.34976 3 2.0125 3H21.0125C21.6752 3 22.2125 3.53726 22.2125 4.2C22.2125 4.86274 21.6752 5.4 21.0125 5.4H2.0125C1.34976 5.4 0.8125 4.86274 0.8125 4.2Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8125 11.2001C4.8125 10.5374 5.34976 10.0001 6.0125 10.0001H17.0125C17.6752 10.0001 18.2125 10.5374 18.2125 11.2001C18.2125 11.8628 17.6752 12.4001 17.0125 12.4001H6.0125C5.34976 12.4001 4.8125 11.8628 4.8125 11.2001Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8125 18.8C2.8125 18.1373 3.34976 17.6 4.0125 17.6H19.0125C19.6752 17.6 20.2125 18.1373 20.2125 18.8C20.2125 19.4627 19.6752 20 19.0125 20H4.0125C3.34976 20 2.8125 19.4627 2.8125 18.8Z"
        fill="#383838"
      />
    </svg>
  );
};

export default TextAlignCenterIcon;
