import React, { memo, useCallback, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import axios from "../../axios_config";
import { BASE_API_URL } from "../../common/endpoints";
import { createPortal } from "react-dom";
import FinishLessonButtonModal from "./user_lesson_modal_windows/finish_lesson_button_modal";
import { useTranslation } from "react-i18next";

const FinishLessonButtonWrapper = styled(motion.div)`
  width: max-content;
  margin: 0 auto;
`;

const FinishLessonButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: max-content;
  padding: 0 36px;
  margin: 0 auto;
  border-radius: 15px;
  background: ${({ background }) => background};
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
  user-select: none;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (max-width: 720px) {
    font-size: 20px;
    border-radius: 12px;
  }

  @media (max-width: 412px) {
    margin-bottom: 100px;
  }
`;

const validateLessonData = (lessonData) => {
  return !lessonData.some((item) => {
    switch (item.type) {
      case "TEST":
        return !item?.data.some((option) => option.user_answer === true);

      case "QUESTION":
        if (item.answer_type === "input") {
          return !item.answer_input;
        } else if (item.answer_type === "options") {
          return !item.selected_answer;
        }
        return false;

      default:
        return false;
    }
  });
};

const FinishLessonButton = ({
  courseMainGradient,
  userLessonInfo,
  userAnswersData,
  filteredComponents,
  setLessonIsFinished,
  setAfterFinishLessonModal,
  lessonIsTest,
  testResults,
  certificatePassRate,
  setTestSuccessModal,
  setTestFailedModal,
}) => {
  const { t } = useTranslation();
  const [hasUnfilledItems, setHasUnfilledItems] = useState(false);

  const finishLessonButtonText = !lessonIsTest
    ? t("lesson-page.finish-lesson-btn.lesson")
    : t("lesson-page.finish-lesson-btn.test");

  const onClickFinishLesson = useCallback(async () => {
    const { _id } = userLessonInfo;

    const newUserAnswersData = {
      study_answers: userAnswersData,
      user_test_pass_rate: testResults,
    };

    try {
      await axios.patch(`${BASE_API_URL}/userstudy/${_id}`, newUserAnswersData);

      localStorage.removeItem(userLessonInfo._id);
      setLessonIsFinished(true);

      if (!lessonIsTest) {
        setAfterFinishLessonModal(true);
      } else {
        if (testResults >= certificatePassRate) {
          setTestSuccessModal(true);
        } else {
          setTestFailedModal(true);
        }
      }
    } catch (error) {
      console.error("Ошибка завершения урока или Теста пользователя:", error);
    }
  }, [
    userLessonInfo,
    userAnswersData,
    testResults,
    lessonIsTest,
    certificatePassRate,
  ]);

  const onClickFinishButton = useCallback(() => {
    if (!filteredComponents?.length) return;

    if (validateLessonData(filteredComponents)) {
      onClickFinishLesson();
    } else {
      setHasUnfilledItems(true);
    }
  }, [filteredComponents, onClickFinishLesson]);

  return (
    <FinishLessonButtonWrapper
      whileTap={{
        scale: 0.9,
        transition: { type: "spring", stiffness: 500, damping: 15 },
      }}
    >
      <FinishLessonButtonContainer
        background={courseMainGradient}
        onClick={onClickFinishButton}
      >
        {finishLessonButtonText}
      </FinishLessonButtonContainer>

      {hasUnfilledItems &&
        createPortal(
          <FinishLessonButtonModal
            isTest={lessonIsTest}
            setOpen={setHasUnfilledItems}
            onClickFinishLesson={onClickFinishLesson}
          />,
          document.body
        )}
    </FinishLessonButtonWrapper>
  );
};

export default memo(FinishLessonButton);
