import React, { memo } from "react";
import styled from "styled-components";
import MainBlockButtons from "./main_block_buttons";
import GroupsList from "./groups_list";

const AdminGroupsMainBlockContainer = styled.div`
  width: 100%;
`;

const AdminGroupsMainBlock = () => {
  return (
    <AdminGroupsMainBlockContainer>
      <MainBlockButtons />
      <GroupsList />
    </AdminGroupsMainBlockContainer>
  );
};

export default memo(AdminGroupsMainBlock);
