import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VisibilityWrapperComponent from "../../common/components/lesson/visibility_wrapper_component";
import SpinnerLoader from "../../common/components/loaders/spinner_loader";
import { LESSON_COMPONENTS_MAPPING } from "../../common/components/lesson/lesson_utils";
import {
  handleBibleVerseClick,
  handleDefinitionClick,
} from "../../common/components/text_editor/text_editor_functions";
import FinishLessonButton from "./finish_lesson_button";
import LessonTextDefinitionModal from "../../common/components/lesson/lesson_text_definition_modal";
import { createPortal } from "react-dom";

const LessonComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 80px 15px 45px;
  max-width: 620px;
  margin: 0 auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
`;

const UserLessonComponentsList = ({
  userData,
  lessonData,
  userLessonPageRef,
  isLoading,
  filteredComponents,
  appColorTheme,
  courseMainColor,
  authors,
  courseMainGradient,
  lessonIsFinished,
  lessonIsTest,
  userAnswersData,
  shouldShowFinishButton,
  userLessonInfo,
  testResults,
  certificatePassRate,
  setLessonData,
  setUserAnswersData,
  setTextBibleModalData,
  setLessonIsFinished,
  setAfterFinishLessonModal,
  setTestSuccessModal,
  setTestFailedModal,
}) => {
  const containerRef = useRef(null);
  const [textEditorComponentHtml, setTextEditorComponentHtml] = useState(null);
  const [textDefinitionModal, setTextDefinitionModal] = useState({});

  useEffect(() => {
    const container = containerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");
    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [textEditorComponentHtml]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const definitionElements = container.querySelectorAll(".highlighted-text");
    if (!definitionElements.length) return;

    const handleClick = (event) => {
      handleDefinitionClick(event, (definitionId) => {
        const definitionData = lessonData?.lesson_vocabulary?.[definitionId];
        if (definitionData) {
          setTextDefinitionModal(definitionData);
        }
      });
    };

    definitionElements.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      definitionElements.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [textEditorComponentHtml, lessonData?.lesson_vocabulary]);

  return (
    <>
      {!isLoading ? (
        <LessonComponentList ref={containerRef}>
          {filteredComponents?.map((component) => {
            const CurrentLessonComponent =
              LESSON_COMPONENTS_MAPPING?.[component?.type]?.component;

            return (
              <VisibilityWrapperComponent
                key={component.id}
                withAnimation={true}
              >
                <CurrentLessonComponent
                  userData={userData}
                  userLessonPageRef={userLessonPageRef}
                  componentData={component}
                  authors={authors}
                  withAnimation={true}
                  lessonIsFinished={lessonIsFinished}
                  setLessonData={setLessonData}
                  setTextEditorComponentHtml={setTextEditorComponentHtml}
                  setTextBibleModalData={setTextBibleModalData}
                  inComponent="user_lesson"
                  colorTheme={appColorTheme}
                  courseMainColor={courseMainColor}
                  courseMainGradient={courseMainGradient}
                  setUserAnswersData={setUserAnswersData}
                />
              </VisibilityWrapperComponent>
            );
          })}

          {shouldShowFinishButton && !!courseMainGradient && (
            <FinishLessonButton
              userLessonInfo={userLessonInfo}
              userAnswersData={userAnswersData}
              courseMainGradient={courseMainGradient}
              filteredComponents={filteredComponents}
              lessonIsTest={lessonIsTest}
              testResults={testResults}
              certificatePassRate={certificatePassRate}
              setLessonIsFinished={setLessonIsFinished}
              setAfterFinishLessonModal={setAfterFinishLessonModal}
              setTestSuccessModal={setTestSuccessModal}
              setTestFailedModal={setTestFailedModal}
            />
          )}
        </LessonComponentList>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {!!textDefinitionModal.text &&
        createPortal(
          <LessonTextDefinitionModal
            textDefinitionModal={textDefinitionModal}
            userData={userData}
            setOpen={setTextDefinitionModal}
            setTextBibleModalData={setTextBibleModalData}
            colorTheme={appColorTheme}
            courseMainColor={courseMainColor}
            courseMainGradient={courseMainGradient}
          />,
          document.body
        )}
    </>
  );
};

export default memo(UserLessonComponentsList);
