import React, { memo } from "react";
import styled from "styled-components";
import QuestionsFilters from "./questions_filters";
import UsersQuestionsList from "./users_questions_list";

const AdminPageQuestionsListContainer = styled.div`
  flex: 1;
  padding-top: 30px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 120px;

  @media (max-width: 1024px) {
    padding-top: 5px;
  }
`;

const AdminPageQuestionsList = ({
  questionsList,
  questionSettingsData,
  questionSettingsMemoData,
  questionsFilterSettings,
  showUsersName,
  setShowUsersName,
  setQuestionsFilterSettings,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  setOpenEditQuestionModal,
  setTextBibleModalData,
  isLoading,
  fetchQuestions,
}) => {
  return (
    <AdminPageQuestionsListContainer>
      <QuestionsFilters
        questionsFilterSettings={questionsFilterSettings}
        setQuestionsFilterSettings={setQuestionsFilterSettings}
        showUsersName={showUsersName}
        setShowUsersName={setShowUsersName}
      />
      <UsersQuestionsList
        questionsList={questionsList}
        questionsFilterSettings={questionsFilterSettings}
        questionSettingsData={questionSettingsData}
        questionSettingsMemoData={questionSettingsMemoData}
        showUsersName={showUsersName}
        setQuestionSettingsData={setQuestionSettingsData}
        setQuestionSettingsMemoData={setQuestionSettingsMemoData}
        setOpenEditQuestionModal={setOpenEditQuestionModal}
        setTextBibleModalData={setTextBibleModalData}
        setQuestionsFilterSettings={setQuestionsFilterSettings}
        isLoading={isLoading}
        fetchQuestions={fetchQuestions}
      />
    </AdminPageQuestionsListContainer>
  );
};

export default memo(AdminPageQuestionsList);
