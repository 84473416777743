import React, { memo, useCallback } from "react";
import styled from "styled-components";
import LeftArrowIcon from "../../common/assets/icons/left_arrow_icon";
import { useNavigate } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../common/utils/routes";
import LightThemeIcon from "../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/light_theme_icon";
import DarkThemeIcon from "../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/dark_theme_icon";
import useWindowBreakpoint from "../../common/hooks/use_window_breakpoint";
import LightShareIcon from "../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/light_share_icon";
import DarkShareIcon from "../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/lesson_play_mode_icons/dark_share_icon";
import { Slide, toast } from "react-toastify";
import copy from "copy-to-clipboard";
import AskQuestionLessonIcon from "../../common/assets/icons/lesson_icons/ask_question_lesson_icon";
import { useTranslation } from "react-i18next";

const LessonHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: ${({ background }) => background};
  position: fixed;
  z-index: 9999;
`;

const LessonHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
  max-width: 710px;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 80%;
  }

  @media (max-width: 830px) {
    max-width: 100%;
  }
`;

const LessonHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  height: 100%;
  width: 100%;

  @media (max-width: 1024px) {
    gap: 35px;
  }

  @media (max-width: 900px) {
    gap: 20px;
  }

  @media (max-width: 744px) {
    gap: 15px;
  }

  @media (max-width: 500px) {
    gap: 5px;
  }
`;

const LeftArrowIconContainer = styled.div`
  cursor: pointer;
  padding: 6px 15px;

  &:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const LessonHeaderTitle = styled.div`
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  color: #fff;

  padding: 2px 0;
  margin-right: 10px;

  @media (max-width: 730px) {
    font-size: 18px;
  }

  & > span {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin-right: 12px;
`;

const HeaderButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  ${({ background }) => `background: ${background};`}

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.2s;
  }
`;

const LessonQuestionButton = styled(HeaderButton)``;

const LessonShareButton = styled(HeaderButton)``;

const ColorThemeButton = styled(HeaderButton)`
  ${({ background }) => `background: ${background};`}
`;

const UserLessonPageHeader = ({
  userData,
  appColorTheme,
  lessonData,
  lessonNumber,
  courseData,
  lessonIsFinished,
  setAppColorTheme,
  setOpenUserQuestionModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isTablet = useWindowBreakpoint(1024);

  const courseFeedbackIsEnabled = courseData.course_feedback_is_enabled;

  const isTest =
    lessonData?.lesson_type === "test"
      ? true
      : lessonData?.lesson_type === "lesson"
      ? false
      : null;

  const headerBackground =
    appColorTheme === "DARK"
      ? "linear-gradient(180deg, rgba(81, 114, 234, 0.60) -18.38%, rgba(122, 90, 238, 0.60) 108.82%), #232323"
      : "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)";

  const headerButtonsBackground =
    appColorTheme === "LIGHT" ? "#fff" : "rgba(255, 255, 255, 0.25)";

  const notificationText = !isTest
    ? t("lesson-page.share-lesson-notification.test")
    : t("lesson-page.share-lesson-notification.lesson");

  const goToCoursePage = useCallback(
    (event) => {
      event.stopPropagation();

      navigate(
        APPLICATION_ROUTES.STUDY_COURSES + `/${lessonData?.lesson_course?._id}`
      );
    },
    [lessonData]
  );

  const handleLessonColorTheme = useCallback((event) => {
    event.stopPropagation();
    setAppColorTheme((prevState) => (prevState === "LIGHT" ? "DARK" : "LIGHT"));
  }, []);

  const handleAskQuestionModal = useCallback((event) => {
    event.stopPropagation();
    setOpenUserQuestionModal(true);
  }, []);

  const showNotification = () => {
    toast(notificationText, {
      position: "top-left",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const onClickShareLesson = useCallback(
    (event) => {
      event.stopPropagation();

      if (!lessonIsFinished) return;

      const baseUrl = window.location.origin;
      const lessonPath = isTest
        ? `${baseUrl}/shared_lesson/${userData._id}/${lessonData._id}`
        : `${baseUrl}/shared_lesson/${userData._id}/${lessonData._id}?n=${lessonNumber}`;

      try {
        copy(lessonPath);
        showNotification();
      } catch (err) {
        console.error("Ошибка при копировании: ", err);
        alert("Не удалось скопировать ссылку.");
      }
    },
    [userData, lessonData, lessonNumber, isTest, lessonIsFinished]
  );

  return (
    <LessonHeaderWrapper background={headerBackground}>
      <LessonHeaderContainer>
        <LessonHeaderContentWrapper>
          <LeftArrowIconContainer onClick={goToCoursePage}>
            <LeftArrowIcon />
          </LeftArrowIconContainer>

          <LessonHeaderTitle>
            {!isTest && isTest !== null && (
              <>
                {!isTablet && t("lesson-page.header-lesson-label")}{" "}
                {lessonNumber}.{" "}
              </>
            )}
            <span>{lessonData.lesson_name}</span>
          </LessonHeaderTitle>

          <HeaderButtonsContainer>
            {!isTest && courseFeedbackIsEnabled && (
              <LessonQuestionButton
                background={headerButtonsBackground}
                onClick={handleAskQuestionModal}
              >
                <AskQuestionLessonIcon
                  color={appColorTheme === "LIGHT" ? "#725FED" : "#fff"}
                />
              </LessonQuestionButton>
            )}

            {lessonIsFinished && (
              <LessonShareButton
                background={headerButtonsBackground}
                onClick={onClickShareLesson}
              >
                {appColorTheme === "LIGHT" ? (
                  <LightShareIcon />
                ) : (
                  <DarkShareIcon />
                )}
              </LessonShareButton>
            )}

            <ColorThemeButton
              background={
                appColorTheme === "LIGHT"
                  ? "linear-gradient(234deg, #9E00FF -17.21%, #63218E 36.71%, #55187C 52.72%, #301E46 103.33%, #1B1B1F 151.29%)"
                  : "linear-gradient(50deg, #0C7CAC -11.7%, #0EC5ED 110.56%)"
              }
              onClick={handleLessonColorTheme}
            >
              {appColorTheme === "DARK" ? (
                <LightThemeIcon />
              ) : (
                <DarkThemeIcon />
              )}
            </ColorThemeButton>
          </HeaderButtonsContainer>
        </LessonHeaderContentWrapper>
      </LessonHeaderContainer>
    </LessonHeaderWrapper>
  );
};

export default memo(UserLessonPageHeader);
