import React, { memo } from "react";
import { styleMap } from ".";
import { useTextSettingsBibleContext } from "./text_editor_bible_context";

const BibleVerseDecorator = ({ contentState, entityKey }) => {
  const { setOpenBibleModalWindow, setBibleVersesData } =
    useTextSettingsBibleContext();

  const addedVerse = contentState.getEntity(entityKey).getData();

  const handleClick = () => {
    setOpenBibleModalWindow(true);
    setBibleVersesData({
      ...addedVerse,
      id: entityKey,
    });
  };

  return (
    <button style={styleMap.BIBLE_VERSE} onClick={handleClick}>
      {addedVerse.verseAddress}
    </button>
  );
};

export default memo(BibleVerseDecorator);
