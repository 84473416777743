import React, { useCallback, useEffect, useState } from "react";
import axios from "../../../axios_config";
import styled from "styled-components";
import VerticalLine from "../../../common/assets/icons/vertical_line";
import { BASE_API_URL } from "../../../common/endpoints";
import { useTranslation } from "react-i18next";

const TestSuccessModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const TestSuccessModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  width: 510px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 15px;
    padding: 25px;
  }

  h5 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    line-height: 120%;

    @media (max-width: 420px) {
      font-size: 24px;
    }
  }

  h6 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 131.25%;

    @media (max-width: 420px) {
      font-size: 14px;
    }

    span {
      font-weight: 400;
      color: #f12b2b;
      line-height: 120%;
    }
  }
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 92%;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 54px;
  overflow: hidden;
`;

const ProgressBarFiller = styled.div`
  position: relative;
  height: 100%;
  width: ${({ width }) => width}%;
  background-color: #b6b6b6;
  transition: width 1.8s ease-in-out;
`;

const UserTestResult = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ smallResult }) => (smallResult ? "5px" : "50%")};
  transform: ${({ smallResult }) =>
    smallResult ? "translateY(-50%)" : "translate(-50%, -50%)"};
  color: #fff;
  border-radius: 10px;
  padding: 0 5px;
  background-color: #b6b6b6;
  font-size: 18px;
  font-weight: 600;
  line-height: 144.444%;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
  }
`;

const ViewAnswers = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
  width: 49%;
`;

const RetakeTest = styled(ButtonStyles)`
  width: 49%;
  color: ${({ isHoverViewAnswers }) =>
    isHoverViewAnswers ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const TestFailedModal = ({
  userTestResult,
  lessonId,
  userStudyId,
  setTestFailedModal,
  userLessonPageRef,
  fetchUserLessonData,
  setLessonIsFinished,
  setUserAnswersData,
}) => {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);
  const [isHoverViewAnswers, setIsHoverOnViewAnswers] = useState(false);

  const toggleHoverOnViewAnswers = useCallback(() => {
    setIsHoverOnViewAnswers((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const progressTimer = setTimeout(() => {
      setProgress(userTestResult);
    }, 100);

    return () => {
      clearTimeout(progressTimer);
    };
  }, [userTestResult]);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setTestFailedModal(false);
  }, []);

  const onClickViewAnswers = useCallback(
    (e) => {
      e.stopPropagation();
      setTestFailedModal(false);
      userLessonPageRef.current.scrollTo({ top: 0, behavior: "smooth" });
    },
    [userLessonPageRef]
  );

  const onClickLessonRetake = async (e) => {
    e.stopPropagation();

    const newUserAnswersData = {
      study_answers: [],
    };

    try {
      await axios.patch(
        `${BASE_API_URL}/userstudy/${userStudyId}`,
        newUserAnswersData
      );

      localStorage.removeItem(lessonId);
      setTestFailedModal(false);
      fetchUserLessonData();
      setLessonIsFinished(false);
      setUserAnswersData([]);
    } catch (error) {
      console.error("Ошибка создания перепрохождения Урока или Теста:", error);
    }
  };

  return (
    <TestSuccessModalWrapper onClick={handleClose}>
      <TestSuccessModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h5>{t("lesson-page.test-failed-modal.text.top")}</h5>
        <h6>{t("lesson-page.test-failed-modal.text.middle")}</h6>
        <h6>
          <span>{t("lesson-page.test-failed-modal.text.bottom")}</span>
        </h6>

        <ProgressBarWrapper>
          <ProgressBarFiller width={progress}>
            <UserTestResult smallResult={userTestResult <= 20}>
              {userTestResult >= 0 && userTestResult + "%"}
            </UserTestResult>
          </ProgressBarFiller>
        </ProgressBarWrapper>

        <ModalButtonsContainer>
          <ViewAnswers
            onMouseEnter={toggleHoverOnViewAnswers}
            onMouseLeave={toggleHoverOnViewAnswers}
            onClick={onClickViewAnswers}
          >
            {t("lesson-page.test-failed-modal.buttons.review")}
          </ViewAnswers>
          <VerticalLineStyled color={"#5172EA"} />
          <RetakeTest
            isHoverViewAnswers={isHoverViewAnswers}
            onClick={onClickLessonRetake}
          >
            {t("lesson-page.test-failed-modal.buttons.retake")}
          </RetakeTest>
        </ModalButtonsContainer>
      </TestSuccessModalContainer>
    </TestSuccessModalWrapper>
  );
};

export default TestFailedModal;
