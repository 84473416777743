import React, { memo, useCallback, useState } from "react";
import BlackArrowDownIcon from "../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
  QuestionsFilterOptionItem,
  QuestionsFilterOptionsContainer,
} from "../../admin_questions_styled_components";

const QuestionSortByAnswerDate = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  z-index: 10000;
  transform: translateX(100%);
`;

const QuestionsFilterByStatus = ({
  extendedOption,
  handleExtendOptions,
  setQuestionsFilterSettings,
  inComponent = "",
}) => {
  const { t } = useTranslation();
  const [isHoveringAnswered, setIsHoveringAnswered] = useState(false);
  const [isHoveringSubmenu, setIsHoveringSubmenu] = useState(false);

  const handleStatusFilter = useCallback(
    (status) => {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_status: status,
      }));
      setIsHoveringAnswered(false);
      setIsHoveringSubmenu(false);
    },
    [setQuestionsFilterSettings]
  );

  const handleSortByAnswerDate = useCallback(
    (order) => {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_by_answer_date: order,
        question_status: "answered",
      }));
    },
    [setQuestionsFilterSettings]
  );

  const showSortSubmenu =
    (isHoveringAnswered || isHoveringSubmenu) && inComponent === "admin";

  return (
    <QuestionsFilterOption
      type="status"
      isExtended={extendedOption === "status"}
      onClick={(e) => handleExtendOptions(e, "status")}
    >
      {t("common-components.questions-filter-by-status.title")}
      <QuestionsFilterOptionArrow isExtended={extendedOption === "status"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>

      {extendedOption === "status" && (
        <QuestionsFilterOptionsContainer>
          <QuestionsFilterOptionItem
            onClick={() => handleStatusFilter("noanswer")}
          >
            {t("common-components.questions-filter-by-status.noanswer")}
          </QuestionsFilterOptionItem>

          <QuestionsFilterOptionItem
            onClick={() => handleStatusFilter("answered")}
            onMouseEnter={() => setIsHoveringAnswered(true)}
            onMouseLeave={() => setIsHoveringAnswered(false)}
          >
            {t("common-components.questions-filter-by-status.answered")}
            {showSortSubmenu && (
              <QuestionSortByAnswerDate
                onMouseEnter={() => setIsHoveringSubmenu(true)}
                onMouseLeave={() => setIsHoveringSubmenu(false)}
              >
                <QuestionsFilterOptionItem
                  onClick={() => handleSortByAnswerDate("newest")}
                >
                  {t(
                    "common-components.questions-filter-by-status.newest-answer"
                  )}{" "}
                  ↓
                </QuestionsFilterOptionItem>
                <QuestionsFilterOptionItem
                  onClick={() => handleSortByAnswerDate("oldest")}
                >
                  {t(
                    "common-components.questions-filter-by-status.oldest-answer"
                  )}{" "}
                  ↓
                </QuestionsFilterOptionItem>
              </QuestionSortByAnswerDate>
            )}
          </QuestionsFilterOptionItem>
        </QuestionsFilterOptionsContainer>
      )}
    </QuestionsFilterOption>
  );
};

export default memo(QuestionsFilterByStatus);
