import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const BibleModalSearchPlaceButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
`;

const SearchWholeBibleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  background: #fff;
  opacity: 0.6;
  color: #1e1e1e;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `
  opacity: 1;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  `}

  &: hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const BibleModalSearchPlaceButton = styled(SearchWholeBibleButton)`
  width: max-content;
  flex: 1;
`;

const BibleModalSearchPlaceButtons = ({
  bibleModalSearchPlace,
  setBibleModalSearchPlace,
}) => {
  const { t } = useTranslation();

  const handleTestaments = useCallback((part) => {
    setBibleModalSearchPlace(part);
  }, []);

  useEffect(() => {
    return () => setBibleModalSearchPlace("");
  }, []);

  return (
    <BibleModalSearchPlaceButtonsContainer>
      <SearchWholeBibleButton
        isSelected={bibleModalSearchPlace === ""}
        onClick={() => handleTestaments("")}
      >
        {t("bible-page.place.whole-bible")}
      </SearchWholeBibleButton>

      <BibleModalSearchPlaceButton
        isSelected={bibleModalSearchPlace === "ot"}
        onClick={() => handleTestaments("ot")}
      >
        {t("bible-page.place.old-testaments")}
      </BibleModalSearchPlaceButton>

      <BibleModalSearchPlaceButton
        isSelected={bibleModalSearchPlace === "nt"}
        onClick={() => handleTestaments("nt")}
      >
        {t("bible-page.place.new-testaments")}
      </BibleModalSearchPlaceButton>
    </BibleModalSearchPlaceButtonsContainer>
  );
};

export default BibleModalSearchPlaceButtons;
