import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import {
  handleBibleVerseClick,
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  getCustomOptions,
} from "../../../../common/components/text_editor/text_editor_functions";
import { useUserDataContext } from "../../../../user_data_context";
import { useAppContext } from "../../../../app_context";
import { TextEditorViewComponent } from "../../../../common/components/text_editor/editor_styled_components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";

const QuestionAnswerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 6px 8px 8px;
  margin-top: 12px;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(81, 114, 234, 0.2) -18.38%,
    rgba(122, 90, 238, 0.2) 108.82%
  );
`;

const QuestionAnswerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h6 {
    color: #828282;
    font-size: 10px;
    line-height: 140%;
  }
`;

const AnswerTextContainer = styled(TextEditorViewComponent)`
  line-height: 143%;
  font-size: 14px !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  transition: 0.2s;

  input.bible-verse {
    font-size: 12px !important;
  }

  a {
    font-size: 12px !important;
  }

  ${({ showAllText }) =>
    !showAllText &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    `}
`;

const ReadAllButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  width: 50px;
  height: 30px;
  margin-bottom: -12px;
  border-radius: 8px;
  cursor: pointer;

  ${({ showAllText }) =>
    showAllText &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}
`;

const AnswerReadStatus = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  background: #f8d254;
  border-radius: 50%;
  width: 11px;
  height: 11px;
`;

const UserProfileQuestionAnswer = ({
  questionAnswer,
  setTextBibleModalData = () => {},
}) => {
  const { t } = useTranslation();

  const { userData } = useUserDataContext();
  const { appColorTheme } = useAppContext();

  const htmlContentRef = useRef(null);

  const [html, setHtml] = useState("");
  const [showAllText, setShowAllText] = useState(false);
  const [showToggleButton, setShowToggleButton] = useState(false);
  const [answerIsUnread, setAnswerIsUnread] = useState(null);

  const sanitizedHtml = DOMPurify.sanitize(html);

  const currentUserName = userData?.user_name || "Пользователь";

  useEffect(() => {
    if (questionAnswer.answer_read === false) {
      setAnswerIsUnread(true);
    }
  }, [questionAnswer]);

  useEffect(() => {
    if (questionAnswer.answer_text?.blocks?.length) {
      try {
        let contentState = convertFromRaw(questionAnswer.answer_text);

        const options = getCustomOptions(appColorTheme);
        let htmlContent = stateToHTML(contentState, options);

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );

        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [questionAnswer, currentUserName, appColorTheme]);

  useEffect(() => {
    const elements = htmlContentRef.current?.querySelectorAll(".bible-verse");
    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    elements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      elements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [html]);

  useEffect(() => {
    const handleResize = () => {
      if (!sanitizedHtml || !htmlContentRef.current) return;

      const textElement = htmlContentRef.current;
      const isTextOverflowing =
        textElement.scrollHeight > textElement.clientHeight;
      setShowToggleButton(isTextOverflowing);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sanitizedHtml]);

  const toggleShowAllText = useCallback(
    (event) => {
      event.stopPropagation();
      setShowAllText((prevState) => !prevState);
    },
    [setShowAllText]
  );

  return (
    <QuestionAnswerContainer>
      <QuestionAnswerHeader>
        <h6>{t("user-profile-page.questions.question.answer-label")}:</h6>
        <h6>{questionAnswer.answer_author.answer_from}</h6>
      </QuestionAnswerHeader>

      <AnswerTextContainer
        showAllText={showAllText}
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />

      {(showToggleButton || showAllText) && (
        <ReadAllButton showAllText={showAllText} onClick={toggleShowAllText}>
          <AskQuestionArrowIcon />
        </ReadAllButton>
      )}

      {answerIsUnread && <AnswerReadStatus />}
    </QuestionAnswerContainer>
  );
};

export default memo(UserProfileQuestionAnswer);
