import React, { memo } from "react";
import styled from "styled-components";
import PlusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import {
  AddUserButton,
  AddUserButtonContainer,
} from "../../../admin_page_components/admin_page_styled_components";
import GroupSmallPreview from "../group_small_preview";

const GroupsAuthorWrapper = styled.div`
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-top: 1px solid #cacaca;
`;

const GroupsAuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GroupsAuthorGroupsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  max-height: 320px;
  overflow: auto;
`;

const AddGroupButtonContainer = styled(AddUserButtonContainer)`
  padding: 0;
`;

const GroupsAuthor = ({ groupsAuthorsList }) => {
  return (
    <GroupsAuthorWrapper>
      Группы
      <GroupsAuthorContainer>
        {!!groupsAuthorsList.length && (
          <GroupsAuthorGroupsList>
            {groupsAuthorsList?.map((group) => {
              return (
                <GroupSmallPreview key={group?._id} component={"groups"} />
              );
            })}
          </GroupsAuthorGroupsList>
        )}

        <AddGroupButtonContainer>
          <AddUserButton>
            <PlusIcon />
          </AddUserButton>
        </AddGroupButtonContainer>
      </GroupsAuthorContainer>
    </GroupsAuthorWrapper>
  );
};

export default memo(GroupsAuthor);
