import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  handleBibleVerseClick,
  getCustomOptions,
} from "../text_editor/text_editor_functions";
import { useUserDataContext } from "../../../user_data_context";
import { TextEditorViewComponent } from "../text_editor/editor_styled_components";

const ArticleTextContainer = styled(TextEditorViewComponent)`
  line-height: 143%;
  padding: ${({ inComponent }) =>
    inComponent === "admin_page" ? "9px 9px 0" : "16px 16px 0"};

  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "18px"};
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 24px;
  transition: all 0.2s;

  li {
    margin-bottom: ${({ inComponent }) =>
      inComponent === "admin_page" ? "6px" : "8px"} !important;
  }

  ul {
    li {
      &::before {
        left: ${({ inComponent }) =>
          inComponent === "admin_page" ? "-15px" : "-20px"} !important;
      }
    }
  }

  ol {
    li {
      &::before {
        font-size: ${({ inComponent }) =>
          inComponent === "admin_page" ? "12px" : "20px"};
        left: ${({ inComponent }) =>
          inComponent === "admin_page" ? "-15px" : "-20px"} !important;
        top: ${({ inComponent }) =>
          inComponent === "admin_page" ? "-2px" : "0px"} !important;
        line-height: 120%;
      }
    }
  }

  input.bible-verse {
    font-size: ${({ inComponent }) =>
      inComponent === "admin_page" ? "10px" : "18px"} !important;
  }

  a {
    font-size: ${({ inComponent }) =>
      inComponent === "admin_page" ? "10px" : "18px"} !important;
  }

  ${({ showAllText }) =>
    !showAllText &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

     @media (max-width: 744px) {
       -webkit-line-clamp: 2;
      }
    `}

  @media (max-width: 840px) {
    font-size: 16px;
  }

  @media (max-width: 744px) {
    padding: 8px 12px 0;
  }
`;

const ReadAllButtonWrapper = styled.div`
  text-align: right;
  margin: ${({ inComponent }) =>
    inComponent === "admin_page" ? "2px 11px 0 0" : "8px 20px 0 0"};

  @media (max-width: 744px) {
    margin-top: 4px;
  }
`;

const ReadAllButton = styled.button`
  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "18px"};
  line-height: 100%;
  color: #828282;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ArticleText = ({
  article,
  inComponent,
  appColorTheme,
  setTextBibleModalData,
}) => {
  const { t } = useTranslation();

  const { userData } = useUserDataContext();

  const htmlContentRef = useRef(null);

  const [html, setHtml] = useState("");
  const [showAllText, setShowAllText] = useState(false);
  const [showToggleButton, setShowToggleButton] = useState(false);

  const sanitizedHtml = DOMPurify.sanitize(html);

  const currentUserName = userData?.user_name || "Пользователь";

  const buttonText = showAllText
    ? t("home-page.article-read-less-btn")
    : t("home-page.article-read-all-btn");

  useEffect(() => {
    const { blocks, entityMap } = article.article_text || {};

    if (blocks?.length) {
      try {
        const contentState = convertFromRaw({
          blocks,
          entityMap: entityMap || {},
        });

        const options = getCustomOptions(appColorTheme);
        let htmlContent = stateToHTML(contentState, options);

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [article.article_text, currentUserName]);

  useEffect(() => {
    const elements = htmlContentRef.current?.querySelectorAll(".bible-verse");

    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    elements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      elements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [sanitizedHtml]);

  useEffect(() => {
    const handleResize = () => {
      if (!sanitizedHtml || !htmlContentRef.current) return;

      const textElement = htmlContentRef.current;
      const isTextOverflowing =
        textElement.scrollHeight > textElement.clientHeight;
      setShowToggleButton(isTextOverflowing);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sanitizedHtml]);

  const toggleShowAllText = useCallback(
    (event) => {
      event.stopPropagation();
      setShowAllText((prevState) => !prevState);
    },
    [setShowAllText]
  );

  return (
    <>
      <ArticleTextContainer
        showAllText={showAllText}
        inComponent={inComponent}
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />

      {(showToggleButton || showAllText) && (
        <ReadAllButtonWrapper inComponent={inComponent}>
          <ReadAllButton onClick={toggleShowAllText} inComponent={inComponent}>
            {buttonText}
          </ReadAllButton>
        </ReadAllButtonWrapper>
      )}
    </>
  );
};

export default memo(ArticleText);
