import React, { memo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OnBibleLogo, HeaderDonateIcon } from "../../assets/icons";
import { APPLICATION_ROUTES } from "../../utils/routes";
import {
  HomeIcon,
  ChatIcon,
  BibleIcon,
  MainNavContainer,
  HeaderButtonsContainer,
  AppHeaderContainer,
  StudyIcon,
  DonateIconContainer,
  HeaderLogoContainer,
  AdminButtonContainer,
  HideHeaderButton,
  HideHeaderButtonWrapper,
} from "./header_components/header_styled_components";
import HeaderMenuItem from "./header_components/header_menu_item";
import HeaderLanguageSelector from "./header_components/header_language_selector";
import HeaderMoreMenu from "./header_components/header_more_menu";
import { useAppContext } from "../../../app_context";
import HeaderAdminIcon from "../../assets/icons/header_icons/header_admin_icon";
import { useUserDataContext } from "../../../user_data_context";
import HeaderUserProfileButtons from "./header_components/header_user_profile_buttons";
import HideArrowIcon from "../../assets/icons/header_icons/hide_arrow_icon";

const NAV_LINKS = [
  {
    path: APPLICATION_ROUTES.HOME,
    icon: HomeIcon,
    textKey: "app-header.menu.home",
  },
  {
    path: APPLICATION_ROUTES.STUDY,
    icon: StudyIcon,
    textKey: "app-header.menu.study",
  },
  {
    path: APPLICATION_ROUTES.BIBLE,
    icon: BibleIcon,
    textKey: "app-header.menu.bible",
  },
  {
    path: APPLICATION_ROUTES.CHATS,
    icon: ChatIcon,
    textKey: "app-header.menu.chats",
  },
];

const Header = () => {
  const navigate = useNavigate();

  const { showAppHeader, setShowAppHeader, showHideHeaderButton } =
    useAppContext();
  const { userData, unreadAnswersCount, userLanguage } = useUserDataContext();

  const adminPageIsOpen = window.location.pathname.includes("admin");

  const showAdminButton =
    userData?.user_role === "superadmin" ||
    userData?.user_access?.is_admin_of_platform ||
    userData?.user_access?.is_admin_of_courses ||
    userData?.user_access?.is_admin_of_groups ||
    userData?.user_access?.is_author;

  const appNavItems = NAV_LINKS.filter((link) => {
    if (link.path === APPLICATION_ROUTES.CHATS) {
      return userData?.user_role === "superadmin";
    }
    return true;
  });

  const onClickAdminButton = useCallback(
    (event) => {
      event.stopPropagation();
      if (!adminPageIsOpen) {
        navigate(APPLICATION_ROUTES.ADMIN);
      }
    },
    [adminPageIsOpen]
  );

  const handleHideHeader = useCallback((event) => {
    event.stopPropagation();
    setShowAppHeader((prevState) => !prevState);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {showAppHeader && (
        <AppHeaderContainer>
          <HeaderLogoContainer>
            <Link to={APPLICATION_ROUTES.HOME}>
              <OnBibleLogo />
            </Link>
          </HeaderLogoContainer>

          <MainNavContainer>
            {appNavItems.map((menuItem) => (
              <HeaderMenuItem key={menuItem.path} menuItem={menuItem} />
            ))}

            <HeaderMoreMenu
              unreadAnswersCount={unreadAnswersCount}
              showAdminButton={showAdminButton}
            />
          </MainNavContainer>

          <HeaderButtonsContainer>
            {/* <DonateIconContainer to={APPLICATION_ROUTES.DONATE}>
              <HeaderDonateIcon />
            </DonateIconContainer> */}

            <HeaderUserProfileButtons
              userData={userData}
              unreadAnswersCount={unreadAnswersCount}
            />

            <HeaderLanguageSelector userLanguage={userLanguage} />

            {showAdminButton && (
              <AdminButtonContainer onClick={onClickAdminButton}>
                <HeaderAdminIcon />
              </AdminButtonContainer>
            )}
          </HeaderButtonsContainer>
        </AppHeaderContainer>
      )}

      {showHideHeaderButton && (
        <HideHeaderButtonWrapper
          showAppHeader={showAppHeader}
          onClick={handleHideHeader}
        >
          <HideHeaderButton showAppHeader={showAppHeader}>
            <HideArrowIcon showAppHeader={showAppHeader} />
          </HideHeaderButton>
        </HideHeaderButtonWrapper>
      )}
    </div>
  );
};

export default memo(Header);
