import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import axios from "./axios_config";
import { BASE_API_URL } from "./common/endpoints";

const UserDataContext = createContext();

const defaultAccessRights = {
  isSuperadmin: false,
  isAdminOfCourses: false,
  isAdminOfGroups: false,
  isAuthor: false,
  isAdminOfPlatformUsers: false,
  isAdminOfPlatformCourses: false,
  isAdminOfPlatformGroups: false,
  isAdminOfPlatformQuestions: false,
  isAdminOfPlatformAnalytics: false,
  isAdminOfPlatformHomepage: false,
  isMentor: false,
  isLeader: false,
  isOperatorOfSupport: false,
};

export const useUserDataState = () => {
  const { i18n } = useTranslation();

  const [userData, setUserData] = useState({});
  const [authError, setAuthError] = useState(null);
  const [accessRights, setAccessRights] = useState(defaultAccessRights);
  const [userLanguage, setUserLanguage] = useState(null);
  const [userQuestionsList, setUserQuestionsList] = useState([]);
  const [unreadAnswersCount, setUnreadAnswersCount] = useState(0);

  const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    if (language.startsWith("uk")) return "uk";
    if (language.startsWith("ru")) return "ru";
    return "en";
  };

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setUserLanguage(lng);
    };

    if (i18n.isInitialized) {
      setUserLanguage(i18n.language);
      i18n.on("languageChanged", handleLanguageChange);
    } else {
      setUserLanguage(getBrowserLanguage());
    }

    return () => {
      if (i18n.isInitialized) {
        i18n.off("languageChanged", handleLanguageChange);
      }
    };
  }, [i18n]);

  const fetchUserQuestions = useCallback(async () => {
    if (!userData._id) return;

    try {
      const response = await axios.get(
        `${BASE_API_URL}/questions/fromauthor/${userData._id}`
      );
      const questions = response.data;

      setUserQuestionsList(questions);
    } catch (error) {
      console.error("Error fetching user questions:", error);
    }
  }, [userData?._id]);

  useEffect(() => {
    if (!userData._id) return;

    fetchUserQuestions();

    const interval = setInterval(() => {
      fetchUserQuestions();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [userData?._id]);

  const calculateUnreadAnswers = (questions) => {
    return questions.filter(
      (question) => question.question_answer?.answer_read === false
    ).length;
  };

  useEffect(() => {
    const unreadCount = calculateUnreadAnswers(userQuestionsList);
    setUnreadAnswersCount(unreadCount);
  }, [userQuestionsList]);

  const defineAccessRights = (userData) => {
    const isAdminOfPlatform = userData.user_access?.is_admin_of_platform;

    setAccessRights({
      ...defaultAccessRights,
      isSuperadmin: userData.user_role === "superadmin",
      isAdminOfCourses: userData.user_access?.is_admin_of_courses,
      isAdminOfGroups: userData.user_access?.is_admin_of_groups,
      isAuthor: userData.user_access?.is_author,
      isAdminOfPlatform,
      isAdminOfPlatformUsers:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.users,
      isAdminOfPlatformCourses:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.courses,
      isAdminOfPlatformGroups:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.groups,
      isAdminOfPlatformQuestions:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.questions,
      isAdminOfPlatformAnalytics:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.analytics,
      isAdminOfPlatformHomePage:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.homepage,
      isMentor: userData.user_access?.is_mentor,
      isLeader: userData.user_access?.is_leader,
      isOperatorOfSupport: userData.user_access?.is_operator_of_support,
    });
  };

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    try {
      const response = await axios.get(`${BASE_API_URL}/auth/profile`);
      const user = response.data.user;

      setUserData(user);
      defineAccessRights(user);
      setAuthError(null);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUserData({});
      setAuthError(
        error.response?.data?.message || "Error fetching user data."
      );
    }
  }, [defineAccessRights]);

  return {
    userData,
    accessRights,
    fetchUserData,
    authError,
    userLanguage,
    unreadAnswersCount,
    userQuestionsList,
    fetchUserQuestions,
    setUserQuestionsList,
  };
};

export const UserDataProvider = ({ children }) => {
  const {
    userData,
    fetchUserData,
    authError,
    accessRights,
    userLanguage,
    unreadAnswersCount,
    userQuestionsList,
    fetchUserQuestions,
    setUserQuestionsList,
  } = useUserDataState();

  return (
    <UserDataContext.Provider
      value={{
        userData,
        fetchUserData,
        authError,
        accessRights,
        userLanguage,
        unreadAnswersCount,
        userQuestionsList,
        fetchUserQuestions,
        setUserQuestionsList,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  return useContext(UserDataContext);
};
