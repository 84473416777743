import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HorizontalLine from "../../../../common/assets/icons/horizontal_line";
import LevelAndLessonInfo from "../../study_page_courses/course_card/level_and_lesson_info";
import LessonsInfoItem from "./lessons_info_item";
import CourseTestItem from "./course_test_item";

const CourseLevelAndLessonsInfoContainer = styled.div`
  margin: 0 auto;
  width: 508px;
  height: 160px;
  border-radius: 0px 0px 25px 25px;
  background: #ffffff;
  box-shadow: 2px 4px 25px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  user-select: none;

  @media (max-width: 514px) {
    width: 100%;
    height: 159px;
    border-radius: 0;
    border-bottom: 1px solid #cacaca;
  }

  @media (max-width: 412px) {
    width: 100%;
    height: 142px;
    border-radius: 0;
    border-bottom: 1px solid #cacaca;
  }
`;

const CourseLessonsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justContCenter }) =>
    justContCenter ? "center" : "flex-start"};
  gap: 15px;
  padding: 22px 30px;
  overflow-x: hidden;
  white-space: nowrap;
  cursor: grab;

  @media (max-width: 412px) {
    padding: 20px;
    gap: 12px;
  }
`;

const CourseLevelAndLessonsInfo = ({
  courseData,
  courseLessonsList,
  inComponent,
  handleClickOnLesson,
  showMessageId,
  userCourse,
  setShowMessageId,
  courseTestData,
  authError,
  handleClickOnTest,
}) => {
  const lessonsRef = useRef(null);
  const timerRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const isNewCourse = !userCourse;

  const onMouseDown = useCallback(
    (e) => {
      setIsDragging(true);
      setStartX(e.pageX - lessonsRef.current.offsetLeft);
      setScrollLeft(lessonsRef.current.scrollLeft);
      lessonsRef.current.style.cursor = "grabbing";
    },
    [lessonsRef]
  );

  const onMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;
      const x = e.pageX - lessonsRef.current.offsetLeft;
      const walk = (x - startX) * 1;
      lessonsRef.current.scrollLeft = scrollLeft - walk;
    },
    [isDragging, startX, scrollLeft, lessonsRef]
  );

  const onMouseUp = useCallback(() => {
    setIsDragging(false);
    lessonsRef.current.style.cursor = "grab";
  }, [lessonsRef]);

  const onTouchStart = useCallback(
    (e) => {
      if (e.touches.length > 0) {
        setIsDragging(true);
        setStartX(e.touches[0].clientX - lessonsRef.current.offsetLeft);
        setScrollLeft(lessonsRef.current.scrollLeft);
        lessonsRef.current.style.cursor = "grabbing";
      }
    },
    [lessonsRef]
  );

  const onTouchMove = useCallback(
    (e) => {
      if (!isDragging) return;
      const x = e.touches[0].clientX - lessonsRef.current.offsetLeft;
      const walk = (x - startX) * 1;
      lessonsRef.current.scrollLeft = scrollLeft - walk;
    },
    [isDragging, startX, scrollLeft, lessonsRef]
  );

  const onTouchEnd = useCallback(() => {
    setIsDragging(false);
    lessonsRef.current.style.cursor = "grab";
  }, [lessonsRef]);

  useEffect(() => {
    if (showMessageId) {
      timerRef.current = setTimeout(() => {
        setShowMessageId(null);
      }, 4500);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [showMessageId, setShowMessageId]);

  return (
    <CourseLevelAndLessonsInfoContainer>
      <LevelAndLessonInfo blockVariant={"SMALL_SIZE"} courseData={courseData} />
      <HorizontalLine width={"100%"} />
      <CourseLessonsList
        ref={lessonsRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={() => isDragging && onMouseUp()}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        justContCenter={courseData?.course_lessons?.length < 10}
      >
        {courseLessonsList?.map((lesson, index) => {
          return (
            <LessonsInfoItem
              key={lesson._id}
              index={index}
              lesson={lesson}
              isNewCourse={isNewCourse}
              showMessageId={showMessageId}
              lessonsRef={lessonsRef}
              lessonId={lesson._id}
              userCourseLessons={userCourse?.course_lessons}
              courseStatus={courseData.course_status}
              authError={authError}
              inComponent={inComponent}
              setShowMessageId={setShowMessageId}
              handleClickOnLesson={handleClickOnLesson}
            />
          );
        })}

        {courseTestData?._id && (
          <CourseTestItem
            courseTestData={courseTestData}
            userCourseLessons={userCourse?.course_lessons}
            lessonsRef={lessonsRef}
            inComponent={inComponent}
            authError={authError}
            courseStatus={courseData.course_status}
            handleClickOnTest={handleClickOnTest}
            lastLessonId={
              courseLessonsList?.[courseLessonsList?.length - 1]?._id
            }
          />
        )}
      </CourseLessonsList>
    </CourseLevelAndLessonsInfoContainer>
  );
};

export default memo(CourseLevelAndLessonsInfo);
