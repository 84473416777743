import smallImage1 from "../assets/images/course_certificate_images/certificate_small_1.png";
import smallImage2 from "../assets/images/course_certificate_images/certificate_small_2.png";
import smallImage3 from "../assets/images/course_certificate_images/certificate_small_3.png";
import smallImage4 from "../assets/images/course_certificate_images/certificate_small_4.png";
import smallImage5 from "../assets/images/course_certificate_images/certificate_small_5.png";

export const COURSE_CERTIFICATE_IMAGES = {
  TYPE_1: {
    type: "TYPE_1",
    smallImage: smallImage1,
    largeImage: "https://api.on-bible.com/uploads/2f36acff5658db368e19.jpg",
  },
  TYPE_2: {
    type: "TYPE_2",
    smallImage: smallImage2,
    largeImage: "https://api.on-bible.com/uploads/d47ca8ec3d88gd7e92a8.jpg",
  },
  TYPE_3: {
    type: "TYPE_3",
    smallImage: smallImage3,
    largeImage: "https://api.on-bible.com/uploads/fcfcaba2c47cfd361191.jpg",
  },
  TYPE_4: {
    type: "TYPE_4",
    smallImage: smallImage4,
    largeImage: "https://api.on-bible.com/uploads/f7g05bc9f1fa5d28dg96.jpg",
  },
  TYPE_5: {
    type: "TYPE_5",
    smallImage: smallImage5,
    largeImage: "https://api.on-bible.com/uploads/42dbf20b3g03bgeab644.jpg",
  },
};

export const LANGUAGES = [
  { code: "ru", label: "Рус" },
  { code: "uk", label: "Укр" },
  { code: "en", label: "Eng" },
];

export const ARTICLE_BACKGROUNDS = {
  BLUE_VIOLET: `linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)`,
  COLOR_2: `linear-gradient(49deg, #092DC6 -5.13%, #0AB6A1 105.54%)`,
  RED_PURPLE_PINK: `linear-gradient(23deg, #B00 8.67%, #C406A6 89.6%)`,
  RED_VIOLET_BLUE: `linear-gradient(72deg, #E73535 -7.36%, #D028A6 48.66%, #912DF5 105.17%)`,
  BRIGHT_PURPLE_NAVY_BLUE: `linear-gradient(249deg, #DB32EA -11.61%, #3D1AC9 109.78%)`,
  BLACK_PURPLE: `linear-gradient(250deg, #9E00FF -11.61%, #55187C 18.88%, #301E46 58.51%, #1B1B1F 104.27%)`,
  BLACK_BLUE: `linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), linear-gradient(49deg, #092DC6 -5.13%, #0AB6A1 105.54%)`,
};

export const LESSON_COLORS = {
  COLOR_1: `linear-gradient(26deg, #0A97B6 31.96%, #092DC6 107.31%)`,
  COLOR_2: `linear-gradient(180deg, #5172EA 18.38%, #7A5AEE 108.82%)`,
  COLOR_3: `linear-gradient(173deg, #9932EA 24%, #1ABEC9 136.73%)`,
  COLOR_4: `linear-gradient(50deg, #0C7CAC 11.7%, #0EC5ED 110.56%)`,
  COLOR_5: `linear-gradient(23deg, #B00 8.67%, #C406A6 89.6%)`,
  COLOR_6: `linear-gradient(9deg, #D028A6 38.45%, #912DF5 116.27%)`,
  COLOR_7: `linear-gradient(234deg, #9E00FF 17.21%, #55187C 46.41%, #301E46 103.33%, #1B1B1F 151.29%)`,
  COLOR_8: `linear-gradient(85deg, #243748 3.05%, #4B749F 67.79%)`,
};

export const LESSON_BIBLE_VERSE_COLORS = {
  COLOR_1: "#0A47C2",
  COLOR_2: "#5B6DEB",
  COLOR_3: "#7E51E3",
  COLOR_4: "#0DA3CF",
  COLOR_5: "#C00353",
  COLOR_6: "#AC2CD4",
  COLOR_7: "#652191",
  COLOR_8: "#4C75A0",
};

export const LESSON_COLORS_THEME = {
  LIGHT: {
    GRADIENTS: {
      FUCHSIA: `linear-gradient(9deg, #D028A6 -38.45%, #912DF5 116.27%)`,
      BLUE: `linear-gradient(26deg, #0A97B6 -31.96%, #092DC6 107.31%)`,
      AQUA: `linear-gradient(50deg, #0C7CAC -11.7%, #0EC5ED 110.56%)`,
      CYAN: `linear-gradient(173deg, #9932EA -24%, #1ABEC9 136.73%)`,
      MAGENTA: `linear-gradient(23deg, #B00 8.67%, #C406A6 89.6%)`,
      PURPLE: `linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)`,
      VIOLET: `linear-gradient(234deg, #9E00FF -17.21%, #63218E 36.71%, #55187C 52.72%, #301E46 103.33%, #1B1B1F 151.29%)`,
      DEEP: `linear-gradient(85deg, #243748 -3.05%, #4B749F 67.79%)`,
      ORANGE: `linear-gradient(49deg, #FD5D1D -8.85%, #F22B02 103.89%)`,
      SKYLOR: `linear-gradient(252deg, #0AB6A1 -4.79%, #235EB7 47.61%, #B10AC0 104.97%)`,
      FUSORA: `linear-gradient(249deg, #DB32EA -11.61%, #3D1AC9 109.78%)`,
      MINT: `linear-gradient(185deg, #05BAA4 -3.03%, #28BACE 112.21%)`,
      GREEN: `linear-gradient(194deg, #066156 2.82%, #0D7177 108.27%)`,
      TEAL: `linear-gradient(49deg, #092DC6 -5.13%, #0AB6A1 105.54%)`,
      SPACE: `linear-gradient(249deg, #076448 2.46%, #B10AC0 104.86%)`,
      WHITE: `#FFF`,
      BLACK: `#1E1E1E`,
    },
    COLORS: {
      FUCHSIA: "#B82AC5",
      BLUE: "#0A63BE",
      AQUA: "#0D86B5",
      CYAN: "#536ABF",
      MAGENTA: "#BE0237",
      PURPLE: "#725FED",
      VIOLET: "#8A2AC8",
      DEEP: "#1E4D7E",
      ORANGE: "#FA5016",
      SKYLOR: "#4749B9",
      FUSORA: "#7E24D6",
      MINT: "#069AA4",
      GREEN: "#0B6163",
      TEAL: "#0A61B8",
      SPACE: "#464375",
      WHITE: "#FFF",
      BLACK: "#1E1E1E",
    },
  },
  DARK: {
    GRADIENTS: {
      FUCHSIA: `linear-gradient(9deg, rgba(208, 40, 166, 0.60) -38.45%, rgba(145, 45, 245, 0.60) 116.27%)`,
      BLUE: `linear-gradient(26deg, rgba(10, 151, 182, 0.60) -31.96%, rgba(9, 45, 198, 0.60) 107.31%)`,
      AQUA: `linear-gradient(50deg, rgba(12, 124, 172, 0.60) -11.7%, rgba(14, 197, 237, 0.60) 110.56%)`,
      CYAN: `linear-gradient(173deg, rgba(153, 50, 234, 0.60) -24%, rgba(26, 190, 201, 0.60) 136.73%)`,
      MAGENTA: `linear-gradient(23deg, rgba(187, 0, 0, 0.60) 8.67%, rgba(196, 6, 166, 0.60) 89.6%)`,
      PURPLE: `linear-gradient(180deg, rgba(81, 114, 234, 0.60) -18.38%, rgba(122, 90, 238, 0.60) 108.82%)`,
      VIOLET: `linear-gradient(234deg, #9E00FF -17.21%, #63218E 36.71%, #55187C 52.72%, #301E46 103.33%, #1B1B1F 151.29%)`,
      DEEP: `linear-gradient(85deg, #243748 -3.05%, #4B749F 67.79%)`,
      ORANGE: `linear-gradient(49deg, rgba(253, 93, 29, 0.60) -8.85%, rgba(242, 43, 2, 0.60) 103.89%)`,
      SKYLOR: `linear-gradient(252deg, rgba(10, 182, 161, 0.60) -4.79%, rgba(35, 94, 183, 0.60) 47.61%, rgba(177, 10, 192, 0.60) 104.97%)`,
      FUSORA: `linear-gradient(249deg, rgba(219, 50, 234, 0.60) -11.61%, rgba(61, 26, 201, 0.60) 109.78%)`,
      MINT: `linear-gradient(185deg, rgba(5, 186, 164, 0.60) -3.03%, rgba(40, 186, 206, 0.60) 112.21%)`,
      GREEN: `linear-gradient(194deg, #066156 2.82%, #0D7177 108.27%)`,
      TEAL: `linear-gradient(49deg, rgba(9, 45, 198, 0.60) -5.13%, rgba(10, 182, 161, 0.60) 105.54%)`,
      SPACE: `linear-gradient(249deg, rgba(7, 100, 72, 0.60) 2.46%, rgba(177, 10, 192, 0.60) 104.86%)`,
      WHITE: `rgba(255, 255, 255, 0.15)`,
      BLACK: `#141414`,
    },
    COLORS: {
      FUCHSIA: "#D22FE1",
      BLUE: "#1F88F3",
      AQUA: "#08A8E7",
      CYAN: "#6888FE",
      MAGENTA: "#DF104A",
      PURPLE: "#725FED",
      VIOLET: "#A71EFF",
      DEEP: "#3F8BDA",
      ORANGE: "#FA5016",
      SKYLOR: "#5457DE",
      FUSORA: "#932FF4",
      MINT: "#069AA4",
      GREEN: "#069AA4",
      TEAL: "#1D7FE0",
      SPACE: "#847EEA",
      WHITE: "#FFF",
      BLACK: "#141414",
    },
  },
};
