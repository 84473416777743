import React, { memo } from "react";
import styled from "styled-components";
import { EditorState } from "draft-js";
import {
  ButtonGroup,
  SettingsButtonsContainer,
  ToolbarButton,
} from "../editor_styled_components";
import TextSettingsButtonsHeader from "./text_settings_buttons_header";
import TextAlignmentIcon from "../../../assets/icons/text_editor_icons/text_alignment/text_alignment_icon";
import TextAlignRightIcon from "../../../assets/icons/text_editor_icons/text_alignment/text_align_right_icon";
import TextAlignCenterIcon from "../../../assets/icons/text_editor_icons/text_alignment/text_align_center_icon";
import TextAlignLeftIcon from "../../../assets/icons/text_editor_icons/text_alignment/text_align_left_icon";

const TextAlignmentButton = styled(ToolbarButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 44px;
`;

const TextAlignmentButtons = ({
  editorState,
  setEditorState,
  openTextSettings,
  setOpenTextSettings,
}) => {
  const currentBlock = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey());
  const currentAlignment = currentBlock.getData().get("textAlign") || "left"; // по умолчанию "left"

  const toggleTextAlignment = (alignment) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const currentAlignment = block.getData().get("textAlign") || "left";

    const newAlignment = currentAlignment === alignment ? "left" : alignment;

    const newContentState = contentState.merge({
      blockMap: contentState
        .getBlockMap()
        .set(
          blockKey,
          block.set("data", block.getData().set("textAlign", newAlignment))
        ),
    });

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "change-block-data"
    );

    setEditorState(EditorState.forceSelection(newEditorState, selection));
  };

  return (
    <ButtonGroup>
      <TextSettingsButtonsHeader
        isOpen={openTextSettings === "text_alignment"}
        setOpen={setOpenTextSettings}
        buttonsGroup={"text_alignment"}
      >
        <TextAlignmentIcon />
      </TextSettingsButtonsHeader>

      {openTextSettings === "text_alignment" && (
        <SettingsButtonsContainer>
          <TextAlignmentButton
            active={currentAlignment === "left"}
            onClick={() => toggleTextAlignment("left")}
          >
            <TextAlignLeftIcon />
          </TextAlignmentButton>
          <TextAlignmentButton
            active={currentAlignment === "center"}
            onClick={() => toggleTextAlignment("center")}
          >
            <TextAlignCenterIcon />
          </TextAlignmentButton>
          <TextAlignmentButton
            active={currentAlignment === "right"}
            onClick={() => toggleTextAlignment("right")}
          >
            <TextAlignRightIcon />
          </TextAlignmentButton>
        </SettingsButtonsContainer>
      )}
    </ButtonGroup>
  );
};

export default memo(TextAlignmentButtons);
