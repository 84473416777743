import React, { memo } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast, Slide } from "react-toastify";
import AdminPageCopyIcon from "../../../common/assets/icons/admin_page_icons/admin_page_copy_icon.js";

const FeedbackItem = styled.li`
  background: #eaeaea;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const FeedbackText = styled.p`
  color: #1e1e1e;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
`;

const FeedbackFooter = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #8f8f8f;
  margin-bottom: 5px;
  font-size: 12px;
  color: #8f8f8f;
  margin-bottom: 5px;
`;

const FeedbackDate = styled.span`
  color: #828282;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const FeedbackUserName = styled.span`
  color: #828282;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const FeedbackDelimiter = styled.span`
  flex-grow: 1;
`;

const FeedbackCopyButton = styled.span`
  padding-left: 2px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.12);
  }
`;

const Feedback = ({ feedback }) => {
  const { t } = useTranslation();

  const feedbackDate =
    new Date(feedback.created_at).toLocaleDateString("ru-RU") + "  ";
  const feedbackUserName =
    feedback.user.user_name + " " + feedback.user.user_second_name;

  const onCopyHandler = (feedback) => {
    try {
      const isCopied = copy(feedback.course_feedback);
      if (isCopied) {
        showNotification();
      } else {
        console.error(t("admin-page.feedback-modal.copy.error"));
        alert(t("admin-page.feedback-modal.copy.error"));
      }
    } catch (error) {
      console.error(t("admin-page.feedback-modal.copy.error"), error);
      alert(t("admin-page.feedback-modal.copy.error"));
    }
  };

  const showNotification = () => {
    toast(t("admin-page.feedback-modal.copy.notification"), {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  return (
    <FeedbackItem>
      <FeedbackText>{feedback.course_feedback}</FeedbackText>
      <FeedbackFooter>
        <FeedbackDate>{feedbackDate}</FeedbackDate>
        <FeedbackUserName>{feedbackUserName}</FeedbackUserName>
        <FeedbackDelimiter />
        <FeedbackCopyButton onClick={() => onCopyHandler(feedback)}>
          <AdminPageCopyIcon />
        </FeedbackCopyButton>
      </FeedbackFooter>
    </FeedbackItem>
  );
};

export default memo(Feedback);
