import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const BibleStrongRootsContainer = styled.div`
  color: #6b6b6b;
  font-size: 16px;
  line-height: 140%;

  .title {
    margin-right: 5px;
  }
`;

const CodeStrongContainer = styled.span``;

const CodeStrong = styled.span`
  color: #5172ea;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
`;

const BibleStrongRoots = ({
  strongRoots,
  setCurrentCodeIndex,
  setCodesStrongHistory,
  currentCodeIndex,
  currentCodeStrong,
}) => {
  const [filteredStrongsRoots, setFilteredStrongsRoots] = useState([]);

  useEffect(() => {
    if (strongRoots?.length && currentCodeStrong) {
      const filteredList = strongRoots.filter(
        (code) => code !== currentCodeStrong
      );
      setFilteredStrongsRoots(filteredList);
    }
  }, [strongRoots, currentCodeStrong]);

  const onClickCode = useCallback(
    (event, code) => {
      event.stopPropagation();

      setCodesStrongHistory((prevState) => {
        const newHistory = prevState.slice(0, currentCodeIndex + 1);

        const updatedHistory = [...newHistory, code];

        setCurrentCodeIndex(newHistory.length);

        return updatedHistory;
      });
    },
    [setCodesStrongHistory, currentCodeIndex]
  );

  return (
    <BibleStrongRootsContainer>
      <span className="title">Однокоренные:</span>

      {filteredStrongsRoots?.length > 0 ? (
        <>
          {filteredStrongsRoots?.map((code, index) => (
            <CodeStrongContainer key={code}>
              <CodeStrong onClick={(e) => onClickCode(e, code)}>
                {code}
              </CodeStrong>
              {index < filteredStrongsRoots.length - 1 && ","}{" "}
            </CodeStrongContainer>
          ))}
        </>
      ) : (
        "-"
      )}
    </BibleStrongRootsContainer>
  );
};

export default memo(BibleStrongRoots);
