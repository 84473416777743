import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BIBLE_BOOK_NAMES_DATA } from "../../../pages/bible/bible_page_data";
import BibleCopyLeftArrowIcon from "../../assets/icons/bible_page_icons/bible_copy_left_arrow_icon";
import BibleCopyRightArrowIcon from "../../assets/icons/bible_page_icons/bible_copy_right_arrow_icon";
import BibleCopyModalIcon from "../../assets/icons/bible_page_icons/bible_copy_modal_icon";
import {
  bibleVersesWithoutTags,
  extractingTextOfBibleVerses,
  formatVerseNumbers,
} from "../../utils/functions/bible_page_functions";

const BibleCopyModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100000;
`;

const BibleCopyModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ flexEnd }) => (flexEnd ? "flex-end" : "flex-start")};
  width: 500px;
  height: max-content;
  padding: 25px;
  border-radius: 25px;
  background: #eaeaea;

  @media (max-width: 505px) {
    padding: 16px;
    border-radius: 15px;
    width: 100%;
  }
`;

const CopyTextInputContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 245px;
  margin-bottom: 8px;
`;

const CopyTextInput = styled.textarea`
  resize: none;
  width: 100%;
  height: 100%;
  padding: 14px 15px;
  font-size: 16px;
  line-height: 150%;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const CopyVersesRef = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: max-content;
  padding: 0 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;

  ${({ marginBottom }) => marginBottom && "margin-bottom: 8px;"}
`;

const RefSettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 15px;
`;

const BibleCopySettings = styled.div`
  & > p {
    margin-bottom: 8px;
    color: #1e1e1e;
    font-size: 10px;
    line-height: 120%;
  }
`;

const BibleCopySettingsButtons = styled.div`
  display: flex;
  gap: 5px;
`;

const BibleCopySettingsButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: max-content;
  height: 25px;
  border-radius: 4px;
  background: #fff;
  user-select: none;
  cursor: pointer;
  transition: box-shadow 0.2s;

  & > p {
    color: #383838;
    font-size: 14px;
    line-height: 120%;
  }

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
`;

const TextSettingsContainer = styled.div`
  width: 100%;
`;

const ArrowIconContainer = styled.div`
  height: 100%;
  ${({ right }) =>
    right ? "padding: 6px 7px 0 4px;" : "padding: 6px 4px 0 7px;"}
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;

const CopyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
`;

const BibleCopyModalWindow = ({
  selectedVerses,
  currentBibleScreen,
  setOpenCopyModal,
  copyToClipboard,
  currentBibleLocale,
}) => {
  const { t } = useTranslation();

  const [copyInputValue, setCopyInputValue] = useState("");
  const [versesRefLength, setVersesRefLength] = useState("short"); // short, long
  const [versesRefPlace, setVersesRefPlace] = useState("end"); // start, end
  const [versesTextFormat, setVersesTextFormat] = useState("numbered"); // numbered, unnumbered
  const [versesRefValue, setVersesRefValue] = useState("");

  const selectedRefLength =
    versesRefLength === "short"
      ? t("bible-page.sidebar.copy-modal.ref-length.short")
      : t("bible-page.sidebar.copy-modal.ref-length.long");
  const selectedRefPlace =
    versesRefPlace === "start"
      ? t("bible-page.sidebar.copy-modal.ref-place.start")
      : t("bible-page.sidebar.copy-modal.ref-place.end");
  const selectedTextFormat =
    versesTextFormat === "numbered"
      ? t("bible-page.sidebar.copy-modal.text-format.numbered")
      : t("bible-page.sidebar.copy-modal.text-format.unnumbered");

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("copyBibleVerses"));
    if (savedSettings) {
      if (savedSettings.refLength) {
        setVersesRefLength(savedSettings.refLength);
      }
      if (savedSettings.refPlace) {
        setVersesRefPlace(savedSettings.refPlace);
      }
      if (savedSettings.textFormat) {
        setVersesTextFormat(savedSettings.textFormat);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "copyBibleVerses",
      JSON.stringify({
        refLength: versesRefLength,
        refPlace: versesRefPlace,
        textFormat: versesTextFormat,
      })
    );
  }, [versesRefLength, versesRefPlace, versesTextFormat]);

  useEffect(() => {
    let text = "";
    let versesRef = "";

    if (
      currentBibleScreen === "bible_chapter" &&
      selectedVerses?.verses?.length > 0
    ) {
      const firstVerse = selectedVerses.verses[0];
      const bookCode = selectedVerses?.verses?.[0]?.book_code;

      const verseNumbers = formatVerseNumbers(
        selectedVerses.verses.map((verse) => verse.verse_num)
      );

      const bookName =
        versesRefLength === "short"
          ? BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[bookCode]?.short_name
          : BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[bookCode]?.secondName ||
            BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[bookCode]?.name;

      versesRef = `${bookName} ${firstVerse.chapter_num}:${verseNumbers}`;

      text = selectedVerses.verses
        .reduce((acc, verse) => {
          if (versesTextFormat === "numbered") {
            return (
              acc +
              `${verse.verse_num}. ${extractingTextOfBibleVerses(
                verse.verse_text
              )}\n`
            );
          } else {
            return acc + `${extractingTextOfBibleVerses(verse.verse_text)} `;
          }
        }, "")
        .trim();

      if (versesTextFormat === "unnumbered") {
        text = `«${text.trim()}»`;
      }
    }

    if (
      currentBibleScreen === "bible_chapter_modal_window" &&
      selectedVerses.length > 0
    ) {
      const bookCode = selectedVerses?.[0]?.book_code;

      const bookName =
        versesRefLength === "short"
          ? BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[bookCode]?.short_name
          : BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[bookCode]?.secondName ||
            BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[bookCode]?.name;

      const verseNumbers = formatVerseNumbers(
        selectedVerses.map((verse) => verse.verse_num)
      );

      versesRef = `${bookName} ${selectedVerses?.[0]?.chapter_num}:${verseNumbers}`;

      text = selectedVerses
        .reduce((acc, verse) => {
          if (versesTextFormat === "numbered") {
            return (
              acc +
              `${verse.verse_num}. ${bibleVersesWithoutTags(
                verse.verse_text
              )}\n`
            );
          } else {
            return acc + `${bibleVersesWithoutTags(verse.verse_text)} `;
          }
        }, "")
        .trim();

      if (versesTextFormat === "unnumbered") {
        text = `«${text.trim()}»`;
      }
    }

    setVersesRefValue(versesRef);
    setCopyInputValue(text);
  }, [
    versesRefLength,
    selectedVerses,
    currentBibleLocale,
    currentBibleScreen,
    versesTextFormat,
  ]);

  const handleCopyValue = (event) => {
    setCopyInputValue(event.target.value);
  };

  const handleCloseModalWindow = (event) => {
    event.stopPropagation();
    setOpenCopyModal(false);
  };

  const handleRefLengthChange = () => {
    setVersesRefLength((prev) => (prev === "short" ? "long" : "short"));
  };

  const handleRefPlaceChange = () => {
    setVersesRefPlace((prev) => (prev === "start" ? "end" : "start"));
  };

  const handleTextFormatChange = () => {
    setVersesTextFormat((prev) =>
      prev === "numbered" ? "unnumbered" : "numbered"
    );
  };

  const onClickCopyButton = (event) => {
    event.stopPropagation();

    let formattedText = "";

    if (versesTextFormat === "unnumbered") {
      formattedText =
        versesRefPlace === "start"
          ? `(${versesRefValue}) ${copyInputValue}`
          : `${copyInputValue} (${versesRefValue})`;
    } else {
      formattedText =
        versesRefPlace === "start"
          ? `(${versesRefValue})\n${copyInputValue}`
          : `${copyInputValue}\n(${versesRefValue})`;
    }

    copyToClipboard(formattedText);
  };

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        handleCloseModalWindow(e);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <BibleCopyModalWindowWrapper
      onMouseDown={handleCloseModalWindow}
      onClick={(e) => e.stopPropagation()}
    >
      <BibleCopyModalWindowContainer
        flexEnd={versesRefPlace === "end"}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {versesRefPlace === "start" && (
          <CopyVersesRef marginBottom={true}>{versesRefValue}</CopyVersesRef>
        )}
        <CopyTextInputContainer>
          <CopyTextInput value={copyInputValue} onChange={handleCopyValue} />
        </CopyTextInputContainer>
        {versesRefPlace === "end" && (
          <CopyVersesRef>{versesRefValue}</CopyVersesRef>
        )}

        <TextSettingsContainer>
          <BibleCopySettings>
            <p>{t("bible-page.sidebar.copy-modal.text-settings-title")}</p>
            <BibleCopySettingsButtons>
              <BibleCopySettingsButton onClick={handleTextFormatChange}>
                <ArrowIconContainer>
                  <BibleCopyLeftArrowIcon />
                </ArrowIconContainer>
                {selectedTextFormat}
                <ArrowIconContainer right={true}>
                  <BibleCopyRightArrowIcon />
                </ArrowIconContainer>
              </BibleCopySettingsButton>
            </BibleCopySettingsButtons>
          </BibleCopySettings>
        </TextSettingsContainer>

        <RefSettingsContainer>
          <BibleCopySettings>
            <p>{t("bible-page.sidebar.copy-modal.ref-settings-title")}</p>
            <BibleCopySettingsButtons>
              <BibleCopySettingsButton onClick={handleRefLengthChange}>
                <ArrowIconContainer>
                  <BibleCopyLeftArrowIcon />
                </ArrowIconContainer>
                {selectedRefLength}
                <ArrowIconContainer right={true}>
                  <BibleCopyRightArrowIcon />
                </ArrowIconContainer>
              </BibleCopySettingsButton>

              <BibleCopySettingsButton onClick={handleRefPlaceChange}>
                <ArrowIconContainer>
                  <BibleCopyLeftArrowIcon />
                </ArrowIconContainer>
                {selectedRefPlace}
                <ArrowIconContainer right={true}>
                  <BibleCopyRightArrowIcon />
                </ArrowIconContainer>
              </BibleCopySettingsButton>
            </BibleCopySettingsButtons>
          </BibleCopySettings>

          <CopyButton onClick={onClickCopyButton}>
            <BibleCopyModalIcon />
          </CopyButton>
        </RefSettingsContainer>
      </BibleCopyModalWindowContainer>
    </BibleCopyModalWindowWrapper>
  );
};

export default memo(BibleCopyModalWindow);
