import React, { memo } from "react";
import styled from "styled-components";

const ENABLED_BUTTON_STYLES = `
  border: 2px solid #5172EA;
  color: #5172EA;
`;

const DISABLED_BUTTON_STYLES = `
  border: 2px solid #a6aaec;
  color: #a6aaec;
`;

const StudyPageSortButtonStyles = styled.button`
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive ? ENABLED_BUTTON_STYLES : DISABLED_BUTTON_STYLES}
  font-size: 16px;
  line-height: 120%;
  transition: 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  @media (max-width: 670px) {
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-width: 2px;
    height: 30px;
  }

  @media (max-width: 412px) {
    font-size: 12px;
    border-width: 1px;
    height: 26px;
  }
`;

const StudyPageSortButton = ({ children, onClick, isActive }) => {
  return (
    <StudyPageSortButtonStyles onClick={onClick} isActive={isActive}>
      {children}
    </StudyPageSortButtonStyles>
  );
};

export default memo(StudyPageSortButton);
