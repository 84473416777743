import React, { memo, useCallback } from "react";
import styled from "styled-components";
import TextEditor from "../../../../../common/components/text_editor";

const ArticleTextInputContainer = styled.div`
  height: fit-content;
  border-radius: 8px;
  ${({ isEmpty }) => isEmpty && "border: 1px solid red;"}
`;

const ArticleTextInput = ({
  articleSettingsData,
  setArticleSettingsData,
  emptyInput,
}) => {
  const isEmpty =
    emptyInput && !articleSettingsData?.article_text?.blocks?.[0]?.text;

  const onEditorChange = useCallback(
    (updatedInputText) => {
      setArticleSettingsData((prevState) => ({
        ...prevState,
        article_text: updatedInputText,
      }));
    },
    [setArticleSettingsData]
  );

  return (
    <ArticleTextInputContainer isEmpty={isEmpty}>
      <TextEditor
        contentStateData={articleSettingsData.article_text}
        onEditorChange={onEditorChange}
        placeholder="Основной текст"
        inComponent={"article_text"}
        withoutTextSize={true}
        key={"article_text" + articleSettingsData._id}
      />
    </ArticleTextInputContainer>
  );
};

export default memo(ArticleTextInput);
