import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import TranslationsList from "./translations_list";
import TranslationPanelInput from "./translation_panel_input";
import { useBiblePageContext } from "../../../bible_page_context";
import useWindowBreakpoint from "../../../../../common/hooks/use_window_breakpoint";
import { useTranslation } from "react-i18next";

const TranslationSelectContainer = styled.div``;

const TranslationSelect = ({
  bibleTranslations,
  currentBibleLanguage,
  currentTranslation,
  setCurrentScreen,
  setCurrentTranslation,
  setBibleTranslations,
  isSecond,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { setActiveScreenMobile } = useBiblePageContext();

  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedTranslations, setSelectedTranslations] = useState([]);

  const isMobile = useWindowBreakpoint(744);

  const getFilteredTranslation = useCallback(
    (bibleTranslations, inputSearchValue) => {
      const sortedTranslationByFavorite = bibleTranslations?.sort(
        (tr1, tr2) => tr2.isFavorite - tr1.isFavorite
      );
      const filteredByLanguage = sortedTranslationByFavorite?.filter(
        (translation) =>
          translation.bible_locale === currentBibleLanguage?.code ||
          translation.isFavorite
      );

      const filteredBySearch = filteredByLanguage?.filter(
        (translation) =>
          translation.bible_code
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.bible_name
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.isFavorite
      );

      return filteredBySearch;
    },
    [currentBibleLanguage]
  );

  useEffect(() => {
    if (bibleTranslations) {
      setSelectedTranslations(
        getFilteredTranslation(bibleTranslations, inputSearchValue)
      );
    }
  }, [bibleTranslations, inputSearchValue, currentBibleLanguage]);

  const onTranslationClick = useCallback(
    (translationData) => {
      setCurrentTranslation(translationData);

      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set(
          isSecond ? "2bibleId" : "bibleId",
          translationData.bible_code
        );

        if (currentTranslation?.bible_code === newParams.get("selectBible")) {
          newParams.set("selectBible", translationData.bible_code);
        }

        return newParams;
      });

      setInputSearchValue("");

      if (isMobile) {
        setActiveScreenMobile("bible_chapter");
      }
    },
    [isSecond, setSearchParams, isMobile, currentTranslation]
  );

  return (
    <TranslationSelectContainer>
      <TranslationPanelInput
        placeholder={t(
          "bible-page.translation-panel.input-placeholder.translation"
        )}
        section={"translation"}
        onClickButton={() => setCurrentScreen("languages")}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
        currentBibleLanguage={currentBibleLanguage}
      />
      <TranslationsList
        selectedTranslations={selectedTranslations}
        currentTranslation={currentTranslation}
        setBibleTranslations={setBibleTranslations}
        onTranslationClick={onTranslationClick}
      />
    </TranslationSelectContainer>
  );
};

export default memo(TranslationSelect);
