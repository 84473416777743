import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import AdminPageCopyIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_copy_icon";
import AdminPageInfoIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_info_icon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BASE_API_URL } from "../../../../common/endpoints";
import PlayIcon from "../../../../common/assets/icons/play_icon";
import CourseLessonArrows from "./course_lesson_arrows";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";

const CourseLessonPreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  cursor: pointer;
  opacity: ${({ unselected }) => (unselected ? "0.5" : "1")};

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    padding: 0 12px;
    min-width: 73px;
    text-align: center;
  }

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const LessonInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  width: 554px;
  height: 100%;
  padding: 0 16px;
  border-radius: 8px;
  background: #eaeaea;
  border: 2px solid #5172ea;

  & > h6 {
    flex: 1;
    color: #000;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LessonButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  height: 20px;
`;

const LessonButton = styled.div`
  cursor: pointer;
  height: 100%;

  &:hover {
    transition: 0.2s;
    transform: scale(1.12);
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  width: 100%;
  height: 18px;
  padding-left: 2px;
`;

const CourseLessonPreview = ({
  lesson,
  lessonSettingsData,
  courseSettingsData,
  userIsCreatorOrSuperAdmin,
  setLessonInfoModalData,
  lessonIndex,
  fetchCourses,
  fetchCourse,
}) => {
  const navigate = useNavigate();
  const lessonRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const isTest = lesson.lesson_type === "test";

  const currentLesson = lesson._id === lessonSettingsData._id;
  const lessonNumber = lessonIndex + 1;

  const lessonInfo =
    lesson._id === lessonSettingsData?._id ? lessonSettingsData : lesson;

  const unselected =
    lessonSettingsData?._id && lessonSettingsData?._id !== lesson._id;

  const lessonUrl = `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${lesson?._id}`;
  const queryParams =
    lesson?.lesson_type === "test" ? "?p=list" : `?n=${lessonNumber}&p=list`;

  const onClickLessonPreview = useCallback(
    (event) => {
      event.stopPropagation();
      searchParams.set("lessonId", lesson._id);
      setSearchParams(searchParams);
    },
    [lesson]
  );

  const onDoubleClickLessonPreview = useCallback(
    (event) => {
      event.stopPropagation();

      const baseUrl = `/admin/create_lesson/${lesson._id}`;
      const queryString =
        lesson.lesson_type === "test" ? "" : `?n=${lessonNumber}`;

      navigate(baseUrl + queryString);
    },
    [navigate, lesson, lessonNumber]
  );

  const onClickInfoButton = useCallback(
    (event) => {
      event.stopPropagation();
      setLessonInfoModalData(lesson);
    },
    [lesson]
  );

  const onClickCopyLessonButton = useCallback(
    async (event) => {
      event.stopPropagation();

      if (isTest) return;

      try {
        const response = await axios.post(
          `${BASE_API_URL}/lessons/${lesson._id}/copy`
        );

        if (response.data.lesson) {
          searchParams.set("lessonId", response.data.lesson._id);
          setSearchParams(searchParams);

          fetchCourses();
          fetchCourse();
        } else {
          console.error("Некорректный ответ от сервера:", response);
        }
      } catch (error) {
        console.error("Ошибка при копировании урока:", error);
      }
    },
    [lesson, searchParams, setSearchParams, isTest]
  );

  useEffect(() => {
    if (currentLesson && lessonRef.current) {
      lessonRef.current.scrollIntoView({ block: "center" });
    }
  }, []);

  return (
    <CourseLessonPreviewContainer
      ref={lessonRef}
      unselected={unselected}
      onClick={onClickLessonPreview}
      onDoubleClick={onDoubleClickLessonPreview}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {!isTest ? <p>Урок {lessonNumber}</p> : <p>Тест</p>}
      <LessonInfoContainer>
        <h6>{lessonInfo.lesson_name}</h6>

        <LessonButtonsContainer>
          <LessonButton onClick={onClickInfoButton}>
            <AdminPageInfoIcon />
          </LessonButton>

          {userIsCreatorOrSuperAdmin && !isTest && (
            <LessonButton onClick={onClickCopyLessonButton}>
              <AdminPageCopyIcon />
            </LessonButton>
          )}

          <Link
            onClick={(e) => e.stopPropagation()}
            to={lessonUrl + queryParams}
          >
            <LessonButton>
              <PlayIconStyles color={"#F8D254"} />
            </LessonButton>
          </Link>
        </LessonButtonsContainer>
      </LessonInfoContainer>

      {currentLesson && !isTest && (
        <CourseLessonArrows
          lessonIndex={lessonIndex}
          courseSettingsData={courseSettingsData}
          fetchCourse={fetchCourse}
          fetchCourses={fetchCourses}
        />
      )}
    </CourseLessonPreviewContainer>
  );
};

export default memo(CourseLessonPreview);
