import React from "react";

const TextAlignRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1875 4.2C23.1875 3.53726 22.6502 3 21.9875 3H2.9875C2.32476 3 1.7875 3.53726 1.7875 4.2C1.7875 4.86274 2.32476 5.4 2.9875 5.4H21.9875C22.6502 5.4 23.1875 4.86274 23.1875 4.2Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1875 11.2001C23.1875 10.5374 22.6502 10.0001 21.9875 10.0001H9.9875C9.32476 10.0001 8.7875 10.5374 8.7875 11.2001C8.7875 11.8628 9.32476 12.4001 9.9875 12.4001H21.9875C22.6502 12.4001 23.1875 11.8628 23.1875 11.2001Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1875 18.8C23.1875 18.1373 22.6502 17.6 21.9875 17.6H6.9875C6.32476 17.6 5.7875 18.1373 5.7875 18.8C5.7875 19.4627 6.32476 20 6.9875 20H21.9875C22.6502 20 23.1875 19.4627 23.1875 18.8Z"
        fill="#383838"
      />
    </svg>
  );
};

export default TextAlignRightIcon;
