import React, { memo, useCallback } from "react";
import CourseLessonArrowIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_arrow_icon";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import { useUserDataContext } from "../../../../user_data_context";

const CourseLessonArrowsContainer = styled.div`
  position: absolute;
  top: 50%;
  right: -26px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
`;

const CourseLessonArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 24px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    transition: 0.2s;
  }
`;

const CourseLessonArrowUp = styled(CourseLessonArrow)``;

const CourseLessonArrowDown = styled(CourseLessonArrow)`
  transform: rotate(180deg);
  padding-right: 1px;
`;

const updateCourseDataOnServer = async (
  courseSettingsData,
  updatedLessons,
  editorId
) => {
  await axios.patch(`${BASE_API_URL}/courses/${courseSettingsData._id}`, {
    ...courseSettingsData,
    course_modified_at: new Date().toISOString(),
    course_modified_by: { _id: editorId },
    course_lessons: updatedLessons,
  });
};

const CourseLessonArrows = ({
  lessonIndex,
  courseSettingsData,
  fetchCourse,
  fetchCourses,
}) => {
  const { userData } = useUserDataContext();
  const editorId = userData._id;

  const courseLessonsCount =
    courseSettingsData.course_lessons?.filter(
      (lesson) => lesson.lesson_type !== "test"
    )?.length || null;

  const moveLessonUp = useCallback(async () => {
    if (lessonIndex <= 0) return;

    const updatedLessons = [...courseSettingsData.course_lessons];
    [updatedLessons[lessonIndex - 1], updatedLessons[lessonIndex]] = [
      updatedLessons[lessonIndex],
      updatedLessons[lessonIndex - 1],
    ];

    await updateCourseDataOnServer(
      courseSettingsData,
      updatedLessons,
      editorId
    );
    await fetchCourse();
    await fetchCourses();
  }, [lessonIndex, courseSettingsData, editorId, fetchCourse, fetchCourses]);

  const moveLessonDown = useCallback(async () => {
    if (lessonIndex >= courseLessonsCount - 1) return;

    const updatedLessons = [...courseSettingsData.course_lessons];
    [updatedLessons[lessonIndex], updatedLessons[lessonIndex + 1]] = [
      updatedLessons[lessonIndex + 1],
      updatedLessons[lessonIndex],
    ];

    await updateCourseDataOnServer(
      courseSettingsData,
      updatedLessons,
      editorId
    );
    await fetchCourse();
    await fetchCourses();
  }, [lessonIndex, courseSettingsData, editorId, fetchCourse, fetchCourses]);

  return (
    <CourseLessonArrowsContainer onClick={(e) => e.stopPropagation()}>
      {lessonIndex > 0 && (
        <CourseLessonArrowUp onClick={moveLessonUp}>
          <CourseLessonArrowIcon />
        </CourseLessonArrowUp>
      )}

      {lessonIndex < courseLessonsCount - 1 && (
        <CourseLessonArrowDown onClick={moveLessonDown}>
          <CourseLessonArrowIcon />
        </CourseLessonArrowDown>
      )}
    </CourseLessonArrowsContainer>
  );
};

export default memo(CourseLessonArrows);
