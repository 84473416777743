import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { SearchIcon } from "../../../../common/assets/icons";
import Checkbox from "../../../../common/components/checkbox";
import RemoveIcon from "../../../../common/assets/icons/remove_icon";

const BibleSearchPanelWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const BibleSearchPanelContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  min-height: 30px;
  padding-left: 14px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  ${({ searchInputError }) => searchInputError && "border: 1px solid red;"}

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const BibleSearchInput = styled.input`
  flex: 1;
  font-size: 13px;
  width: 100%;
  letter-spacing: -0.13px;
`;

const SearchIconContainer = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0 10px 0 6px;
  height: 100%;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 13px;
  height: 13px;
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 15px;
  height: 15px;
`;

const SearchCheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 12px;
  font-size: 12px;
`;

const BibleSearchPanel = ({
  activeScreenDesktop,
  bibleSearchPlace,
  bibleSearchInput,
  setActiveScreenDesktop,
  setBibleSearchResults,
  setBibleSearchInput,
  setExpandedSectionName,
  setIsLoading,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchWholeWord, setWholeWordCheckbox] = useState(false);
  const [searchWithRegister, setWithRegisterCheckbox] = useState(false);
  const [searchInputError, setSearchInputError] = useState(false);
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon, remove_icon

  const bibleSearchTranslate = searchParams.get("bibleId");

  const handleSearchError = () => {
    setSearchInputError(true);
    setTimeout(() => setSearchInputError(false), 2000);
  };

  const onSearchSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (bibleSearchInput.trim().length > 1) {
        setIsLoading(true);
        setActiveScreenDesktop("bible_search");
        try {
          const response = await axios.post(
            "https://api.on-bible.com/bible/verses/lookup",
            {
              bible_code: bibleSearchTranslate,
              search_value: bibleSearchInput,
              search_option_place: bibleSearchPlace,
              search_option_whole_word: searchWholeWord,
              search_option_with_register: searchWithRegister,
            }
          );
          setBibleSearchResults({
            searchBible: bibleSearchTranslate,
            searchTestament: bibleSearchPlace,
            searchWord: bibleSearchInput,
            searchResults: response.data.results,
          });
        } catch (error) {
          console.error("Ошибка при отправке запроса", error);
          handleSearchError();
          setBibleSearchResults({});
        }

        setExpandedSectionName("");
        setShowedInputIcon("remove_icon");

        if (activeScreenDesktop === "bible_chapter") {
          setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            newParams.delete("2bibleId");
            newParams.delete("selectVerses");
            newParams.delete("selectBible");
            return newParams;
          });
        }

        setIsLoading(false);
      } else {
        handleSearchError();
      }
    },
    [
      bibleSearchTranslate,
      bibleSearchInput,
      bibleSearchPlace,
      searchWholeWord,
      searchWithRegister,
      setSearchParams,
      activeScreenDesktop,
    ]
  );

  useEffect(() => {
    if (bibleSearchInput && activeScreenDesktop === "bible_search") {
      const fakeEvent = { preventDefault: () => {} };
      onSearchSubmit(fakeEvent);
    }

    if (!bibleSearchInput.length && activeScreenDesktop === "bible_search") {
      setSearchInputError(true);

      setTimeout(() => {
        setSearchInputError(false);
      }, 2000);
    }
  }, [
    bibleSearchTranslate,
    bibleSearchPlace,
    searchWholeWord,
    searchWithRegister,
  ]);

  useEffect(() => {
    if (activeScreenDesktop !== "bible_search") {
      setWholeWordCheckbox(false);
      setWithRegisterCheckbox(false);
      setShowedInputIcon("search_icon");
    }
  }, [activeScreenDesktop]);

  useEffect(() => {
    setShowedInputIcon("search_icon");

    if (bibleSearchInput === "") {
      setBibleSearchResults((prevState) => ({
        ...prevState,
        searchResults: [],
        searchWord: "",
      }));
    }
  }, [bibleSearchInput]);

  const onRemoveIconClick = useCallback((event) => {
    event.stopPropagation();
    setBibleSearchInput("");
    setShowedInputIcon("search_icon");
  }, []);

  const handleSearchInput = useCallback((event) => {
    setBibleSearchInput(event.target.value);
  }, []);

  const handleWholeWordCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWholeWordCheckbox((prevState) => !prevState);
  }, []);

  const handleWithRegisterCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWithRegisterCheckbox((prevState) => !prevState);
  }, []);

  return (
    <BibleSearchPanelWrapper>
      <BibleSearchPanelContainer
        searchInputError={searchInputError}
        onSubmit={onSearchSubmit}
      >
        <BibleSearchInput
          placeholder={t("bible-page.search.search-placeholder")}
          value={bibleSearchInput}
          onChange={handleSearchInput}
        />

        {showedInputIcon === "search_icon" && (
          <SearchIconContainer type="submit">
            <SearchIconStyled />
          </SearchIconContainer>
        )}

        {showedInputIcon === "remove_icon" && (
          <SearchIconContainer onClick={onRemoveIconClick}>
            <RemoveIconStyled color="#383838" />
          </SearchIconContainer>
        )}
      </BibleSearchPanelContainer>

      {activeScreenDesktop === "bible_search" && (
        <SearchCheckboxContainer>
          <Checkbox
            checked={searchWholeWord}
            onClickCheckbox={handleWholeWordCheckbox}
            color={searchWholeWord ? "#1e1e1e" : "#828282"}
            fontSize={"10px"}
            letterSpacing={"0.4px"}
            component={"bible_search"}
          >
            {t("bible-page.search.whole-word")}
          </Checkbox>

          <Checkbox
            checked={searchWithRegister}
            onClickCheckbox={handleWithRegisterCheckbox}
            color={searchWithRegister ? "#1e1e1e" : "#828282"}
            fontSize={"10px"}
            letterSpacing={"0.4px"}
            component={"bible_search"}
          >
            {t("bible-page.search.with-registr")}
          </Checkbox>
        </SearchCheckboxContainer>
      )}
    </BibleSearchPanelWrapper>
  );
};

export default memo(BibleSearchPanel);
