import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import HomePreviewDailyVerse from "./home_preview_daily_verse";
import HomePreviewAricles from "./home_preview_articles";
import HorizontalLine from "../../../../common/assets/icons/horizontal_line";
import { BASE_API_URL } from "../../../../common/endpoints";
import { createPortal } from "react-dom";
import BibleChapterModalWindow from "../../../../common/components/modal_windows/bible_chapter_modal_window";
import BibleModalWindow from "../../../../common/components/modal_windows/bible_modal_window";

const AdminPageHomePreviewContainer = styled.div`
  flex: 1;
  padding-top: 50px;
  height: 400px;
  max-width: 630px;
  margin: 0 auto;
`;

const AdminPageHomePreviewAricles = styled.div`
  padding-bottom: 80px;
  opacity: ${({ inactive }) => inactive && "0.5"};
`;

const AddArticleButton = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
  width: max-content;
  margin: 22px 0;
  height: 40px;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: default;
  `}

  &: hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const currentDate = new Date().toISOString();

const DEFAULT_ARTICLE_DATA = {
  article_created_at: currentDate,
  article_title: {},
  article_text: {},
  article_image_url: "",
  article_video_url: "",
  article_background_color: "",
  article_is_visible: false,
  article_is_pinned: false,
  _id: "new_article",
};

const AdminPageHomePreview = ({
  activeSettingsBlock,
  homePageArticles,
  homePageDailyVerse,
  editedArticleId,
  loadingArticles,
  articleSettingsData,
  articleSettingsDataMemo,
  setActiveSettingsBlock,
  setHomePageArticles,
  setArticleSettingsData,
  setEditedArticleId,
  setArticleSettingsDataMemo,
  setHomePageDailyVerse,
  setOpenEditQuestionModal,
  onCloseActiveSettings,
  appColorTheme,
}) => {
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  const fetchBibleDailyVerse = async () => {
    axios
      .get(BASE_API_URL + "/settings/daily_bible_verse")
      .then((response) => {
        setHomePageDailyVerse(response.data.scope_props.bible_verse);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  };

  useEffect(() => {
    fetchBibleDailyVerse();
  }, []);

  const handleAddArticle = useCallback(
    (event) => {
      event.stopPropagation();
      const lastColor = localStorage.getItem("lastColor");
      const defaultArticleData = {
        ...DEFAULT_ARTICLE_DATA,
        article_background_color: lastColor ? lastColor : "PURPLE",
      };

      if (!activeSettingsBlock) {
        setActiveSettingsBlock("article_settings");
        setArticleSettingsDataMemo(defaultArticleData);
        setArticleSettingsData(defaultArticleData);
        setEditedArticleId(defaultArticleData._id);
        setHomePageArticles((prevState) => [defaultArticleData, ...prevState]);
      } else {
        onCloseActiveSettings(event);
      }
    },
    [activeSettingsBlock]
  );

  return (
    <AdminPageHomePreviewContainer>
      <HomePreviewDailyVerse
        homePageDailyVerse={homePageDailyVerse}
        editedArticleId={editedArticleId}
        setActiveSettingsBlock={setActiveSettingsBlock}
        onCloseActiveSettings={onCloseActiveSettings}
      />

      <HorizontalLine width={"630px"} height={"3px"} />

      <AdminPageHomePreviewAricles
        inactive={activeSettingsBlock === "daily_verse_settings"}
      >
        <AddArticleButton
          onClick={handleAddArticle}
          disabled={!!activeSettingsBlock}
          onMouseDown={(e) => e.stopPropagation()}
        >
          Добавить публикацию
        </AddArticleButton>

        <HomePreviewAricles
          homePageArticles={homePageArticles}
          editedArticleId={editedArticleId}
          loadingArticles={loadingArticles}
          articleSettingsData={articleSettingsData}
          articleSettingsDataMemo={articleSettingsDataMemo}
          setActiveSettingsBlock={setActiveSettingsBlock}
          setArticleSettingsData={setArticleSettingsData}
          setEditedArticleId={setEditedArticleId}
          setArticleSettingsDataMemo={setArticleSettingsDataMemo}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          setTextBibleModalData={setTextBibleModalData}
          appColorTheme={appColorTheme}
        />
      </AdminPageHomePreviewAricles>

      {textBibleModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={textBibleModalData}
            setOpen={setTextBibleModalData}
            setBibleVersesData={setBibleVersesData}
            setOpenBibleModalWindow={setOpenBibleModalWindow}
          />,
          document.body
        )}

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            bibleVersesData={bibleVersesData}
            setBibleVersesData={setBibleVersesData}
            inComponent={"simple_modal"}
          />,
          document.body
        )}
    </AdminPageHomePreviewContainer>
  );
};

export default memo(AdminPageHomePreview);
