import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import BibleIcon from "../../../../../common/assets/icons/bible_icon";
import SmileIcon from "../../../../../common/assets/icons/smile_icon";
import SendMessageIcon from "../../../../../common/assets/icons/send_message_icon";
import ChatInputAdditionalBlock from "./chat_input_additional_block";
import { useChatContentContext } from "../chat_content_context";
import BibleModalWindow from "../../../../../common/components/modal_windows/bible_modal_window";

const ChatInputWrapper = styled.div`
  margin: 16px 25px 0px;
  padding: 15px 16px;
  min-height: 56px;
  max-width: 929px;
  background: #ffffff;
  border-radius: 25px;

  @media (max-width: 1280px) {
    max-width: calc(100vw - 351px);
  }

  @media (max-width: 900px) {
    max-width: calc(100vw - 331px);
    margin: 16px 15px 0px;
  }

  @media (max-width: 790px) {
    max-width: calc(100vw - 301px);
  }

  @media (max-width: 745px) {
    max-width: calc(100vw - 31px);
  }
`;

const ChatInputMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const ChatInputStyled = styled.textarea`
  font-size: 16px;
  line-heigh: 20px;
  flex: 1;
  resize: none;
  max-height: 80px;

  @media (max-width: 420px) {
    font-size: 14px;
    line-heigh: 16px;
    max-height: 70px;
  }
`;

const BibleIconContainer = styled.div`
  cursor: pointer;
`;

const SmileAndSendIconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const SmileIconStyled = styled(SmileIcon)`
  cursor: pointer;
`;

const SendMessageIconContainer = styled.div`
  cursor: pointer;
`;

const ChatInput = ({ chatId }) => {
  const { editMessage, setEditMessage, chatInputValue, setChatInputValue } =
    useChatContentContext();
  const textAreaRef = useRef(null);
  const [biblicalVerses, setBiblicalVerses] = useState([]);
  const [bibleVersesData, setBibleVersesData] = useState({});
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);

  useEffect(() => {
    if (Object.entries(editMessage).length) {
      setChatInputValue(editMessage.text);
    }
  }, [editMessage]);

  useEffect(() => {
    return () => {
      setChatInputValue("");
      setEditMessage({});
    };
  }, []);

  const handleSendOrEditMessage = useCallback(() => {
    if (Object.entries(editMessage).length) {
      // ...code for EDIT

      setEditMessage({});
      setChatInputValue("");
    } else {
      // ...code for SEND

      setChatInputValue("");
    }
  }, [editMessage]);

  const inputHandler = useCallback((e) => {
    setChatInputValue(e.target.value);
  }, []);

  const handleOpenBibleModalWindow = useCallback(() => {
    setOpenBibleModalWindow(true);
  }, []);

  const handleAddBibleVerses = useCallback((addedVerses) => {
    setBiblicalVerses((prevState) => [...prevState, addedVerses]);
  }, []);

  useEffect(() => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  }, [chatInputValue]);

  return (
    <ChatInputWrapper>
      <ChatInputAdditionalBlock
        biblicalVerses={biblicalVerses}
        setBiblicalVerses={setBiblicalVerses}
        chatId={chatId}
      />

      <ChatInputMainContainer>
        <BibleIconContainer onClick={handleOpenBibleModalWindow}>
          <BibleIcon />
        </BibleIconContainer>

        <ChatInputStyled
          type="text"
          rows={"1"}
          placeholder="Type a message..."
          value={chatInputValue}
          onChange={inputHandler}
          ref={textAreaRef}
        />

        <SmileAndSendIconsContainer>
          <SmileIconStyled />
          {chatInputValue && (
            <SendMessageIconContainer onClick={handleSendOrEditMessage}>
              <SendMessageIcon />
            </SendMessageIconContainer>
          )}
        </SmileAndSendIconsContainer>
      </ChatInputMainContainer>

      {openBibleModalWindow && (
        <BibleModalWindow
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          setChatInputValue={setChatInputValue}
          bibleVersesData={bibleVersesData}
          setBibleVersesData={setBibleVersesData}
          inComponent={"chat_page"}
        />
      )}
    </ChatInputWrapper>
  );
};

export default ChatInput;
