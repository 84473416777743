import React, { memo, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import BibleVersesMobile from "./bible_verses_mobile";
import BibleNavigationMobile from "./bible_navigation_mobile.js";
import { BASE_API_URL } from "../../../../endpoints";
import BibleTranslationMobile from "./bible_translation_mobile.js";
import BibleModalSearchMobile from "./bible_modal_search_mobile/index.js";
import InsertionVariantButtonsMobile from "./insertion_variant_buttons_mobile.js";

const BiblePageMobileContainer = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  border-radius: 25px;
  background: #eaeaea;
  overflow: hidden;

  @media (max-width: 500px) {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
`;

const BibleModalWindowMobile = ({
  activeScreenMobile,
  setActiveScreenMobile,
  currentBibleParams,
  inComponent,
  bibleFontSettings,
  selectedVerses,
  setSelectedVerses,
  searchWords,
  setSearchWords,
  bibleTranslations,
  currentTranslation,
  currentTranslationData,
  setCurrentTranslationData,
  setBibleTranslations,
  setCurrentBibleParams,
  setCurrentTranslation,
  bibleModalSearchPlace,
  setBibleModalSearchPlace,
  bibleModalSearchInput,
  setBibleModalSearchInput,
  bibleModalSearchResults,
  setBibleModalSearchResults,
  searchIsLoading,
  setSearchIsLoading,
  handleCloseBibleModalWindow,
  handleAddBibleVerses,
  setChatInputValue,
  setBibleVersesData,
}) => {
  const fetchTranslations = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/bible/transl`);
      const translations = response.data;
      setBibleTranslations(
        translations.bibles.map((transl) => {
          return { ...transl, isFavorite: false };
        })
      );
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  useEffect(() => {
    fetchTranslations();
  }, []);

  return (
    <BiblePageMobileContainer onClick={(e) => e.stopPropagation()}>
      {activeScreenMobile === "bible_modal_chapter" && (
        <BibleVersesMobile
          bibleFontSettings={bibleFontSettings}
          inComponent={inComponent}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentBibleParams={currentBibleParams}
          currentTranslation={currentTranslation}
          searchWords={searchWords}
          currentTranslationData={currentTranslationData}
          setActiveScreenMobile={setActiveScreenMobile}
          setCurrentBibleParams={setCurrentBibleParams}
          handleCloseBibleModalWindow={handleCloseBibleModalWindow}
        />
      )}

      {activeScreenMobile === "bible_modal_search" && (
        <BibleModalSearchMobile
          bibleModalSearchPlace={bibleModalSearchPlace}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
          bibleModalSearchInput={bibleModalSearchInput}
          setBibleModalSearchInput={setBibleModalSearchInput}
          bibleModalSearchResults={bibleModalSearchResults}
          setBibleModalSearchResults={setBibleModalSearchResults}
          searchIsLoading={searchIsLoading}
          setSearchIsLoading={setSearchIsLoading}
          setActiveScreenMobile={setActiveScreenMobile}
          currentTranslation={currentTranslation}
          activeScreenMobile={activeScreenMobile}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
          setSearchWords={setSearchWords}
        />
      )}

      {activeScreenMobile === "bible_modal_navigation" && (
        <BibleNavigationMobile
          currentTranslation={currentTranslation}
          currentBibleParams={currentBibleParams}
          activeScreenMobile={activeScreenMobile}
          currentTranslationData={currentTranslationData}
          setCurrentTranslationData={setCurrentTranslationData}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
          setActiveScreenMobile={setActiveScreenMobile}
        />
      )}

      {activeScreenMobile === "bible_modal_translation" && (
        <BibleTranslationMobile
          bibleTranslations={bibleTranslations}
          currentTranslation={currentTranslation}
          setBibleTranslations={setBibleTranslations}
          setCurrentTranslation={setCurrentTranslation}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
          setActiveScreenMobile={setActiveScreenMobile}
        />
      )}

      {activeScreenMobile === "bible_modal_chapter" && (
        <InsertionVariantButtonsMobile
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslation={currentTranslation}
          currentBibleParams={currentBibleParams}
          handleAddBibleVerses={handleAddBibleVerses}
          handleCloseBibleModalWindow={handleCloseBibleModalWindow}
          setChatInputValue={setChatInputValue}
          setBibleVersesData={setBibleVersesData}
          inComponent={inComponent}
        />
      )}
    </BiblePageMobileContainer>
  );
};

export default memo(BibleModalWindowMobile);
