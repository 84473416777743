import React, { useCallback, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import HeaderLanguageSelector from "./header_language_selector";
import { HeaderMoreIcon as DefaultMoreIcon } from "../../../assets/icons/header_icons";
import { useUserDataContext } from "../../../../user_data_context";
import HeaderUserProfileButtons from "./header_user_profile_buttons";
import useWindowBreakpoint from "../../../hooks/use_window_breakpoint";
import HeaderAdminIcon from "../../../assets/icons/header_icons/header_admin_icon";
import { AdminButtonContainer } from "./header_styled_components";
import { APPLICATION_ROUTES } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";

export const MoreMenuButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
  user-select: none;
  color: ${({ isMoreMenuOpened }) =>
    isMoreMenuOpened ? "#1e1e1e" : " #8e8e8e"};

  ${({ isMoreMenuOpened }) =>
    isMoreMenuOpened &&
    `svg {
    fill: #1e1e1e;
  }`}

  @media (max-width: 1024px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

const MoreIcon = styled(DefaultMoreIcon)`
  fill: #8e8e8e;
`;

const MoreMenuWrapper = styled.div`
  position: relative;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MoreMenuContainer = styled.div`
  position: absolute;
  z-index: 999;
  left: 50%;
  bottom: calc(100% + 35px);
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 24px 20px;
  border-radius: 15px;

  @media (max-width: 1024px) {
    font-size: 24px;
    border-radius: 25px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;

const MoreMenuItem = styled.div`
  border-bottom: 1px solid rgb(202 202 202 / 52%);
  padding-bottom: 20px;
  cursor: pointer;

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }
`;

const HeaderMoreMenu = ({ unreadAnswersCount, showAdminButton }) => {
  const { t } = useTranslation();
  const moreMenuRef = useRef();
  const navigate = useNavigate();

  const { userData, userLanguage } = useUserDataContext();
  const [isMoreMenuOpened, setIsMoreMenuOpened] = useState(false);

  const isTablet = useWindowBreakpoint(1024);

  const adminPageIsOpen = window.location.pathname.includes("admin");

  const moreButtonClickHandler = useCallback(() => {
    setIsMoreMenuOpened((prevState) => !prevState);
  }, []);

  useOnclickOutside(() => setIsMoreMenuOpened(false), { refs: [moreMenuRef] });

  const onClickAdminButton = useCallback(
    (event) => {
      event.stopPropagation();
      if (!adminPageIsOpen) {
        navigate(APPLICATION_ROUTES.ADMIN);
      }
      setIsMoreMenuOpened(false);
    },
    [adminPageIsOpen]
  );

  return (
    <MoreMenuWrapper ref={moreMenuRef}>
      <MoreMenuButton
        isMoreMenuOpened={isMoreMenuOpened}
        onClick={moreButtonClickHandler}
      >
        <MoreIcon />
        {t("app-header.more-btn")}
      </MoreMenuButton>

      {isMoreMenuOpened && (
        <MoreMenuContainer>
          {showAdminButton && (
            <AdminButtonContainer onClick={onClickAdminButton}>
              <HeaderAdminIcon />
            </AdminButtonContainer>
          )}

          <HeaderUserProfileButtons
            userData={userData}
            unreadAnswersCount={unreadAnswersCount}
            isTablet={isTablet}
            setIsMoreMenuOpened={setIsMoreMenuOpened}
          />

          <MoreMenuItem>
            <HeaderLanguageSelector userLanguage={userLanguage} />
          </MoreMenuItem>
        </MoreMenuContainer>
      )}
    </MoreMenuWrapper>
  );
};
export default HeaderMoreMenu;
