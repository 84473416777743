import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "../../../../../axios_config";
import styled from "styled-components";
import { SearchIcon } from "../../../../../common/assets/icons";
import StaffListModalWindowItems from "./staff_list_modal_window_items";
import { BASE_API_URL } from "../../../../../common/endpoints";
import StaffListModalWindowButtons from "./staff_list_modal_window_buttons";

const StaffListModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const StaffListModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 390px;
  height: 450px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
`;

const SearchUsersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 20px 0;
  width: 100%;
  height: 50px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #cacaca;
  background: #fff;

  & > input {
    font-size: 16px;
    color: #1e1e1e;
    flex: 1;
    line-height: 24px;
  }
`;

const ButtonsContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`;

const StaffListModalWindow = ({
  openStaffListModal,
  courseSettingsData,
  setCourseSettingsData,
  setOpen,
}) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [searchUserInput, setSearchUserInput] = useState("");
  const [staffListByRole, setStaffListByRole] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const fetchUsers = useCallback(async () => {
    axios
      .get(BASE_API_URL + "/users")
      .then((response) => {
        const filteredUsers = response.data.filter((user) => {
          if (openStaffListModal === "authors") {
            return (
              user.user_name &&
              user.user_access.is_author &&
              !courseSettingsData.course_authors.find(
                (author) => author._id === user._id
              )
            );
          }
          if (openStaffListModal === "admins") {
            return (
              user.user_name &&
              (user.user_access.is_admin_of_courses ||
                (user.user_access.is_admin_of_platform &&
                  user.user_access.admin_of_platform.courses)) &&
              !courseSettingsData.course_admins.find(
                (admin) => admin._id === user._id
              )
            );
          }
          if (openStaffListModal === "feedback") {
            return (
              user.user_name &&
              !courseSettingsData.course_feedback_recipients.find(
                (recipient) => recipient._id === user._id
              )
            );
          }
        });

        setStaffListByRole(filteredUsers);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, [openStaffListModal, courseSettingsData]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const title =
    openStaffListModal === "authors"
      ? "Найти автора"
      : openStaffListModal === "admins"
      ? "Найти админа"
      : "Найти получателя обратной связи";

  const filterUsers = useCallback(
    (searchInput) => {
      const trimmedSearch = searchInput.trim().toLowerCase();

      if (!trimmedSearch) {
        setFilteredUsers(staffListByRole);
        return;
      }

      setFilteredUsers(
        staffListByRole.filter(({ user_name = "", user_second_name = "" }) =>
          [user_name, user_second_name]
            .map((name) => name.trim().toLowerCase())
            .some((name) => name.includes(trimmedSearch))
        )
      );
    },
    [staffListByRole]
  );

  useEffect(() => {
    if (staffListByRole.length) {
      filterUsers(searchUserInput);
    }
  }, [searchUserInput, staffListByRole]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen("");
  }, []);

  const handleSearchUserInput = useCallback((event) => {
    setSearchUserInput(event.target.value);
  }, []);

  const handleSelectButton = useCallback(() => {
    if (selectedUser._id) {
      if (openStaffListModal === "authors") {
        setCourseSettingsData((prevState) => ({
          ...prevState,
          course_authors: [
            { _id: selectedUser._id },
            ...prevState.course_authors,
          ],
        }));
      } else if (openStaffListModal === "admins") {
        setCourseSettingsData((prevState) => ({
          ...prevState,
          course_admins: [
            { _id: selectedUser._id },
            ...prevState.course_admins,
          ],
        }));
      } else if (openStaffListModal === "feedback") {
        setCourseSettingsData((prevState) => ({
          ...prevState,
          course_feedback_recipients: [
            { _id: selectedUser._id },
            ...prevState.course_feedback_recipients,
          ],
        }));
      }
      setOpen("");
    }
  }, [selectedUser, openStaffListModal]);

  return (
    <StaffListModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <StaffListModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <SearchUsersContainer>
          <SearchIcon />
          <input
            placeholder={title}
            type="text"
            value={searchUserInput}
            onChange={handleSearchUserInput}
          />
        </SearchUsersContainer>

        <StaffListModalWindowItems
          filteredUsers={filteredUsers}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />

        <ButtonsContainer>
          <StaffListModalWindowButtons
            handleClose={handleClose}
            onClickConfirmButton={handleSelectButton}
            isDisableConfirmButton={!selectedUser._id}
          />
        </ButtonsContainer>
      </StaffListModalWindowContainer>
    </StaffListModalWindowWrapper>
  );
};

export default memo(StaffListModalWindow);
