import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppProvider } from "./app_context";
import { UserDataProvider } from "./user_data_context";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserDataProvider>
    <AppProvider>
      <App />
    </AppProvider>
  </UserDataProvider>
);
