import React, { useCallback, useState } from "react";
import CheckIcon from "../../common/assets/icons/bible_modal_window_icons/check_icon";
import CancelIcon from "../../common/assets/icons/bible_modal_window_icons/cancel_icon";
import styled from "styled-components";

const InputControlButtonsContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 6px;
  z-index: 9;
  background: #fff;
`;

const InputControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 26px;
  border-radius: 8px;
  transition: 0.2s;
  opacity: 0.6;
`;

const CancelButton = styled(InputControlButton)`
  &:hover {
    opacity: 1;
  }
`;

const SaveButton = styled(InputControlButton)`
  opacity: ${({ isHoverOnCancel }) => (isHoverOnCancel ? "0.6" : "1")};
`;

const CancelIconStyled = styled(CancelIcon)`
  height: 18px;
  width: 18px;
`;

const CheckIconStyled = styled(CheckIcon)`
  height: 18px;
  width: 18px;
`;

const InputControlButtons = ({
  showSaveButton = true,
  onClickCancelButton,
  onClickSaveButton,
}) => {
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);

  const toggleHoverOnCancelButton = useCallback(() => {
    setIsHoverOnCancel((prevState) => !prevState);
  }, []);

  return (
    <InputControlButtonsContainer>
      <CancelButton
        onMouseEnter={toggleHoverOnCancelButton}
        onMouseLeave={toggleHoverOnCancelButton}
        onClick={onClickCancelButton}
      >
        <CancelIconStyled />
      </CancelButton>

      {showSaveButton && (
        <SaveButton
          isHoverOnCancel={isHoverOnCancel}
          onClick={onClickSaveButton}
        >
          <CheckIconStyled />
        </SaveButton>
      )}
    </InputControlButtonsContainer>
  );
};

export default InputControlButtons;
