import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { useTranslation } from "react-i18next";

const AfterFinishCourseModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const AfterFinishCourseModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 475px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0;
  }

  & > h5 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    line-height: 150%;
    width: 90%;
  }
`;

const AfterFinishCourseModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  user-select: none;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.2s;
  }
`;

const AfterFinishCourseModal = ({
  courseData,
  setCourseFinishModal,
  setCertificateProgressBarModal,
}) => {
  const { t } = useTranslation();

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setCourseFinishModal(false);
  }, []);

  const onClickGetCertificateButton = useCallback((e) => {
    e.stopPropagation();
    setCourseFinishModal(false);
    setCertificateProgressBarModal(true);
  }, []);

  return (
    <AfterFinishCourseModalWrapper onClick={handleClose}>
      <AfterFinishCourseModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h5>
          {t("lesson-page.course-success-modal.congratulations", {
            course_name: courseData.course_name,
          })}
        </h5>

        {courseData.course_certificate?.certificate_is_enabled ? (
          <AfterFinishCourseModalButton onClick={onClickGetCertificateButton}>
            {t("lesson-page.course-success-modal.buttons.get-certificate")}
          </AfterFinishCourseModalButton>
        ) : (
          <Link to={APPLICATION_ROUTES.HOME} style={{ width: "100%" }}>
            <AfterFinishCourseModalButton>
              {t("lesson-page.course-success-modal.buttons.to-home")}
            </AfterFinishCourseModalButton>
          </Link>
        )}
      </AfterFinishCourseModalContainer>
    </AfterFinishCourseModalWrapper>
  );
};

export default memo(AfterFinishCourseModal);
