import { useEffect, useRef } from "react";

export const insertUserNameInText = (text = "", userName) => {
  return text.replace(/\*ИМЯ/g, userName);
};

export const getUserNameInitials = (name = "", surname = "") => {
  const firstNameInitial = name.trim().charAt(0).toUpperCase();

  const lastNameInitial =
    surname.trim() && /^[a-zA-Zа-яА-ЯёЁ]/.test(surname.trim().charAt(0))
      ? surname.trim().charAt(0).toUpperCase()
      : "";

  return lastNameInitial
    ? `${firstNameInitial}${lastNameInitial}`
    : firstNameInitial;
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const getPluralForm = (count, wordForms) => {
  let remainder10 = count % 10;
  let remainder100 = count % 100;

  if (remainder100 >= 11 && remainder100 <= 14) {
    return `${count} ${wordForms[2]}`;
  } else if (remainder10 === 1) {
    return `${count} ${wordForms[0]}`;
  } else if (remainder10 >= 2 && remainder10 <= 4) {
    return `${count} ${wordForms[1]}`;
  } else {
    return `${count} ${wordForms[2]}`;
  }
};
