import React from "react";
import styled from "styled-components";
import { styleMap } from ".";

const HighlightedText = styled.span`
  position: relative;

  &::after {
    content: "...";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 9px;
    height: 9px;
    padding-right: 1px;
    padding-bottom: 6px;
    margin-left: 1px;
    margin-top: -10px;
    font-size: 8px;
    letter-spacing: -0.05px;
    border-radius: 50% 50% 50% 0;
    background-color: #0085ff !important;
    vertical-align: middle;
    cursor: pointer;
  }
`;

const DefinitionDecorator = ({ entityKey, children, contentState }) => {
  const entity = contentState.getEntity(entityKey);
  const { definitionId } = entity.getData();

  return (
    <HighlightedText
      data-definition-id={definitionId}
      style={styleMap.HIGHLIGHT}
    >
      {children}
    </HighlightedText>
  );
};

export default DefinitionDecorator;
