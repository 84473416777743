import React, { memo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import useWindowBreakpoint from "../../common/hooks/use_window_breakpoint";
import LightThemeIcon from "../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/light_theme_icon";
import DarkThemeIcon from "../../common/assets/icons/create_lesson_icons/lesson_preview_page_icons/dark_theme_icon";
import { useTranslation } from "react-i18next";

const LessonHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: ${({ background }) => background};
  position: fixed;
  z-index: 9999;
`;

const LessonHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
  max-width: 710px;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 80%;
  }

  @media (max-width: 830px) {
    max-width: 100%;
  }
`;

const LessonHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  height: 100%;
  width: 100%;
  margin: 0 12px;

  @media (max-width: 1024px) {
    gap: 35px;
  }

  @media (max-width: 900px) {
    gap: 20px;
  }

  @media (max-width: 744px) {
    gap: 15px;
  }

  @media (max-width: 500px) {
    gap: 5px;
  }
`;

const LessonHeaderTitle = styled.div`
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  color: #fff;

  padding: 2px 0;
  margin-right: 10px;

  @media (max-width: 730px) {
    font-size: 18px;
  }

  & > span {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const ColorThemeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  ${({ background }) => `background: ${background};`}

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.2s;
  }
`;

const SharedLessonHeader = ({
  lessonData,
  appColorTheme,
  setAppColorTheme,
}) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const lessonNumber = searchParams.get("n");

  const isTablet = useWindowBreakpoint(1024);

  const isTest =
    lessonData?.lesson_type === "test"
      ? true
      : lessonData?.lesson_type === "lesson"
      ? false
      : null;

  const headerBackground =
    appColorTheme === "DARK"
      ? "linear-gradient(180deg, rgba(81, 114, 234, 0.60) -18.38%, rgba(122, 90, 238, 0.60) 108.82%), #232323"
      : "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)";

  const handleLessonColorTheme = useCallback((event) => {
    event.stopPropagation();
    setAppColorTheme((prevState) => (prevState === "LIGHT" ? "DARK" : "LIGHT"));
  }, []);

  return (
    <LessonHeaderWrapper background={headerBackground}>
      <LessonHeaderContainer>
        <LessonHeaderContentWrapper>
          <LessonHeaderTitle>
            {!isTest && isTest !== null && (
              <>
                {!isTablet && t("lesson-page.header-lesson-label")}{" "}
                {lessonNumber}.{" "}
              </>
            )}
            <span>{lessonData.lesson_name}</span>
          </LessonHeaderTitle>

          <ColorThemeButton
            background={
              appColorTheme === "LIGHT"
                ? "linear-gradient(234deg, #9E00FF -17.21%, #63218E 36.71%, #55187C 52.72%, #301E46 103.33%, #1B1B1F 151.29%)"
                : "linear-gradient(50deg, #0C7CAC -11.7%, #0EC5ED 110.56%)"
            }
            onClick={handleLessonColorTheme}
          >
            {appColorTheme === "DARK" ? <LightThemeIcon /> : <DarkThemeIcon />}
          </ColorThemeButton>
        </LessonHeaderContentWrapper>
      </LessonHeaderContainer>
    </LessonHeaderWrapper>
  );
};

export default memo(SharedLessonHeader);
