import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import Article from "../../../common/components/article";
import { BASE_API_URL } from "../../../common/endpoints";
import SpinnerLoader from "../../../common/components/loaders/spinner_loader";
import { useTranslation } from "react-i18next";

const HomePageArticlesSectionContainer = styled.div`
  width: 100%;
  padding-bottom: 90px;
  margin-top: 107px;

  @media (max-width: 1124px) {
    padding: 0 20px 160px;
    margin-top: 50px;
  }

  @media (max-width: 940px) {
    padding-bottom: 110px;
  }

  @media (max-width: 880px) {
    padding-bottom: 90px;
  }

  @media (max-width: 744px) {
    padding: 0 12px 80px;
    margin-top: 20px;
  }
`;

const ArticleSectionTitle = styled.p`
  color: #1e1e1e;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.4px;

  @media (max-width: 744px) {
    font-size: 26px;
  }
`;

const ArticlesContainer = styled.div`
  margin: 35px auto 0;
  width: 100%;

  @media (max-width: 744px) {
    margin: 16px auto;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

const HomePageArticlesSection = ({ appColorTheme, setTextBibleModalData }) => {
  const { t } = useTranslation();

  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchArticlesData = () => {
    setIsLoading(true);
    axios
      .get(BASE_API_URL + "/articles")
      .then((response) => {
        const filteredArticles = response.data.filter(
          (article) => article.article_is_visible
        );
        const sortedArticles = filteredArticles
          .sort((a, b) => {
            return (
              new Date(b.article_created_at) - new Date(a.article_created_at)
            );
          })
          .sort((a, b) => {
            return b.article_is_pinned - a.article_is_pinned;
          });
        setArticles(sortedArticles);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchArticlesData();
  }, []);

  return (
    <HomePageArticlesSectionContainer>
      <ArticleSectionTitle>
        {t("home-page.articles-section-title")}
      </ArticleSectionTitle>
      {isLoading ? (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      ) : (
        <ArticlesContainer>
          <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 744: 2 }}>
            <Masonry columnsCount={2} gutter="20px">
              {articles.map((article, index) => (
                <Article
                  key={index + article._id}
                  article={article}
                  inComponent={"home_page"}
                  appColorTheme={appColorTheme}
                  setTextBibleModalData={setTextBibleModalData}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </ArticlesContainer>
      )}
    </HomePageArticlesSectionContainer>
  );
};

export default memo(HomePageArticlesSection);
