import React, { memo, useCallback } from "react";
import styled from "styled-components";
import GeneralIcon from "../../common/assets/icons/user_profile_page_icons/general_icon";
import SecurityIcon from "../../common/assets/icons/user_profile_page_icons/security_icon";
import StudyIcon from "../../common/assets/icons/user_profile_page_icons/study_icon";
import QuestionsIcon from "../../common/assets/icons/user_profile_page_icons/questions_icon";

const sidebarItems = [
  {
    icon: GeneralIcon,
    name: "general",
  },
  //   {
  //     icon: SecurityIcon,
  //     name: "security",
  //   },
  {
    icon: StudyIcon,
    name: "activity",
  },
  {
    icon: QuestionsIcon,
    name: "questions",
  },
];

const UserProfileSidebarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: max-content;
  width: max-content;
  margin: 0 auto;
  padding: 30px 0 30px 30px;

  @media (max-width: 855px) {
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #5172ea;
    padding: 20px 20px 13px;
  }
`;

const SidebarButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 12.273px;
  background: #fff;
  transition: 0.2s;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
      transform: scale(1.23);
      border-radius: 15.909px;
      box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  `}

  :hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 700px) {
    width: 46px;
    height: 46px;
    border-radius: 9.263px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  @media (max-width: 420px) {
    width: 38px;
    height: 38px;

    svg {
      width: 17px;
      height: 17px;
    }
  }
`;

const UnreadAnswersCount = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #f8d254;

  p {
    color: #000;
    font-size: 9px;
    line-height: 130%;
  }

  @media (max-width: 1024px) {
    top: -5px;
    right: -5px;
  }
`;

const UserProfilePageSidebar = ({
  activeProfileSection,
  setActiveProfileSection,
  unreadAnswersCount,
}) => {
  const handleActiveSection = useCallback((event, section) => {
    event.stopPropagation();
    setActiveProfileSection(section);
  }, []);

  return (
    <UserProfileSidebarContainer>
      {sidebarItems.map((item) => {
        const Icon = item.icon;

        return (
          <SidebarButton
            key={item.name}
            isActive={activeProfileSection === item.name}
            onClick={(e) => handleActiveSection(e, item.name)}
          >
            <Icon />
            {item.name === "questions" && unreadAnswersCount > 0 && (
              <UnreadAnswersCount>{unreadAnswersCount}</UnreadAnswersCount>
            )}
          </SidebarButton>
        );
      })}
    </UserProfileSidebarContainer>
  );
};

export default memo(UserProfilePageSidebar);
