import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchVerse from "../../bible_modal_window_desktop/bible_modal_search_results/search_verse";
import SpinnerLoader from "../../../../loaders/spinner_loader";
import { useTranslation } from "react-i18next";

const BiblePageSearchResultsContainer = styled.div`
  height: calc(100% - 52px);
  overflow: hidden;
`;

const SearchVersesResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding: 0 12px 100px;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eaeaea;
  }
`;

const EmptySearchResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 0 50px 0 40px;
  font-size: 16px;
  line-height: 140%;
  color: #383838;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const BibleModalSearchResultsMobile = ({
  currentTranslation,
  setCurrentBibleParams,
  setSelectedVerses,
  isLoading,
  bibleSearchResults,
  bibleSearchInput,
  setActiveScreenMobile,
  setSearchWords,
}) => {
  const { t } = useTranslation();

  const searchVersesResultsRef = useRef(null);

  const [visibleResultsCount, setVisibleResultsCount] = useState(20);

  const totalResults = bibleSearchResults?.searchResults?.length || 0;
  const visibleSearchResults =
    bibleSearchResults?.searchResults?.slice(0, visibleResultsCount) || [];

  useEffect(() => {
    if (searchVersesResultsRef.current) {
      searchVersesResultsRef.current.scrollTop = 0;
    }
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    setVisibleResultsCount(20);
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    const scrollContainer = searchVersesResultsRef.current;
    const handleScroll = () => {
      if (scrollContainer) {
        const scrolledHeight =
          scrollContainer.scrollTop + scrollContainer.clientHeight;
        const totalHeight = scrollContainer.scrollHeight;
        if (scrolledHeight >= totalHeight) {
          const newVisibleResults = visibleResultsCount + 20;
          if (newVisibleResults <= totalResults) {
            setVisibleResultsCount(newVisibleResults);
          }
        }
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleResultsCount, totalResults]);

  return (
    <BiblePageSearchResultsContainer>
      {!isLoading && !bibleSearchResults?.searchResults?.length && (
        <EmptySearchResult>
          <p>
            {t("bible-page.search.empty-result", {
              searchWord: bibleSearchResults.searchWord || bibleSearchInput,
            })}
          </p>
        </EmptySearchResult>
      )}

      {!isLoading ? (
        <SearchVersesResults ref={searchVersesResultsRef}>
          {visibleSearchResults?.map((verse) => (
            <SearchVerse
              key={verse._id}
              verse={verse}
              bibleSearchWord={bibleSearchResults.searchWord.trim()}
              currentTranslation={currentTranslation}
              setActiveScreenDesktop={setActiveScreenMobile}
              setCurrentBibleParams={setCurrentBibleParams}
              setSelectedVerses={setSelectedVerses}
              setSearchWords={setSearchWords}
            />
          ))}
        </SearchVersesResults>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}
    </BiblePageSearchResultsContainer>
  );
};

export default BibleModalSearchResultsMobile;
