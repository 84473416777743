import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../common/components/checkbox";
import AddBibleVerse from "../../../../../common/components/added_bible_verse";
import BibleModalWindow from "../../../../../common/components/modal_windows/bible_modal_window";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import ColorSelect from "../../../../../common/components/color_select";
import { LESSON_COLORS_THEME } from "../../../../../common/utils/enums";
import { createComponentId } from "../../../../../common/components/lesson/lesson_utils";

const BibleVersesSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 219px;
  width: 434px;
  padding: 26px 24px;
  max-height: 83vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const BibleVersesBorderColor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > h5 {
    color: #1e1e1e;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
`;

const SelectedVersesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const DEFAULT_SETTINGS_DATA_BIBLE_VERSES = {
  type: "BIBLE_VERSES",
  data: [1],
  is_group: false,
  color: "",
  id: "no_verse",
};

const BibleVersesSettings = ({
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockMemoData,
  setSettingsBlockData,
  setActiveSettingsBlock,
  onClickSaveButton,
}) => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [clickedButton, setClickedButton] = useState({});
  const [bibleVersesData, setBibleVersesData] = useState({});

  const bibleVersesBorderColor = settingsBlockData.color;
  const lastColor = localStorage.getItem("lastColor");

  const defaultBibleVersesData = {
    ...DEFAULT_SETTINGS_DATA_BIBLE_VERSES,
    id: createComponentId(),
    color: lastColor && lastColor !== "TRANSPARENT" ? lastColor : "BLUE",
  };

  useEffect(() => {
    if (editBlockIndex === null) {
      setSettingsBlockData(defaultBibleVersesData);
      setSettingsBlockMemoData(defaultBibleVersesData);
      setOpenBibleModalWindow(true);
      setClickedButton({ button: "address_button", verseId: "" });
    }
  }, [editBlockIndex]);

  useEffect(() => {
    if (!openBibleModalWindow && settingsBlockData.data?.[0] === 1) {
      setSettingsBlockData({});
      setSettingsBlockMemoData({});
      setActiveSettingsBlock("");
    }
  }, [openBibleModalWindow, settingsBlockData]);

  useEffect(() => {
    if (settingsBlockData.data?.length < 2) {
      setSettingsBlockData((prevState) => ({ ...prevState, is_group: false }));
    }
  }, [settingsBlockData.data]);

  const handleOpenBibleModalWindow = useCallback(() => {
    setOpenBibleModalWindow(true);
  }, []);

  const handleAddBibleVerses = useCallback(
    (addedVerse) => {
      const verseText =
        addedVerse.versesData.length > 1
          ? addedVerse.versesData
              .map((verse) => `${verse.verse_num}. ${verse.verse_text}`)
              .join("\n")
          : `«${addedVerse.versesData[0].verse_text}»`;

      if (clickedButton.button === "plus_button") {
        setSettingsBlockData((prevState) => {
          const notIsDublicate = !prevState.data.some(
            (verse) => verse.verseAddress === addedVerse.verseAddress
          );

          if (notIsDublicate) {
            return prevState.data[0] !== 1
              ? {
                  ...prevState,
                  data: [
                    ...prevState.data,
                    { ...addedVerse, verseText: verseText },
                  ],
                }
              : { ...prevState, data: [addedVerse] };
          } else return prevState;
        });
      }

      if (clickedButton.button === "address_button") {
        setSettingsBlockData((prevState) => {
          const updatedVerseData = { ...addedVerse, verseText };

          if (prevState.data[0] !== 1) {
            const updatedVerses = prevState.data.map((verse) =>
              verse.verseId === clickedButton.verseId ? updatedVerseData : verse
            );
            return { ...prevState, data: updatedVerses };
          } else return { ...prevState, data: [updatedVerseData] };
        });
      }
      setClickedButton({});
    },
    [clickedButton]
  );

  const handleRemoveVerse = (verseInfo) => {
    setSettingsBlockData((prevState) => {
      if (prevState.data.length !== 1) {
        const updatedVersesData = prevState.data.filter(
          (verse) => verse.verseId !== verseInfo.verseId
        );

        return {
          ...prevState,
          data: updatedVersesData,
        };
      } else return defaultBibleVersesData;
    });
  };

  const handleSelectBorderColor = useCallback((event, color) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      color,
    }));
  }, []);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      is_group: !prevState.is_group,
    }));
  }, []);

  const handleVersesInputValue = (event, verseIndex) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      data: prevState.data.map((verse, index) =>
        verseIndex === index
          ? { ...verse, verseText: event.target.value }
          : verse
      ),
    }));
  };

  return (
    <div>
      {settingsBlockData?.type === "BIBLE_VERSES" && (
        <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
          <BibleVersesSettingsContainer>
            <BibleVersesBorderColor>
              <h5>Цвет обводки</h5>
              <ColorSelect
                selectedColor={bibleVersesBorderColor}
                handleSelectColor={handleSelectBorderColor}
                colors={LESSON_COLORS_THEME["LIGHT"].COLORS}
              />
            </BibleVersesBorderColor>

            <SelectedVersesContainer>
              {settingsBlockData.data?.map((addedVerse, index, array) => {
                return (
                  <AddBibleVerse
                    key={addedVerse.id || "no_verse" + index}
                    verseInfo={addedVerse}
                    verseIndex={index}
                    handleVersesInputValue={handleVersesInputValue}
                    isLastElement={index === array.length - 1}
                    setBibleVersesData={setBibleVersesData}
                    handleRemoveVerse={handleRemoveVerse}
                    handleOpenBibleModalWindow={handleOpenBibleModalWindow}
                    setClickedButton={setClickedButton}
                    setSettingsBlockData={setSettingsBlockData}
                    showMinusButton={settingsBlockData.data?.length > 1}
                    inComponent="create_lesson"
                  />
                );
              })}
            </SelectedVersesContainer>

            {settingsBlockData.data?.length > 1 && (
              <Checkbox
                checked={settingsBlockData.is_group}
                onClickCheckbox={onClickCheckbox}
                fontSize={"14px"}
              >
                Группа
              </Checkbox>
            )}

            <CreateLessonSaveButton
              settingsBlockData={settingsBlockData}
              onClickSaveButton={onClickSaveButton}
              disabled={settingsBlockData.data?.[0] === 1}
            />
          </BibleVersesSettingsContainer>
        </SettingsBlockFixedWrapper>
      )}

      {openBibleModalWindow && (
        <BibleModalWindow
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          bibleVersesData={bibleVersesData}
          setBibleVersesData={setBibleVersesData}
          inComponent={"create_lesson-bible_settings"}
        />
      )}
    </div>
  );
};

export default memo(BibleVersesSettings);
