import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useUserDataContext } from "../../../user_data_context";
import { BASE_API_URL } from "../../../common/endpoints";
import SpinnerLoader from "../../../common/components/loaders/spinner_loader";
import AdminCoursePreview from "./admin_course_preview";
import AdminCourseModal from "./admin_course_modal";
import { ToastNotification } from "../../../common/components/toast_notification";

const AdminPageAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  h5 {
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const CourseListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  h6 {
    text-align: center;
  }
`;

const AdminPageAnalytics = () => {
  const navigate = useNavigate();
  const { userData, accessRights } = useUserDataContext();
  const [coursesList, setCoursesList] = useState([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);
  const [clickedCourseForModal, setClickedCourseForModal] = useState({});

  const handleOpenModal = (clickedCourse) => {
    setClickedCourseForModal(clickedCourse);
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setClickedCourseForModal({});
  };

  const hasAccess =
    accessRights.isSuperadmin || accessRights.isAdminOfPlatformAnalytics;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, userData]);

  if (!hasAccess) return;

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axios.get(BASE_API_URL + "/courses");
      setCoursesList(response.data);
    } catch (error) {
      console.error("Error on GET /courses:", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCourses(true);
      await fetchCourses();
      setIsLoadingCourses(false);
    };

    fetchData();
  }, [fetchCourses]);

  return (
    <AdminPageAnalyticsContainer>
      <h5>ANALYTICS ADMIN</h5>

      <CourseListContainer>
        <h6>Курсы:</h6>
        {!isLoadingCourses ? (
          coursesList.map((course) => {
            return (
              <AdminCoursePreview
                key={course._id}
                course={course}
                onClickedCourse={handleOpenModal}
              >
                {course.course_name}
              </AdminCoursePreview>
            );
          })
        ) : (
          <LoaderContainer>
            <SpinnerLoader />
          </LoaderContainer>
        )}
        {clickedCourseForModal._id && (
          <AdminCourseModal
            course={clickedCourseForModal}
            onClose={handleCloseModal}
          />
        )}
      </CourseListContainer>

      <ToastNotification />
    </AdminPageAnalyticsContainer>
  );
};

export default memo(AdminPageAnalytics);
