import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import ShareIcon from "../../../common/assets/icons/share_icon";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import LeftArrowMobileIcon from "../../../common/assets/icons/left_arrow_mobile_icon";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import CourseInfoAuthor from "../study_page_courses/course_card/course_author";

const CoursePageHeaderContainer = styled.div`
  max-width: 1100px;
  height: 240px;
  margin: 0 auto;
  border-radius: 0px 0px 25px 25px;
  background-image: url(${({ headerBackground }) => headerBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 542px) {
    width: 100%;
    border-radius: 0px;
  }

  @media (max-width: 412px) {
    max-width: 412px;
    width: 100%;
    height: 287px;
  }
`;

const CoursePageHeaderShadowWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 25px;
  border-radius: 0px 0px 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.67) 0%,
    rgba(0, 0, 0, 0.16) 75.44%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 590px) {
    padding-top: 50px;
  }

  @media (max-width: 542px) {
    border-radius: 0px;
  }

  @media (max-width: 412px) {
    padding: 60px 0 0 30px;
  }
`;

const CoursePageHeaderContent = styled.div`
  position: relative;
  width: 510px;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 514px) {
    width: 100%;
  }
`;

const CourseAuthorsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ShareCourseButton = styled.div`
  position: absolute;
  top: 7px;
  right: 0px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 590px) {
    top: -34px;
    right: 18px;
  }

  @media (max-width: 530px) {
    right: 28px;
  }

  @media (max-width: 412px) {
    top: -37px;
  }

  svg {
    width: 24px;
    height: 22px;
  }
`;

const ArrowsContainer = styled.div`
  position: absolute;
  top: 16px;
  left: -60px;
  cursor: pointer;
  padding: 6px 5px;
  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 665px) {
    left: -45px;
  }

  @media (max-width: 620px) {
    left: -25px;
  }

  @media (max-width: 590px) {
    top: -35px;
    left: 13px;
    padding: 3px;
  }

  @media (max-width: 514px) {
    left: 17px;
  }

  @media (max-width: 412px) {
    top: -39px;
    left: -3px;
  }
`;

const LeftArrowStyles = styled(LeftArrowIcon)`
  @media (max-width: 590px) {
    display: none;
  }
`;

const LeftArrowMobileStyles = styled(LeftArrowMobileIcon)`
  display: none;

  @media (max-width: 590px) {
    display: block;
  }
`;

const CoursePageHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 92%;
  height: 100%;
  padding-left: 20px;
  color: #ffffff;

  @media (max-width: 570px) {
    width: 97%;
  }

  @media (max-width: 412px) {
    padding-left: 0;
  }
`;

const CourseTopic = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 590px) {
    font-size: 22px;
    line-height: 120%;
  }

  @media (max-width: 412px) {
    font-size: 20px;
  }
`;

const CourseDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;

  @media (max-width: 590px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.1px;
  }

  @media (max-width: 412px) {
    font-size: 12px;
  }
`;

const StartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: max-content;
  padding: 0 22px;
  background: #f8d254;
  border-radius: 8px;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;

  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: "Raleway";

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 590px) {
    min-width: 120px;
    height: 33px;
    line-height: 140%;
  }
`;

const CoursePageHeader = ({
  courseData,
  inComponent,
  userCourse,
  authError,
  handleClickOnLesson,
  handleClickOnTest,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [allLessonsIsFinished, setAllLessonsIsFinished] = useState(null);

  const courseLessons = courseData?.course_lessons?.filter(
    (lesson) => lesson.lesson_type !== "test"
  );
  const courseTest =
    courseData?.course_lessons?.find(
      (lesson) => lesson.lesson_type === "test"
    ) || null;

  const userStudyLessons = userCourse?.course_lessons?.filter(
    (lesson) => lesson.lesson_type !== "test"
  );
  const userStudyTest =
    userCourse?.course_lessons?.find(
      (lesson) => lesson.lesson_type === "test"
    ) || null;

  const onArrowUrl =
    inComponent === "admin_page"
      ? APPLICATION_ROUTES.ADMIN_COURSES + "?courseId=" + courseData._id
      : APPLICATION_ROUTES.STUDY_COURSES;

  useEffect(() => {
    if (courseData?.course_lessons && userCourse?.course_lessons) {
      setAllLessonsIsFinished(
        courseData?.course_lessons?.length ===
          userCourse?.course_lessons.length &&
          userCourse?.course_lessons.every(
            (lesson) => lesson.lesson_status === "finished"
          )
      );
    } else {
      setAllLessonsIsFinished(false);
    }
  }, [courseData, userCourse]);

  const onClickStartButton = useCallback(
    (event) => {
      if (inComponent === "admin_page" || !courseData?.course_lessons?.length)
        return;

      if (authError) {
        navigate(APPLICATION_ROUTES.LOGIN);
        return;
      }

      if (!userCourse?.course_status) {
        const firstLessonId = courseLessons?.[0]?._id;

        handleClickOnLesson(event, firstLessonId, "next", 1);
        return;
      }

      if (userCourse?.course_status === "in_process") {
        const lessonInProcessIndex = userStudyLessons?.findIndex(
          (lesson) => lesson.lesson_status === "in_process"
        );

        if (lessonInProcessIndex >= 0) {
          const lessonId = userStudyLessons?.[lessonInProcessIndex]?._id;
          const lessonNum = lessonInProcessIndex + 1;

          navigate(
            `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
          );
        } else if (
          lessonInProcessIndex < 0 &&
          userStudyLessons.length !== courseLessons?.length
        ) {
          const lessonId = courseLessons?.[userStudyLessons?.length]?._id;

          const lessonNum =
            courseLessons?.findIndex((lesson) => lesson._id === lessonId) + 1;

          handleClickOnLesson(event, lessonId, "next", lessonNum);
        } else if (
          lessonInProcessIndex < 0 &&
          userStudyLessons?.length === courseLessons?.length
        ) {
          if (courseTest && !userStudyTest) {
            handleClickOnTest(event, courseTest._id, "next", null);
          } else if (courseTest && userStudyTest) {
            if (userStudyTest.lesson_status === "in_process") {
              handleClickOnTest(
                event,
                courseTest._id,
                "in_process",
                userStudyTest.user_study_id
              );
            } else {
              handleClickOnTest(
                event,
                courseTest._id,
                "finished",
                userStudyTest.user_study_id
              );
            }
          }
        }
      }
    },
    [
      inComponent,
      courseData,
      authError,
      userCourse,
      courseLessons,
      courseTest,
      userStudyLessons,
      userStudyTest,
      handleClickOnLesson,
      handleClickOnTest,
    ]
  );

  const showNotification = () => {
    toast(t("course-page.header-share-btn-notification"), {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    try {
      const isCopied = copy(text);
      if (isCopied) {
        showNotification();
      } else {
        console.error("Не удалось скопировать текст.");
        alert("Ошибка при копировании ссылки.");
      }
    } catch (error) {
      console.error("Ошибка копирования: ", error);
      alert("Ошибка при копировании ссылки.");
    }
  };

  const handleCopyCourseLink = useCallback(
    (event) => {
      event.stopPropagation();
      const courseLink = `${process.env.REACT_APP_BASE_URL}${APPLICATION_ROUTES.STUDY_COURSES}/${courseData._id}`;
      copyToClipboard(courseLink);
    },
    [courseData]
  );

  return (
    <CoursePageHeaderContainer
      headerBackground={courseData.course_header_image}
    >
      <CoursePageHeaderShadowWrapper>
        <CoursePageHeaderContent>
          <Link to={onArrowUrl}>
            <ArrowsContainer>
              <LeftArrowStyles />
              <LeftArrowMobileStyles />
            </ArrowsContainer>
          </Link>

          <ShareCourseButton onClick={handleCopyCourseLink}>
            <ShareIcon />
          </ShareCourseButton>

          <CoursePageHeaderInfo>
            <CourseTopic>{courseData.course_name}</CourseTopic>
            <CourseDescription>
              {courseData.course_description}
            </CourseDescription>

            <CourseAuthorsContainer>
              {courseData?.course_authors?.map((author, index) => {
                return (
                  <CourseInfoAuthor
                    key={"course_author-" + index}
                    authorId={author._id}
                  />
                );
              })}
            </CourseAuthorsContainer>

            {userCourse?.course_status !== "finished" &&
              !allLessonsIsFinished &&
              allLessonsIsFinished !== null && (
                <>
                  {(courseData.course_status === "open" ||
                    authError ||
                    inComponent === "admin_page") && (
                    <StartButton onClick={onClickStartButton}>
                      {userCourse?.course_status === "in_process"
                        ? t("course-page.header-btn.continue")
                        : t("course-page.header-btn.start")}
                    </StartButton>
                  )}

                  {/* {courseData.course_status === "announcement" && (
                    <StartButton>
                      {t("course-page.header-btn.notify")}
                    </StartButton>
                  )} */}
                </>
              )}
          </CoursePageHeaderInfo>
        </CoursePageHeaderContent>
      </CoursePageHeaderShadowWrapper>
    </CoursePageHeaderContainer>
  );
};

export default memo(CoursePageHeader);
