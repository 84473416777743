import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AddNewBlockDashedLineIcon from "../../../../common/assets/icons/create_lesson_icons/add_new_block_dashed_line_icon";
import AddNewBlockPlusIcon from "../../../../common/assets/icons/create_lesson_icons/add_new_block_plus_icon";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";
import CreateLessonSkeletComponent from "./create_lesson_skelet_component";
import { componentsIconsArray } from "../../../../common/components/lesson/lesson_utils";

const AddNewBlockContainer = styled.div`
  position: relative;
  ${({ showBlock }) =>
    showBlock
      ? `display: flex;
         max-height: 46px;
         padding: 22px 0;
       `
      : "display: none;"}
  align-items: center;
  justify-content: center;
  max-height: 46px;
  padding: 22px 0;
  color: #828282;
  opacity: ${({ activeBlock }) => (activeBlock ? 1 : 0)};

  &:hover {
    opacity: 1;
    transition: 0.2s;
  }
`;

const ComponentSelectionContainer = styled.div`
  display: flex;
  gap: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ComponentSelectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 9px;
  background: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
    transform: scale(1.2);
    transition: 0.2s;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const PlusButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  cursor: pointer;
`;

const CreateLessonAddNewBlock = ({
  userData,
  index,
  lessonData,
  lessonPreviewArray,
  settingsBlockData,
  activeSettingsBlock,
  activeAddNewBlockIndex,
  insertBlockIndex,
  setInsertBlockIndex,
  setActiveSettingsBlock,
  setActiveAddNewBlockIndex,
  setEditBlockIndex,
  settingsBlockMemoData,
  setOpenEditeComponentModal,
  setSettingsBlockMemoData,
  setSettingsBlockData,
  courseMainColor,
  courseMainGradient,
}) => {
  const [activeBlock, setActiveBlock] = useState(null);
  const [showPlusButtonBlock, setShowPlusButtonBlock] = useState(true);
  const addNewComponentRef = useRef(null);
  const skeletonRef = useRef(null);

  const showSkeleton =
    !!settingsBlockData.type &&
    insertBlockIndex !== null &&
    activeBlock === "skeleton";

  const onPlusButtonClick = useCallback(
    (event) => {
      event.stopPropagation();

      if (!showPlusButtonBlock) return;

      const anchorElementForInsertion = lessonPreviewArray[index - 1];

      if (compareObjects(settingsBlockMemoData, settingsBlockData)) {
        setActiveBlock("components");

        if (anchorElementForInsertion) {
          const anchorIndex =
            lessonData.lesson_data.findIndex(
              (item) => item.id === anchorElementForInsertion.id
            ) + 1;

          setInsertBlockIndex(anchorIndex);
        } else {
          setInsertBlockIndex(0);
        }

        setActiveAddNewBlockIndex(index);
        setEditBlockIndex(null);
        setSettingsBlockData({});
        setSettingsBlockMemoData({});
        setActiveSettingsBlock("");
        setOpenEditeComponentModal(false);
      } else {
        setOpenEditeComponentModal(true);
      }
    },
    [
      settingsBlockData,
      settingsBlockMemoData,
      lessonPreviewArray,
      index,
      lessonData.lesson_data,
    ]
  );

  const handleActiveSettingsBlock = useCallback((event, blockType) => {
    event.stopPropagation();
    setActiveSettingsBlock(blockType);
    setActiveBlock("skeleton");
  }, []);

  useEffect(() => {
    if (activeSettingsBlock && index === lessonPreviewArray.length - 1) {
      setShowPlusButtonBlock(false);
    } else setShowPlusButtonBlock(true);
  }, [activeSettingsBlock, index, lessonPreviewArray]);

  useEffect(() => {
    if (skeletonRef?.current && activeBlock && settingsBlockData.type) {
      skeletonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeBlock, skeletonRef, settingsBlockData.type]);

  useEffect(() => {
    if (insertBlockIndex === null) {
      setActiveBlock(null);
    }
  }, [insertBlockIndex]);

  useEffect(() => {
    if (activeAddNewBlockIndex !== index) {
      setActiveBlock(null);
    }
  }, [activeAddNewBlockIndex, index]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !activeSettingsBlock &&
        addNewComponentRef.current &&
        !addNewComponentRef.current.contains(event.target)
      ) {
        setActiveBlock(null);
      }
    },
    [activeSettingsBlock, addNewComponentRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div ref={addNewComponentRef}>
      <AddNewBlockContainer
        activeBlock={activeBlock}
        showBlock={showPlusButtonBlock}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={onPlusButtonClick}
      >
        {showPlusButtonBlock && (
          <>
            {activeBlock !== "skeleton" && <AddNewBlockDashedLineIcon />}

            {!activeBlock && (
              <PlusButton>
                <AddNewBlockPlusIcon />
              </PlusButton>
            )}

            {activeBlock === "components" && (
              <ComponentSelectionContainer>
                {componentsIconsArray().map((item, index) => {
                  const Icon = item.icon;
                  return (
                    <ComponentSelectionItem
                      key={index + item.type}
                      onClick={(e) => handleActiveSettingsBlock(e, item.type)}
                    >
                      <Icon />
                    </ComponentSelectionItem>
                  );
                })}
              </ComponentSelectionContainer>
            )}
          </>
        )}
      </AddNewBlockContainer>

      {showSkeleton && (
        <div ref={skeletonRef}>
          <CreateLessonSkeletComponent
            userData={userData}
            settingsBlockData={settingsBlockData}
            lastElement={lessonPreviewArray.length - 1 === index}
            courseMainColor={courseMainColor}
            courseMainGradient={courseMainGradient}
          />
        </div>
      )}
    </div>
  );
};

export default memo(CreateLessonAddNewBlock);
