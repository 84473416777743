import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Vimeo from "@vimeo/player";
import PlayVideoIcon from "../../../../assets/icons/create_lesson_icons/play_video_icon";

const VideoComponentPlayerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  height: ${({ videoWithTitle }) => (videoWithTitle ? "306px" : "330px")};
  border-radius: ${({ videoWithTitle }) => (videoWithTitle ? "8px" : "15px")};
  background: black;
`;

const PlayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ background }) => background};
  width: 61px;
  height: 61px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: 0.2s;

  &: hover {
    transform: translate(-50%, -50%) scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${({ videoWithTitle }) => (videoWithTitle ? "8px" : "15px")};
  object-fit: cover;
`;

const NoImage = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px;
  color: #383838;
  background: #fff;
  border-radius: ${({ videoWithTitle }) => (videoWithTitle ? "8px" : "15px")};
  object-fit: cover;
`;

const VideoComponentPlayer = ({
  componentData,
  backgroundColor,
  inComponent,
}) => {
  const { t } = useTranslation();

  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);

  const { with_title, image_url, video_url } = componentData;

  const getEmbedUrl = (url) => {
    if (!url) return null;

    // YouTube
    const youtubeMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
    );
    if (youtubeMatch)
      return `https://www.youtube.com/embed/${youtubeMatch[1]}?autoplay=1`;

    // Vimeo
    const vimeoMatch = url.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
    if (vimeoMatch)
      return `https://player.vimeo.com/video/${vimeoMatch[1]}?autoplay=1`;

    // Dailymotion
    const dailymotionMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?dailymotion\.com\/video\/([^_]+)/
    );
    if (dailymotionMatch) {
      return `https://www.dailymotion.com/embed/video/${dailymotionMatch[1]}?autoplay=1&mute=0&endscreen-enable=false&queue-enable=true`;
    }

    // Facebook
    const facebookMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?facebook\.com\/watch\/?\?v=([^&]+)/
    );
    if (facebookMatch)
      return `https://www.facebook.com/video/embed?video_id=${facebookMatch[1]}&autoplay=1`;

    // TikTok
    const tiktokMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@[^/]+\/video\/(\d+)/
    );
    if (tiktokMatch)
      return `https://www.tiktok.com/embed/${tiktokMatch[1]}?autoplay=1`;

    // Twitch
    const twitchMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?twitch\.tv\/videos\/(\d+)/
    );
    if (twitchMatch)
      return `https://player.twitch.tv/?video=${twitchMatch[1]}&parent=yourdomain.com&autoplay=1`;

    // Rumble
    const rumbleMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?rumble\.com\/(?:embed\/)?([a-zA-Z0-9]+)/
    );
    if (rumbleMatch)
      return `https://rumble.com/embed/${rumbleMatch[1]}/?autoplay=1`;

    // Direct video links
    if (url.match(/\.(mp4|webm|ogg)$/)) return url;

    return null; // Формат не поддерживается
  };

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  const handleClickOutside = (event) => {
    if (
      playerRef.current &&
      !playerRef.current.contains(event.target) &&
      inComponent === "create_lesson"
    ) {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (inComponent === "create_lesson") {
      if (isPlaying) {
        window.addEventListener("mousedown", handleClickOutside);
      } else {
        window.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isPlaying, inComponent]);

  const embedUrl = getEmbedUrl(video_url);

  useEffect(() => {
    if (video_url.includes("vimeo.com") && isPlaying) {
      const iframe = document.querySelector("iframe");
      if (iframe) {
        const vimeoInstance = new Vimeo(iframe);
        setVimeoPlayer(vimeoInstance);

        vimeoInstance.on("ended", () => {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          }
          setIsPlaying(false);
        });
      }
    }

    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off("ended");
      }
    };
  }, [video_url, isPlaying]);

  return (
    <VideoComponentPlayerContainer ref={playerRef} videoWithTitle={with_title}>
      {isPlaying ? (
        embedUrl ? (
          embedUrl.includes("youtube.com") ||
          embedUrl.includes("vimeo.com") ||
          embedUrl.includes("dailymotion.com") ||
          embedUrl.includes("facebook.com") ||
          embedUrl.includes("twitch.tv") ||
          embedUrl.includes("rumble.com") ? (
            <iframe
              ref={video_url.includes("vimeo.com") ? playerRef : null}
              width="100%"
              height="100%"
              src={embedUrl ? `${embedUrl}` : "#"}
              title="Video player"
              style={{
                border: "none",
                borderRadius: with_title ? "8px" : "15px",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              style={{
                border: "none",
                borderRadius: with_title ? "8px" : "15px",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
              }}
            >
              <source src={embedUrl} type="video/mp4" />
              {t("lesson-page.components.video.video-not-supported")}
            </video>
          )
        ) : (
          <NoImage videoWithTitle={with_title}>
            {t("lesson-page.components.video.wrong-video-or-format")}
          </NoImage>
        )
      ) : (
        <>
          {image_url ? (
            <ThumbnailImage
              src={image_url}
              alt="Video thumbnail"
              videoWithTitle={with_title}
            />
          ) : (
            <NoImage videoWithTitle={with_title}>
              {t("lesson-page.components.video.download-cover")}
            </NoImage>
          )}

          <PlayButton background={backgroundColor} onClick={handlePlayVideo}>
            <PlayVideoIcon />
          </PlayButton>
        </>
      )}
    </VideoComponentPlayerContainer>
  );
};

export default memo(VideoComponentPlayer);
