import React, { memo } from "react";
import styled from "styled-components";
import PlayIcon from "../../../../../common/assets/icons/play_icon";
import AdminPageInfoIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_info_icon";

const GroupPreviewContainer = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  height: 95px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  opacity: ${({ unselected }) => (unselected ? "0.5" : "1")};
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }
`;

const GroupImageContainer = styled.div`
  border-radius: 8px;
  min-width: 119px;
  max-width: 119px;
  height: 100%;
  background: rgb(195 194 248);
  overflow: hidden;
`;

const GroupImage = styled.img`
  width: 100%;
  height: 100%;
`;

const GroupButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  width: 70px;
`;

const GroupButton = styled.div`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.12);
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  width: 100%;
  height: 18px;
  padding-left: 2px;
`;

const GroupPreview = () => {
  const previewImage =
    "https://api.on-bible.com/uploads/ee1e3bb55d2a134c7dc6.jpg";

  return (
    <GroupPreviewContainer unselected={false}>
      <GroupImageContainer>
        {previewImage && <GroupImage src={previewImage} alt="Group Image" />}
      </GroupImageContainer>

      <GroupButtonsContainer>
        <GroupButton onClick={() => {}}>
          <AdminPageInfoIcon />
        </GroupButton>
        <GroupButton onClick={() => {}}>
          <PlayIconStyles color={"#F8D254"} />
        </GroupButton>
      </GroupButtonsContainer>
    </GroupPreviewContainer>
  );
};

export default memo(GroupPreview);
