import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import AdminPageInfoIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_info_icon";
import AdminPageCopyIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_copy_icon";
import PlayIcon from "../../../../common/assets/icons/play_icon";
import { BASE_API_URL } from "../../../../common/endpoints";
import { COURSE_COMPLEXITY_LEVELS } from "..";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertMinutesToHoursAndMinutes } from "../../../../common/utils/functions/time_functions";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";

const CoursePreviewContainer = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  height: 95px;
  max-width: 630px;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  opacity: ${({ unselected }) => (unselected ? "0.5" : "1")};
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }
`;

const CourseImageContainer = styled.div`
  border-radius: 8px;
  min-width: 119px;
  max-width: 119px;
  height: 71px;
  background: rgb(195 194 248);
  overflow: hidden;
`;

const CourseImage = styled.img`
  width: 100%;
  height: 100%;
`;

const CourseStatus = styled.div`
  position: absolute;
  top: 9px;
  left: 9px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${({ color }) => color};
`;

const CourseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 2px 0;
`;

const CourseTitle = styled.div`
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  max-width: 393px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CourseAuthors = styled.div`
  display: flex;
  gap: 15px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
`;

const AdditionalCourseInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AdditionalCourseInfoItem = styled.div`
  color: #cacaca;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.4px;
  user-select: none;

  span {
    color: #f8d254;
  }
`;

const CourseButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  width: 70px;
`;

const CourseButton = styled.div`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.12);
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  width: 100%;
  height: 18px;
  padding-left: 2px;
`;

const CoursePreview = ({
  course,
  courseSettingsData,
  allAuthorsList,
  courseSettingsMemoData,
  setActivePreviewsBlock,
  setCourseSettingsData,
  setCourseSettingsMemoData,
  setCourseInfoModalData,
  setOpenEditCourseModal,
  fetchCourses,
}) => {
  const navigate = useNavigate();
  const courseRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [authorsList, setAuthorsList] = useState("");

  const currentCourse = course._id === courseSettingsData._id;
  const courseInfo =
    course._id === courseSettingsData._id ? courseSettingsData : course;
  const unselected =
    courseSettingsData._id && courseSettingsData._id !== course._id;

  const courseTitle = courseInfo.course_name;
  const previewImage = courseInfo.course_preview_image;
  const courseAuthors = courseInfo.course_authors;
  const courseLevel = COURSE_COMPLEXITY_LEVELS[courseInfo.course_level] || "";
  const courseWithTest = courseInfo.course_certificate?.certificate_after_test;
  const courseLessonsCount =
    courseInfo.course_lessons?.filter((lesson) => lesson.lesson_type !== "test")
      ?.length || null;

  const duration = convertMinutesToHoursAndMinutes(
    courseInfo.course_duration_min
  );

  const courseStatusColor =
    courseInfo.course_status === "open"
      ? "#F12B2B"
      : courseInfo.course_status === "closed"
      ? "#A1A1A1"
      : "#141414";

  useEffect(() => {
    const authorNames = courseAuthors
      .map((courseAuthor) => {
        const author = allAuthorsList.find((a) => a._id === courseAuthor._id);
        if (author) {
          return `${author.user_name} ${author.user_second_name}`.trim();
        }
        return null;
      })
      .filter(Boolean)
      .join(", ");

    setAuthorsList(authorNames);
  }, [allAuthorsList, courseAuthors]);

  const onClickCoursePreview = useCallback(
    (event) => {
      event.stopPropagation();

      if (!currentCourse) {
        if (compareObjects(courseSettingsData, courseSettingsMemoData)) {
          searchParams.set("courseId", course._id);
          setSearchParams(searchParams);

          setCourseSettingsData(course);
          setCourseSettingsMemoData(course);
        } else {
          setOpenEditCourseModal(true);
        }
      }
    },
    [
      course,
      currentCourse,
      searchParams,
      courseSettingsData,
      courseSettingsMemoData,
    ]
  );

  const onDoubleClickCoursePreview = useCallback(
    (event) => {
      event.stopPropagation();

      if (
        compareObjects(courseSettingsData, courseSettingsMemoData) ||
        (!courseSettingsData.about_course?.blocks?.[0]?.text &&
          !courseSettingsMemoData.about_course?.blocks?.[0]?.text)
      ) {
        searchParams.set("lessons", true);
        setSearchParams(searchParams);

        setActivePreviewsBlock("lessons");
      } else {
        setOpenEditCourseModal(true);
      }
    },
    [searchParams, courseSettingsData, courseSettingsMemoData]
  );

  const onClickCourseInfoButton = useCallback(
    (event) => {
      event.stopPropagation();
      setCourseInfoModalData(courseInfo);
    },
    [courseInfo]
  );

  const onClickCopyCourseButton = useCallback(
    async (event) => {
      event.stopPropagation();

      try {
        const response = await axios.post(
          `${BASE_API_URL}/courses/${course._id}/copy`
        );

        if (response.data.course) {
          searchParams.set("courseId", response.data.course._id);
          setSearchParams(searchParams);

          fetchCourses();
        } else {
          console.error("Некорректный ответ от сервера:", response);
        }
      } catch (error) {
        console.error("Ошибка при копировании курса:", error);
      }
    },
    [course, searchParams, setSearchParams, fetchCourses]
  );

  const toCoursePreviewPage = useCallback(
    (event) => {
      event.stopPropagation();
      navigate(`${APPLICATION_ROUTES.ADMIN_COURSE_PREVIEW}/${courseInfo._id}`);
    },
    [courseInfo]
  );

  useEffect(() => {
    if (currentCourse && courseRef.current) {
      courseRef.current.scrollIntoView({ block: "center" });
    }
  }, []);

  return (
    <CoursePreviewContainer
      unselected={unselected}
      onClick={onClickCoursePreview}
      onDoubleClick={onDoubleClickCoursePreview}
      onMouseDown={(e) => e.stopPropagation()}
      ref={courseRef}
    >
      <CourseStatus color={courseStatusColor} />

      <CourseImageContainer>
        {previewImage && <CourseImage src={previewImage} alt="Course Image" />}
      </CourseImageContainer>

      <CourseInfoContainer>
        <CourseTitle>{courseTitle}</CourseTitle>
        <CourseAuthors>{authorsList}</CourseAuthors>
        <AdditionalCourseInfo>
          <AdditionalCourseInfoItem>
            Уровень: <span>{courseLevel}</span>
          </AdditionalCourseInfoItem>
          <AdditionalCourseInfoItem>
            Уроки:{" "}
            <span>
              {courseLessonsCount} {courseWithTest && "+ Тест"}
            </span>
          </AdditionalCourseInfoItem>

          <AdditionalCourseInfoItem>
            Время: {!!duration.hours && <span>{duration.hours}ч </span>}
            {!!duration.minutes && <span>{duration.minutes}мин</span>}
            {!duration.hours && !duration.minutes && <span> - </span>}
          </AdditionalCourseInfoItem>
        </AdditionalCourseInfo>
      </CourseInfoContainer>

      <CourseButtonsContainer>
        <CourseButton onClick={onClickCourseInfoButton}>
          <AdminPageInfoIcon />
        </CourseButton>
        <CourseButton onClick={onClickCopyCourseButton}>
          <AdminPageCopyIcon />
        </CourseButton>
        <CourseButton onClick={toCoursePreviewPage}>
          <PlayIconStyles color={"#F8D254"} />
        </CourseButton>
      </CourseButtonsContainer>
    </CoursePreviewContainer>
  );
};

export default memo(CoursePreview);
