import React, { useCallback } from "react";
import { toast, Slide } from "react-toastify";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import { BiblePageSidebarItem } from "./";
import BiblePageShareIcon from "../../../common/assets/icons/bible_page_icons/bible_page_share_icon";

const BiblePageSidebarShare = ({ currentBibleScreen }) => {
  const { t } = useTranslation();

  const showNotification = () => {
    toast(t("bible-page.sidebar.share.notification"), {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const handleShareLink = useCallback(() => {
    if (currentBibleScreen === "bible_chapter") {
      const url = window.location.href;
      try {
        const isCopied = copy(url);
        if (isCopied) {
          showNotification();
        } else {
          console.error(t("bible-page.sidebar.share.copy-link-error"));
          alert(t("bible-page.sidebar.share.copy-link-error"));
        }
      } catch (error) {
        console.error(t("bible-page.sidebar.share.copy-link-error"), error);
        alert(t("bible-page.sidebar.share.copy-link-error"));
      }
    }
  }, [currentBibleScreen]);

  return (
    <BiblePageSidebarItem onClick={handleShareLink}>
      <BiblePageShareIcon />
      <p>{t("bible-page.sidebar.share.title")}</p>
    </BiblePageSidebarItem>
  );
};

export default BiblePageSidebarShare;
