import React, { memo, useCallback } from "react";
import styled from "styled-components";
import ThreeDotsIcon from "../../../../../common/assets/icons/three_dots_icon";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionsContainer,
} from "../../admin_questions_styled_components";
import Checkbox from "../../../../../common/components/checkbox";

const MoreSettingsContainer = styled(QuestionsFilterOptionsContainer)`
  width: max-content;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 638px) {
    left: 0;
    transform: none;
  }
`;

const ThreeDotsIconStyled = styled(ThreeDotsIcon)`
  height: 32px;
  gap: 3px;
`;

const QuestionsMoreSettings = ({
  extendedOption,
  handleExtendOptions,
  setShowUsersName,
  showUsersName,
  questionsFilterSettings,
  setQuestionsFilterSettings,
}) => {
  const byFavorite = questionsFilterSettings.question_by_favorite;

  const handleShowUsersName = useCallback(
    (event) => {
      event.stopPropagation();

      setShowUsersName((prevState) => {
        const newState = !prevState;
        localStorage.setItem("showUsersName", JSON.stringify(newState));
        return newState;
      });
    },
    [setShowUsersName]
  );

  const handleShowFavoritedQuestions = useCallback(
    (event) => {
      event.stopPropagation();

      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_by_favorite: !prevState.question_by_favorite,
      }));
    },
    [setQuestionsFilterSettings]
  );

  return (
    <QuestionsFilterOption
      type="status"
      isExtended={extendedOption === "more"}
      onClick={(e) => handleExtendOptions(e, "more")}
    >
      <ThreeDotsIconStyled color={"#383838"} />

      {extendedOption === "more" && (
        <MoreSettingsContainer
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Checkbox
            checked={showUsersName}
            onClickCheckbox={handleShowUsersName}
            color={showUsersName ? "#1e1e1e" : "#828282"}
            fontSize={"14px"}
            letterSpacing={"0.4px"}
          >
            С именами
          </Checkbox>
          <Checkbox
            checked={byFavorite}
            onClickCheckbox={handleShowFavoritedQuestions}
            color={byFavorite ? "#1e1e1e" : "#828282"}
            fontSize={"14px"}
            letterSpacing={"0.4px"}
          >
            Избранные ↓
          </Checkbox>
        </MoreSettingsContainer>
      )}
    </QuestionsFilterOption>
  );
};

export default memo(QuestionsMoreSettings);
