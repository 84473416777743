import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SettingsBlockFixedWrapper } from "../../../create_lesson_components/create_lesson_styled_components";
import CreateLessonSaveButton from "../../../create_lesson_components/create_lesson_save_button";
import Checkbox from "../../../../../../common/components/checkbox";
import TextSettingsImage from "./text_settings_image";
import TextBackgrounds from "./text_backgrounds";
import TextEditor from "../../../../../../common/components/text_editor";
import { createComponentId } from "../../../../../../common/components/lesson/lesson_utils";

const TextSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  min-height: 200px;
  padding: 26px 24px;
  max-height: 83vh;
  overflow: auto;
  margin-right: -60px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const DEFAULT_SETTINGS_DATA_TEXT = {
  type: "TEXT",
  contentState: {},
  is_group: false,
  background: "TRANSPARENT",
  with_image: false,
  image_url: "",
  vocabulary_keys: [],
  id: "",
};

const TextSettings = ({
  settingsBlockData,
  editBlockIndex,
  lessonData,
  setSettingsBlockData,
  settingsBlockMemoData,
  setSettingsBlockMemoData,
  setLessonData,
  onClickSaveButton,
}) => {
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [showGroupCheckbox, setShowGroupCheckbox] = useState(true);

  const isCurrentImage =
    settingsBlockData.image_url === settingsBlockMemoData.image_url;

  const onEditorChange = useCallback(
    (updatedContent) => {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        contentState: updatedContent,
      }));
    },
    [setSettingsBlockData]
  );

  useEffect(() => {
    if (settingsBlockData.background !== "TRANSPARENT") {
      setShowGroupCheckbox(false);
      setSettingsBlockData((prevState) => ({
        ...prevState,
        is_group: false,
      }));
    } else {
      setShowGroupCheckbox(true);
    }
  }, [settingsBlockData.background]);

  useEffect(() => {
    if (editBlockIndex === null) {
      const defaultTextData = {
        ...DEFAULT_SETTINGS_DATA_TEXT,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultTextData);
      setSettingsBlockMemoData(defaultTextData);
    }
  }, [editBlockIndex]);

  useEffect(() => {
    const blocks = settingsBlockData.contentState?.blocks;

    if (blocks) {
      const allBlocksEmpty = blocks.every((block) => !block.text.trim().length);
      setDisabledSaveButton(allBlocksEmpty);
    }
  }, [settingsBlockData]);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      is_group: !prevState.is_group,
    }));
  }, []);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <TextSettingsContainer>
        <TextEditor
          key={settingsBlockData.id}
          contentStateData={settingsBlockData.contentState}
          inComponent={"text_component"}
          withBackground={settingsBlockData.background !== "TRANSPARENT"}
          isEdite={editBlockIndex !== null}
          lessonData={lessonData}
          setLessonData={setLessonData}
          setSettingsBlockData={setSettingsBlockData}
          onEditorChange={onEditorChange}
        />

        {showGroupCheckbox && (
          <Checkbox
            checked={settingsBlockData.is_group}
            onClickCheckbox={onClickCheckbox}
            fontSize={"14px"}
          >
            Группа
          </Checkbox>
        )}

        <TextSettingsImage
          isCurrentImage={isCurrentImage}
          settingsBlockData={settingsBlockData}
          setSettingsBlockData={setSettingsBlockData}
          setDisabledSaveButton={setDisabledSaveButton}
        />

        <TextBackgrounds
          settingsBlockData={settingsBlockData}
          setSettingsBlockData={setSettingsBlockData}
        />

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </TextSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(TextSettings);
