import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { bibleVersesWithoutTags } from "../../../utils/functions/bible_page_functions";
import { BASE_API_URL } from "../../../endpoints";
import SpinnerLoader from "../../loaders/spinner_loader";
import BibleVerse from "./bible_verse";
import VerticalLine from "../../../assets/icons/vertical_line";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../pages/bible/bible_page_data";
import RemoveIcon from "../../../assets/icons/remove_icon";
import BibleChapterModalWindowSidebar from "../bible_modal_window_sidebar";
import BibleChapterModalHeader from "./bible_chapter_modal_header";

const BibleChapterModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
`;

const BibleChapterModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  width: 500px;
  height: 670px;
  max-height: 98vh;
  padding: 18px;
  border-radius: 25px;
  background: #eaeaea;

  @media (max-width: 774px) {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    border-radius: 0px;
    padding: 10px;
  }
`;

const BibleChapterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 12px 1px 0 12px;
  border-radius: 15px;
  border: 2px solid var(--solid-colors-white, #fff);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const BibleVerses = styled.div`
  padding-bottom: 50px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

const CloseTranslationButton = styled.div`
  display: none;

  @media (max-width: 774px) {
    position: absolute;
    top: 24px;
    right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 30px;
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 16px;
  height: 16px;
`;

const BibleChapterModalWindow = ({
  bibleModalData,
  setOpen,
  inComponent = "",
  bibleLocale = "ru",
  setBibleVersesData = () => {},
  setOpenBibleModalWindow = () => {},
}) => {
  const [currentBibleParams, setCurrentBibleParams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [openCopyModal, setOpenCopyModal] = useState(false);

  useEffect(() => {
    if (Object.values(bibleModalData)) {
      setCurrentBibleParams(bibleModalData);
    }
  }, [bibleModalData]);

  useEffect(() => {
    if (!chapterData?.length && !currentBibleParams?.selectedVerses) return;

    if (+currentBibleParams.chapterId !== +bibleModalData.chapterId) {
      return;
    }

    if (inComponent === "bible_search") {
      const verse = chapterData.filter(
        (verse) => verse.verse_num === currentBibleParams?.selectedVerses
      );
      setSelectedVerses(verse);
    } else {
      const selectedNums = currentBibleParams.selectedVerses
        ?.split(",")
        ?.map((num) => +num);
      const verses = chapterData.filter((verse) =>
        selectedNums.includes(verse.verse_num)
      );
      setSelectedVerses(verses);
    }
  }, [currentBibleParams?.selectedVerses, chapterData]);

  const fetchChapter = useCallback(async ({ bibleId, bookId, chapterId }) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const parsedChapter = response.data.verses.map((verse) => ({
        ...verse,
        verse_text: bibleVersesWithoutTags(verse.verse_text),
      }));

      setChapterData(parsedChapter);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentBibleParams) {
      fetchChapter(currentBibleParams);
    }
  }, [currentBibleParams]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen({});
  }, []);

  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape" && !openCopyModal) {
        handleClose(e);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [openCopyModal]);

  return (
    <BibleChapterModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <BibleChapterModalWindowContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <BibleChapterContainer>
          <BibleChapterModalHeader
            chapterData={chapterData}
            bibleLocale={bibleLocale}
            currentBibleParams={currentBibleParams}
            inComponent={inComponent}
            selectedVerses={selectedVerses}
            setBibleVersesData={setBibleVersesData}
            setCurrentBibleParams={setCurrentBibleParams}
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            setSelectedVerses={setSelectedVerses}
            setOpen={setOpen}
          />

          {!isLoading ? (
            <BibleVerses>
              {chapterData.map((verse) => (
                <BibleVerse
                  key={verse._id}
                  verse={verse}
                  inComponent={inComponent}
                  selectedVerses={selectedVerses}
                  searchWords={currentBibleParams?.searchWord}
                  setSelectedVerses={setSelectedVerses}
                />
              ))}
            </BibleVerses>
          ) : (
            <LoaderContainer>
              <SpinnerLoader />
            </LoaderContainer>
          )}
        </BibleChapterContainer>

        <CloseTranslationButton onClick={handleClose}>
          <RemoveIconStyled color="#383838" />
        </CloseTranslationButton>

        {!!selectedVerses.length && (
          <BibleChapterModalWindowSidebar
            selectedVerses={selectedVerses}
            bibleLocale={bibleLocale}
            openCopyModal={openCopyModal}
            setOpenCopyModal={setOpenCopyModal}
          />
        )}
      </BibleChapterModalWindowContainer>
    </BibleChapterModalWindowWrapper>
  );
};

export default memo(BibleChapterModalWindow);
