import React from "react";

const DeleteQuestionAnswerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 4H3.33333H14"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6654 3.99967V13.333C12.6654 13.6866 12.5249 14.0258 12.2748 14.2758C12.0248 14.5259 11.6857 14.6663 11.332 14.6663H4.66536C4.31174 14.6663 3.9726 14.5259 3.72256 14.2758C3.47251 14.0258 3.33203 13.6866 3.33203 13.333V3.99967M5.33203 3.99967V2.66634C5.33203 2.31272 5.47251 1.97358 5.72256 1.72353C5.9726 1.47348 6.31174 1.33301 6.66536 1.33301H9.33203C9.68565 1.33301 10.0248 1.47348 10.2748 1.72353C10.5249 1.97358 10.6654 2.31272 10.6654 2.66634V3.99967"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteQuestionAnswerIcon;
