import React, { createContext, useContext, useState } from "react";

const TextSettingsBibleContext = createContext();

export const useTextSettingsBibleState = () => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  return {
    openBibleModalWindow,
    setOpenBibleModalWindow,
    bibleVersesData,
    setBibleVersesData,
  };
};

export const TextSettingsBibleProvider = ({ children }) => {
  const {
    openBibleModalWindow,
    setOpenBibleModalWindow,
    bibleVersesData,
    setBibleVersesData,
  } = useTextSettingsBibleState();

  return (
    <TextSettingsBibleContext.Provider
      value={{
        openBibleModalWindow,
        setOpenBibleModalWindow,
        bibleVersesData,
        setBibleVersesData,
      }}
    >
      {children}
    </TextSettingsBibleContext.Provider>
  );
};

export const useTextSettingsBibleContext = () => {
  return useContext(TextSettingsBibleContext);
};
