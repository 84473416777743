import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../../../common/assets/icons";

const QuestionsFilterSearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 12px 0 12px;
  border-radius: 8px;
  background: #fff;
  min-width: 38px;
  height: 38px;
  ${({ showSearchInput }) => showSearchInput && "flex: 1;"}
  ${({ showSearchInput }) => !showSearchInput && "cursor: pointer;"}
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }
`;

const QuestionsFilterSearchInput = styled.input`
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
  flex: 1;
`;

const QuestionsFilterSearchIcon = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;

  ${({ showSearchInput }) =>
    showSearchInput &&
    `
      &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
    `}
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 14px;
  height: 14px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -13px;
  left: 11px;
  color: red;
  font-size: 10px;
`;

const QuestionsFilterBySearchWords = ({
  setQuestionsFilterSettings,
  showSearchInput,
  setShowSearchInput,
}) => {
  const searchInputRef = useRef(null);

  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchError, setSearchError] = useState(false);

  useEffect(() => {
    if (showSearchInput && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearchInput]);

  const handleSearchInputValue = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  const onFilterBySearchWords = useCallback(() => {
    if (!showSearchInput) return;

    if (searchInputValue.length > 2) {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        search_words: [...prevState.search_words, searchInputValue],
      }));
      setSearchInputValue("");
    } else {
      setSearchError(true);
      setTimeout(() => {
        setSearchError(false);
      }, 2000);
    }
  }, [searchInputValue, showSearchInput]);

  const onClickOpenSearchInput = useCallback(() => {
    if (!showSearchInput) {
      setShowSearchInput(true);
    }
  }, [showSearchInput]);

  return (
    <QuestionsFilterSearchContainer
      showSearchInput={showSearchInput}
      onClick={onClickOpenSearchInput}
    >
      {showSearchInput && (
        <QuestionsFilterSearchInput
          ref={searchInputRef}
          placeholder="Поиск"
          value={searchInputValue}
          onChange={handleSearchInputValue}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onFilterBySearchWords(event);
            }
          }}
        />
      )}
      <QuestionsFilterSearchIcon
        showSearchInput={showSearchInput}
        onClick={onFilterBySearchWords}
      >
        <SearchIconStyled />
      </QuestionsFilterSearchIcon>
      {!!searchError && (
        <ErrorMessage>Необходимо ввести не менее 3х букв</ErrorMessage>
      )}
    </QuestionsFilterSearchContainer>
  );
};

export default QuestionsFilterBySearchWords;
