import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { getFormattedDate } from "../../../../common/utils/functions/time_functions";
import { COURSE_CERTIFICATE_IMAGES } from "../../../../common/utils/enums";
import signatureImg from "../../../../common/assets/images/course_certificate_images/signature_small.png";
import CertificateLineSmallIcon from "../../../../common/assets/icons/course_certificate_icons/certificate_line_small_icon";

const CertificateModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const CertificateModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: max-content;
  background: #eaeaea;
  padding: 60px;
  border-radius: 25px;
`;

const CertificatePreviewWrapper = styled.div`
  width: 350px;
  border-radius: 14.664px;
  overflow: hidden;
  box-shadow: 0px 2.737px 30.1px -8px rgba(0, 0, 0, 0.47);
`;

const CertificatePreviewContainer = styled.div`
  width: 100%;
  padding: 40px 30px 15px 40px;
  background: #fff;
`;

const CertificateHeader = styled.div`
  margin-bottom: 49px;

  h6 {
    color: #373962;
    font-family: Ysabeau Infant;
    font-size: 13px;
    font-weight: 600;
    line-height: 100%;
  }

  p {
    margin-top: -2px;
    color: #373962;
    font-family: Ysabeau Infant;
    font-size: 10px;
    font-weight: 400;
    line-height: 130%;
  }
`;

const CertificateRecipientContainer = styled.div`
  h6 {
    color: #373962;
    font-family: Montserrat;
    font-size: 6.454px;
    font-weight: 600;
    line-height: 130%;
  }
`;

const CourseStudentName = styled.div`
  margin-top: 6px;
  margin-bottom: 40px;
  width: max-content;
  border: 1px solid #373962;
  padding: 10px 32px;
  color: #373962;
  font-family: Montserrat;
  font-size: 19.948px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

const CourseTitleContainer = styled.div`
  h6 {
    color: #373962;
    font-family: Montserrat;
    font-size: 7px;
    font-weight: 600;
    line-height: 130%;
  }
`;

const CourseTitle = styled.p`
  margin-top: 3px;
  color: #373962;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
`;

const CourseСonclusion = styled.div`
  display: flex;
  align-items: center;
  height: 80px;

  p {
    margin-top: -15px;
    color: #373962;
    font-family: Montserrat;
    font-size: 7px;
    font-weight: 600;
    line-height: 130%;
  }
`;

const SignatureAndDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-left: -35px;
    margin-top: -18px;
  }

  svg {
    margin-left: -10px;
    margin-top: -7px;
  }

  span {
    margin-top: 2px;
    margin-left: 20px;
    color: #373962;
    font-family: Montserrat;
    font-size: 7.439px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 2px;
    color: #373962;
    font-family: Montserrat;
    font-size: 7.439px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const CourseFinishDate = styled.p`
  margin-top: -2px;
  padding-bottom: 2px;
  color: #373962;
  font-family: Montserrat;
  font-size: 8.214px;
  font-weight: 600;
  line-height: 140%;
`;

const CertificateFooterContainer = styled.div`
  width: 350px;
  height: 121px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const AdminCourseCertificateModal = ({
  courseSettingsData,
  courseStudentName,
  setOpenViewCertificateModal,
}) => {
  const currentDate = getFormattedDate(new Date().toISOString());

  const certificateFooterImage =
    COURSE_CERTIFICATE_IMAGES?.[
      courseSettingsData.course_certificate?.certificate_image
    ]?.largeImage;

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpenViewCertificateModal(false);
  }, []);

  return (
    <CertificateModalWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <CertificateModalContainer onClick={(e) => e.stopPropagation()}>
        <CertificatePreviewWrapper>
          <CertificatePreviewContainer>
            <CertificateHeader>
              <h6>СЕРТИФИКАТ</h6> <p>о прохождении</p>
            </CertificateHeader>

            <CertificateRecipientContainer>
              <h6>С ЛЮБОВЬЮ ПРЕДОСТАВЛЕН ДЛЯ:</h6>
              <CourseStudentName>{courseStudentName}</CourseStudentName>
            </CertificateRecipientContainer>

            <CourseTitleContainer>
              <h6>Который успешно прошел курс:</h6>
              <CourseTitle>{courseSettingsData.course_name}</CourseTitle>
            </CourseTitleContainer>

            <CourseСonclusion>
              <p>
                {courseSettingsData.course_certificate.certificate_description}
              </p>
            </CourseСonclusion>

            <SignatureAndDateContainer>
              <SignatureContainer>
                <img src={signatureImg} alt="certificate signature" />
                <CertificateLineSmallIcon />
                <span>подпись</span>
              </SignatureContainer>

              <DateContainer>
                <CourseFinishDate>{currentDate}</CourseFinishDate>
                <CertificateLineSmallIcon />
                <span>дата</span>
              </DateContainer>
            </SignatureAndDateContainer>
          </CertificatePreviewContainer>

          <CertificateFooterContainer
            backgroundImage={certificateFooterImage}
          ></CertificateFooterContainer>
        </CertificatePreviewWrapper>
      </CertificateModalContainer>
    </CertificateModalWrapper>
  );
};

export default memo(AdminCourseCertificateModal);
