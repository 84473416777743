import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import CourseActivity from "../../../common/components/user_activity/course";
import { useTranslation } from "react-i18next";
import LessonIsFinishedModalWindow from "../../study/course_page/lesson_is_finished_modal_window";

const UserProfileActivityContainer = styled.div`
  max-width: 440px;
  margin: 0 auto;
`;

const UserActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 100px;
`;

const EmptyActivity = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 15px;
  margin-top: 50px;
  border-radius: 8px;
  text-align: center;
  user-select: none;
`;

const UserProfileActivity = ({ userData }) => {
  const { t } = useTranslation();

  const [lessonIsFinishedModalData, setLessonIsFinishedModalData] =
    useState(null);

  const userActivity = userData?.user_activity;

  useEffect(() => {
    localStorage.setItem("lastProfileTab", "activity");
  }, []);

  return (
    <UserProfileActivityContainer>
      <UserActivityList>
        {userActivity?.length > 0 ? (
          <>
            {userActivity.map((activityItemInfo) => {
              if (activityItemInfo.type === "course") {
                return (
                  <CourseActivity
                    key={activityItemInfo.course_id}
                    userCourse={activityItemInfo}
                    userData={userData}
                    inComponent={"user_profile"}
                    setLessonIsFinishedModalData={setLessonIsFinishedModalData}
                  />
                );
              }
            })}
          </>
        ) : (
          <EmptyActivity>
            {t("user-profile-page.activity.empty-activity")}...
          </EmptyActivity>
        )}
      </UserActivityList>

      {lessonIsFinishedModalData && (
        <LessonIsFinishedModalWindow
          userData={userData}
          lessonIsFinishedModalData={lessonIsFinishedModalData}
          setLessonIsFinishedModalData={setLessonIsFinishedModalData}
        />
      )}
    </UserProfileActivityContainer>
  );
};

export default memo(UserProfileActivity);
