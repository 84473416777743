import React, {
  useCallback,
  useEffect,
  useRef,
  memo,
  useState,
  useMemo,
} from "react";
import styled from "styled-components";
import {
  extractingTextOfBibleVerses,
  highlightWords,
} from "../../../../../utils/functions/bible_page_functions";

const VerseContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 12px;
  border-radius: 5px;
  margin-right: 8px;
  background: ${({ isSelected }) => (isSelected ? "#cacaca" : "none")};
  cursor: ${({ inComponent }) =>
    inComponent !== "simple_modal" ? "pointer" : "default"};
  user-select: none;

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? "#cacaca" : "rgb(223 223 223)"};
    transition: 0.2s;
  }

  @media (max-width: 744px) {
    padding: 0 6px;
  }
`;

const VerseTextContainer = styled.div`
  flex: 1;
  height: 100%;
`;

const VerseText = styled.div`
  & > span {
    font-family: ${({ fontFamily }) => fontFamily} !important;
    font-size: ${({ fontSize }) => fontSize}px;
    line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
    color: #1e1e1e;
  }
`;

const VerseNumber = styled.div`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
  color: #1e1e1e;
`;

const BibleModalWindowVerse = ({
  verse,
  bibleFontSettings,
  selectedVerses,
  searchWords,
  setSelectedVerses,
  inComponent = "",
}) => {
  if (!verse.verse_num) return;

  const verseRef = useRef(null);

  const [verseIsSelected, setVerseIsSelected] = useState(false);

  const isFirstSelectedVerse =
    +verse.verse_num === +selectedVerses?.[0]?.verse_num;

  const renderedVerseText = useMemo(() => {
    return searchWords
      ? highlightWords(
          extractingTextOfBibleVerses(verse.verse_text),
          searchWords
        )
      : verse.verse_text;
  }, [verse.verse_text, searchWords]);

  useEffect(() => {
    const includes = selectedVerses?.filter(
      (obj) => obj.verse_num === verse.verse_num
    ).length;

    if (includes) {
      setVerseIsSelected(true);
    } else setVerseIsSelected(false);
  }, [selectedVerses, verse]);

  const handleSelectVerses = useCallback(
    (event) => {
      event.stopPropagation();

      if (inComponent !== "simple_modal") {
        if (selectedVerses?.some((v) => v.verse_num === verse.verse_num)) {
          const updatedVerses = selectedVerses.filter(
            (v) => v.verse_num !== verse.verse_num
          );
          setSelectedVerses(updatedVerses);
        } else {
          const updatedVerses = selectedVerses
            ? [...selectedVerses, verse]
            : [verse];
          setSelectedVerses(updatedVerses);
        }
      }
    },
    [selectedVerses, verse, inComponent, setSelectedVerses]
  );

  useEffect(() => {
    if (isFirstSelectedVerse && verseRef.current) {
      verseRef.current.scrollIntoView({ block: "start" });
    }
  }, []);

  return (
    <VerseContainer
      ref={verseRef}
      isSelected={verseIsSelected}
      inComponent={inComponent}
      onClick={handleSelectVerses}
    >
      <VerseNumber
        fontFamily={bibleFontSettings.fontVariant}
        fontSize={bibleFontSettings.fontSize}
      >
        {verse.verse_num}.
      </VerseNumber>
      <VerseTextContainer>
        <VerseText
          fontFamily={bibleFontSettings.fontVariant}
          fontSize={bibleFontSettings.fontSize}
        >
          {renderedVerseText}
        </VerseText>
      </VerseTextContainer>
    </VerseContainer>
  );
};

export default memo(BibleModalWindowVerse);
