import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LeftArrowIcon from "../../../../../common/assets/icons/left_arrow_icon";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";
import { SearchIcon } from "../../../../../common/assets/icons";
import BibleSearchSettingsIcon from "../../../../../common/assets/icons/bible_page_icons/bible_search_settings_icon";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";

const BibleSearchHeaderContainer = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
  padding: 14px 14px 0;
  max-width: 100%;
`;

const BackToChapterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  height: 38px;
  width: 42px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
`;

const LeftArrowIconStyled = styled(LeftArrowIcon)`
  height: 16px;
  width: 16px;
`;

const BibleSearchInputContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  min-height: 38px;
  max-height: 38px;
  padding-left: 14px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  ${({ inputIsEmpty }) => inputIsEmpty && "border: 1px solid red;"}
`;

const BibleSearchInput = styled.input`
  color: #1e1e1e;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.14px;
  height: 100%;
  width: 82%;
`;

const SearchIconContainer = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;
  padding: 0 13px 0 8px;
  transition: 0.2s;
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 16px;
  height: 16px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 14px;
  height: 14px;
`;

const SearchSettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 36px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
`;

const BiblePageSearchInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 12px;
`;

const BibleAndTestamentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 12px;
  min-width: 215px;
  width: max-content;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const BibleSearchAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const BibleSearchTestament = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const BiblePageSearchResultCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.1px;
  color: #1e1e1e;
`;

const VerticalLineStyled = styled(VerticalLine)`
  margin-top: 1px;
`;

const BibleSearchHeaderMobile = ({
  bibleSearchInput,
  inputIsEmpty,
  showedInputIcon,
  setBibleSearchInput,
  setShowedInputIcon,
  setActiveScreenMobile,
  setShowBibleSearchSettings,
  setBibleSearchPlace,
  onClearSearchSettings,
  onSearchSubmit,
  backScreen,
  bibleSearchResults,
  bibleSearchTranslate,
}) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const searchTestament =
    bibleSearchResults.searchTestament === "ot"
      ? t("bible-page.place.old-testaments")
      : bibleSearchResults.searchTestament === "nt"
      ? t("bible-page.place.new-testaments")
      : t("bible-page.place.whole-bible");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onClickBackButton = useCallback(
    (event) => {
      event.stopPropagation();
      setActiveScreenMobile(backScreen);
      onClearSearchSettings();
      setBibleSearchPlace("");
    },
    [onClearSearchSettings, backScreen]
  );

  const onRemoveIconClick = useCallback(
    (event) => {
      event.stopPropagation();
      setShowedInputIcon("search_icon");
      onClearSearchSettings();
    },
    [onClearSearchSettings]
  );

  const handleSearchInput = useCallback((event) => {
    setBibleSearchInput(event.target.value);
  }, []);

  const handleShowSearchSettings = useCallback((event) => {
    event.stopPropagation();
    setShowBibleSearchSettings((prevState) => !prevState);
  }, []);

  return (
    <div>
      <BibleSearchHeaderContainer>
        <BackToChapterButton onClick={onClickBackButton}>
          <LeftArrowIconStyled color="#383838" />
        </BackToChapterButton>

        <BibleSearchInputContainer
          inputIsEmpty={inputIsEmpty}
          onSubmit={onSearchSubmit}
        >
          <BibleSearchInput
            ref={inputRef}
            placeholder={t("bible-page.search.search-placeholder")}
            value={bibleSearchInput}
            onChange={handleSearchInput}
          />

          {showedInputIcon === "search_icon" && (
            <SearchIconContainer type="submit">
              <SearchIconStyled />
            </SearchIconContainer>
          )}

          {showedInputIcon === "remove_icon" && (
            <SearchIconContainer onClick={onRemoveIconClick} type="button">
              <RemoveIconStyled color="#383838" />
            </SearchIconContainer>
          )}
        </BibleSearchInputContainer>

        <SearchSettingsButton onClick={handleShowSearchSettings}>
          <BibleSearchSettingsIcon />
        </SearchSettingsButton>
      </BibleSearchHeaderContainer>

      <BiblePageSearchInfoContainer>
        {bibleSearchTranslate && (
          <BibleAndTestamentContainer>
            <BibleSearchAbbreviation>
              {bibleSearchTranslate}
            </BibleSearchAbbreviation>
            <VerticalLineStyled height="13px" />
            <BibleSearchTestament>{searchTestament}</BibleSearchTestament>
          </BibleAndTestamentContainer>
        )}

        {bibleSearchResults?.searchWord?.trim() && (
          <BiblePageSearchResultCount>
            {t("bible-page.search.count-found")}:{" "}
            {bibleSearchResults?.searchResults?.length || 0}
          </BiblePageSearchResultCount>
        )}
      </BiblePageSearchInfoContainer>
    </div>
  );
};

export default memo(BibleSearchHeaderMobile);
