import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatMessages from "./chat_messages";
import ChatHeader from "./chat_header";
import ChatInput from "./chat_input";
import { useAppContext } from "../../../../app_context";
import { ChatContentProvider } from "./chat_content_context";
import ChatBlockingNotice from "./chat_blocking_notice";

const ChatContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 30px;

  @media (max-width: 500px) {
    height: 100vh;
    padding-bottom: 5px;
  }
`;

const ChatContent = ({
  chatInfo,
  messages,
  setSelectedChatId,
  windowWidth,
}) => {
  const { setShowAppHeader } = useAppContext();
  const [showOnlyFavoriteMessages, setShowOnlyFavoriteMessages] =
    useState(false);

  const messagesForShow = !showOnlyFavoriteMessages
    ? messages
    : messages.filter((message) => message.favorite);

  useEffect(() => {
    if (windowWidth < 500) {
      setShowAppHeader(false);
    }

    return () => {
      setShowAppHeader(true);
    };
  }, [windowWidth]);

  return (
    <ChatContentProvider>
      <ChatContentContainer>
        <ChatHeader
          onBackClick={setSelectedChatId}
          chatTitle={chatInfo.title}
          windowWidth={windowWidth}
          setShowOnlyFavoriteMessages={setShowOnlyFavoriteMessages}
          showOnlyFavoriteMessages={showOnlyFavoriteMessages}
        />
        <ChatMessages messages={messagesForShow} />
        {!chatInfo.blockedFromWriting ? (
          <ChatInput key={chatInfo.id} chatId={chatInfo.id} />
        ) : (
          <ChatBlockingNotice timeLeft={chatInfo.blockedFromWritingTime} />
        )}
      </ChatContentContainer>
    </ChatContentProvider>
  );
};

export default ChatContent;
