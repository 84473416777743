import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import BibleModalWindowVerse from "../bible_modal_window_desktop/bible_modal_window_verses/bible_modal_window_verse";
import VersesLoader from "../../../../../pages/bible/bible_page_desktop/bible_chapter_desktop/verses_loader";
import { BASE_API_URL } from "../../../../endpoints";
import BibileModalHeader from "./bible_modal_header";
import BibleModalChapterArrows from "./bible_modal_chapter_arrows";
import { useBibleModalWindowContext } from "../bible_modal_window_context";
import { modalTransformTextWithStrongCode } from "../bible_modal_window_desktop/bible_modal_window_verses";
import BibleStrongModalWindow from "../../bible_strong_modal_window";
import { createPortal } from "react-dom";
import BibleModalWindowSidebar from "../../bible_modal_window_sidebar";
import { extractingTextOfBibleVerses } from "../../../../utils/functions/bible_page_functions";

const BibleVersesMobileContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const VersesContainer = styled.div`
  height: calc(100% - 54px);
  padding-bottom: 100px;
  padding-left: 10px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const VerseError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BibleVersesMobile = ({
  bibleFontSettings,
  inComponent,
  selectedVerses,
  setSelectedVerses,
  currentTranslation,
  currentTranslationData,
  currentBibleParams,
  searchWords,
  setCurrentBibleParams,
  setActiveScreenMobile,
  handleCloseBibleModalWindow,
}) => {
  const versesRef = useRef(null);

  const {
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  } = useBibleModalWindowContext();

  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [versesError, setVersesError] = useState(null);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [selectedVersesData, setSelectedVersesData] = useState([]);

  const { bibleId, chapterId, bookId } = currentBibleParams;

  useEffect(() => {
    if (chapterData) {
      setCurrentTestament(chapterData?.[0]?.book_place);
    }
  }, [chapterData]);

  useEffect(() => {
    if (chapterData.length && selectedVerses.length) {
      const selectedVersesData = chapterData
        .filter((verse) =>
          selectedVerses.some(
            (selected) => selected.verse_num === verse.verse_num
          )
        )
        .map((verse) => {
          const isString = (value) => typeof value === "string";

          return {
            ...verse,
            verse_text: isString(verse.verse_text)
              ? verse.verse_text
              : extractingTextOfBibleVerses(verse.verse_text),
          };
        });

      setSelectedVersesData(selectedVersesData);
    }
  }, [chapterData, selectedVerses]);

  const fetchChapterData = async (bibleId, bookId, chapterId) => {
    try {
      const { data } = await axios.get(`${BASE_API_URL}/bible/verses`, {
        params: {
          bible: bibleId,
          book: bookId,
          chapter: chapterId,
        },
      });

      if (data.verses) {
        const parsedChapter = data.verses.map((verse) => ({
          ...verse,
          verse_text: modalTransformTextWithStrongCode(verse.verse_text),
        }));

        return { data: parsedChapter, error: null };
      } else {
        return {
          data: [],
          error: "Данной книги/стиха нет в выбранном переводе",
        };
      }
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      return { data: [], error: "Произошла ошибка при загрузке данных" };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (bibleId && chapterId && bookId) {
        setIsLoading(true);
        try {
          const { data, error } = await fetchChapterData(
            bibleId,
            bookId,
            chapterId
          );
          setChapterData(data);
          setVersesError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [bibleId, chapterId, bookId]);

  return (
    <BibleVersesMobileContainer>
      <BibileModalHeader
        chapterData={chapterData}
        setActiveScreenMobile={setActiveScreenMobile}
        currentBibleParams={currentBibleParams}
        currentTranslation={currentTranslation}
        inComponent={inComponent}
        handleCloseBibleModalWindow={handleCloseBibleModalWindow}
      />
      <VersesContainer
        ref={versesRef}
        size={bibleFontSettings?.fontSize}
        font={bibleFontSettings?.fontVariant}
      >
        {isLoading ? (
          <VersesLoader bibleFontSize={bibleFontSettings?.fontSize} />
        ) : (
          <div>
            <VerseError>{versesError}</VerseError>

            {chapterData?.map((verse) => (
              <BibleModalWindowVerse
                key={verse._id}
                verse={verse}
                bibleFontSettings={bibleFontSettings}
                selectedVerses={selectedVerses}
                setSelectedVerses={setSelectedVerses}
                searchWords={searchWords}
                inComponent={inComponent}
              />
            ))}
          </div>
        )}
      </VersesContainer>

      <BibleModalChapterArrows
        currentBibleParams={currentBibleParams}
        setCurrentBibleParams={setCurrentBibleParams}
        currentTranslationData={currentTranslationData}
      />

      {!!strongsCodeModalData &&
        currentTranslation?.bible_strong &&
        createPortal(
          <BibleStrongModalWindow
            strongsCodeModalData={strongsCodeModalData}
            currentTranslation={currentTranslation}
            currentTestament={currentTestament}
            setOpen={setStrongsCodeModalData}
            setShowStrongsCode={setShowStrongsCode}
          />,
          document.body
        )}

      {inComponent === "search_modal" && !!selectedVerses.length && (
        <BibleModalWindowSidebar
          selectedVerses={selectedVersesData}
          bibleLocale={currentTranslation?.bible_locale}
          openCopyModal={openCopyModal}
          setOpenCopyModal={setOpenCopyModal}
          inComponent={inComponent}
        />
      )}
    </BibleVersesMobileContainer>
  );
};

export default memo(BibleVersesMobile);
