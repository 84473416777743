import { EditorState } from "draft-js";
import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import TextEditor from ".";
import VerticalLine from "../../assets/icons/vertical_line";

const TextDefinitionModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const TextDefinitionModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 500px;
  padding: 20px;
  border-radius: 25px;
  background: #eaeaea;

  & > h3 {
    font-size: 16px;
    color: #828282;
  }

  @media (max-width: 500px) {
    width: 100%;
    padding: 16px;
    margin: 10px;
    border-radius: 15px;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ showDeleteButton }) => (showDeleteButton ? "32%" : "49%")};
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: ${({ showDeleteButton }) => (showDeleteButton ? "33%" : "51%")};
  }
`;

const SaveButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const DeleteButton = styled(SaveButton)``;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSave }) =>
    isHoverOnSave ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const TextDefinitionModal = ({
  definitionEditorState,
  showDeleteButton,
  onSave,
  onDelete,
  setTextDefinitionModal,
  setDefinitionEditorState,
}) => {
  const [isHoverOnSave, setIsHoverOnSave] = useState(false);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setTextDefinitionModal(false);
  }, []);

  const onEditorChange = (updatedEditorState) => {
    setDefinitionEditorState(updatedEditorState);
  };

  const toggleHoverOnSaveButton = useCallback(() => {
    setIsHoverOnSave((prevState) => !prevState);
  }, []);

  return (
    <TextDefinitionModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <TextDefinitionModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h3>
          {!showDeleteButton ? "Ввести определение" : "Изменить определение"}
        </h3>

        <TextEditor
          key={"modal_text_editor"}
          placeholder={"Напишите определение..."}
          contentStateData={definitionEditorState}
          inComponent={"modal_definition"}
          onEditorChange={onEditorChange}
        />

        <ModalButtonsContainer>
          <SaveButton
            showDeleteButton={showDeleteButton}
            onMouseEnter={toggleHoverOnSaveButton}
            onMouseLeave={toggleHoverOnSaveButton}
            onClick={onSave}
          >
            Сохранить
          </SaveButton>
          {showDeleteButton && (
            <>
              <VerticalLineStyled color={"#5172EA"} />
              <DeleteButton
                showDeleteButton={showDeleteButton}
                onMouseEnter={toggleHoverOnSaveButton}
                onMouseLeave={toggleHoverOnSaveButton}
                onClick={onDelete}
              >
                Удалить
              </DeleteButton>
            </>
          )}
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton
            showDeleteButton={showDeleteButton}
            isHoverOnSave={isHoverOnSave}
            onClick={handleClose}
          >
            Отмена
          </CancelButton>
        </ModalButtonsContainer>
      </TextDefinitionModalContainer>
    </TextDefinitionModalWrapper>
  );
};

export default memo(TextDefinitionModal);
