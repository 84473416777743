import React, { useCallback } from "react";
import styled from "styled-components";
import { Slide, toast } from "react-toastify";
import copy from "copy-to-clipboard";
import AdminPageCopyIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_copy_icon";

const CopyQuestionContainer = styled.div`
  ${({ unselected }) =>
    !unselected &&
    `
   cursor: pointer;
   transition: 0.2s;

   &:hover {
     transform: scale(1.12);
  }
`}
`;

const CopyUserQuestion = ({ question, unselected }) => {
  const showNotification = () => {
    toast("Вопрос скопирован!", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    try {
      const isCopied = copy(text);
      if (isCopied) {
        showNotification();
      } else {
        console.error("Не удалось скопировать.");
        alert("Ошибка при копировании");
      }
    } catch (error) {
      console.error("Ошибка копирования: ", error);
      alert("Ошибка при копировании");
    }
  };

  const handleCopyQuestion = useCallback(
    (event) => {
      if (!unselected) {
        event.stopPropagation();

        const verseAddressesString = question.question_bible_verses
          .map((v) => `(${v.verseAddress})`)
          .join(", ");

        copyToClipboard(`«${question.question_text}»\n${verseAddressesString}`);
      }
    },
    [question, unselected]
  );

  return (
    <CopyQuestionContainer
      unselected={unselected}
      onClick={handleCopyQuestion}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <AdminPageCopyIcon />
    </CopyQuestionContainer>
  );
};

export default CopyUserQuestion;
