import styled from "styled-components";

export const QuestionsFilterOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  height: 38px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 20px;
  user-select: none;
  transition: 0.2s;

  ${({ isExtended, type }) =>
    !isExtended
      ? `&:hover {
          box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
        }`
      : `
          z-index: 100000;
          ${type !== "bible" && type !== "date" && "position: relative;"}
        `}
`;

export const QuestionsFilterOptionArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;

  ${({ isExtended }) =>
    isExtended &&
    `
  padding-bottom: 3px;
  transform: rotate(180deg);
  `}
`;

export const QuestionsFilterOptionsContainer = styled.div`
  position: absolute;
  top: 42px;
  left: 0px;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  z-index: 1000000;
  cursor: default;
`;

export const QuestionsFilterOptionItem = styled.div`
  position: relative;
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;
  min-width: max-content;
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    background: #e0e0fb;
  }
`;
