import React, { memo } from "react";
import styled from "styled-components";
import { UserInfoSectionContainer } from ".";

export const UserInformationItemContainer = styled.div`
  & > p {
    color: #828282;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.1px;
    padding-bottom: 4px;
  }
`;

const UserInfoItemsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

export const UserInformationItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 15px;
  min-height: 40px;
  max-width: 100%;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  background: #fff;
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const UserInformationSection = ({ selectedUserInfo }) => {
  return (
    <UserInfoSectionContainer>
      Информация
      <UserInfoItemsContainer>
        <UserInformationItem>{selectedUserInfo.user_name}</UserInformationItem>
        <UserInformationItem>
          {selectedUserInfo.user_second_name}
        </UserInformationItem>
      </UserInfoItemsContainer>
      <UserInformationItemContainer>
        <p>Контакты</p>
        <UserInformationItem>{selectedUserInfo.user_phone}</UserInformationItem>
      </UserInformationItemContainer>
      <UserInformationItemContainer>
        <p>Почта</p>
        <UserInformationItem>{selectedUserInfo.user_email}</UserInformationItem>
      </UserInformationItemContainer>
      <UserInformationItemContainer>
        <p>Местоположение</p>
        <UserInfoItemsContainer>
          <UserInformationItem>
            {selectedUserInfo.user_city}
          </UserInformationItem>
          <UserInformationItem>
            {selectedUserInfo.user_country}
          </UserInformationItem>
        </UserInfoItemsContainer>
      </UserInformationItemContainer>
      {/* <UserInformationItemContainer>
        <p>Религия-конфессия</p>
        <UserInformationItem>
          {selectedUserInfo.user_religion}
        </UserInformationItem>
      </UserInformationItemContainer> */}
    </UserInfoSectionContainer>
  );
};

export default memo(UserInformationSection);
